<template>
    <div v-if="weapon !== undefined">

        <!--View Files-->
        <FileViewer2 :flag="fileViewerFlag" :fileId="fileViewStorageId" :closeMethod="closeViewModal"/>

        <!--Edit Weapon-->
        <b-modal class="modal-dark" scrollable title="Weapon" v-model="weaponInfo.postPut"
                 no-close-on-backdrop>
            <b-input-group class="input-group-sm" prepend="Source" style="margin-bottom: 1px">
                <b-form-input
                    :class=" 'form-control is-' + (filledOut(weaponInfo.data.source) ? 'valid':'invalid')"
                    v-model="weaponInfo.data.source"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Description" style="margin-bottom: 1px">
                <b-form-input
                    :class=" 'form-control is-' + (filledOut(weaponInfo.data.description) ? 'valid':'invalid')"
                    v-model="weaponInfo.data.description"/>
            </b-input-group>
            <br>
            <b-form-group>
                <label>Other Notes</label>&nbsp;
                <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(weaponInfo.data.notes, {text: ''})"/>
                <b-input-group :key="i" class="input-group-sm" v-for="(e, i) in weaponInfo.data.notes">
                    <b-form-input v-model="weaponInfo.data.notes[i].text"/>
                    <b-input-group-append>
                        <b-button @click="splice(i, weaponInfo.data.notes)" variant="dark">
                            <fa-icon :icon="['fas', 'close']"/>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="weaponInfo.postPut = false" size="sm" variant="secondary">Cancel
                </b-button>
                <b-button :disabled="disableWeaponsSubmit()" @click="putWeapons()" size="sm"
                          variant="primary">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <!--Delete Weapon-->
        <b-modal class="modal-dark" scrollable title="Remove Weapon" v-model="deleteObjects.modal" no-close-on-backdrop>
            <p>If you would like to delete this {{ deleteObjects.name }} please type the prompt below.</p>
            <br>
            <p style="margin-bottom: 0">Enter "{{ deleteObjects.name }}":</p>
            <b-form-group description="case sensitive">
                <b-form-input id="basicName2" type="text" v-model="deleteObjects.deleteText"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="deleteObjects.modal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button :disabled="deleteObjects.deleteText !== deleteObjects.name || disableDeleteFlag"
                          @click="deleteWeapon()"
                          size="sm" variant="danger">
                    Delete {{ deleteObjects.name }}
                </b-button>
            </template>
        </b-modal>

        <!--Main Body-->
        <b-card no-body style="border-color: #0e0e0e;background-color: #202020">
        <span class="d-flex w-100 justify-content-between"
              style="background-color: #0E0E0E; padding: 0 5px 0 5px; border-radius: 3px 3px 0 0">
            <b style="font-size: medium">Weapon #{{ index + 1 }}</b>
            <span v-if="editable === true">
                    <fa-icon :icon="['fas', 'pen-to-square']" @click="openModalEditWeapons(weapon)" style="cursor: pointer;"/>&nbsp;
                    <fa-icon :icon="['fas', 'close']" @click="openModalDeleteObject('Weapon', weapon.weaponId)" size="lg"
                             style="cursor: pointer; transform: translateY(1px);"/>
            </span>
        </span>
            <b-row style="margin: 0; padding: 0">
                <b-col class="d-flex flex-column" style="margin: 0; padding: 0"
                       :xl="weapon.documents && weapon.documents.length > 0 ? 7 : 12">
                    <div style="margin: 5px">
                        <div><strong>Description: </strong>{{ weapon.description }}</div>
                        <div><strong>Source: </strong>{{ weapon.source }}</div>
                    </div>
                </b-col>
                <b-col style="margin: 0; padding: 0" xl="5"
                       class="d-flex justify-content-center"
                       v-if="weapon.documents && weapon.documents.length > 0">
                <span
                    style="width: auto; max-height: 100px; margin: 5px; border: 2px solid #23282c; border-radius: 5px; background-color: #0E0E0E;"
                    class="image-parent">
                    <img class="img-center-ass"
                         style="border-radius: 5px;"
                         v-if="weapon.documents && weapon.documents.length > 0"
                         v-auth-image="getImage(weapon.documents[0].storageFileName)"
                         @click="openViewModal(weapon.documents[0].storageFileName)"
                         alt=""/>
                    <img v-else class="img-center-ass"
                         style="border-radius: 5px;"
                         src="/NoImageAvailable.png"
                         alt="">
                </span>
                </b-col>
            </b-row>
            <div v-if="noteExpand === true" style="padding: 5px">
                <div style="padding: 10px">
                    <NotesCardComponent :notes="weapon.notes" :scope="scope"/>
                    <br>
                    <DocumentCardComponent :editable="editable"
                                           :documents="weapon.documents ? weapon.documents : []"
                                           :editMethod="changeDocumentDesc"
                                           :deleteMethod="deleteEmbeddedDoc"
                                           :submitSelectorMethod="setFileFavorite"
                                           :submitUploadMethod="addWeaponDocument"/>
                </div>
                <div style="text-align: center; margin: 0; cursor: pointer;" @click="noteExpand = false">
                    <fa-icon :icon="['fas', 'angle-up']" style="text-align: center"/>
                </div>
            </div>
            <div v-else style="text-align: center; cursor: pointer;" @click="getMoreInfo">
                <fa-icon :icon="['fas', 'angle-down']"/>
            </div>
        </b-card>
    </div>
</template>

<script>
import risc from "@/services/risc";
import NotesCardComponent from "@/views/expansion_modules/risc/components/notes_components/NotesCardComponent";
import DocumentCardComponent
    from "@/views/expansion_modules/risc/components/documents_components/DocumentCardComponent";
import FileViewer2 from '@/views/expansion_modules/risc/components/utility_components/FileViewer2.0';

export default {
    name: "WeaponsComponent",
    props: {
        weaponObject: Object,
        index: Number,
        scope: String,
        linkId: String,
        editable: Boolean,
        getImages: Boolean
    },

    components: {
        NotesCardComponent,
        DocumentCardComponent,
        FileViewer2
    },

    created() {
        this.weapon = this.weaponObject;
    },

    data() {
        return {
            disableDeleteFlag: false,
            weapon: null,
            deleteObjects: {
                modal: false,
                name: 'Weapon',
                deleteText: '',
                id: '',
            },
            weaponInfo: {
                data: {},
                postPut: false,
            },
            fileViewStorageId: null,
            fileViewerFlag: false,
            moreInfo: false,
            noteExpand: false,
        }
    },

    methods: {
        addWeaponDocument(description, fileId) {
            this.weapon.documents.push({
                description: description,
                storageFileName: fileId,
                arrayOrder: this.weapon.documents.length
            });
            risc.putWeapon(this.weapon).then(() => {
                this.getWeapon()
            });
        },
        changeDocumentDesc(doc) {
            this.weapon.images.forEach(document => {
                if (document.imageId === doc.imageId) {
                    document.description = doc.description;
                }
            });
            risc.putWeapon(this.weapon).then(() => {
                this.getWeapon();
            });
        },
        closeViewModal() {
            this.fileViewerFlag = false;
        },
        deleteEmbeddedDoc(doc) {
            this.weapon.documents = this.weapon.documents.filter((document) => {
                return document.documentId !== doc.documentId;
            });
            risc.putWeapon(this.drug).then(() => {
                this.getWeapon();
            });
        },
        deleteWeapon() {
            this.disableDeleteFlag = true;
            risc.unlinkProfileWeapon(this.linkId, this.deleteObjects.id).then(() => {
                this.deleteObjects.modal = false;
                this.$emit('deleteWeapon', this.deleteObjects.id)
            });
        },
        disableWeaponsSubmit() {
            return !this.filledOut(this.weaponInfo.data.source) ||
                !this.filledOut(this.weaponInfo.data.description);
        },
        filledOut(i) {
            return !(i === null || i === undefined || i === '' || i === 0);
        },
        getImage(storageId) {
            return risc.getImage(storageId);
        },
        getMoreInfo() {
            if (this.moreInfo === false) {
                this.getWeapon(() => {
                    this.noteExpand = true;
                });
            } else {
                this.noteExpand = true;
            }
        },
        getWeapon(callback) {
            risc.getWeapon(this.weapon.weaponId).then(response => {
                this.weapon = response.data;
                this.moreInfo = true;
                if (callback) {
                    callback();
                }
            });
        },
        openModalDeleteObject(name, id) {
            this.disableDeleteFlag = false;
            this.deleteObjects.modal = true;
            this.deleteObjects.name = name;
            this.deleteObjects.deleteText = '';
            this.deleteObjects.id = id;
        },
        openModalEditWeapons() {
            if (this.moreInfo === false) {
                this.getWeapon(() => {
                    this.weaponInfo.data = Object.assign({}, JSON.parse(JSON.stringify(this.weapon)));
                    this.weaponInfo.postPut = true;
                });
            } else {
                this.weaponInfo.data = Object.assign({}, JSON.parse(JSON.stringify(this.weapon)));
                this.weaponInfo.postPut = true;
            }
        },
        openViewModal(storageId) {
            this.fileViewStorageId = storageId;
            this.fileViewerFlag = true;
        },
        push(field, item) {
            field.push(JSON.parse(JSON.stringify(item)));
        },
        putWeapons() {
            risc.putWeapon(this.weaponInfo.data).then(() => {
                this.getWeapon();
            });
            this.weaponInfo.postPut = false;
        },
        setFileFavorite(files) {
            this.weapon.documents = files
            risc.putWeapon(this.weapon).then(() => {
                this.getWeapon();
            });
        },
        splice(index, field, item) {
            field.splice(index, 1);
            if (item && field.length === 0) {
                this.push(field, item);
            }
        },
    },
    watch: {
        getImages() {
            if (this.getImages === true && this.moreInfo === false) {
                this.getWeapon();
            }
        },
        weaponObject() {
            this.weapon = this.weaponObject;
        }
    }
}
</script>

<style scoped>

</style>