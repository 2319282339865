<template>
    <div v-if="socialMedias !== undefined">

        <!--Add Social Media-->
        <b-modal title="Add Social Media" class="modal-dark" no-close-on-backdrop
                 scrollable v-model="addSocialMediaFlag">
            <b-input-group class="input-group-sm" prepend="Site" style="margin-bottom: 1px">
                <b-form-select
                    :class=" 'form-control is-' + (filledOut(socialMediaAdd.site) ? 'valid':'invalid')"
                    :options="sites"
                    size="sm"
                    v-model="socialMediaAdd.site"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Username" style="margin-bottom: 1px">
                <b-form-input
                    :class=" 'form-control is-' + (filledOut(socialMediaAdd.userName) ? 'valid':'invalid')"
                    v-model="socialMediaAdd.userName"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Link" style="margin-bottom: 1px">
                <b-form-input v-model="socialMediaAdd.link"/>
            </b-input-group>
            <br>
            <label class="labelForm">Notes</label>&nbsp;
            <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(socialMediaAdd.notes, {text: ''})"/>
            <b-input-group :key="i" class="input-group-sm" v-for="(e,i) in socialMediaAdd.notes">
                <b-form-input v-model="socialMediaAdd.notes[i].text"/>
                <b-input-group-append>
                    <b-button @click="splice(i, socialMediaAdd.notes)" variant="dark">
                        <fa-icon :icon="['fas', 'close']"/>
                    </b-button>
                </b-input-group-append>
            </b-input-group>
            <template slot="modal-footer">
                <b-button @click="addSocialMediaFlag = false" size="sm" variant="secondary">Cancel
                </b-button>
                <b-button :disabled="disableSocialSubmit() || disableButtonFlag" @click="addSocialMedia" size="sm" variant="primary">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <div v-if="editable === true" style="text-align: center; width: 100%; margin-bottom: 10px;">
            <span style="cursor: pointer;" @click="openAddSocialMedia()">
                <fa-icon :icon="['fas', 'plus']"/> Add Social Media
            </span>
        </div>
        <p v-if="socialMedias.length === 0" class="noAdded"><i><strong>No Social Media Added</strong></i></p>
        <div :key="i" v-for="(socialMedia,i) in socialMedias">
            <SocialMediaProfileComponent :socialMediaObject="socialMedia" :editable="editable" @updateSocialMedia="updateSocialMedia" @deleteSocialMedia="deleteSocialMedia"/>
        </div>
    </div>
</template>

<script>

import SocialMediaProfileComponent from "@/views/expansion_modules/risc/components/socialmediaprofile_components/SocialMediaProfileComponent";

export default {
    name: "SocialMediaProfileTabComponent",
    components: {
        SocialMediaProfileComponent
    },
    props: {
        socialMedias: { type: Array },
        editable: { type: Boolean }
    },
    data() {
        return {
            disableButtonFlag: false,
            socialMediaAdd: {
                notes: []
            },
            addSocialMediaFlag: false,
            sites: [
                'FACEBOOK', 'YOUTUBE', 'WHATSAPP', 'MESSENGER', 'WECHAT', 'INSTAGRAM', 'TUMBLR', 'TIK TOK',
                'TWITTER', 'REDDIT', 'LINKEDIN', 'SNAPCHAT', 'PINTEREST', 'LINE', 'TELEGRAM', 'GOOGLE+', 'MEETUP'
            ]
        };
    },
    methods: {
        addSocialMedia() {
            this.disableButtonFlag = true;
            this.$emit('addSocialMedia', this.socialMediaAdd);
            this.addSocialMediaFlag = false;
        },
        deleteSocialMedia(socialMedia) {
            this.$emit('deleteSocialMedia', socialMedia);
        },
        disableSocialSubmit() {
            return !this.filledOut(this.socialMediaAdd.site) ||
                !this.filledOut(this.socialMediaAdd.userName);

        },
        filledOut(i){
            return !(i === null || i === undefined || i === '' || i === 0);
        },
        openAddSocialMedia() {
            this.socialMediaAdd = {notes: []};
            this.addSocialMediaFlag = true;
            this.disableButtonFlag = false;
        },
        push(field, item) {
            field.push(JSON.parse(JSON.stringify(item)));
        },
        splice(index, field) {
            field.splice(index, 1);
        },
        updateSocialMedia(socialMedia) {
            this.$emit('updateSocialMedia', socialMedia);
        },

    }
}
</script>

<style scoped>

.noAdded {
    text-align: center;
    margin-bottom: 0;
    color: grey
}

</style>