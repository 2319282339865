<template>
    <div v-if="involvements !== undefined">

        <!--Add/Edit Profiles-->
        <b-modal class="modal-dark" no-close-on-backdrop scrollable size="lg"
                 title="Add Profiles To Involvement"
                 v-model="profilesModal">
            <v-client-table :columns="profiles.columns" :data="profiles.all" :options="profiles.tOptions"
                            :theme="profiles.theme"
                            class="dataTable" ref="profileTable" style="width: 100%">
                <b-form-checkbox :value="props.row" slot="check" slot-scope="props"
                                 v-model="profiles.selected"></b-form-checkbox>
                <div slot="h__check">
                    <b-form-checkbox
                        @change="profiles.selected = unmarkAndMarkAllProfiles(profiles.selected, profiles.allMarked)"
                        class="check-all" style="padding-left: 24px"
                        type="checkbox"
                        v-model='profiles.allMarked'></b-form-checkbox>
                </div>
            </v-client-table>
            <template slot="modal-footer">
                <b-button @click="profilesModal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="updateProfiles()" size="sm" variant="primary">Submit</b-button>
            </template>
        </b-modal>

        <!--Add/Edit Orgs-->
        <b-modal class="modal-dark" no-close-on-backdrop scrollable size="lg"
                 title="Add Profiles To Involvement"
                 v-model="orgsModal">
            <v-client-table :columns="organizations.columns" :data="organizations.all" :options="organizations.tOptions"
                            :theme="organizations.theme"
                            class="dataTable" ref="orgTable" style="width: 100%">
                <b-form-checkbox :value="props.row" slot="check" slot-scope="props"
                                 v-model="organizations.selected"></b-form-checkbox>
                <div slot="h__check">
                    <b-form-checkbox
                        @change="organizations.selected = unmarkAndMarkAllOrgs(organizations.selected, organizations.allMarked)"
                        class="check-all" style="padding-left: 24px"
                        type="checkbox"
                        v-model='organizations.allMarked'></b-form-checkbox>
                </div>
            </v-client-table>
            <template slot="modal-footer">
                <b-button @click="orgsModal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="updateOrgs()" size="sm" variant="primary">Submit</b-button>
            </template>
        </b-modal>

        <!-- Add Involvement -->
        <b-modal class="modal-dark" no-close-on-backdrop scrollable title="Add Involvement" v-model="addModal">
            <b style="margin-left: 5px;">Involvement</b>
            <b-input-group class="input-group-sm" style="padding: 5px;">
                <div class="col" style="padding: 0; width: 55px">
                    <b-form-select v-model="involvementAdd.involvementInfo"
                                   :options="involvementOptions" plain>
                    </b-form-select>
                </div>
            </b-input-group>
            <div style="margin-top: 5px;">
                <b style="margin-left: 5px;">Profiles</b>
                <b-badge style="height: fit-content; margin-bottom: 5px; cursor: pointer; margin-left: 10px;"
                         variant="success" @click="openProfilesModal()">
                    <fa-icon :icon="['fas', 'pencil']"/> Edit Profiles
                </b-badge>
                <b-list-group v-if="involvementAdd.profiles && involvementAdd.profiles.length > 0" style="border: none" :key="sIndex" v-for="(profile, sIndex) in involvementAdd.profiles">
                    <b-list-group-item style="margin: 5px">
                        <span class="d-flex w-100 justify-content-between align-content-center">
                            <b style="margin-bottom: 0">{{ profile.tableName }}</b>
                            <router-link class="non-link"
                                         :to="{ name: 'Profile Details', params: {id: profile.profileId}}"
                                         target="_blank">
                                <fa-icon :icon="['fas', 'up-right-from-square']" style="cursor: pointer;"/>
                            </router-link>
                        </span>
                    </b-list-group-item>
                </b-list-group>
                <p v-if="!involvementAdd.profiles || involvementAdd.profiles.length === 0" style="text-align: center; width: 100%" class="noAdded"><i><strong>No Profiles Added</strong></i></p>
            </div>
            <div style="margin-top: 5px;">
                <b style="margin-left: 5px;">Organizations</b>
                <b-badge style="height: fit-content; margin-bottom: 5px; cursor: pointer; margin-left: 10px;"
                         variant="success" @click="openOrgsModal()">
                    <fa-icon :icon="['fas', 'pencil']"/> Edit Organizations
                </b-badge>
                <b-list-group v-if="involvementAdd.organizations && involvementAdd.organizations.length > 0" style="border: none" :key="sIndex" v-for="(org, sIndex) in involvementAdd.organizations">
                    <b-list-group-item style="margin: 5px">
                        <span class="d-flex w-100 justify-content-between align-content-center">
                            <b style="margin-bottom: 0">{{ org.tableName }}</b>
                            <router-link class="non-link"
                                         :to="{ name: 'Organization Details', params: {id: org.organizationId}}"
                                         target="_blank">
                                <fa-icon :icon="['fas', 'up-right-from-square']" style="cursor: pointer;"/>
                            </router-link>
                        </span>
                    </b-list-group-item>
                </b-list-group>
                <p v-if="!involvementAdd.organizations || involvementAdd.organizations.length === 0" style="text-align: center; width: 100%" class="noAdded"><i><strong>No Organizations Added</strong></i></p>
            </div>
            <template slot="modal-footer">
                <b-button @click="cancel()" variant="secondary" style="margin: 0 5px 0 5px">
                    Cancel
                </b-button>
                <b-button @click="add()" variant="primary" :disabled="involvementAdd.involvementInfo === undefined || disableButtonFlag">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <div style="display: flex; align-content: space-between; align-items: center;">
            <h4 style="margin-bottom: 5px; margin-right: 5px;"><strong>Involvements</strong></h4>
            <b-badge v-if="editable === true" variant="success" style="height: fit-content; margin-bottom: 5px; cursor: pointer;"
                     @click="addInvolvement()">
                <fa-icon :icon="['fas', 'plus']"/>Add
            </b-badge>
        </div>
        <div v-for="(involvement, index) in involvements">
            <InvolvementComponent :involvementObject="involvement" :editable="editable" @updateInvolvements="updateInvolvements"/>
        </div>
        <b-card v-if="involvements === null || involvements.length === 0" no-body style="margin-bottom: 5px; width: 100%; padding: 3px; background-color: #131313">
            <p style="text-align: center; width: 100%" class="noAdded"><i><strong>No Involvements Added</strong></i></p>
        </b-card>
    </div>
</template>

<script>
import InvolvementComponent from "@/views/expansion_modules/risc/components/involvement_components/InvolvementComponent";
import risc from "@/services/risc";

export default {
    name: "InvolvementCardComponent",
    components: {
        InvolvementComponent
    },
    props: {
        involvements: Array,
        leReportLink: String,
        editable: Boolean
    },
    data() {
        return {
            involvementAdd: {},
            addModal: false,
            profilesModal: false,
            disableButtonFlag: false,
            orgsModal: false,
            involvementOptions: ['ARRESTEE', 'WITNESS', 'SUSPECT', 'VICTIM', 'OTHER'],
            profiles: {
                all: [],
                selected: [],
                allMarked: false,
                theme: 'bootstrap4',
                columns: ['check', 'tableName', 'tableDOB'],
                tOptions: {
                    sortable: ['tableName', 'tableDOB'],
                    filterable: ['tableName', 'tableDOB'],
                    orderBy: {column: 'tableName', ascending: true},
                    headings: {tableName: 'Name', tableDOB: 'DOB',},
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {chunk: 5, edge: false, nav: 'scroll'},
                    perPage: 25,
                    skin: 'table table-striped table-hover',
                },
            },
            organizations: {
                all: [],
                selected: [],
                allMarked: false,
                theme: 'bootstrap4',
                columns: ['check', 'tableName'],
                tOptions: {
                    sortable: ['tableName'],
                    filterable: ['tableName'],
                    orderBy: {column: 'tableName', ascending: true},
                    headings: {tableName: 'Org Name',},
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {chunk: 5, edge: false, nav: 'scroll'},
                    perPage: 25,
                    skin: 'table table-striped table-hover',
                },
            }
        };
    },
    methods: {
        add() {
            this.disableButtonFlag = true;
            risc.postInvolvement(this.involvementAdd).then(response => {
                risc.linkLeReportInvolvement(this.leReportLink, response.data.involvementId).then(response2 => {
                    this.addModal = false;
                    this.disableButtonFlag = false;
                    this.$emit('updateInvolvements');
                }).catch(e => {
                    this.addModal = false;
                    this.disableButtonFlag = false;
                    console.error(e);
                })
                if (this.involvementAdd.profiles && this.involvementAdd.profiles.length > 0) {
                    this.involvementAdd.profiles.forEach(profile => {
                        risc.linkProfileInvolvement(profile.profileId, response.data.involvementId).then(response3 => {});
                    });
                }
                if (this.involvementAdd.organizations && this.involvementAdd.organizations.length > 0) {
                    this.involvementAdd.organizations.forEach(org => {
                        risc.linkOrgInvolvement(org.organizationId, response.data.involvementId).then(response4 => {});
                    });
                }
            });
        },
        addInvolvement() {
            this.involvementAdd = {};
            this.profiles.selected = [];
            this.organizations.selected = [];
            this.addModal = true;
        },
        cancel() {
            this.addModal = false;
        },
        dateify(month, day, year) {
            let result = "";
            result += (!month ? 'XX' : (month < 10 ? "0" + month : month)) + '/';
            result += (!day ? 'XX' : (day < 10 ? "0" + day : day)) + '/';
            result += (!year ? 'XXXX' : year);
            return result;
        },
        nameify(profile) {
            return (
                (profile.lastName ? profile.lastName : 'UNKNOWN') + ', '
                + (profile.firstName ? profile.firstName : 'UNKNOWN') + ' '
                + (profile.middleName === null || profile.middleName === undefined  ? '' : profile.middleName.slice(0, 1))
            )
        },
        openOrgsModal() {
            risc.getOrganizations().then(response => {
                this.organizations.all = response.data.map(org => {
                    return({
                        organizationId: org.organizationId,
                        tableName: org.orgName
                    });
                });
                this.organizations.allMarked = (this.organizations.selected.length === this.organizations.all.length);
                this.orgsModal = true
            });
        },
        openProfilesModal() {
            risc.getProfiles().then(response => {
                this.profiles.all = response.data.map(profile => {
                    return({
                        profileId: profile.profileId,
                        tableDOB: this.dateify(profile.birthMonth, profile.birthDay, profile.birthYear),
                        tableName: this.nameify(profile)
                    });
                });
                this.profiles.allMarked = (this.profiles.selected.length === this.profiles.all.length);
                this.profilesModal = true
            });
        },
        unmarkAndMarkAllOrgs(selected, allMarked) {
            if (allMarked === true) {
                let temp = [];
                if (this.$refs.orgTable.allFilteredData) {
                    temp = this.$refs.orgTable.allFilteredData;
                }
                allMarked = false;
                return (temp)
            } else {
                allMarked = true;
                return ([]);
            }
        },
        unmarkAndMarkAllProfiles(selected, allMarked) {
            if (allMarked === true) {
                let temp = [];
                if (this.$refs.profileTable.allFilteredData) {
                    temp = this.$refs.profileTable.allFilteredData;
                }
                allMarked = false;
                return (temp)
            } else {
                allMarked = true;
                return ([]);
            }
        },
        updateInvolvements() {
            this.$emit('updateInvolvements');
        },
        updateOrgs() {
            this.involvementAdd.organizations = this.organizations.selected;
            this.orgsModal = false;
        },
        updateProfiles() {
            this.involvementAdd.profiles = this.profiles.selected;
            this.profilesModal = false;
        },

    }
}
</script>

<style scoped>

.noAdded {
    text-align: center;
    margin-bottom: 0;
    color: grey
}

</style>