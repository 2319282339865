<template>
    <div v-if="involvement !== undefined">

        <!--Delete Involvement -->
        <b-modal class="modal-dark" scrollable title="Remove Involvement" v-model="deleteModal" no-close-on-backdrop>
            <p>Are you sure you want to delete this Involvement?</p>
            <template slot="modal-footer">
                <b-button @click="deleteModal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="deleteInvolvement()" size="sm" variant="danger" :disabled="disableDeleteFlag">Delete Involvement
                </b-button>
            </template>
        </b-modal>

        <!--Add/Edit Profiles-->
        <b-modal class="modal-dark" no-close-on-backdrop scrollable size="lg"
                 title="Add Profiles To Involvement"
                 v-model="profilesModal">
            <v-client-table :columns="profiles.columns" :data="profiles.all" :options="profiles.tOptions"
                            :theme="profiles.theme"
                            class="dataTable" ref="profileTable" style="width: 100%">
                <b-form-checkbox :value="props.row" slot="check" slot-scope="props"
                                 v-model="profiles.selected"></b-form-checkbox>
                <div slot="h__check">
                    <b-form-checkbox
                        @change="profiles.selected = unmarkAndMarkAllProfiles(profiles.selected, profiles.allMarked)"
                        class="check-all" style="padding-left: 24px"
                        type="checkbox"
                        v-model='profiles.allMarked'></b-form-checkbox>
                </div>
            </v-client-table>
            <template slot="modal-footer">
                <b-button @click="profilesModal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="updateProfiles()" size="sm" variant="primary">Submit</b-button>
            </template>
        </b-modal>

        <!--Add/Edit Orgs-->
        <b-modal class="modal-dark" no-close-on-backdrop scrollable size="lg"
                 title="Add Profiles To Involvement"
                 v-model="orgsModal">
            <v-client-table :columns="organizations.columns" :data="organizations.all" :options="organizations.tOptions"
                            :theme="organizations.theme"
                            class="dataTable" ref="orgTable" style="width: 100%">
                <b-form-checkbox :value="props.row" slot="check" slot-scope="props"
                                 v-model="organizations.selected"></b-form-checkbox>
                <div slot="h__check">
                    <b-form-checkbox
                        @change="organizations.selected = unmarkAndMarkAllOrgs(organizations.selected, organizations.allMarked)"
                        class="check-all" style="padding-left: 24px"
                        type="checkbox"
                        v-model='organizations.allMarked'></b-form-checkbox>
                </div>
            </v-client-table>
            <template slot="modal-footer">
                <b-button @click="orgsModal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="updateOrgs()" size="sm" variant="primary">Submit</b-button>
            </template>
        </b-modal>

        <!-- Edit Display Card -->
        <b-modal class="modal-dark" no-close-on-backdrop scrollable title="Edit Involvement" v-model="editModal">
            <b style="margin-left: 5px;">Involvement</b>
            <b-input-group class="input-group-sm" style="padding: 5px;">
                <div class="col" style="padding: 0; width: 55px">
                    <b-form-select v-model="involvement.involvementInfo"
                                   :options="involvementOptions" plain>
                    </b-form-select>
                </div>
            </b-input-group>
            <div style="margin-top: 5px;">
                <b style="margin-left: 5px;">Profiles</b>
                <b-badge style="height: fit-content; margin-bottom: 5px; cursor: pointer; margin-left: 10px;"
                         variant="success" @click="openProfilesModal()">
                    <fa-icon :icon="['fas', 'pencil']"/> Edit Profiles
                </b-badge>
                <b-list-group v-if="involvement.profiles && involvement.profiles.length > 0" style="border: none" :key="sIndex" v-for="(profile, sIndex) in involvement.profiles">
                    <b-list-group-item style="margin: 5px">
                        <span class="d-flex w-100 justify-content-between align-content-center">
                            <b style="margin-bottom: 0">{{ profile.tableName }}</b>
                            <router-link class="non-link"
                                         :to="{ name: 'Profile Details', params: {id: profile.profileId}}"
                                         target="_blank">
                                <fa-icon :icon="['fas', 'up-right-from-square']" style="cursor: pointer;"/>
                            </router-link>
                        </span>
                    </b-list-group-item>
                </b-list-group>
                <p v-if="!involvement.profiles || involvement.profiles.length === 0" style="text-align: center; width: 100%" class="noAdded"><i><strong>No Profiles Added</strong></i></p>
            </div>
            <div style="margin-top: 5px;">
                <b style="margin-left: 5px;">Organizations</b>
                <b-badge style="height: fit-content; margin-bottom: 5px; cursor: pointer; margin-left: 10px;"
                         variant="success" @click="openOrgsModal()">
                    <fa-icon :icon="['fas', 'pencil']"/> Edit Organizations
                </b-badge>
                <b-list-group v-if="involvement.organizations && involvement.organizations.length > 0" style="border: none" :key="sIndex" v-for="(org, sIndex) in involvement.organizations">
                    <b-list-group-item style="margin: 5px">
                        <span class="d-flex w-100 justify-content-between align-content-center">
                            <b style="margin-bottom: 0">{{ org.tableName }}</b>
                            <router-link class="non-link"
                                         :to="{ name: 'Organization Details', params: {id: org.organizationId}}"
                                         target="_blank">
                                <fa-icon :icon="['fas', 'up-right-from-square']" style="cursor: pointer;"/>
                            </router-link>
                        </span>
                    </b-list-group-item>
                </b-list-group>
                <p v-if="!involvement.organizations || involvement.organizations.length === 0" style="text-align: center; width: 100%" class="noAdded"><i><strong>No Organizations Added</strong></i></p>
            </div>
            <template slot="modal-footer">
                <b-button style="height: fit-content; cursor: pointer; margin: 0 auto 0 10px"
                         variant="danger" @click="openDeleteModal()">
                    <fa-icon :icon="['fas', 'trash-can']"/> Delete Involvement
                </b-button>
                <b-button @click="cancel()" variant="secondary" style="margin: 0 5px 0 5px">
                    Cancel
                </b-button>
                <b-button @click="update()" variant="primary" :disabled="submitting">
                    Submit <fa-icon v-if="submitting === true" :icon="['fas', 'circle-notch']" spin/>
                </b-button>
            </template>
        </b-modal>

        <!-- Normal Display Card -->
        <b-card no-body>
            <span class="d-flex w-100 justify-content-between"
                  style="background-color: #0e0e0e; padding: 0 5px 0 5px; border-radius: 3px 3px 0 0">
                <span class="d-flex align-items-center">
                    <b style="font-size: medium; margin-right: 5px">{{ involvement.involvementInfo }}</b>
                </span>
                <span v-if="editable === true">
                    <fa-icon :icon="['fas', 'pen-to-square']" @click="editModal = true"/>
                </span>
            </span>
            <div style="margin-top: 5px;">
                <b style="margin-left: 5px;">Profiles</b>
                <b-list-group v-if="involvement.profiles && involvement.profiles.length > 0" style="border: none" :key="sIndex" v-for="(profile, sIndex) in involvement.profiles">
                    <b-list-group-item style="margin: 5px">
                        <span class="d-flex w-100 justify-content-between align-content-center">
                            <b style="margin-bottom: 0">{{ profile.tableName }}</b>
                            <router-link class="non-link"
                                         :to="{ name: 'Profile Details', params: {id: profile.profileId}}"
                                         target="_blank">
                                <fa-icon :icon="['fas', 'up-right-from-square']" style="cursor: pointer;"/>
                            </router-link>
                        </span>
                    </b-list-group-item>
                </b-list-group>
                <p v-if="!involvement.profiles || involvement.profiles.length === 0" style="text-align: center; width: 100%" class="noAdded"><i><strong>No Profiles Added</strong></i></p>
            </div>
            <div style="margin-top: 5px;">
                <b style="margin-left: 5px;">Organizations</b>
                <b-list-group v-if="involvement.organizations && involvement.organizations.length > 0" style="border: none" :key="sIndex" v-for="(org, sIndex) in involvement.organizations">
                    <b-list-group-item style="margin: 5px">
                        <span class="d-flex w-100 justify-content-between align-content-center">
                            <b style="margin-bottom: 0">{{ org.tableName }}</b>
                            <router-link class="non-link"
                                         :to="{ name: 'Organization Details', params: {id: org.organizationId}}"
                                         target="_blank">
                                <fa-icon :icon="['fas', 'up-right-from-square']" style="cursor: pointer;"/>
                            </router-link>
                        </span>
                    </b-list-group-item>
                </b-list-group>
                <p  v-if="!involvement.organizations || involvement.organizations.length === 0" style="text-align: center; width: 100%" class="noAdded"><i><strong>No Organizations Added</strong></i></p>
            </div>
        </b-card>
    </div>
</template>

<script>
import risc from "@/services/risc";

export default {
    name: "InvolvementComponent",
    props: {
        involvementObject: Object,
        editable: Boolean,
    },
    created() {
        this.involvement = this.involvementObject;
        this.originalInvolvement = JSON.parse(JSON.stringify(this.involvement));
        this.getInvolvement();
    },
    data() {
        return {
            submitting: false,
            disableDeleteFlag: false,
            editModal: false,
            deleteModal: false,
            editMode: false,
            involvement: null,
            originalInvolvement: null,
            profilesModal: false,
            orgsModal: false,
            involvementOptions: ['ARRESTEE', 'WITNESS', 'SUSPECT', 'VICTIM', 'OTHER'],
            profiles: {
                all: [],
                selected: [],
                allMarked: false,
                theme: 'bootstrap4',
                columns: ['check', 'tableName', 'tableDOB'],
                tOptions: {
                    sortable: ['tableName', 'tableDOB'],
                    filterable: ['tableName', 'tableDOB'],
                    orderBy: {column: 'tableName', ascending: true},
                    headings: {tableName: 'Name', tableDOB: 'DOB',},
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {chunk: 5, edge: false, nav: 'scroll'},
                    perPage: 25,
                    skin: 'table table-striped table-hover',
                },
            },
            organizations: {
                all: [],
                selected: [],
                allMarked: false,
                theme: 'bootstrap4',
                columns: ['check', 'tableName'],
                tOptions: {
                    sortable: ['tableName'],
                    filterable: ['tableName'],
                    orderBy: {column: 'tableName', ascending: true},
                    headings: {tableName: 'Org Name',},
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {chunk: 5, edge: false, nav: 'scroll'},
                    perPage: 25,
                    skin: 'table table-striped table-hover',
                },
            }
        };
    },
    methods: {
        cancel() {
            this.involvement = JSON.parse(JSON.stringify(this.originalInvolvement));
            this.profiles.selected = this.involvement.profiles;
            this.organizations.selected = this.involvement.organizations;
            this.editModal = false;
        },
        dateify(month, day, year) {
            let result = "";
            result += (!month ? 'XX' : (month < 10 ? "0" + month : month)) + '/';
            result += (!day ? 'XX' : (day < 10 ? "0" + day : day)) + '/';
            result += (!year ? 'XXXX' : year);
            return result;
        },
        deleteInvolvement() {
            this.disableDeleteFlag = true;
            risc.deleteInvolvement(this.involvement.involvementId).then(() => {
                this.disableDeleteFlag = false;
                this.deleteModal = false;
                this.editModal = false;
                this.$emit('updateInvolvements');
            }).catch(e => {
                this.disableDeleteFlag = false;
                this.deleteModal = false;
                this.editModal = false;
                console.error(e)
            })
        },
        getInvolvement() {
            risc.getInvolvement(this.involvement.involvementId).then(response => {
                if (response.data.profiles) {
                    response.data.profiles = response.data.profiles.map(profile => {
                        return ({
                            profileId: profile.profileId,
                            tableDOB: this.dateify(profile.birthMonth, profile.birthDay, profile.birthYear),
                            tableName: this.nameify(profile)
                        });
                    });
                }
                if (response.data.organizations) {
                    response.data.organizations = response.data.organizations.map(org => {
                        return({
                            organizationId: org.organizationId,
                            tableName: org.orgName
                        });
                    });
                }
                this.organizations.selected = response.data.organizations;
                this.profiles.selected = response.data.profiles;
                this.originalInvolvement = JSON.parse(JSON.stringify(response.data));
                this.involvement = response.data;
            });
        },
        nameify(profile) {
            return (
                (profile.lastName ? profile.lastName : 'UNKNOWN') + ', '
                + (profile.firstName ? profile.firstName : 'UNKNOWN') + ' '
                + (profile.middleName === null || profile.middleName === undefined ? '' : profile.middleName.slice(0, 1))
            )
        },
        openDeleteModal() {
            this.deleteModal = true;
        },
        openOrgsModal() {
            risc.getOrganizations().then(response => {
                this.organizations.all = response.data.map(org => {
                    return({
                        organizationId: org.organizationId,
                        tableName: org.orgName
                    });
                });
                this.organizations.allMarked = (this.organizations.selected.length === this.organizations.all.length);
                this.orgsModal = true
            });
        },
        openProfilesModal() {
            risc.getProfiles().then(response => {
                this.profiles.all = response.data.map(profile => {
                    return({
                        profileId: profile.profileId,
                        tableDOB: this.dateify(profile.birthMonth, profile.birthDay, profile.birthYear),
                        tableName: this.nameify(profile)
                    });
                });
                this.profiles.allMarked = (this.profiles.selected.length === this.profiles.all.length);
                this.profilesModal = true
            });
        },
        unmarkAndMarkAllOrgs(selected, allMarked) {
            if (allMarked === true) {
                let temp = [];
                if (this.$refs.orgTable.allFilteredData) {
                    temp = this.$refs.orgTable.allFilteredData;
                }
                allMarked = false;
                return (temp)
            } else {
                allMarked = true;
                return ([]);
            }
        },
        unmarkAndMarkAllProfiles(selected, allMarked) {
            if (allMarked === true) {
                let temp = [];
                if (this.$refs.profileTable.allFilteredData) {
                    temp = this.$refs.profileTable.allFilteredData;
                }
                allMarked = false;
                return (temp)
            } else {
                allMarked = true;
                return ([]);
            }
        },
        async update() {
            this.submitting = true;
            await risc.putInvolvement(this.involvement);
            let profilesUnlink = this.originalInvolvement.profiles.filter(_ => !this.profiles.selected.some(profile => profile.profileId === _.profileId));
            for (let profile of profilesUnlink) {
                await risc.unlinkProfileInvolvement(profile.profileId, this.involvement.involvementId);
            }
            let profilesLink = this.profiles.selected.filter(_ => !this.originalInvolvement.profiles.some(profile => profile.profileId === _.profileId));
            for (let profile of profilesLink) {
                await risc.linkProfileInvolvement(profile.profileId, this.involvement.involvementId);
            }
            let orgsUnlink = this.originalInvolvement.organizations.filter(_ => !this.organizations.selected.some(organization => organization.organizationId === _.organizationId));
            for (let org of orgsUnlink) {
                await risc.unlinkOrgInvolvement(org.organizationId, this.involvement.involvementId);
            }
            let orgsLink = this.organizations.selected.filter(_ => !this.originalInvolvement.organizations.some(organization => organization.organizationId === _.organizationId));
            for (let org of orgsLink) {
                await risc.linkOrgInvolvement(org.organizationId, this.involvement.involvementId);
            }
            this.submitting = false;
            this.editModal = false;
            this.getInvolvement();
            //});
        },
        updateOrgs() {
            this.involvement.organizations = this.organizations.selected;
            this.orgsModal = false;
        },
        updateProfiles() {
            this.involvement.profiles = this.profiles.selected;
            this.profilesModal = false;
        },


    },
    watch: {
        involvementObject() {
            this.involvement = this.involvementObject;
            this.originalInvolvement = JSON.parse(JSON.stringify(this.involvement));
            this.getInvolvement();
        },
    }
}
</script>

<style scoped>

</style>