<template>
    <div v-if="vehicle !== null">

        <!--Edit Vehicle-->
        <b-modal class="modal-dark" scrollable title="Vehicle" no-close-on-backdrop v-model="vehicleInfo.vehicleEdit" v-if="vehicleInfo.vehicleEdit === true">
            <b-input-group class="input-group-sm d-flex no-wrap" prepend="Make" style="margin-bottom: 1px">
                <formSelectSearch :upperCase="true" :options="allMakes" v-model="vehicleMake" :mergeLeft="true"
                                  :arrow="false" size="sm" v-if="allMakes && allMakes.length > 0" class="flex-grow-1"/>
                <p v-else style="width: 184px; margin: 0; padding-top: 3px; text-align: center;">Loading</p>
            </b-input-group>
            <b-input-group class="input-group-sm d-flex no-wrap" prepend="Model" style="margin-bottom: 2px">
                <formSelectSearch :upperCase="true" :options="allModels" v-model="vehicleInfo.data.model"
                                  :arrow="false" :mergeLeft="true" size="sm" v-if="vehicleMake" class="flex-grow-1"/>
                <b-input-group-append v-else class="input-group-sm flex-grow-1 d-flex">
                    <b-input-group-text style="background-color: #333333" class="d-flex flex-grow-1">
                        <span class="flex-grow-1 align-content-center">Select a make first</span>
                    </b-input-group-text>
                </b-input-group-append>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Color" style="margin-bottom: 1px">
                <b-form-input style="width: 15px" v-model="vehicleInfo.data.color" v-uppercase/>
                <b-input-group-append class="input-group-sm">
                    <b-input-group-text>Year</b-input-group-text>
                </b-input-group-append>
                <b-form-select :options="arrayify(1900,(new Date()).getFullYear() + 1, false)"
                               type="number"
                               v-model.number="vehicleInfo.data.year"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Plate" style="margin-bottom: 1px">
                <b-form-input v-model="vehicleInfo.data.plate" v-uppercase style="width: 265px"/>
                <b-input-group-append class="input-group-sm">
                    <b-input-group-text>State</b-input-group-text>
                </b-input-group-append>
                <b-form-select :options="states" v-model="vehicleInfo.data.state"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="VIN" style="margin-bottom: 1px">
                <b-form-input v-model="vin" v-uppercase/>
            </b-input-group>
            <div style="text-align: center;">
                <b-button :disabled="vin === ''" @click="encodeVehicleVIN()" style="margin: 3px;">Decode Information
                    with VIN
                </b-button>
                <p style="font-size: 10px; margin: 3px;">Press button to autofill vehicle fields based on the VIN
                    number</p>
            </div>
            <b-input-group class="input-group-sm" prepend="Registered Owner" style="margin-bottom: 1px">
                <b-form-input v-model="vehicleInfo.data.registeredOwner" v-uppercase/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Description" style="margin-bottom: 1px">
                    <textarea class="form-control rounded-0" rows="3" v-model="vehicleInfo.data.description"
                              type="text"></textarea>
            </b-input-group>
            <br>
            <b-form-group><br>
                <label>Other Notes</label>&nbsp;
                <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(vehicleInfo.data.notes, {text: '', scope: scope})"/>
                <div v-if="vehicleInfo.data.notes" :key="i"
                     v-for="(note,i) in refactorScope(vehicleInfo.data.notes)">
                    <b-input-group v-if="!note.scope || note.scope === scope || note.scope === 'global'" class="input-group-sm">
                        <div class="col" style="padding: 0; width: 55px">
                            <b-form-input size="sm" v-model="vehicleInfo.data.notes[i].text"></b-form-input>
                        </div>
                        <b-input-group-append>
                            <div class="col-auto" style="padding: 0 4px 0 2px;">
                                <b-form-select v-model ="vehicleInfo.data.notes[i].scope"
                                               size="sm" plain
                                               style="background-color: #191919; border-color: #191919; ">
                                    <option :value="scope">Scoped</option>
                                    <option :value="'global'">Global</option>
                                </b-form-select>
                            </div>
                            <b-button @click="splice(i, vehicleInfo.data.notes)" variant="dark">
                                <fa-icon :icon="['fas', 'close']"/>
                            </b-button>
                        </b-input-group-append>

                    </b-input-group>
                </div>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="vehicleInfo.vehicleEdit = false; vin = ''" size="sm" variant="secondary">
                    Cancel
                </b-button>
                <b-button v-if="vehicleInfo.vehicleEdit"
                          :disabled="oneFilledOut(vehicleInfo.data, vehicleInfo.fields) || disableButtonFlag"
                          @click="putVehicle()" size="sm" variant="primary">Submit
                </b-button>
            </template>
        </b-modal>

        <!--Delete Vehicle-->
        <b-modal class="modal-dark" scrollable title="Remove Vehicle" v-model="deleteObjects.modal" no-close-on-backdrop>
            <p>If you would like to delete this {{deleteObjects.name}} please type the prompt below.</p>
            <br>
            <p style="margin-bottom: 0">Enter "{{deleteObjects.name}}":</p>
            <b-form-group description="case sensitive">
                <b-form-input id="basicName2" type="text" v-model="deleteObjects.deleteText"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="deleteObjects.modal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button :disabled="deleteObjects.deleteText !== deleteObjects.name || disableButtonFlag"
                          @click="deleteVehicle()"
                          size="sm" variant="danger">
                    Delete {{deleteObjects.name}}
                </b-button>
            </template>
        </b-modal>

        <!--Main Vehicle Card-->
        <b-card no-body
                style="border-color: #0e0e0e;background-color: #202020">
            <span class="d-flex w-100 justify-content-between"
                  style="background-color: #0e0e0e; padding: 0 5px 0 5px; border-radius: 3px 3px 0 0">
                <b style="font-size: medium">{{vehicle.year > 0 ? vehicle.year : '' }} {{ vehicle.make }} {{ vehicle.model }}</b>
                <span v-if="editable">
                    <fa-icon :icon="['fas', 'pen-to-square']" @click="openModalEditVehicles()" style="cursor: pointer;"/>&nbsp;
                    <fa-icon :icon="['fas', 'close']" @click="openModalDeleteObject(vehicle.vehicleId)" size="lg"
                             style="cursor: pointer; transform: translateY(1px);"/>
                </span>
            </span>
            <b-row style="margin: 0; padding: 0">
                <b-col class="d-flex flex-column"
                       style="margin: 0; padding: 0"
                       :xl="vehicle.documents && vehicle.documents.length > 0 ? 7 : 12">
                    <div style="margin: 6px">
                        <div v-if="linkType === 'Org'"><strong>Associated: </strong>{{profileName}}</div>
                        <div><strong>Plate: </strong> ({{ vehicle.state }}) {{ vehicle.plate }}</div>
                        <div><strong>Color: </strong>{{ vehicle.color }}</div>
                        <div v-if="vehicle.vin !== ''"><strong>VIN: </strong>{{ vehicle.vin }}</div>
                        <div v-else><strong>VIN: </strong>N/A</div>
                        <div><strong>Registered Owner: </strong>{{ vehicle.registeredOwner }}</div>
                        <div><strong>Description: </strong>{{ vehicle.description }}</div>
                    </div>
                </b-col>
                <b-col style="margin: 0; padding: 0" xl="5"
                       class="d-flex justify-content-center"
                       v-if="vehicle.documents && vehicle.documents.length > 0">
                    <span
                        style="width: auto; max-height: 100px; margin: 5px; border: 2px solid #23282c; border-radius: 5px; background-color: #0E0E0E;"
                        class="image-parent">
                        <img class="img-center-ass"
                             style="width: auto; max-height: 100px; border-radius: 5px;"
                             v-if="vehicle.documents && vehicle.documents.length > 0"
                             v-auth-image="getFirstDocumentImage(vehicle.documents)"
                             @click="openViewModal(vehicle.documents[0].storageFileName)"
                             alt=""/>
                        <img v-else class="img-center-ass"
                             style="border-radius: 5px;"
                             src="/NoImageAvailable.png"
                             alt="">
                    </span>
                </b-col>
            </b-row>
            <div v-if="noteExpand === true" style="border-radius: 3px"><br>
                <div style="padding: 10px">
                    <NotesCardComponent :notes="vehicle.notes" :scope="scope"/><br>
                    <DocumentCardComponent :editable="editable"
                                           :documents="vehicle.documents ? vehicle.documents : []"
                                           :editMethod="changeDocumentDesc"
                                           :deleteMethod="deleteEmbeddedDoc"
                                           :submitSelectorMethod="setFileFavorite"
                                           :submitUploadMethod="addVehicleDocument"/>
                </div>
                <div style="text-align: center; margin: 0; cursor: pointer;" @click="noteExpand = false">
                    <fa-icon :icon="['fas', 'angle-up']" style="text-align: center"/>
                </div>
            </div>
            <div v-else @click="getMoreInfo" style="text-align: center; margin: 0; border-radius: 3px; z-index: 1; cursor: pointer;">
                <fa-icon :icon="['fas', 'angle-down']"/>
            </div>
        </b-card>

        <!--View Files-->
        <FileViewer2 :flag="fileViewerFlag" :fileId="fileViewStorageId" :closeMethod="closeViewModal"/>
    </div>
</template>

<script>
import risc from "@/services/risc";
import formSelectSearch from "@/views/expansion_modules/risc/formSelectSearch";
import FileViewer2 from "@/views/expansion_modules/risc/components/utility_components/FileViewer2.0";
import NotesCardComponent from "@/views/expansion_modules/risc/components/notes_components/NotesCardComponent";
import DocumentCardComponent from "@/views/expansion_modules/risc/components/documents_components/DocumentCardComponent";
import axios from "axios";

export default {
    name: "VehicleComponent",

    components: {
        formSelectSearch,
        FileViewer2,
        NotesCardComponent,
        DocumentCardComponent,
    },

    props: {
        vehicleObject: {type: Object},
        profileName: {type: String},
        scope: {type: String},
        editable: {type: Boolean},
        linkId: {type: String},
        linkType: {type: String},
        getImages: {type: Boolean}
    },

    created() {
        this.vehicle = this.vehicleObject;
    },

    data() {
        return {
            vehicle: null,
            noteExpand: false,
            fileViewStorageId: null,
            allMakes: [],
            allModels: [],
            vehicleMake: '',
            vehicleInfo: {
                data: {},
                vehicleEdit: false,
                fields: {
                    state: '',
                    vin: '',
                    registeredOwner: '',
                    make: '',
                    model: '',
                    year: 0,
                    plate: '',
                    color: '',
                    description: '',
                    notes: [{text: '', scope: this.scope}],
                    documents: [],
                    vehicleId: '',
                },
            },
            states: [
                'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
                'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
                'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
                'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
                'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
            ],
            vin: '',
            disableButtonFlag: false,
            notesNewToOld: false,
            deleteObjects: {
                modal: false,
                name: 'Vehicle',
                deleteText: '',
                id: '',
            },
            fileViewerFlag: false,
            refresh: true,
            moreInfo: false,
          }
    },

    methods: {
        addVehicleDocument(description, fileId) {
            this.vehicle.documents.push({
                description: description,
                storageFileName: fileId,
                arrayOrder: this.vehicle.documents.length
            })
            risc.putVehicle(this.vehicle).then(() => {
                this.getVehicle()
            });
        },
        arrayify(start, end, addToEnd) {
            let result = [];
            for (let i = start; i < end + 1; i++) {
                if (addToEnd) {
                    if (i > 9) {
                        result.push({text: i.toString(), value: i})
                    } else if (i >= 0) {
                        result.push({text: "0" + i, value: i})
                    }
                } else {
                    if (i > 9) {
                        result.unshift({text: i.toString(), value: i})
                    } else if (i >= 0) {
                        result.unshift({text: "0" + i, value: i})
                    }
                }
            }
            return (result);
        },
        changeDocumentDesc(doc) {
            this.vehicle.documents.forEach(document => {
                if (document.documentId === doc.documentId) {
                    document.description = doc.description
                }
            })
            risc.putVehicle(this.vehicle).then(() => {
                this.getVehicle();
            });
        },
        closeViewModal() {
            this.fileViewerFlag = false;
        },
        deleteEmbeddedDoc(doc) {
            this.vehicle.documents = this.vehicle.documents.filter((document) => {
                return document.documentId !== doc.documentId;
            });
            risc.putVehicle(this.vehicle).then(() => {
                this.getVehicle();
            });
        },
        deleteVehicle() {
            this.disableButtonFlag = true;
            risc.unlinkProfileVehicle(this.linkId, this.deleteObjects.id).then(() => {
                this.deleteObjects.modal = false;
                this.disableButtonFlag = false;
                this.$emit('deleteVehicle', this.deleteObjects.id)
            })
        },
        encodeVehicleVIN() {
            axios
                .get('https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/' + this.vin + '*BA?format=json')
                .then(response => {
                    response.data.Results.forEach(result => {
                        if (result.Variable === 'Make') {
                            this.vehicleInfo.data.make = result.Value;
                            this.vehicleMake = result.Value;
                        }
                        if (result.Variable === 'Model Year') {
                            this.vehicleInfo.data.year = result.Value;
                        }
                        if (result.Variable === 'Model') {
                            this.vehicleInfo.data.model = result.Value;
                        }
                    })
                })
                .catch(() => {})
        },
        getVehicle(callback) {
            risc.getVehicle(this.vehicle.vehicleId).then(response => {
                this.vehicle = response.data;
                this.moreInfo = true;
                if(callback) {
                    callback();
                }
            })
        },
        getFirstDocumentImage(docArray) {
            let stId = 0;
            docArray.forEach(doc => {
                if (doc.arrayOrder === 0) {
                    stId = doc.storageFileName;
                }
            })
            if (stId !== 0) {
                return risc.getImage(stId);
            } else {
                return risc.getImage(docArray[0].storageFileName);
            }
        },
        getAllMakes(callback) {
            this.allMakes = [];
            axios
                .get('https://vpic.nhtsa.dot.gov/api/vehicles/GetAllMakes?format=json')
                .then(response => {
                    this.allMakes = response.data.Results.map(i => ({
                        text: i.Make_Name,
                        value: i.Make_ID
                    }));
                    callback();
                })
                .catch(() => {
                    callback();
                    this.$mToast({
                        style: 'error',
                        title: 'Error Loading Vehicle API',
                        text: 'Vehicle API did not load correctly, contact Montel Support.'
                    });
                })
        },
        getAllModels() {
            let temp = this.allMakes.find(_ => _.value === this.vehicleMake)
            if(temp) {
                axios
                    .get('https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMakeId/' + temp.value + '?format=json')
                    .then(response => {
                        this.allModels = response.data.Results.map(i => i.Model_Name);
                    })
                    .catch(() => {
                        this.$mToast({
                            style: 'error',
                            title: 'Error Loading Vehicle API',
                            text: 'Vehicle API did not load correctly, contact Montel Support.'
                        });
                    })
            }
        },
        getMoreInfo() {
            if(this.moreInfo === false) {
                this.getVehicle(() => {
                    this.noteExpand = true;
                });
            } else {
                this.noteExpand = true;
            }
        },
        oneFilledOut(obj, fields) {
            if(this.disableButtonFlag === true){
                return this.disableButtonFlag;
            }
            let result = false;
            let newObj = Object.assign({}, JSON.parse(JSON.stringify(obj)));
            Object.keys(fields).forEach((field, index) => {
                if (index + 1 === Object.keys(fields).length) {
                    result = JSON.stringify(newObj) === JSON.stringify(fields);
                }
            })
            return result;
        },
        openModalDeleteObject(id) {
            this.deleteObjects.modal = true
            this.deleteObjects.deleteText = ''
            this.deleteObjects.id = id
        },
        openModalEditVehicles(item) {
            if(this.moreInfo === false) {
                this.getVehicle(() => {
                    this.getAllMakes(() => {
                        this.vehicleInfo.data = JSON.parse(JSON.stringify(this.vehicle));
                        this.vehicleMake = this.vehicleInfo.data.make
                        this.vehicleInfo.vehicleEdit = true;
                    });
                });
            } else {
                this.getAllMakes(() => {
                    this.vehicleInfo.data = JSON.parse(JSON.stringify(this.vehicle));
                    this.vehicleMake = this.vehicleInfo.data.make
                    this.vehicleInfo.vehicleEdit = true;
                });
            }
        },
        openViewModal(storageId) {
            this.fileViewStorageId = storageId;
            this.fileViewerFlag = true;
        },
        putVehicle() {
            this.disableButtonFlag = true;
            this.vehicleInfo.data.vin = this.vin;
            this.vehicleInfo.data.make = this.allMakes.find(_ => _.value === this.vehicleMake) ? this.allMakes.find(_ => _.value === this.vehicleMake).text : this.vehicleMake;
            risc.putVehicle(this.vehicleInfo.data).then(() => {
                this.getVehicle();
                this.disableButtonFlag = false;
            }).catch(err => {
                this.disableButtonFlag = false;
            })
            this.vehicleInfo.vehicleEdit = false
            this.vin = '';
            this.vehicleMake = '';
        },
        push(field, item) {
            if (this.notesNewToOld === true) {
                field.unshift(JSON.parse(JSON.stringify(item)));
            } else {
                field.push(JSON.parse(JSON.stringify(item)));
            }
            this.refresh = false;
            this.refresh = true;
        },
        refactorScope(arr) {
            return arr ? arr.map(_ => {_.scope = !_.text ? this.scope : _.scope; return _}): []
        },
        setFileFavorite(files) {
            this.vehicle.documents = files
            risc.putVehicle(this.vehicle).then(() => {
                this.getVehicle();
            });
        },
        splice(index, field, item) {
            field.splice(index, 1);
            if (item && field.length === 0) {
                this.push(field, item)
            }
            this.refresh = false;
            this.refresh = true;
        },
    },

    watch: {
        getImages() {
            if (this.getImages === true && this.moreInfo === false) {
                this.getVehicle();
                this.moreInfo = true;
            }
        },
        vehicleMake() {
            this.getAllModels();
        },
        vehicleObject() {
            this.vehicle = this.vehicleObject;
        }
    }
}
</script>

<style scoped>

</style>