
<template>
    <div>
        <b-modal class="modal-dark" :id="'print-doc-' + printType" no-close-on-backdrop scrollable title="Print Profile">
            <b-tabs v-if="open && options && options.wanted" style="margin: -16px">

                <b-tab title="General" active>
                        <div style="margin-top: 10px">Case Number</div>
                        <b-form-input v-model="options.wanted.caseNumber"/>

                        <div style="margin-top: 10px">Offense</div>
                        <b-form-input v-model="options.wanted.offense"/>

                        <div style="margin-top: 10px">Bond</div>
                        <b-form-input v-model="options.wanted.bond"/>

                        <div style="margin-top: 10px">Notes</div>
                        <b-form-textarea v-model="options.wanted.notes"/>

                        <div style="margin-top: 10px">Unit</div>
                        <b-form-input v-model="options.wanted.unit"/>

                        <div style="margin-top: 10px">Detective/Officer</div>
                        <b-form-input v-model="options.wanted.author"/>

                        <div style="margin-top: 10px">Approved by Supervisor/Rank</div>
                        <b-form-input v-model="options.wanted.approvedBy"/>

                        <div style="margin-top: 10px">Phone</div>
                        <masked-input placeholder="(999) 999-9999" type="tel" :guide="true" class="form-control"
                                      v-model="options.wanted.phone" :mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]" />
                </b-tab>

                <b-tab title="Information Cards">
                    <div>
                        <span style="display: flex; justify-content: space-between">
                            <div>ADDRESSES</div>
                            <b-checkbox v-model="options.images.addresses" style="padding: 0 25px 0 0">
                                IMAGES
                            </b-checkbox>
                        </span>
                        <div v-for="address in options.otherInfo.addresses.children">
                            <b-checkbox v-model="options.wanted.otherObjects" :value="addTitle(address, 'ADDRESS')" :disabled="isWantedDisabled(address)">
                                {{address.name}}
                            </b-checkbox>
                        </div>

                        <span style="display: flex; justify-content: space-between">
                            <div>VEHICLES</div>
                            <b-checkbox v-model="options.images.vehicles" style="padding: 0 25px 0 0">
                                IMAGES
                            </b-checkbox>
                        </span>
                        <div v-for="vehicle in options.otherInfo.vehicles.children">
                            <b-form-checkbox v-model="options.wanted.otherObjects" :value="addTitle(vehicle, 'VEHICLE')" :disabled="isWantedDisabled(vehicle)">
                                {{vehicle.name}}
                            </b-form-checkbox>
                        </div>

                        <span style="display: flex; justify-content: space-between">
                            <div>LINKED ASSOCIATES</div>
                            <b-checkbox v-model="options.images.linkedAssociates" style="padding: 0 25px 0 0">
                                IMAGES
                            </b-checkbox>
                        </span>
                        <div v-for="linkedAssociate in options.otherInfo.linkedAssociates.children">
                            <b-checkbox v-model="options.wanted.otherObjects" :value="addTitle(linkedAssociate, 'LINKED ASSOCIATE')" :disabled="isWantedDisabled(linkedAssociate)">
                                {{linkedAssociate.name}}
                            </b-checkbox>
                        </div>

                        <span style="display: flex; justify-content: space-between">
                            <div>OTHER ASSOCIATES</div>
                            <b-checkbox v-model="options.images.otherAssociates" style="padding: 0 25px 0 0">
                                IMAGES
                            </b-checkbox>
                        </span>
                        <div v-for="associate in options.otherInfo.associates.children">
                            <b-checkbox v-model="options.wanted.otherObjects" :value="addTitle(associate, 'OTHER ASSOCIATE')" :disabled="isWantedDisabled(associate)">
                                {{associate.name}}
                            </b-checkbox>
                        </div>
                    </div>
                </b-tab>

                <b-tab title="Profile Information">
                    <b-checkbox v-model="options.name"> NAME </b-checkbox>
                    <b-checkbox v-model="options.image"> IMAGE </b-checkbox>
                    <b-checkbox v-model="options.general.show"> GENERAL INFO </b-checkbox>
                    <div style="padding-left: 24px">
                        <b-checkbox v-model="options.general.streetnames.show" :disabled="!options.general.show">
                            STREET NAMES
                        </b-checkbox>
                        <div style="padding-left: 24px" v-for="sName in options.general.streetnames.children">
                            <b-checkbox v-model="sName.show" :disabled="!options.general.show || !options.general.streetnames.show">
                                {{sName.name}}
                            </b-checkbox>
                        </div>
                        <b-checkbox v-model="options.general.employer" :disabled="!options.general.show">
                            EMPLOYER
                        </b-checkbox>
                        <b-checkbox v-model="options.general.dob" :disabled="!options.general.show">
                            DOB
                        </b-checkbox>
                        <b-checkbox v-model="options.general.race" :disabled="!options.general.show">
                            RACE
                        </b-checkbox>
                        <b-checkbox v-model="options.general.sex" :disabled="!options.general.show">
                            SEX
                        </b-checkbox>
                        <b-checkbox v-model="options.general.height" :disabled="!options.general.show">
                            HEIGHT
                        </b-checkbox>
                        <b-checkbox v-model="options.general.weight" :disabled="!options.general.show">
                            WEIGHT
                        </b-checkbox>
                        <b-checkbox v-model="options.general.ssn" :disabled="!options.general.show">
                            SSN
                        </b-checkbox>
                        <b-checkbox v-model="options.general.mid" :disabled="!options.general.show">
                            MID
                        </b-checkbox>
                        <b-checkbox v-model="options.general.sid" :disabled="!options.general.show">
                            SID
                        </b-checkbox>
                        <b-checkbox v-model="options.general.fbi" :disabled="!options.general.show">
                            FBI
                        </b-checkbox>
                        <b-checkbox v-model="options.general.ids.show" :disabled="!options.general.show">
                            IDs
                        </b-checkbox>
                        <div style="padding-left: 24px" v-for="id in options.general.ids.children">
                            <b-checkbox v-model="id.show" :disabled="!options.general.show || !options.general.ids.show">
                                {{id.name}}
                            </b-checkbox>
                        </div>

                        <b-checkbox v-model="options.general.addresses.show" :disabled="!options.general.show">
                            ADDRESSES
                        </b-checkbox>
                        <div style="padding-left: 24px" v-for="address in options.general.addresses.children">
                            <b-checkbox v-model="address.show" :disabled="!options.general.show || !options.general.addresses.show">
                                {{address.name}}
                            </b-checkbox>
                        </div>

                        <b-checkbox v-model="options.general.alias.show" :disabled="!options.general.show">
                            ALIAS
                        </b-checkbox>
                        <div style="padding-left: 24px" v-for="alias in options.general.alias.children">
                            <b-checkbox v-model="alias.show" :disabled="!options.general.show || !options.general.alias.show">
                                {{alias.name}}
                            </b-checkbox>
                        </div>

                        <b-checkbox v-model="options.general.orgs.show" :disabled="!options.general.show">
                            ORGANIZATIONS
                        </b-checkbox>
                        <div style="padding-left: 24px" v-for="org in options.general.orgs.children">
                            <b-checkbox v-model="org.show" :disabled="!options.general.show || !options.general.orgs.show">
                                {{org.name}}
                            </b-checkbox>
                        </div>

                        <b-checkbox v-model="options.general.phone.show" :disabled="!options.general.show">
                            PHONE NUMBERS
                        </b-checkbox>
                        <div style="padding-left: 24px" v-for="phone in options.general.phone.children">
                            <b-checkbox v-model="phone.show" :disabled="!options.general.show || !options.general.phone.show">
                                {{phone.name}}
                            </b-checkbox>
                        </div>

                        <b-checkbox v-model="options.general.social.show" :disabled="!options.general.show">
                            SOCIAL MEDIA
                        </b-checkbox>
                        <div style="padding-left: 24px" v-for="social in options.general.social.children">
                            <b-checkbox v-model="social.show" :disabled="!options.general.show || !options.general.social.show">
                                {{social.name}}
                            </b-checkbox>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>

            <!--Cancel and Submit Buttons-->
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="close(printType)">Close</b-button>
                <b-button size="sm" variant="primary" :disabled="printing === true" @click="print(printType);">Print
                    <fa-icon v-if="printing === true" :icon="['fas', 'circle-notch']" spin/>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import {printSetupMixin} from "@/views/expansion_modules/risc/profile_management/printSetups/PrintSetupMixin";
import MaskedInput from 'vue-text-mask';
/*import ClaimsTable from "@/components/ClaimsTable";
import TestComponent from "@/views/admin/Component";
import claimsStore from "@/store/claimsStore";*/

export default {
    name: "WantedPrintSetup",
    mixins: [printSetupMixin],
    components: { MaskedInput },
    data: () => {
        return {
            printType: 2,
        }
    },
    watch: {
        open() {
            if (this.open) {
                if (this.updateOptions()) {
                    this.$bvModal.show('print-doc-' + this.printType);
                }
            }
        }
    },
    methods: {
        isWantedDisabled(data) {
            return this.options.wanted.otherObjects.length > 4 && !this.options.wanted.otherObjects.includes(data)
        }
    }
}
</script>

<style scoped>

</style>