<template>
    <div>

        <!--Modals-->
        <span>

            <!--File Selector-->
            <FileSelector2 :flag="fileSelectorFlag" :files="fileSelectorFiles" :submitMethod="setImageFavorite" :closeMethod="closeSelector"/>

            <!--Edit Profile-->
            <b-modal class="modal-dark" no-close-on-backdrop scrollable title="Profile Editor" v-model="editProfile"
                     v-if="editProfile">
                <b-row>
                    <b-col sm="6">
                        <b-form-group>
                            <label class="labelForm">First</label>
                            <b-form-input
                                    v-model="profileEdit.firstName" v-uppercase/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group>
                            <label class="labelForm">Last</label>
                            <b-form-input
                                    v-model="profileEdit.lastName" v-uppercase/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="10">
                        <b-form-group>
                            <label class="labelForm">Middle</label>
                            <b-form-input v-model="profileEdit.middleName" v-uppercase/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="2">
                        <b-form-group>
                            <label class="labelForm">Suffix</label>
                            <b-form-input v-model="profileEdit.suffix" v-uppercase/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group>
                            <label class="labelForm">Birth Month</label>
                            <b-form-select
                                    :options="arrayify(1,12, true)"
                                    v-model="profileEdit.birthMonth"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group>
                            <label class="labelForm">Birth Day</label>
                            <b-form-select
                                    :options="arrayify(1,31, true)"
                                    v-model="profileEdit.birthDay"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group>
                            <label class="labelForm">Birth Year</label>
                            <b-form-select :options="arrayify(1900,(new Date()).getFullYear(), false)"
                                           v-model="profileEdit.birthYear"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group>
                            <label class="labelForm">Sex</label>
                            <b-form-select :options="dropdownData.sex" v-model="profileEdit.sex"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group>
                            <label class="labelForm">Race</label>
                            <b-form-select :options="dropdownData.races" v-model="profileEdit.race"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12">
                        <b-form-group>
                            <label class="labelForm">Weight(lb)</label>
                            <b-form-input type="number" v-model.number="profileEdit.weight"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group>
                            <label class="labelForm">Height(ft)</label>
                            <b-form-input type="number" v-model.number="profileEdit.heightFeet"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group>
                            <label class="labelForm">Height(in)</label>
                            <b-form-input type="number" v-model.number="profileEdit.heightInch"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group>
                            <label class="labelForm">MID</label>
                            <b-form-input v-model="profileEdit.mid" v-uppercase/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group>
                            <label class="labelForm">SID</label>
                            <b-form-input v-model="profileEdit.sid" v-uppercase/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group>
                            <label class="labelForm">FBI</label>
                            <b-form-input v-model="profileEdit.fbi" v-uppercase/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group>
                            <label class="labelForm">Social Security</label>
                            <b-form-input v-mask="'###-##-####'" placeholder="___-__-____"
                                          v-model="profileEdit.socialSecurity"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group>
                            <label class="labelForm">Employer</label>
                            <b-form-input v-model="profileEdit.employer" v-uppercase/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12">
                        <label class="labelForm">Ribbons</label>
                        <b-row>
                            <b-col sm="4" style="display: flex" v-for="(ribbon, index) in dropdownData.ribbon"
                                   :key="index + 'r'">
                                <span v-if="(profileEdit.ribbons && profileEdit.ribbons.length < 3) || (profileEdit.ribbons.map(r => r.ribbon).includes(ribbon) && (profileEdit.ribbons && profileEdit.ribbons.length === 3))"
                                      style="display: flex">
                                    <b-form-checkbox v-model="profileEdit.ribbons" :value="{ribbon: ribbon}"/>{{ribbon}}
                                </span>
                                <span v-else style="display: flex; color: grey">
                                    <b-form-checkbox v-model="profileEdit.ribbons" :value="{ribbon: ribbon}" disabled/>
                                    <span style="color: grey">{{ribbon}}</span>
                                </span>
                            </b-col>
                        </b-row>
                        <br>
                    </b-col>
                    <b-col sm="12">
                        <label class="labelForm">Alias</label> &nbsp;
                        <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(profileEdit.alias, fields.alias[0])"/>
                        <b-list-group :key="i" v-for="(e,i) in profileEdit.alias">
                            <b-list-group-item>
                                <strong>Alias #{{i+1}}</strong>
                                <fa-icon :icon="['fas', 'close']" @click="splice(i, profileEdit.alias, fields.alias[0])" style="float:right; cursor: pointer;"
                                   v-if="(profileEdit.alias && profileEdit.alias.length > 1) || (e.notes[0] !== '' && e.knownAlias !== '')"/>
                                <b-input-group class="input-group-sm" prepend="Name" style="margin-bottom: 1px">
                                    <b-form-input v-model="profileEdit.alias[i].knownAlias" v-uppercase/>
                                </b-input-group>
                                <b-input-group class="input-group-sm" prepend="Notes">
                                    <b-form-input v-model="profileEdit.alias[i].notes[0].text"/>
                                </b-input-group>
                            </b-list-group-item>
                        </b-list-group>
                        <br>
                    </b-col>
                    <b-col sm="12">
                        <label class="labelForm">Street Names</label>&nbsp;
                        <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;"
                                 @click="push(profileEdit.streetName, fields.streetName[0])"/>
                        <b-list-group :key="i" v-for="(e,i) in profileEdit.streetName">
                            <b-list-group-item>
                                <strong>Street Name #{{i+1}}</strong>
                                <fa-icon :icon="['fas', 'close']" @click="splice(i, profileEdit.streetName, fields.streetName[0])"
                                         style="float:right; cursor: pointer;"
                                   v-if="(profileEdit.streetName && profileEdit.streetName.length > 1) || (e.notes[0] !== '' && e.streetName !== '')"/>
                                <b-input-group class="input-group-sm" prepend="Name" style="margin-bottom: 1px">
                                    <b-form-input v-model="profileEdit.streetName[i].streetName" v-uppercase/>
                                </b-input-group>
                                <b-input-group class="input-group-sm" prepend="Notes">
                                    <b-form-input v-model="profileEdit.streetName[i].notes[0].text"/>
                                </b-input-group>
                            </b-list-group-item>
                        </b-list-group>
                        <br>
                    </b-col>
                </b-row>
                <template slot="modal-footer">
                    <b-button @click="editProfile = false" size="sm" variant="secondary">Cancel</b-button>
                    <b-button v-if="editProfile" :disabled="oneFilledOut(profileEdit,fields)" @click="putProfile" size="sm"
                              variant="primary">Submit
                    </b-button>
                </template>
            </b-modal>

            <!--Delete Profile-->
            <b-modal :title='"Remove \"" + nameify(profile) + "\""' class="modal-dark" ok-title="Submit" scrollable no-close-on-backdrop
                     v-model="deleteProfileModal" v-if="deleteProfileModal" @close="deleteProfileModal = false; deleteVerification = null">
                <p>If you would like to delete this profile please type the name of the person you are trying to remove.</p>
                <br>
                <p style="margin-bottom: 0">Enter "{{nameify(profile)}}":</p>
                <b-form-group description="case sensitive">
                    <b-form-input @keyup.enter="deleteEnterButton" id="basicName2" type="text"
                                  v-model="deleteVerification"></b-form-input>
                </b-form-group>
                <template slot="modal-footer">
                    <b-button @click="deleteProfileModal = false; deleteVerification = null" size="sm" variant="secondary">
                        Cancel
                    </b-button>
                    <b-button :disabled="deleteVerification !== nameify(profile)" @click="deleteProfile" size="sm"
                              variant="danger">
                        Submit
                    </b-button>
                </template>
            </b-modal>

            <!--PrintSetups-->
            <b-modal class="modal-dark" scrollable title="Print Format" v-model="openPrintOptions" no-close-on-backdrop>
                <div class="d-flex justify-content-between">
                    <b-button size="sm" variant="info" @click="openPrintSetup = 1; openPrintOptions = false; printOptions = {}">Profile Print</b-button>
                    <b-button size="sm" variant="danger" @click="openPrintSetup = 2; openPrintOptions = false; printOptions = {}">Wanted Print</b-button>
                    <b-button size="sm" variant="warning" @click="openPrintSetup = 3; openPrintOptions = false; printOptions = {}">Wanted For Questioning Print</b-button>
                    <b-button size="sm" variant="success" @click="openPrintSetup = 4; openPrintOptions = false; printOptions = {}">Officer Safety</b-button>
                </div>
                <template slot="modal-footer">
                    <b-button @click="openPrintOptions = false" size="sm" variant="secondary">Cancel</b-button>
                </template>
            </b-modal>

        </span>

        <!--Main Page-->
        <b-card no-body style="height: fit-content">

            <!--Header-->
            <div slot="header">
                <span class="d-flex w-100 justify-content-between flex-wrap">
                    <h5 style="margin-bottom: 0"><b>{{nameify(profile)}}</b></h5>
                    <span style="display: inline-block; white-space: nowrap; cursor: pointer;">
                        <fa-icon v-if="printReady" :icon="['fas', 'print']" size="lg" @click="openPrintSetup = 0; openPrintOptions = true"/>
                        <fa-icon :icon="['fas', 'pen-to-square']" size="lg" @click="showEditModal" style="padding-left: 10px"/>
                        <fa-icon :icon="['fas', 'trash-can']" size="lg" @click="deleteProfileModal = true" style="padding-left: 10px"/>
                    </span>
                </span>
            </div>

            <!--Body-->
            <b-container fluid no-body style="padding: 7px 0 0 0">

                <!--Picture/Info/Tabs/Notes/Map-->
                <b-row style="width: 100%; margin: 0; padding: 0;">

                    <!--Picture/Info-->
                    <b-col sm="3" style="padding: 0 7px 0 7px">

                        <!--Picture-->
                        <b-card bg-variant="dark" footer-tag="footer"
                                header-tag="header" no-body
                                style="width: 100%; height: 536px; margin-bottom: 10px; display: flex; flex-direction: column">
                            <div class="d-flex w-100 justify-content-between" slot="header">
                                <span>
                                    Source:
                                    <span v-if="!profile.images || profile.images.length === 0">Satellite</span>
                                    <span v-else-if="(profile.images && profile.images.length > 0) && profile.images[0].description !== undefined && profile.images[0].description !== ''">
                                        {{profile.images[0].description}}
                                    </span>
                                    <span v-else>Unknown</span>
                                </span>
                                <fa-icon :icon="['fas', 'right-left']" @click="openModalFileSelector(profile.images)"
                                         style="float: right; cursor: pointer;"/>
                            </div>
                            <span style="height: 459px; display: flex; overflow: hidden; justify-content: center; align-items: center; flex-grow: 1; background: #1F1F1F; position: relative">
                                <img v-if="profile.images && profile.images.length > 0" v-auth-image="getImage(profile.images[0].storageFileName)" alt="" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%">
                                <div v-if="profile.images && profile.images.length > 0" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; backdrop-filter: blur(50px)"/>
                                <img v-auth-image="getImage(profile.images[0].storageFileName)" alt="" style="position: absolute"
                                     class="img-center"
                                     v-if="profile.images && profile.images.length > 0 && profile.ribbons && profile.ribbons.length > 0"/>
                                <img class="img-center-no-ribbon"
                                     v-auth-image="getImage(profile.images[0].storageFileName)"
                                     v-else-if="profile.images && profile.images.length > 0" alt=""/>
                                <img class="img-center" src="/img/NoPictureOnFileGrey.png"
                                     v-else-if="profile.ribbons && profile.ribbons.length > 0" alt=""/>
                                <img v-else class="img-center-no-ribbon" src="/img/NoPictureOnFileGrey.png" alt=""/>
                            </span>
                            <div v-if="profile.ribbons && Array.isArray(profile.ribbons) && profile.ribbons.length > 0"
                                  style="display: flex; align-items: center; height: 20px">
                                <div v-for="(r, i) in profile.ribbons" :key="i + 'r1'"
                                      :class="'bg-' + ribbonVariant(r.ribbon)"
                                      style="flex: 1; height: 100%; display: flex; align-items: center">
                                    <p style="margin: 0; width: 100%; text-align: center">{{r.ribbon}}</p>
                                </div>
                            </div>
                        </b-card>

                        <!--Profile Info-->
                        <b-card bg-variant="dark" header-tag="header" no-body
                                style="width: 100%; height: 537px; margin: 0">
                            <div slot="header">
                                <strong>Profile Information</strong>
                            </div>
                            <div style="font-size: 18px; color: #b8b8b8; height: 488px; overflow-y: auto; padding-left: 5px">
                                <div><strong style="color: white">Name: </strong>{{nameify(profile)}}</div>
                                <div><strong style="color: white">Suffix: </strong>{{profile.suffix}}</div>
                                <div class="d-flex justify-content-between flex-wrap">
                                    <span>
                                        <strong style="color: white">Sex: </strong>
                                        {{profile.sex}}
                                    </span>
                                    <span>
                                        <strong style="color: white">Race: </strong>
                                        {{profile.race}}
                                    </span>
                                    <span/>
                                </div>
                                <div class="d-flex justify-content-between flex-wrap">
                                    <span>
                                        <strong style="color: white">Height: </strong>
                                        {{toFtIn(profile.heightFeet,profile.heightInch)}} ({{toIn(profile.heightFeet,profile.heightInch)}}in)
                                    </span>
                                    <span>
                                        <strong style="color: white">Weight: </strong>
                                        {{profile.weight}} lbs.
                                    </span>
                                    <span/>
                                </div>
                                <div><strong style="color: white">DOB: </strong><span v-if="profile.birthMonth || profile.birthYear || profile.birthDay">{{dateifyProfile(profile.birthMonth,profile.birthDay,profile.birthYear)}}</span> {{profileAge}}</div>
                                <div><strong style="color: white">Alias: </strong>{{aliasify(profile.alias)}}</div>
                                <div><strong style="color: white">Street Name(s): </strong>{{streetNameify(profile.streetName)}}</div>
                                <div><strong style="color: white">MID: </strong>{{profile.mid}}</div>
                                <div><strong style="color: white">SID: </strong>{{profile.sid}}</div>
                                <div><strong style="color: white">FBI: </strong>{{profile.fbi}}</div>
                                <div><strong style="color: white">SSN: </strong>{{profile.socialSecurity}}</div>
                                <div><strong style="color: white">Employer: </strong>{{profile.employer}}</div>
                                <div style="margin-bottom: 0; color: white; overflow-x: hidden"><strong>Organization Affiliation(s):</strong></div>
                                <div v-for="(organization, index) in profile.organizations" :key="index + 'org'">
                                    {{organization.orgName}}
                                    <router-link class="non-link"
                                                 :to="{name: 'Organization Details', params: {id: organization.organizationId}}"
                                                 target="_blank">
                                        <fa-icon :icon="['fas', 'up-right-from-square']"/>
                                    </router-link>
                                    <span v-show="profile.organizations && index !== profile.organizations.length - 1">,</span>
                                </div>
                            </div>
                        </b-card>

                    </b-col>

                    <!--Tabs-->
                    <b-col sm="6" style="padding: 0">
                        <b-card bg-variant="dark" no-body style="border-color: #000001; background-color: #0e0e0e !important; margin-bottom: 0; width: 100%; height: 1083px">
                            <b-tabs lazy fluid style="height: 1083px" class="d-flex flex-column" varient="dark">

                                <!--Social Media-->
                                <b-tab class="active tab-scroll" title="Social Media">
                                    <SocialMediaProfileTabComponent :socialMedias="profile.socialMedia"
                                                                    :editable="true"
                                                                    @updateSocialMedia="updateSocialMedia"
                                                                    @deleteSocialMedia="deleteSocialMedia"
                                                                    @addSocialMedia="addSocialMedia"/>
                                </b-tab>

                                <!--Phone Numbers-->
                                <b-tab class="tab-scroll" title="Phone Numbers">
                                    <PhoneNumberProfileTabComponent :phones="profile.phone"
                                                                    :editable="true"
                                                                    @updatePhone="updatePhone"
                                                                    @deletePhone="deletePhone"
                                                                    @addPhone="addPhone"/>
                                </b-tab>

                                <!--Vehicles-->
                                <b-tab class="tab-scroll" title="Vehicles">
                                    <VehicleTabComponent :vehicleArray="profile.vehicles"
                                                         :scope="profileId"
                                                         :editable="true"
                                                         :linkId="id.toString()"
                                                         :linkType="'Profile'"
                                                         @deleteVehicle="deleteVehicle"/>
                                </b-tab>

                                <!--Addresses-->
                                <b-tab class="tab-scroll" title="Addresses">
                                    <AddressTabComponent :addresses="profile.addresses"
                                                         :editable="true"
                                                         :scope="profileId"
                                                         :linkId="id.toString()"
                                                         :linkType="'Profile'"
                                                         :color="'blue'"
                                                         @deleteAddress="deleteAddress"/>
                                </b-tab>

                                <!--Drugs-->
                                <b-tab class="tab-scroll" title="Drugs">
                                    <DrugsTabComponent :hasDrugHistory="profile.hasDrugHistory"
                                                       :drugs="profile.drugs"
                                                       :scope="profileId"
                                                       :linkId="id.toString()"
                                                       :editable="true"
                                                       :drugNotes="profile.profileDrugNotes"
                                                       @deleteDrug="deleteDrug"
                                                       @editDrugHistory="editDrugHistory"
                                                       @deleteDrugNote="deleteDrugNote"
                                                       @addDrugNote="addDrugNote"
                                                       @editDrugNote="editDrugNote"/>
                                </b-tab>

                                <!--Weapons-->
                                <b-tab class="tab-scroll" title="Weapons">
                                    <WeaponsTabComponent :hasWeaponHistory="profile.hasWeaponHistory"
                                                         :weapons="profile.weapons"
                                                         :scope="profileId"
                                                         :linkId="id.toString()"
                                                         :editable="true"
                                                         :weaponNotes="profile.profileWeaponNotes"
                                                         @deleteWeapon="deleteWeapon"
                                                         @editWeaponHistory="editWeaponHistory"
                                                         @addWeaponNote="addWeaponNote"
                                                         @editWeaponNote="editWeaponNote"
                                                         @deleteWeaponNote="deleteWeaponNote"/>
                                </b-tab>

                                <!--LE-Reports-->
                                <b-tab class="tab-scroll" title="LE-Reports">
                                    <LeReportTabComponent :scope="profileId"
                                                          :editable="true"
                                                          :leReportsObject="profile.leReports"
                                                          :linkId="id.toString()"
                                                          :linkType="'Profile'"
                                                          @deleteLeReport="deleteLeReport"
                                                          @updateLeReport="updateLeReport"/>
                                </b-tab>

                                <!--IDs-->
                                <b-tab class="active tab-scroll" title="IDs">
                                    <IdTabComponent :ids="profile.identifications"
                                                    :editable="true"
                                                    @updateId="updateId"
                                                    @deleteId="deleteId"
                                                    @addId="addId"/>
                                </b-tab>

                                <!--Felonies-->
                                <b-tab class="tab-scroll" title="Felonies">
                                    <FelonyTabComponent :felonies="profile.felonies"
                                                        :editable="true"
                                                        @addFelony="addFelony"
                                                        @updateFelony="updateFelony"
                                                        @deleteFelony="deleteFelony"/>
                                </b-tab>

                                <!--Criminal History-->
                                <b-tab class="active tab-scroll" title="Criminal History">
                                    <CriminalHistoryTabComponent :criminalHistories="profile.criminalHistories"
                                                                 :editable="true"
                                                                 @updateCriminalHistory="updateCriminalHistory"
                                                                 @deleteCriminalHistory="deleteCriminalHistory"
                                                                 @addCriminalHistory="addCriminalHistory"/>
                                </b-tab>

                                <!--Markings-->
                                <b-tab class="tab-scroll" title="S/M/T">
                                    <MarkingTabComponent :markingsObject="profile.markings.filter(marking => this.markingTypeOptions.includes(marking.type))"
                                                         :editable="true"
                                                         :scope="profileId"
                                                         :other="false"
                                                         @updateMarking="updateMarking"
                                                         @deleteMarking="deleteMarking"
                                                         @addMarking="addMarking"/>
                                </b-tab>

                                <!--Other Descriptors-->
                                <b-tab class="tab-scroll" title="Other Descriptors">
                                    <MarkingTabComponent :markingsObject="profile.markings.filter(marking => !this.markingTypeOptions.includes(marking.type))"
                                                         :editable="true"
                                                         :scope="profileId"
                                                         :other="true"
                                                         @updateMarking="updateMarking"
                                                         @deleteMarking="deleteMarking"
                                                         @addMarking="addMarking"/>
                                </b-tab>

                                <!--Documents Tab-->
                                <b-tab class="tab-scroll" title="Documents/Media">
                                    <DocumentTabComponent :editable="true"
                                                          :documents="profile.images"
                                                          :editMethod="changeDocumentDesc"
                                                          :deleteMethod="deleteEmbeddedDoc"
                                                          :submitUploadMethod="addProfileDocument"/>
                                </b-tab>

                            </b-tabs>
                        </b-card>
                    </b-col>

                    <!--Notes/Map-->
                    <b-col sm="3" style="padding: 0 7px 0 7px;">

                        <!--Notes-->
                        <NotesPageComponent :notes="profile.notes" :addMethod="addNote" :editMethod="editNote" :deleteMethod="deleteNote"/>

                        <!--Map-->
                        <MapComponent :height="537"/>

                    </b-col>
                </b-row>
                
                <!--Associates-->
                <b-row>
                    <b-col sm="12" style="padding: 10px 7px 7px 7px;">
                        <b-col sm="12">
                            <b-card bg-variant="dark" style="margin: 0">

                                <!--Header-->
                                <div slot="header">
                                    <div class="d-flex w-100 justify-content-between">
                                        <div v-if="profileObjects.associates.selected === 'profile'" class="d-flex">
                                            <h5 style="margin-bottom: 0; margin-right: 10px;"><b>Associates</b></h5>&nbsp;
                                            <b-badge class="badge-success"
                                                     style="height: fit-content; align-self: center; cursor: pointer;"
                                                     @click="addAssociate = !addAssociate">
                                                <fa-icon :icon="['fas', 'plus']"/> Add
                                            </b-badge>
                                        </div>
                                        <div v-if="profileObjects.associates.selected === 'linked'" class="d-flex">
                                            <h5 style="margin-bottom: 0; margin-right: 10px;"><b>Associates</b></h5>&nbsp;
                                            <b-badge class="badge-success"
                                                     style="height: fit-content; align-self: center; cursor: pointer;"
                                                     @click="linkAssociate = !linkAssociate">
                                                <fa-icon :icon="['fas', 'plus']"/> Link
                                            </b-badge>
                                        </div>
                                        <span v-else-if="profileObjects.associates.selected === 'org'" class="d-flex">
                                            <h5 style="margin-bottom: 0; margin-right: 10px;"><b>Associates</b></h5>&nbsp;
                                            <router-link style="transform: translateY(-2px)"
                                                         :to="{ name: 'Organization'}" target="_blank">
                                                <span class="badge badge-primary"
                                                      style="height: fit-content; align-self: center">
                                                    <fa-icon :icon="['fas', 'up-right-from-square']"/> Organizations
                                                </span>
                                            </router-link>
                                        </span>
                                        <span class="d-flex">
                                            <b-form-radio-group v-model="profileObjects.associates.selected">
                                                <b-form-radio value="linked">
                                                    Linked Profiles
                                                </b-form-radio>
                                                <b-form-radio value="profile">
                                                    Other Profiles
                                                </b-form-radio>
                                                <b-form-radio value="org">
                                                    Organization
                                                </b-form-radio>
                                            </b-form-radio-group>
                                        </span>
                                    </div>
                                </div>

                                <!--Associates Displayed-->
                                <span>
                                    <b-row style="margin: 10px" align-v="stretch">

                                        <!--Organization Associates Draggable-->
                                        <draggable v-model="associates.profileAssociatesData"
                                                   v-if="profileObjects.associates.selected === 'org'"
                                                   style="display: flex; flex-wrap: wrap">
                                            <div class="card"
                                                 v-for="(associate0, index) in associates.profileAssociatesData"
                                                 :key="index + 'Id'"
                                                 style="width: 250px; height: 420px; margin: 10px; background-color: #0E0E0E; padding: 0">
                                                <strong class="d-flex justify-content-between"
                                                        style="text-align: center;">
                                                    Organization: {{associate0.relationship}}
                                                    <router-link class="non-link"
                                                                 :to="{ name: 'Profile Details', params: {id: associate0.profileId}}"
                                                                 target="_blank"
                                                                 style="padding-right: 5px">
                                                        <fa-icon :icon="['fas', 'up-right-from-square']"/>
                                                    </router-link>
                                                </strong>
                                                <span class="image-parent" style="position: relative" v-if="associate0.images && associate0.images.length >= 1">
                                                    <img v-auth-image="associatesImage(associate0.images)" alt="" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%">
                                                    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; backdrop-filter: blur(50px)"/>
                                                    <img v-auth-image="associatesImage(associate0.images)"
                                                         class="img-center-ass container-fluid p-0" style="position: absolute" alt=""/>
                                                </span>
                                                <span class="image-parent" v-else>
                                                    <img v-auth-image="associatesImage(associate0.images)"
                                                         class="img-center-ass container-fluid p-0" alt=""/>
                                                </span>
                                                <div v-if="associate0.ribbons && Array.isArray(associate0.ribbons) && associate0.ribbons.length > 0"
                                                      style="display: flex; align-items: center">
                                                    <div v-for="(r, i) in associate0.ribbons" :key="i + 'r1'"
                                                          :class="'bg-' + ribbonVariant(r.ribbon)"
                                                          style="flex: 1; height: 100%; display: flex; align-items: center">
                                                        <p style="margin: 0; width: 100%; text-align: center; font-size: 12px">{{r.ribbon}}</p>
                                                    </div>
                                                </div>
                                                <strong style="text-align: center">
                                                     {{nameify(associate0)}}
                                                </strong>
                                                <strong style="text-align: center">
                                                        <span v-if="associate0.birthMonth">{{associate0.birthMonth}}</span>
                                                        <span v-else>XX</span>
                                                        <span v-if="associate0.birthDay">/{{associate0.birthDay}}</span>
                                                        <span v-else>/XX</span>
                                                        <span v-if="associate0.birthYear">/{{associate0.birthYear}}</span>
                                                        <span v-else>/XXXX</span>
                                                </strong>
                                                <strong style="text-align: center"
                                                        v-if="associate0.addresses !== null && associate0.addresses.length > 0">
                                                    {{findAddress(associate0.addresses)}}
                                                </strong>
                                            </div>
                                        </draggable>

                                        <span v-if="profileObjects.associates.selected === 'org' && (!associates.profileAssociatesData || associates.profileAssociatesData.length === 0)"
                                              class="d-flex w-100"
                                              style="display:flex; justify-content: center; align-items: center">
                                            <b-card style="background-color: #0E0E0E; height: 100%; width: fit-content"
                                                    no-body>
                                                <span class="card-body align-items-center d-flex justify-content-center"
                                                   style="padding-right: 100px; padding-left: 100px; ">
                                                    No Organization Associates
                                                </span>
                                            </b-card>
                                        </span>

                                        <span v-else-if="profileObjects.associates.selected === 'profile' && profile.associates && profile.associates.length === 0" class="d-flex w-100" style="display:flex; justify-content: center; align-items: center">
                                            <b-card style="background-color: #0E0E0E; height: 100%; width: fit-content" no-body>
                                                <span class="card-body align-items-center d-flex justify-content-center"
                                                   style="padding-right: 100px; padding-left: 100px; ">
                                                    No Associates
                                                </span>
                                            </b-card>
                                        </span>

                                        <span v-else-if="profileObjects.associates.selected === 'linked' && profile.profileGroups && profile.profileGroups.length === 0" class="d-flex w-100" style="display:flex; justify-content: center; align-items: center">
                                            <b-card style="background-color: #0E0E0E; height: 100%; width: fit-content" no-body>
                                                <span class="card-body align-items-center d-flex justify-content-center"
                                                   style="padding-right: 100px; padding-left: 100px; ">
                                                    No Linked Associates
                                                </span>
                                            </b-card>
                                        </span>

                                        <ProfileAssociateComponent :associatesObject="profile.associates"
                                                                   :profilesTab="profileObjects.associates.selected"
                                                                   :editable="true"
                                                                   :addAssociate="addAssociate"
                                                                   :linkAssociate="linkAssociate"
                                                                   :linkedProfilesObject="profile.profileGroups"
                                                                   :linkId="id.toString()"
                                                                   @updateAssociates="updateAssociates"
                                                                   @updateLinkedProfiles="updateLinkedProfiles"
                                                                   @getProfiles="getProfiles"/>
                                    </b-row>
                                </span>
                            </b-card>
                        </b-col>
                    </b-col>
                </b-row>
            </b-container>
        </b-card>

        <!--Printable Sheet-->
        <ProfilePrintSetup @printOptions="getPrintOptions" :open="openPrintSetup === 1" :profile="profile" @closed="closePrintOptions()"
                    @print="print"/>
        <ProfilePrint v-if="profilePrint === true" :profile="profile" :alias="aliasify(profile.alias)" :streetName="streetNameify(profile.streetName)"
                      :dateOfBirth="dateifyProfile(profile.birthMonth,profile.birthDay,profile.birthYear)" :markings="profile.markings"
                      :weight="profile.weight" :name="nameify(profile)" :ribbons="profile.ribbons"
                      :height="toFtIn(profile.heightFeet,profile.heightInch)"
                      :organizationAssociates="associates.profileAssociatesData" :printOptions="printOptions" @actuallyPrint="actuallyPrint"/>
        <WantedPrintSetup @printOptions="getPrintOptions" :open="openPrintSetup === 2" :profile="profile" @closed="closePrintOptions()"
                    @print="print"/>
        <WantedPrint v-if="wantedPrint === true" :profile="profile" :alias="aliasify(profile.alias)" :streetName="streetNameify(profile.streetName)"
                     :dateOfBirth="dateifyProfile(profile.birthMonth,profile.birthDay,profile.birthYear)" :markings="profile.markings"
                     :weight="profile.weight" :name="nameify(profile)" :ribbons="profile.ribbons"
                     :height="toFtIn(profile.heightFeet,profile.heightInch)"
                     :organizationAssociates="associates.profileAssociatesData" :printOptions="printOptions" @actuallyPrint="actuallyPrint"/>
        <WantedForQuestioningPrintSetup @printOptions="getPrintOptions" :open="openPrintSetup === 3" :profile="profile" @closed="closePrintOptions()"
                          @print="print"/>
        <WantedForQuestioningPrint v-if="wantedQuestionPrint === true" :profile="profile" :alias="aliasify(profile.alias)" :streetName="streetNameify(profile.streetName)"
                     :dateOfBirth="dateifyProfile(profile.birthMonth,profile.birthDay,profile.birthYear)" :markings="profile.markings" @actuallyPrint="actuallyPrint"
                     :weight="profile.weight" :name="nameify(profile)" :ribbons="profile.ribbons"
                     :height="toFtIn(profile.heightFeet,profile.heightInch)"
                     :organizationAssociates="associates.profileAssociatesData" :printOptions="printOptions"/>
        <OfficerSafetyPrintSetup v-model="printOptions" :open="openPrintSetup === 4" :profile="profile" @closed="closePrintOptions()"
                                        @print="print"/>
        <OfficerSafetyPrint v-if="officerPrint === true" :profile="profile" :alias="aliasify(profile.alias)" :streetName="streetNameify(profile.streetName)"
                                   :dateOfBirth="dateifyProfile(profile.birthMonth,profile.birthDay,profile.birthYear)" :markings="profile.markings"
                                   :weight="profile.weight" :name="nameify(profile)" :ribbons="profile.ribbons"
                                   :height="toFtIn(profile.heightFeet,profile.heightInch)"
                                   :organizationAssociates="associates.profileAssociatesData" :printOptions="printOptions" @actuallyPrint="actuallyPrint"/>

    </div>
</template>

<script>
    import formSelectSearch from "../formSelectSearch";
    import risc from '@/services/risc.js';
    import Vue from 'vue';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    import MaskedInput from 'vue-text-mask'
    import VueMask from 'v-mask';
    import {ClientTable} from 'vue-tables-2';
    import draggable from 'vuedraggable';
    import VueHtmlToPaper from 'vue-html-to-paper';
    import ProfilePrintSetup from "@/views/expansion_modules/risc/profile_management/printSetups/ProfilePrintSetup";
    import ProfilePrint from "@/views/expansion_modules/risc/profile_management/printouts/ProfilePrint";
    import WantedPrintSetup from "@/views/expansion_modules/risc/profile_management/printSetups/WantedPrintSetup";
    import WantedPrint from "@/views/expansion_modules/risc/profile_management/printouts/WantedPrint";
    import WantedForQuestioningPrintSetup from "@/views/expansion_modules/risc/profile_management/printSetups/WantedForQuestioningPrintSetup";
    import WantedForQuestioningPrint from "@/views/expansion_modules/risc/profile_management/printouts/WantedForQuestioningPrint";
    import OfficerSafetyPrintSetup from "@/views/expansion_modules/risc/profile_management/printSetups/OfficerSafetyPrintSetup";
    import OfficerSafetyPrint from "@/views/expansion_modules/risc/profile_management/printouts/OfficerSafetyPrint";

    import VehicleTabComponent from "@/views/expansion_modules/risc/components/vehicle_components/VehicleTabComponent";
    import AddressTabComponent from "@/views/expansion_modules/risc/components/address_components/AddressTabComponent";
    import NotesPageComponent from "@/views/expansion_modules/risc/components/notes_components/NotesPageComponent";
    import LeReportTabComponent from "@/views/expansion_modules/risc/components/lereport_components/LeReportTabComponent";
    import MarkingTabComponent from "@/views/expansion_modules/risc/components/marking_components/MarkingTabComponent";
    import DocumentTabComponent from "@/views/expansion_modules/risc/components/documents_components/DocumentTabComponent";
    import SocialMediaProfileTabComponent from "@/views/expansion_modules/risc/components/socialmediaprofile_components/SocialMediaProfileTabComponent";
    import DrugsTabComponent from "@/views/expansion_modules/risc/components/drugs_components/DrugsTabComponent";
    import PhoneNumberProfileComponent from "@/views/expansion_modules/risc/components/phonenumberprofile_components/PhoneNumberProfileComponent";
    import PhoneNumberProfileTabComponent
        from "@/views/expansion_modules/risc/components/phonenumberprofile_components/PhoneNumberProfileTabComponent";
    import WeaponsTabComponent from "@/views/expansion_modules/risc/components/weapons_components/WeaponsTabComponent";
    import IdTabComponent from "@/views/expansion_modules/risc/components/id_components/IdTabComponent";
    import FelonyTabComponent from "@/views/expansion_modules/risc/components/felony_components/FelonyTabComponent";
    import ProfileAssociateComponent from "@/views/expansion_modules/risc/components/ProfileAssociateComponent";
    import FileSelector2 from "@/views/expansion_modules/risc/components/utility_components/FileSelector2.0";
    import CriminalHistoryTabComponent from "@/views/expansion_modules/risc/components/criminalhistory_components/CriminalHistoryTabComponent";
    import MapComponent from "@/views/expansion_modules/risc/components/MapComponent";
    import PubSub from 'pubsub-js';

    const options = {
        name: '_blank',
        specs: [
            'fullscreen=yes',
            'titlebar=yes',
            'scrollbars=yes',
        ],
        styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css'
        ],
    }

    Vue.use(VueHtmlToPaper, options);
    Vue.use(draggable);
    Vue.use(ClientTable);
    Vue.use(VueMask);

    export default {
        name: "Detail",
        props: ["id"],
        computed: {
            profileAge() {
                if(this.profile.birthYear !== 0) {
                    let profileBirthday = new Date(this.profile.birthYear, this.profile.birthMonth - 1, this.profile.birthDay);
                    if (((Date.now() - profileBirthday) / 31557600000) > 0 && ((Date.now() - profileBirthday) / 31557600000) < 120) {
                        return '(' + ~~((Date.now() - profileBirthday) / (31557600000)).toString() + 'yrs)';
                    }
                }
            }
        },
        components: {
            MapComponent,
            ProfileAssociateComponent,
            CriminalHistoryTabComponent,
            FelonyTabComponent,
            IdTabComponent,
            WeaponsTabComponent,
            PhoneNumberProfileTabComponent,
            PhoneNumberProfileComponent,
            SocialMediaProfileTabComponent,
            DocumentTabComponent,
            MarkingTabComponent,
            DrugsTabComponent,
            ProfilePrintSetup,
            ProfilePrint,
            WantedPrintSetup,
            WantedPrint,
            WantedForQuestioningPrintSetup,
            WantedForQuestioningPrint,
            OfficerSafetyPrintSetup,
            OfficerSafetyPrint,
            formSelectSearch,
            datePicker,
            MaskedInput,
            draggable,
            AddressTabComponent,
            LeReportTabComponent,
            NotesPageComponent,
            VehicleTabComponent,
            FileSelector2
        },
        data() {
            return {
                fileSelectorFlag: false,
                addAssociate: false,
                linkAssociate: false,
                markingTypeOptions: ['TATTOO', 'SCAR', 'MARKING'],
                profileId: "profile" + this.$route.params.id,
                printReady: false,
                openPrintOptions: false,
                openPrintSetup: 0,
                profilePrint: false,
                wantedPrint: false,
                wantedQuestionPrint: false,
                officerPrint: false,
                printOptions: {},
                dropdownData: {
                    ribbon: ['IN CUSTODY', 'PAROLE/PROBATION', 'WANTED', 'OUT OF TOWN', 'DECEASED'],
                    sex: ['MALE', 'FEMALE'],
                    races: [
                        'AMERICAN INDIAN', 'ASIAN', 'BLACK', 'BIRACIAL', 'HISPANIC',
                        'NATIVE HAWAIIAN / OTHER PACIFIC ISLANDER', 'WHITE', 'OTHER'
                    ]
                },
                associates: {
                    profileAssociatesData: [],
                    allMarked: false,
                    tempInfo: [],
                    dataTable: {
                        columns: ['check', 'name', 'bDay'],
                        options: {
                            sortable: ['name', 'bDay'],
                            filterable: ['name', 'bDay'],
                            orderBy: {column: 'name', ascending: true},
                            headings: {
                                name: 'Name',
                                bDay: 'DOB'
                            },
                            sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                            pagination: {
                                chunk: 5,
                                edge: false,
                                nav: 'scroll'
                            },
                            perPage: 25,
                            skin: 'table table-striped table-hover',
                        },
                        useVuex: false,
                        theme: 'bootstrap4',
                        template: 'default',
                    },
                    modal: false,
                    name: 'associates',
                    profiles: [],
                    replace: true,
                    expandCard: false,
                },
                deleteProfileModal: false,
                deleteVerification: '',
                editProfile: false,
                fields: {
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    suffix: '',
                    ribbons: [],
                    alias: [
                        {knownAlias: '', notes: [{text: ''}], description: ''}
                    ],
                    streetName: [
                        {streetName: '', notes: [{text: ''}], description: ''}
                    ],
                    phone: [
                        {active: true, number: "", notes: ""}
                    ],
                    birthMonth: undefined,
                    birthDay: undefined,
                    birthYear: undefined,
                    heightFeet: 0,
                    heightInch: 0,
                    weight: 0,
                    race: undefined,
                    socialSecurity: '',
                    mid: '',
                    sid: '',
                    fbi: '',
                    socialMedia: [
                        {site: "", userName: "", link: "", notes: [{text: ''}]}
                    ],
                    identifications: [
                        {type: '', number: '', status: '', notes: [{text: ''}]}
                    ],
                    criminalHistory: [
                        {offense: '', numberOf: null, notes: [{text: ''}]}
                    ],
                    markings: [
                        {type: '', location: '', description: ''}
                    ],
                    notes: [
                        {text: ''}
                    ],
                },
                options: {
                    format: 'MM/DD/YYYY',
                    useCurrent: true,
                },
                organization: {},
                profile: {
                    markings: [],
                    documents: []
                },
                profileObjects: {
                    delete: {
                        modal: false,
                        isId: false,
                        name: "",
                        value: "",
                        index: -1,
                    },
                    associates: {
                        postPut: false,
                        delete: false,
                        user: [],
                        data: {},
                        index: -1,
                        organizationAssociates: false,
                        profileAssociates: true,
                        documentDescription: '',
                        selected: 'linked'
                    },
                    criminalHistories: {
                        postPut: false,
                        user: [],
                        data: {},
                        noteExpand: [],
                    },
                },
                profileEdit: {},
                fileSelectorFiles: [],
                disableButtonFlag: false,
            }
        },
        async created() {
            let x = this.getProfiles();
            await x;
            this.printReady = true;
        },
        methods: {
            actuallyPrint() {
                PubSub.publish('riscPrintDone');
                this.$htmlToPaper('printMe');
                this.profilePrint = false;
                this.wantedPrint = false;
                this.wantedQuestionPrint = false;
                this.officerPrint = false;
            },
            addCriminalHistory(criminalHistory) {
                this.profile.criminalHistories.push(criminalHistory);
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            addDrugNote(drugNote) {
                this.profile.profileDrugNotes.push({
                    text: drugNote,
                })
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            addFelony(felony) {
                this.profile.felonies.push(felony);
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            addId(id) {
                this.profile.identifications.push(id);
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            addMarking(marking) {
                this.profile.markings.push(marking);
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            addNote(note) {
                this.profile.notes.push(note)
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles()
                });
            },
            addPhone(phone) {
                this.profile.phone.push(phone);
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            addProfileDocument(description, fileId) {
                this.profile.images.push({
                    description: description,
                    storageFileName: fileId,
                    arrayOrder: this.profile.images.length
                });
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            addSocialMedia(socialMedia) {
                this.profile.socialMedia.push(socialMedia);
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            addWeaponNote(weaponNote) {
                this.profile.profileWeaponNotes.push({
                    text: weaponNote,
                })
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            aliasify(arr) {
                let result = ""
                if (Array.isArray(arr)) {
                    arr.forEach((a, i) => {
                        result += (a.knownAlias.length > 0 ? (i + 1 === arr.length ? a.knownAlias : a.knownAlias + ", ") : "")
                    })
                }
                return result;
            },
            arrayify(start, end, addToEnd) {
                let result = [];
                for (let i = start; i < end + 1; i++) {
                    if (addToEnd) {
                        if (i > 9) {
                            result.push({text: i.toString(), value: i})
                        } else if (i >= 0) {
                            result.push({text: "0" + i, value: i})
                        }
                    } else {
                        if (i > 9) {
                            result.unshift({text: i.toString(), value: i})
                        } else if (i >= 0) {
                            result.unshift({text: "0" + i, value: i})
                        }
                    }
                }
                return (result);
            },
            associatesImage(images) {
                if (images && images.length >= 1) {
                    return (this.getFirstDocumentImage(images));
                } else {
                    return ('/img/NoPictureOnFileGrey.png');
                }
            },
            changeDocumentDesc(doc) {
                this.profile.images.forEach(document => {
                    if (document.imageId === doc.imageId) {
                        document.description = doc.description
                    }
                })
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            editWeaponHistory(weaponHistoryBoolean){
                this.profile.hasWeaponHistory = weaponHistoryBoolean
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles()
                })
            },
            closePrintOptions() {
                this.openPrintSetup = 0;
                this.openPrintOptions = false;
            },
            closeSelector() {
                this.fileSelectorFlag = false;
            },
            dateify(time) {
                let created_date = time;
                let date = [];
                let months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                date.push(created_date.substring(0, 4));
                date.push(created_date.substring(5, 7));
                date.push(created_date.substring(8, 10));
                let mlong = months[parseInt(date[1])];
                let final = mlong + " " + date[2] + ", " + date[0];
                return (final)
            },
            dateifyProfile(month, day, year) {
                let result = "";
                result += (!month ? 'XX' : (month < 10 ? "0" + month : month)) + '/';
                result += (!day ? 'XX' : (day < 10 ? "0" + day : day)) + '/';
                result += (!year ? 'XXXX' : year);
                return result;
            },
            deleteAddress(address) {
                this.profile.addresses = this.profile.addresses.filter(_ => _.addressId !== address.addressId);
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            deleteCriminalHistory(criminalHistory) {
                this.profile.criminalHistories = this.profile.criminalHistories.filter(_ => _.criminalHistoryId !== criminalHistory.criminalHistoryId);
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            deleteDrug(drugId) {
                this.profile.drugs = this.profile.drugs.filter(_ => _.drugId !== drugId);
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles()
                });
            },
            deleteDrugNote(drugNoteId) {
                this.profile.profileDrugNotes = this.profile.profileDrugNotes.filter(_ => _.noteId !== drugNoteId);
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles()
                });
            },
            deleteEmbeddedDoc(doc) {
                this.profile.images = this.profile.images.filter((document) => {
                    return document.imageId !== doc.imageId;
                });
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            deleteEnterButton() {
                if (this.deleteVerification === this.nameify(this.profile)) {
                    this.deleteProfile();
                }
            },
            deleteFelony(felony) {
                this.profile.felonies = this.profile.felonies.filter(_ => _.felonyId !== felony.felonyId);
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            deleteId(id) {
                this.profile.identifications = this.profile.identifications.filter(_ => _.identificationId !== id.identificationId);
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            deleteLeReport(leReport) {
                this.profile.leReports = this.profile.leReports.filter(_ => _.leReportId !== leReport.leReportId);
            },
            deleteMarking(marking) {
                this.profile.markings = this.profile.markings.filter(_ => _.markingId !== marking.markingId);
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            deleteNote(note) {
                this.profile.notes = this.profile.notes.filter(_ => {
                    return _.noteId !== note.noteId
                })
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles()
                });
            },
            deletePhone(phone) {
                this.profile.phone = this.profile.phone.filter(_ => {
                    return _.phoneId !== phone.phoneId;
                });
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            deleteProfile() {
                risc.deleteProfiles(this.profile.profileId).then(() => {
                    window.location = '/#/risc/profiles'
                });
                this.deleteProfileModal = false;
                this.deleteVerification = '';
            },
            deleteSocialMedia(socialMedia) {
                this.profile.socialMedia = this.profile.socialMedia.filter(_ => {
                    return _.socialMediaId !== socialMedia.socialMediaId;
                });
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            deleteVehicle(vehicleId) {
                this.profile.vehicles = this.profile.vehicles.filter(_ => _.vehicleId !== vehicleId);
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles()
                });
            },
            deleteWeapon(weaponId) {
                this.profile.weapons = this.profile.weapons.filter(_ => _.weaponId !== weaponId);
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles()
                });
            },
            deleteWeaponNote(weaponNoteId) {
                this.profile.profileWeaponNotes = this.profile.profileWeaponNotes.filter(_ => _.noteId !== weaponNoteId);
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles()
                });
            },
            editDrugHistory(drugHistoryBoolean){
                this.profile.hasDrugHistory = drugHistoryBoolean;
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles()
                })
            },
            editDrugNote(noteText, noteId) {
                let index = this.profile.profileDrugNotes.findIndex(_ => _.noteId === noteId);
                this.profile.profileDrugNotes[index].text = noteText
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles()
                });
            },
            editNote(note) {
                let index = this.profile.notes.findIndex(_ => _.noteId === note.noteId);
                this.profile.notes[index] = note;
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            editWeaponNote(noteText, noteId) {
                let index = this.profile.profileWeaponNotes.findIndex(_ => _.noteId === noteId);
                this.profile.profileWeaponNotes[index].text = noteText
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles()
                });
            },
            findAddress(arr) {
                for (let i = 0; arr && i < arr.length; i++) {
                    if (arr[i].active) {
                        return (arr[i].address)
                    } else if (i === arr.length - 1) {
                        return (arr[i].address)
                    }
                }
            },
            getFirstDocumentImage(docArray) {
                let stId = 0;
                docArray.forEach(doc => {
                    if (doc.arrayOrder === 0) {
                        stId = doc.storageId;
                    }
                })
                if (stId !== 0) {
                    return risc.getImage(stId);
                } else {
                    return risc.getImage(docArray[0].storageFileName);
                }
            },
            getImage(storageId) {
                return risc.getImage(storageId);
            },
            getPrintOptions(options){
                this.printOptions = Object.assign({}, options);
            },
            getProfiles() {
                return risc.getOneProfile(this.id).then(profile => {
                    this.profile = profile.data;
                    this.associates.profileAssociatesData = [];
                    this.profile.organizations.map(i => i.orgName)
                    let result = [];
                    this.profile.organizations.forEach((org, index) => {
                        risc.getOneOrganization(org.organizationId).then(orgDeep => {
                            risc.getProfilesById(orgDeep.data.profiles.map(profileId => profileId.profileId)).then(profiles => {
                                profiles.data.forEach((profile, i) => {
                                    if (profile.profileId.toString() !== this.id.toString()) {
                                        result.push({...profile, ...{relationship: orgDeep.data.orgName}})
                                    }
                                    if (i + 1 === profiles.data.length && index + 1 === this.profile.organizations.length){
                                        this.associates.profileAssociatesData = result;
                                        //this.addressesToMarkers();
                                    }
                                })
                            })
                        })
                    })
                })
            },
            nameify(profile) {
                if (profile.firstName && profile.lastName && profile.middleName) {
                    return (profile.lastName + ', ' + profile.firstName + ' ' + profile.middleName.slice(0, 1))
                } else if (profile.firstName && profile.lastName) {
                    return (profile.lastName + ', ' + profile.firstName)
                } else if (profile.firstName && profile.middleName) {
                    return ('Unknown, ' + profile.firstName + ' ' + profile.middleName.slice(0, 1))
                } else if (profile.lastName && profile.middleName) {
                    return (profile.lastName + ', Unknown ' + profile.middleName.slice(0, 1))
                } else if (profile.firstName) {
                    return ('Unknown, ' + profile.firstName)
                } else if (profile.lastName) {
                    return (profile.lastName + ', Unknown ')
                } else if (profile.middleName) {
                    return ('Unknown, Unknown ' + profile.middleName.slice(0, 1))
                }
                return '';
            },
            oneFilledOut(obj, fields) {
                if(this.disableButtonFlag === true){
                    return this.disableButtonFlag;
                }
                let result = false;
                let newObj = Object.assign({}, JSON.parse(JSON.stringify(obj)));
                Object.keys(fields).forEach((field, index) => {
                    if (fields[field] && Array.isArray(fields[field]) && obj[field].length > 1) {
                        let match = obj[field].every(ele => JSON.stringify(ele) === JSON.stringify(fields[field][0]));
                        newObj[field] = match ? [obj[field][0]] : false;
                    }
                    if (index + 1 === Object.keys(fields).length) {
                        result = JSON.stringify(newObj) === JSON.stringify(fields);
                    }
                })
                return result;
            },
            onlyNumber($event) {
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                    $event.preventDefault();
                }
            },
            openModalAddCriminalHistories() {
                this.profileObjects.criminalHistories.index = -1
                this.profileEdit = JSON.parse(JSON.stringify(this.profile))
                this.profileObjects.criminalHistories.data = {
                    numberOf: 0,
                    numberOfConvictions: 0,
                    numberOfArrests: 0,
                    offense: '',
                    description: '',
                    notes: [{text: ''}],
                    criminalHistoryId: undefined,
                }
                this.profileObjects.criminalHistories.postPut = true;
            },
            openModalEditCriminalHistories(item, index) {
                this.profileEdit = JSON.parse(JSON.stringify(this.profile))
                this.profileObjects.criminalHistories.data = JSON.parse(JSON.stringify(item))
                this.profileObjects.criminalHistories.index = index
                this.profileObjects.criminalHistories.postPut = true
            },
            openModalFileSelector(files) {
                this.fileSelectorFiles = files;
                this.fileSelectorFlag = true;
            },
            async print(options) {
                this.printOptions = Object.assign({}, options);
                let imagesLength = (this.printOptions.image === true ? 1 : 0);
                // Setup for regular Profile Print
                if (this.printOptions.type === 1) {
                    // Get address image length
                    if (this.printOptions.images.addresses === true && this.printOptions.otherInfo.addresses.show === true) {
                        this.printOptions.otherInfo.addresses.children.forEach(address => {
                            if (address.show === true) {
                                imagesLength++;
                            }
                        });
                    }
                    // Get vehicle image length
                    if (this.printOptions.images.vehicles === true && this.printOptions.otherInfo.vehicles.show === true) {
                        this.printOptions.otherInfo.vehicles.children.forEach(vehicle => {
                            if (vehicle.show === true) {
                                imagesLength++;
                            }
                        });
                    }
                    // Get marking image length
                    if (this.printOptions.images.markings === true && this.printOptions.otherInfo.markings.show === true) {
                        this.printOptions.otherInfo.markings.children.forEach(marking => {
                            if (marking.show === true) {
                                imagesLength++;
                            }
                        });
                    }
                    // Get linked image length
                    if (this.printOptions.images.linkedAssociates === true && this.printOptions.otherInfo.linkedAssociates.show === true) {
                        this.printOptions.otherInfo.linkedAssociates.children.forEach(linkedAssociate => {
                            if (linkedAssociate.show === true) {
                                imagesLength++;
                            }
                        });
                    }
                    // Get other image length
                    if (this.printOptions.images.otherAssociates === true && this.printOptions.otherInfo.associates.show === true) {
                        this.printOptions.otherInfo.associates.children.forEach(associate => {
                            if (associate.show === true) {
                                imagesLength++;
                            }
                        });
                    }
                    if (this.printOptions.images.addresses === true && this.printOptions.otherInfo.addresses.show === true) {
                        for (const [i, address] of this.printOptions.otherInfo.addresses.children.entries()) {
                            if (address.show === true) {
                                await risc.getAddress(address.addressId).then(response => {
                                    this.profile.addresses[i] = Object.assign(this.profile.addresses[i], response.data)
                                })
                            }
                        }
                    }
                    if (this.printOptions.images.vehicles === true && this.printOptions.otherInfo.vehicles.show === true) {
                        for (const [i, vehicle] of this.printOptions.otherInfo.vehicles.children.entries()) {
                            if (vehicle.show === true) {
                                await risc.getVehicle(vehicle.vehicleId).then(response => {
                                    this.profile.vehicles[i] = Object.assign(this.profile.vehicles[i], response.data)
                                })
                            }
                        }
                    }
                    if (this.printOptions.otherInfo.leReports.show === true) {
                        for (const [i, leReport] of this.printOptions.otherInfo.leReports.children.entries()) {
                            if (leReport.show === true) {
                                await risc.getLeReport(leReport.leReportId).then(async (response) => {
                                    this.profile.leReports[i] = Object.assign(this.profile.leReports[i], response.data);
                                    for (const [i2, involvement] of this.profile.leReports[i].involvements.entries()) {
                                        await risc.getInvolvement(involvement.involvementId).then((response2) => {
                                            this.profile.leReports[i].involvements[i2] = Object.assign(this.profile.leReports[i].involvements[i2], response2.data);
                                        });
                                    }
                                })
                            }
                        }
                        this.profile.leReports.sort((current, other) => {
                            let Current = new Date(current.date).getTime()
                            let Other = new Date(other.date).getTime()
                            return Other - Current});
                    }
                }
                // Setup for Wanted Printout
                if (this.printOptions.type === 2 || this.printOptions.type === 3) {
                    for (const [i, otherObject] of this.printOptions.wanted.otherObjects.entries()) {
                        if (otherObject.title === 'ADDRESS') {
                            await risc.getAddress(otherObject.addressId).then(response => {
                                this.printOptions.wanted.otherObjects[i].obj[1] = response.data.documents;
                            })
                            if (this.printOptions.images.addresses === true) {
                                imagesLength++;
                            }
                        } else if (otherObject.title === 'VEHICLE') {
                            await risc.getVehicle(otherObject.vehicleId).then(response => {
                                this.printOptions.wanted.otherObjects[i].obj[1] = response.data.documents;
                            })
                            if (this.printOptions.images.vehicles === true) {
                                imagesLength++;
                            }
                        } else if (otherObject.title === 'LINKED ASSOCIATE') {
                            if (this.printOptions.images.linkedAssociates === true) {
                                imagesLength++;
                            }
                        } else if (otherObject.title === 'OTHER ASSOCIATE') {
                            if (this.printOptions.images.otherAssociates === true) {
                                imagesLength++;
                            }
                        }
                    }
                }
                this.printOptions.imagesLength = imagesLength;
                if (this.printOptions.type === 1) {
                    this.profilePrint = true;
                }
                if (this.printOptions.type === 2) {
                    this.wantedPrint = true;
                }
                if (this.printOptions.type === 3) {
                    this.wantedQuestionPrint = true;
                }
                if (this.printOptions.type === 4) {
                    this.officerPrint = true;
                }
            },
            putProfile() {
                this.disableButtonFlag = true;
                Object.keys(this.fields).forEach(field => {
                    if (JSON.stringify(this.profileEdit[field]) === JSON.stringify(this.fields[field]) || this.profileEdit[field] === undefined) {
                        delete this.profileEdit[field];
                    }
                })
                risc.putProfiles(this.profileEdit).then(() => {
                    this.getProfiles();
                    this.disableButtonFlag = false;
                    this.editProfile = false;
                }).catch(err => {
                    this.disableButtonFlag = false;
                })
            },
            push(field, item) {
                field.push(JSON.parse(JSON.stringify(item)));
            },
            reorderProfileAssociates() {
                for (let x = 0; x < this.profile.associates.length; x++) {
                    this.profile.associates[x].arrayOrder = x
                }
                risc.putProfiles(this.profile)
            },
            ribbonVariant(ribbon) {
                switch (ribbon) {
                    case 'IN CUSTODY':
                        return 'success'
                    case 'PAROLE/PROBATION':
                        return 'warning text-dark'
                    case 'WANTED':
                        return 'danger'
                    case 'OUT OF TOWN':
                        return 'warning text-dark'
                    case 'DECEASED':
                        return 'dark';
                    default:
                        return 'primary';
                }
            },
            setImageFavorite(files) {
                this.profile.images = files;
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            showEditModal() {
                this.profileEdit = JSON.parse(JSON.stringify(this.profile));
                let ribbonTemp = []
                this.profile.ribbons.map(r => r.ribbon).forEach((e, i) => {
                    ribbonTemp.push({ribbon: e})
                    if (i === this.profile.ribbons.map(r => r.ribbon).length - 1) {
                        this.profileEdit.ribbons = ribbonTemp
                    }
                })
                Object.keys(this.fields).forEach(field => {
                    if (!this.profileEdit[field] || this.profileEdit[field] === null || this.profileEdit[field].length === 0) {
                        if (this.fields[field] === undefined) {
                            this.profileEdit[field] = this.fields[field];
                        } else {
                            this.profileEdit[field] = JSON.parse(JSON.stringify(this.fields[field]));
                        }
                    }
                });
                this.editProfile = true;
            },
            splice(index, field, item) {
                field.splice(index, 1);
                if (item && field.length === 0) {
                    this.push(field, item)
                }
            },
            streetNameify(arr) {
                let result = ""
                if (Array.isArray(arr)) {
                    arr.forEach((a, i) => {
                        result += (a.streetName.length > 1 ? (i + 1 === arr.length ? a.streetName : a.streetName + ", ") : "")
                    })
                }
                return result;
            },
            toFtIn(ft, inch) {
                return ((Math.floor(this.toIn(ft, inch) / 12)) + "'" + (this.toIn(ft, inch) % 12) + "\"")
            },
            toIn(ft, inch) {
                return (inch + (ft * 12));
            },
            updateAssociates(associates) {
                this.profile.associates = associates;
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            updateCriminalHistory(criminalHistory) {
                let index = this.profile.criminalHistories.findIndex(_ => _.criminalHistoryId === criminalHistory.criminalHistoryId);
                this.profile.criminalHistories[index] = criminalHistory;
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            updateFelony(felony) {
                let index = this.profile.felonies.findIndex(_ => _.felonyId === felony.felonyId);
                this.profile.felonies[index] = felony;
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            updateId(id) {
                let index = this.profile.identifications.findIndex(_ => _.identificationId === id.identificationId);
                this.profile.identifications[index] = id;
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            updateLeReport() {
                this.getProfiles();
            },
            updatePhone(phone) {
                let index = this.profile.phone.findIndex(_ => _.phoneId === phone.phoneId);
                this.profile.phone[index] = phone;
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            updateLinkedProfiles(linkedAssociates) {
                this.profile.profileGroups = linkedAssociates;
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            updateMarking(marking) {
                let index = this.profile.markings.findIndex(_ => _.markingId === marking.markingId);
                this.profile.markings[index] = marking;
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            updateSocialMedia(socialMedia) {
                let index = this.profile.socialMedia.findIndex(_ => _.socialMediaId === socialMedia.socialMediaId);
                this.profile.socialMedia[index] = socialMedia;
                risc.putProfiles(this.profile).then(() => {
                    this.getProfiles();
                });
            },
            verifyThereAreNoNotes(notes) {
                const notesLengthEqualToZero = notes.every(note => {
                    return note.text.length === 0;
                })
                return notes.length === 0 || notesLengthEqualToZero;
            },
        }
    }
    /*Makes B-Form-Input All Caps*/
    Vue.directive('uppercase', {
        update(el) {
            el.value = el.value.toUpperCase()
        },
    })
</script>

<style>
    .page header {
        flex-grow: 0;
        flex-shrink: 0;
        background-color: #0f0;
        margin: 0 0 10px 20px;
        font-size: 0;
        line-height: 24px;
        height: 24px;
        padding: 15px 20px 15px 0;
    }
    .img-center {
        object-fit: contain;
        max-height: 467px;
        width: 100%;
        height: auto;
    }
    .img-center-ass {
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding: 0;
    }
    .image-parent {
        background: #1F1F1F;
        flex-grow: 1;
        min-height: 0;
        display: flex;
        align-Items: center;
        justify-content: center;
    }
    .img-center-no-ribbon {
        object-fit: contain;
        max-height: 489px;
        width: 100%;
        height: auto;
    }
    .tab-scroll {
        overflow-y: visible;
        min-height: 100%;
        background-color: #1a1a1a !important;
        border: 0 #121517;
    }
    .noAdded {
        text-align: center;
        margin-bottom: 0;
        color: grey
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .labelForm {
        margin: 0;
    }
    .tab-content {
        flex-grow: 1;
        border-bottom: 2px solid #0d0d0d;
        overflow-y: auto;
    }
    .pac-container {
        z-index: 10000 !important;
    }
</style>
