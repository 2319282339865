<template>
    <div v-if="documents !== null">

        <!--View Files-->
        <FileViewer2 :flag="fileViewerFlag" :fileId="fileViewStorageId" :closeMethod="closeViewModal"/>

        <!--Edit Document Modal-->
        <b-modal class="modal-dark" v-model="documentEditFlag" no-close-on-backdrop scrollable title="Edit Document/Media">
            <b-input-group class="input-group-sm" prepend="Description" style="margin-bottom: 1px">
                <b-form-input v-model="documentEdit.description"/>
            </b-input-group>
            <!--Cancel and Submit Buttons-->
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="documentEditFlag = false">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="submit()">Submit</b-button>
            </template>
        </b-modal>

        <!--Delete Embedded Document-->
        <b-modal class="modal-dark" scrollable title="Remove Document" v-model="documentDeleteFlag">
            <p>If you would like to delete this {{documentDelete.name}} please type the prompt below.</p>
            <br>
            <p style="margin-bottom: 0">Enter "{{documentDelete.name}}":</p>
            <b-form-group description="case sensitive">
                <b-form-input id="basicName2" type="text" v-model="documentDelete.deleteText"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="documentDeleteFlag = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button :disabled="documentDelete.deleteText !== documentDelete.name || disableDeleteFlag" @click="deleteDoc()"
                          size="sm"
                          variant="danger">Delete {{documentDelete.name}}
                </b-button>
            </template>
        </b-modal>

        <v-client-table :columns="(editable !== undefined && editable === true) ? dTable.columns : dTable.uneditableColumns" :data="documents"
                        :options="dTable.tOptions" :theme="dTable.theme">
            <div slot="file" slot-scope="props">
                <img v-auth-image="getImage(props.row.storageFileName)" style="max-height: 50px; cursor: pointer;"
                     @click="openViewModal(props.row.storageFileName)" alt=""/>
            </div>
            <span slot="h__edit_delete" style="float: right">Edit/Delete</span>
            <div slot="edit_delete" slot-scope="props" style="float: right">
                <fa-icon :icon="['fas', 'pen-to-square']" @click="openModalEditDocument(props.row)" style="cursor: pointer;"/>&nbsp;
                <fa-icon :icon="['fas', 'close']" @click="openModalDeleteDocument(props.row)" style="cursor: pointer;"/>
            </div>
        </v-client-table>
    </div>
</template>

<script>
import risc from "@/services/risc";
import FileViewer2 from "@/views/expansion_modules/risc/components/utility_components/FileViewer2.0";

export default {
    name: "DocumentTableComponent",
    props: {
        documents: { type: Array },
        editable: { type: Boolean },
        editMethod: { type: Function },
        deleteMethod: { type: Function }
    },
    components: {
        FileViewer2
    },
    data() {
        return {
            disableDeleteFlag: false,
            fileViewStorageId: null,
            fileViewerFlag: false,
            documentEditFlag: false,
            documentEdit: {
                description: ''
            },
            documentDeleteFlag: false,
            documentDelete: {
                name: 'Document',
                deleteText: '',
                document: null
            },
            dTable: {
                theme: 'bootstrap4',
                columns: ['file', 'description', 'edit_delete'],
                uneditableColumns: ['file', 'description'],
                tOptions: {
                    sortable: [],
                    filterable: [],
                    orderBy: {column: 'file', ascending: true},
                    headings: {
                        edit_delete: 'Edit/Delete'
                    },
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll'
                    },
                    perPage: 25,
                    skin: 'table table-striped table-hover',
                },
                useVuex: false,
                template: 'default',
                createCaseModal: false
            },
        };
    },
    methods: {
        closeViewModal() {
            this.fileViewerFlag = false;
        },
        deleteDoc() {
            this.disableDeleteFlag = true;
            this.deleteMethod(this.documentDelete.document);
            this.documentDeleteFlag = false;
        },
        getImage(storageId) {
            if (storageId !== undefined) {
                return risc.getImage(storageId);
            } else {
                return ('/img/NoPictureOnFileGrey.png')
            }
        },
        openModalDeleteDocument(document) {
            this.disableDeleteFlag = false;
            this.documentDeleteFlag = true
            this.documentDelete.deleteText = ''
            this.documentDelete.document = document
        },
        openModalEditDocument(item) {
            this.documentEdit = JSON.parse(JSON.stringify(item));
            this.documentEditFlag = true;
        },
        openViewModal(storageId) {
            this.fileViewStorageId = storageId
            this.fileViewerFlag = true;
        },
        submit() {
            this.editMethod(this.documentEdit);
            this.documentEditFlag = false;
        },

    }
}
</script>

<style scoped>

</style>