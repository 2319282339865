<template>
    <div v-if="felony !== undefined">

        <FileViewer2 :flag="fileViewerFlag" :fileId="fileViewStorageId" :closeMethod="closeViewModal"/>

        <!--Remove Item Modal-->
        <b-modal class="modal-dark" scrollable title="Remove Felony" v-model="deleteObjects.modal">
            <p>If you would like to delete this {{deleteObjects.name}} please type the prompt below.</p>
            <br>
            <p style="margin-bottom: 0">Enter "{{deleteObjects.name}}":</p>
            <b-form-group description="case sensitive">
                <b-form-input id="basicName2" type="text" v-model="deleteObjects.deleteText"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="deleteObjects.modal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button :disabled="deleteObjects.deleteText !== deleteObjects.name || disableDeleteFlag" @click="deleteFelony()"
                          size="sm"
                          variant="danger">Delete {{deleteObjects.name}}
                </b-button>
            </template>
        </b-modal>

        <!--Edit Felonies-->
        <b-modal class="modal-dark" scrollable title="Add Felony" v-model="editFelonyFlag" no-close-on-backdrop>
            <b-input-group class="input-group-sm" prepend=" Felony Charge" style="margin-bottom: 1px">
                <b-form-input v-model="felonyEdit.felonyType" v-uppercase/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Conviction Date" style="margin-bottom: 1px">
                <date-picker :config="dateOptions" v-model="felonyEdit.convictionDate"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Case Number" style="margin-bottom: 1px">
                <b-form-input v-model="felonyEdit.caseNumber" v-uppercase/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Status" style="margin-bottom: 1px">
                <b-form-select :options="['CHARGED', 'GUILTY', 'CONVICTED', 'CHARGES PENDING', 'FOUND NOT GUILTY']"
                               v-model="felonyEdit.status"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Description" style="margin-bottom: 1px">
                    <textarea maxlength="500"
                              class="form-control rounded-0" rows="4"
                              v-model="felonyEdit.description" type="text"></textarea>
            </b-input-group>
            <br>
            <label class="labelForm">Notes</label>&nbsp;
            <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(felonyEdit.notes, {text: ''})"/>
            <b-input-group :key="i" class="input-group-sm" v-for="(e,i) in felonyEdit.notes">
                <b-form-input v-model="felonyEdit.notes[i].text"/>
                <b-input-group-append>
                    <b-button @click="splice(i, felonyEdit.notes)" variant="dark">
                        <fa-icon :icon="['fas', 'close']"/>
                    </b-button>
                </b-input-group-append>
            </b-input-group>
            <template slot="modal-footer">
                <b-button @click="editFelonyFlag = false" size="sm" variant="secondary">Cancel
                </b-button>
                <b-button @click="updateFelony()" size="sm" variant="primary" :disabled="disableFelonySubmit()">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <!-- Main Card -->
        <b-card no-body style="border-color: #0e0e0e;background-color: #202020">
            <span class="d-flex w-100 justify-content-between" style="background-color: #0E0E0E; padding: 0 5px 0 5px; border-radius: 3px 3px 0 0">
                <b style="font-size: medium">Felony Type: {{felony.felonyType}}</b>
                <span v-if="editable === true">
                    <fa-icon :icon="['fas', 'pen-to-square']" @click="openEditModal()" style="cursor: pointer;"/>&nbsp;
                    <fa-icon :icon="['fas', 'close']" @click="openDeleteModal(felony.felonyId)" size="lg" style="cursor: pointer; transform: translateY(1px)"/>
                </span>
            </span>
            <b-row style="margin: 0; padding: 0">
                <b-col class="d-flex flex-column"
                       style="margin: 0; padding: 0"
                       :xl="felony.documents && felony.documents.length > 0 ? 7 : 12">
                    <div style="margin: 5px">
                        <div><strong>Case Number: </strong>{{felony.caseNumber}}</div>
                        <div><strong>Conviction Date: </strong>{{dateToString(felony.convictionDate)}}</div>
                        <div><strong>Status: </strong>{{felony.status}}</div>
                        <div><strong>Description: </strong>{{felony.description}}</div>
                    </div>
                </b-col>
                <b-col style="margin: 0; padding: 0" xl="5"
                       class="d-flex justify-content-center"
                       v-if="felony.documents && felony.documents.length > 0">
                    <span style="width: auto; max-height: 100px; margin: 5px; border: 2px solid #23282c; border-radius: 5px; background-color: #0E0E0E;"
                          class="image-parent">
                        <img class="img-center-ass"
                             style="border-radius: 5px;"
                             v-if="felony.documents && felony.documents.length > 0"
                             v-auth-image="getFirstDocumentImage(felony.documents)"
                             @click="openViewModal(felony.documents[0].storageFileName)"
                             alt=""/>
                        <img v-else class="img-center-ass"
                             style="border-radius: 5px;"
                             src="/NoImageAvailable.png"
                             alt="">
                    </span>
                </b-col>
            </b-row>
            <div v-if="noteExpand"
                 style="padding: 5px">
                <NotesCardComponent :notes="felony.notes"/>
                <br>
                <DocumentCardComponent :editable="editable"
                                       :documents="felony.documents ? felony.documents : []"
                                       :editMethod="changeDocumentDesc"
                                       :deleteMethod="deleteEmbeddedDoc"
                                       :submitSelectorMethod="setFileFavorite"
                                       :submitUploadMethod="addFelonyDocument"/>
                <p style="text-align: center; margin: 0; cursor: pointer;" @click="noteExpand = false">
                    <fa-icon :icon="['fas', 'angle-up']" style="text-align: center"/>
                </p>
            </div>
            <div v-else style="text-align: center; cursor: pointer;" @click="noteExpand = true">
                <fa-icon :icon="['fas', 'angle-down']"/>
            </div>
        </b-card>
    </div>
</template>

<script>
import NotesCardComponent from "@/views/expansion_modules/risc/components/notes_components/NotesCardComponent";
import DocumentCardComponent from "@/views/expansion_modules/risc/components/documents_components/DocumentCardComponent";
import FileViewer2 from "@/views/expansion_modules/risc/components/utility_components/FileViewer2.0";
import datePicker from 'vue-bootstrap-datetimepicker';
import risc from "@/services/risc";
import * as moment from "moment";

export default {
    name: "FelonyComponent",
    components: {
        DocumentCardComponent,
        NotesCardComponent,
        FileViewer2,
        datePicker
    },
    props: {
        felony: { type: Object },
        editable: { type: Boolean }
    },
    data() {
        return {
            disableDeleteFlag: false,
            editFelonyFlag: false,
            felonyEdit: {},
            noteExpand: false,
            fileViewStorageId: null,
            fileViewerFlag: false,
            deleteObjects: {
                modal: false,
                name: "Felony",
                deleteText: "",
                id: '',
            },
            dateOptions: {
                format: 'MM/DD/YYYY',
                useCurrent: false
            }
        };
    },
    methods: {
        addFelonyDocument(description, fileId) {
            this.felony.documents.push({
                description: description,
                storageFileName: fileId,
                arrayOrder: this.felony.documents.length
            })
            this.$emit('updateFelony', this.felony);
        },
        changeDocumentDesc(doc) {
            this.felony.documents.forEach(document => {
                if (document.documentId === doc.documentId) {
                    document.description = doc.description
                }
            })
            this.$emit('updateFelony', this.felony);
        },
        closeViewModal() {
            this.fileViewerFlag = false;
        },
        dateToString(date) {
            if (date === '0001-01-01T00:00:00') {
                return '';
            } else {
                return (moment(date).format('L'));
            }
        },
        deleteEmbeddedDoc(doc) {
            this.felony.documents = this.felony.documents.filter((document) => {
                return document.documentId !== doc.documentId;
            });
            this.$emit('updateFelony', this.felony);
        },
        deleteFelony() {
            this.disableDeleteFlag = true;
            this.$emit('deleteFelony', this.felony);
            this.deleteObjects.modal = false;
        },
        disableFelonySubmit() {
            return !this.filledOut(this.felonyEdit.felonyType) ||
                !this.filledOut(this.felonyEdit.caseNumber) ||
                !this.filledOut(this.felonyEdit.status);

        },
        filledOut(i){
            return !(i === null || i === undefined || i === '' || i === 0);
        },
        getFirstDocumentImage(docArray) {
            let stId = 0;
            docArray.forEach(doc => {
                if (doc.arrayOrder === 0) {
                    stId = doc.storageFileName;
                }
            })
            if (stId !== 0) {
                return risc.getImage(stId);
            } else {
                return risc.getImage(docArray[0].storageFileName);
            }
        },
        openDeleteModal(id) {
            this.disableDeleteFlag = false;
            this.deleteObjects.modal = true
            this.deleteObjects.deleteText = ''
            this.deleteObjects.id = id
        },
        openEditModal() {
            this.felonyEdit = JSON.parse(JSON.stringify(this.felony));
            this.felonyEdit.convictionDate = new Date(this.felonyEdit.convictionDate);
            this.editFelonyFlag = true;
        },
        openViewModal(storageId) {
            this.fileViewStorageId = storageId
            this.fileViewerFlag = true;
        },
        push(field, item) {
            field.push(JSON.parse(JSON.stringify(item)));
        },
        setFileFavorite(files) {
            this.felony.documents = files
            this.$emit('updateFelony', this.felony);
        },
        splice(index, field) {
            field.splice(index, 1);
        },
        updateFelony() {
            this.$emit('updateFelony', this.felonyEdit);
            this.editFelonyFlag = false;
        },

    }
}
</script>

<style scoped>

</style>