<template>
    <div v-if="notes !== undefined">
        <!--Add Notes-->
        <b-modal class="modal-dark" scrollable title="Add Note" v-model="addModalFlag" no-close-on-backdrop>
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <label>Note</label>
                        <b-form-textarea v-model="newNote.text"></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <label>Importance</label>
                        <b-form-select v-model="newNote.importance" :options="importanceOptions"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button @click="addModalFlag = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="addNote()" size="sm" variant="primary" :disabled="newNote.text === '' || disableButtonFlag">Submit
                </b-button>
            </template>
        </b-modal>

        <!-- Main Card -->
        <b-card bg-variant="dark" :style="'width: 100%; height: ' + (height ? height : 536) + 'px; margin-bottom: 10px;'" no-body>
            <span slot="header">
                <div style="display: flex; align-content: space-between; align-items: center;">
                    <strong>Notes</strong>
                    <b-form-select v-model="noteSort" size="sm" :options="noteSortOptions" style="width: fit-content; margin-left: auto;" @change="sortNotes"/>
                </div>
            </span>
            <div class="d-flex flex-column" style="height: 655px; overflow-y: auto;">
                <div style="text-align: center; width: 100%; margin-top: 10px; margin-bottom: 10px;">
                    <span style="cursor: pointer;" @click="openModalAdd()">
                        <fa-icon :icon="['fas', 'plus']"/> Add Note
                    </span>
                </div>
                <div>
                    <div v-for="(note, index) in sortedNotes" :key="index">
                        <Note2 :note="note" :editMethod="editMethod" :deleteMethod="deleteMethod"/>
                    </div>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>

import Note2 from '@/views/expansion_modules/risc/components/notes_components/Note2.0';

export default {
    name: "NotesPageComponent",
    components: {
        Note2
    },
    props: {
        notes: {type: Array},
        addMethod: {type: Function},
        editMethod: {type: Function},
        deleteMethod: {type: Function},
        height: {type: Number}
    },
    data() {
        return {
            sortedNotes: [],
            addModalFlag: false,
            disableButtonFlag: false,
            newNote: {
                text: '',
                importance: 0
            },
            importanceOptions: [
                {value: 1, text: "High"},
                {value: 0, text: "Normal"},
                {value: -1, text: "Low"},
            ],
            noteSort: 0,
            noteSortOptions: [
                {text: "Most Important", value: 0},
                {text: "Least Important", value: 1},
                {text: "Newest", value: 2},
                {text: "Oldest", value: 3},
            ]
        };
    },
    methods: {
        addNote() {
            this.disableButtonFlag = true;
            this.addMethod(this.newNote);
            this.addModalFlag = false;
        },
        openModalAdd() {
            this.disableButtonFlag = false;
            this.newNote = {
                text: '',
                importance: 0
            }
            this.addModalFlag = true;
        },
        sortDates(cur, oth) {
            let nCur = cur.createdDate || cur.date
            let nOth = oth.createdDate || oth.date
            if (nCur < nOth) {
                return 1;
            } else if (nCur > nOth) {
                return -1;
            } else {
                return 0;
            }
        },
        sortImportance(cur, oth) {
            if (cur.importance < oth.importance) {
                return 1;
            } else if (cur.importance > oth.importance) {
                return -1;
            } else {
                return 0;
            }
        },
        sortNotes() {
            this.sortedNotes = this.sortedNotes.sort((cur, oth) => {
                let temp;
                switch (this.noteSort) {
                    case 0:
                        temp = this.sortImportance(cur, oth);
                        return temp !== 0 ? temp : this.sortDates(cur, oth);
                    case 1:
                        temp = this.sortImportance(oth, cur);
                        return temp !== 0 ? temp : this.sortDates(cur, oth);
                    case 2:
                        temp = this.sortDates(cur, oth);
                        return temp !== 0 ? temp : this.sortImportance(cur, oth);
                    default:
                        temp = this.sortDates(oth, cur);
                        return temp !== 0 ? temp : this.sortImportance(cur, oth);
                }
            })
        },
    },
    watch: {
        notes() {
            this.sortedNotes = [...this.notes];
            this.sortNotes();
        }
    }
}
</script>

<style scoped>

</style>