<template>
    <div v-if="felonies !== undefined">

        <!--Add Felonies-->
        <b-modal class="modal-dark" scrollable title="Add Felony" v-model="addFelonyFlag" no-close-on-backdrop>
            <b-input-group class="input-group-sm" prepend=" Felony Charge" style="margin-bottom: 1px">
                <b-form-input v-model="felonyAdd.felonyType" v-uppercase/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Conviction Date" style="margin-bottom: 1px">
                <date-picker :config="dateOptions" v-model="felonyAdd.convictionDate"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Case Number" style="margin-bottom: 1px">
                <b-form-input v-model="felonyAdd.caseNumber" v-uppercase/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Status" style="margin-bottom: 1px">
                <b-form-select :options="['CHARGED', 'GUILTY', 'CONVICTED', 'CHARGES PENDING', 'FOUND NOT GUILTY']"
                               v-model="felonyAdd.status"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Description" style="margin-bottom: 1px">
                    <textarea maxlength="500"
                              class="form-control rounded-0" rows="4"
                              v-model="felonyAdd.description" type="text"></textarea>
            </b-input-group>
            <br>
            <label class="labelForm">Notes</label>&nbsp;
            <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(felonyAdd.notes, {text: ''})"/>
            <b-input-group :key="i" class="input-group-sm" v-for="(e,i) in felonyAdd.notes">
                <b-form-input v-model="felonyAdd.notes[i].text"/>
                <b-input-group-append>
                    <b-button @click="splice(i, felonyAdd.notes)" variant="dark">
                        <fa-icon :icon="['fas', 'close']"/>
                    </b-button>
                </b-input-group-append>
            </b-input-group>
            <template slot="modal-footer">
                <b-button @click="addFelonyFlag = false" size="sm" variant="secondary">Cancel
                </b-button>
                <b-button @click="addFelony()" size="sm" variant="primary" :disabled="disableFelonySubmit() || disableButtonFlag">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <!--Main Body-->
        <div v-if="editable === true" style="text-align: center; width: 100%; margin-bottom: 10px;">
            <span style="cursor: pointer;" @click="openAddModal()">
                <fa-icon :icon="['fas', 'plus']"/> Add Felony
            </span>
        </div>
        <p v-if="felonies.length === 0" class="noAdded"><i><strong>No Felonies Added</strong></i></p>
        <div :key="index" v-for="(felony, index) in felonies">
            <FelonyComponent
                :felony="felony"
                :editable="editable"
                @updateFelony="updateFelony"
                @deleteFelony="deleteFelony"
            />
        </div>
    </div>
</template>

<script>
import FelonyComponent from "@/views/expansion_modules/risc/components/felony_components/FelonyComponent";
import datePicker from 'vue-bootstrap-datetimepicker';

export default {
    name: "FelonyTabComponent",
    components: {
        FelonyComponent,
        datePicker
    },
    props: {
        felonies: { type: Array },
        editable: { type: Boolean }
    },
    data() {
        return {
            felonyAdd: {notes: []},
            addFelonyFlag: false,
            disableButtonFlag: false,
            dateOptions: {
                format: 'MM/DD/YYYY',
                useCurrent: false
            }
        };
    },
    methods: {
        addFelony() {
            this.disableButtonFlag = true;
            this.felonyAdd.convictionDate = new Date(this.felonyAdd.convictionDate);
            this.$emit('addFelony', this.felonyAdd);
            this.addFelonyFlag = false;
        },
        deleteFelony(felony) {
            this.$emit('deleteFelony', felony);
        },
        disableFelonySubmit() {
            return !this.filledOut(this.felonyAdd.felonyType) ||
                !this.filledOut(this.felonyAdd.caseNumber) ||
                !this.filledOut(this.felonyAdd.status);

        },
        filledOut(i){
            return !(i === null || i === undefined || i === '' || i === 0);
        },
        openAddModal() {
            this.disableButtonFlag = false;
            this.felonyAdd = {notes: []};
            this.addFelonyFlag = true;
        },
        push(field, item) {
            field.push(JSON.parse(JSON.stringify(item)));
        },
        splice(index, field) {
            field.splice(index, 1);
        },
        updateFelony(felony) {
            this.$emit('updateFelony', felony);
        },

    }
}
</script>

<style scoped>

.noAdded {
    text-align: center;
    margin-bottom: 0;
    color: grey
}

</style>