<template>
    <div v-if="socialMediaObject !== undefined">
        <!--Delete Social Media -->
        <b-modal class="modal-dark" scrollable title="Remove Social Media" v-model="deleteObjects.modal" no-close-on-backdrop>
            <p>Are you sure you want to delete this {{deleteObjects.name}}?</p>
            <template slot="modal-footer">
                <b-button @click="deleteObjects.modal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="deleteSocialMedia()" size="sm" variant="danger" :disabled="disableDeleteFlag">Delete {{deleteObjects.name}}
                </b-button>
            </template>
        </b-modal>
        <!--Edit Social Media-->
        <b-modal title="Edit Social Media" class="modal-dark" no-close-on-backdrop
                 scrollable v-model="editSocialMediaFlag">
            <b-input-group class="input-group-sm" prepend="Site" style="margin-bottom: 1px">
                <b-form-select
                    :class=" 'form-control is-' + (filledOut(socialMediaEdit.site) ? 'valid':'invalid')"
                    :options="sites"
                    size="sm"
                    v-model="socialMediaEdit.site"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Username" style="margin-bottom: 1px">
                <b-form-input
                    :class=" 'form-control is-' + (filledOut(socialMediaEdit.userName) ? 'valid':'invalid')"
                    v-model="socialMediaEdit.userName"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Link" style="margin-bottom: 1px">
                <b-form-input v-model="socialMediaEdit.link"/>
            </b-input-group>
            <br>
            <label class="labelForm">Notes</label>&nbsp;
            <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(socialMediaEdit.notes, {text: ''})"/>
            <b-input-group :key="i" class="input-group-sm" v-for="(e,i) in socialMediaEdit.notes">
                <b-form-input v-model="socialMediaEdit.notes[i].text"/>
                <b-input-group-append>
                    <b-button @click="splice(i, socialMediaEdit.notes)" variant="dark">
                        <fa-icon :icon="['fas', 'close']"/>
                    </b-button>
                </b-input-group-append>
            </b-input-group>
            <template slot="modal-footer">
                <b-button @click="openDeleteModal(); disableDeleteFlag = false;"
                          size="sm" style="float: left" variant="danger">Delete
                </b-button>
                <b-button @click="editSocialMediaFlag = false" size="sm" variant="secondary">Cancel
                </b-button>
                <b-button :disabled="disableSocialSubmit()" @click="updateSocialMedia" size="sm" variant="primary">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <!--Main Body-->
        <b-card style="margin-bottom: 10px; background-color: #151515" no-body>
            <span class="d-flex w-100 justify-content-between flex-wrap-reverse"
                  :style="'background: ' + socialMediaTheme(socialMedia.site, false) + '; border-top-right-radius: 3px; border-top-left-radius: 3px; border-bottom: 1px solid #2b2b2b;'">
                <h5 style="margin: 5px; padding: 0">
                    <fa-icon :icon="socialMediaTheme(socialMedia.site, true)"/>&nbsp;{{socialMedia.site}}: {{socialMedia.userName}}
                </h5>
                <span style="margin: 5px; flex-grow: 1"
                      class="d-flex justify-content-end">
                    <a v-show="socialMedia.link !== '' && socialMedia.link !== null && socialMedia.link !== undefined"
                       :title="socialMedia.link"
                       :style="'padding: 0; margin: 0; outline-color: ' + socialMediaTheme(socialMedia.site, false)"
                       data-placement="top" data-toggle="tooltip"
                       :href="socialMedia.link" target="_blank">
                        <fa-icon :icon="['fas', 'link']" style="padding-bottom: 0; transform: translateY(-2px); margin-right: 5px;"/>
                    </a>&nbsp;
                    <fa-icon v-if="editable === true" :icon="['fas', 'pen-to-square']" @click="openModalEditSocialMedia()"
                             style="padding-bottom: 0; cursor: pointer;"/>
                </span>
            </span>
            <div v-if="noteExpand === true"
                 style="padding: 6px">
                <NotesCardComponent :notes="socialMedia.notes"/>
                <div @click="noteExpand = false" style="text-align: center; margin: 0; cursor: pointer;">
                    <fa-icon :icon="['fas', 'angle-up']" style="text-align: center"/>
                </div>
            </div>
            <div v-else @click="noteExpand = true" style="text-align: center; cursor: pointer;">
                <fa-icon :icon="['fas', 'angle-down']"/>
            </div>
        </b-card>
    </div>
</template>

<script>
import NotesCardComponent from "@/views/expansion_modules/risc/components/notes_components/NotesCardComponent";
export default {
    name: "SocialMediaProfileComponent",
    components: {NotesCardComponent},
    props: {
        socialMediaObject: { type: Object },
        editable: { type: Boolean }
    },
    created() {
        this.socialMedia = this.socialMediaObject;
    },
    data() {
        return {
            socialMedia: null,
            noteExpand: false,
            socialMediaEdit: {},
            editSocialMediaFlag: false,
            disableDeleteFlag: false,
            deleteObjects: {
                modal: false,
                name: "Social Media",
                socialMedia: null,
            },
            sites: [
                'FACEBOOK', 'YOUTUBE', 'WHATSAPP', 'MESSENGER', 'WECHAT', 'INSTAGRAM', 'TUMBLR', 'TIK TOK',
                'TWITTER', 'REDDIT', 'LINKEDIN', 'SNAPCHAT', 'PINTEREST', 'LINE', 'TELEGRAM', 'GOOGLE+', 'MEETUP'
            ]
        };
    },
    methods: {
        deleteSocialMedia() {
            this.disableDeleteFlag = true;
            this.$emit('deleteSocialMedia', this.deleteObjects.socialMedia);
            this.editSocialMediaFlag = false;
            this.deleteObjects.modal = false;
        },
        disableSocialSubmit() {
            return !this.filledOut(this.socialMediaEdit.site) ||
                !this.filledOut(this.socialMediaEdit.userName);

        },
        filledOut(i){
            return !(i === null || i === undefined || i === '' || i === 0);
        },
        openDeleteModal() {
            this.deleteObjects.socialMedia = this.socialMedia;
            this.deleteObjects.modal = true;
        },
        openModalEditSocialMedia() {
            this.socialMediaEdit = JSON.parse(JSON.stringify(this.socialMedia))
            this.editSocialMediaFlag = true;
        },
        push(field, item) {
            field.push(JSON.parse(JSON.stringify(item)));
        },
        socialMediaTheme(str, forIcon) {
            switch (str) {
                case 'FACEBOOK':
                    return forIcon ? ['fab', 'facebook-f'] : '#4267B2; color: #FFFFFF';
                case 'YOUTUBE' :
                    return forIcon ? ['fab', 'youtube'] : '#FF0000; color: #FFFFFF';
                case 'WHATSAPP' :
                    return forIcon ? ['fab', 'whatsapp'] : '#25D366; color: #FFFFFF';
                case 'MESSENGER' :
                    return forIcon ? ['fab', 'facebook-messenger'] : '#4267B2; color: #FFFFFF';
                case 'WECHAT' :
                    return forIcon ? ['fab', 'weixin'] : '#5CC928; color: #FFFFFF';
                case 'INSTAGRAM' :
                    return forIcon ? ['fab', 'instagram'] : 'linear-gradient(204deg, rgba(64,93,230,1) 0%,' +
                        ' rgba(88,81,219,1) 12%,' +
                        ' rgba(131,58,180,1) 25%,' +
                        ' rgba(193,53,132,1) 35%,' +
                        ' rgba(225,48,108,1) 49%,' +
                        ' rgba(253,29,29,1) 60%,' +
                        ' rgba(247,119,55,1) 72%,' +
                        ' rgba(252,175,69,1) 84%,' +
                        ' rgba(255,220,128,1) 94%); color: #FFFFFF';
                case 'TUMBLR' :
                    return forIcon ? ['fab', 'tumblr'] : '#34526f; color: #FFFFFF';
                case 'TWITTER' :
                    return forIcon ? ['fab', 'twitter'] : '#1DA1F2; color: #FFFFFF';
                case 'REDDIT' :
                    return forIcon ? ['fab', 'reddit-alien'] : '#FF5700; color: #FFFFFF';
                case 'LINKEDIN' :
                    return forIcon ? ['fab', 'linkedin-in'] : '#0e76a8; color: #FFFFFF';
                case 'SNAPCHAT' :
                    return forIcon ? ['fab', 'snapchat'] : '#FFFC00; color: #000000';
                case 'TIK TOK' :
                    return forIcon ? ['fab', 'tiktok'] : '#000000; color: #FFFFFF';
                case 'PINTEREST' :
                    return forIcon ? ['fab', 'pinterest'] : '#c8232c; color: #FFFFFF';
                case 'LINE' :
                    return forIcon ? ['fab', 'line'] : '#00c300; color: #FFFFFF';
                case 'TELEGRAM' :
                    return forIcon ? ['fab', 'telegram'] : '#0088CC; color: #FFFFFF';
                case 'GOOGLE+' :
                    return forIcon ? ['fab', 'google-plus-g'] : '#d34836; color: #FFFFFF';
                case 'MEETUP' :
                    return forIcon ? ['fab', 'meetup'] : '#ED1C40; color: #FFFFFF';
                default:
                    return false;
            }
        },
        splice(index, field) {
            field.splice(index, 1);
        },
        updateSocialMedia() {
            this.$emit('updateSocialMedia', this.socialMediaEdit);
            this.editSocialMediaFlag = false;
        },
        verifyThereAreNoNotes(notes) {
            const notesLengthEqualToZero = notes.every(note => {
                return note.text.length === 0;
            })
            return notes.length === 0 || notesLengthEqualToZero;
        },

    },
    watch: {
        socialMediaObject() {
            this.socialMedia = this.socialMediaObject;
        }
    }
}
</script>

<style scoped>

.noAdded {
    text-align: center;
    margin-bottom: 0;
    color: grey
}

</style>