<template>
    <div v-if="vehicleArray !== null">

        <!--Add Vehicle-->
        <b-modal class="modal-dark" scrollable title="Add Vehicle" v-model="vehicleInfo.addVehicleFlag" no-close-on-backdrop
                 v-if="vehicleInfo.addVehicleFlag" @close="vehicleInfo.addVehicleFlag = false; vin = ''; vehicleMake = '';">
            <b-input-group class="input-group-sm d-flex no-wrap" prepend="Make" style="margin-bottom: 1px">
                <formSelectSearch :upperCase="true" :options="allMakes" v-model="vehicleMake" :mergeLeft="true" style="color: white"
                                  :arrow="false" size="sm" v-if="allMakes && allMakes.length > 0" class="flex-grow-1"/>
                <p v-else style="width: 184px; margin: 0; padding-top: 3px; text-align: center;">Loading</p>
            </b-input-group>
            <b-input-group class="input-group-sm d-flex no-wrap" prepend="Model" style="margin-bottom: 2px">
                <formSelectSearch :upperCase="true" :options="allModels" v-model="vehicleInfo.data.model"
                                  :arrow="false" :mergeLeft="true" size="sm" v-if="vehicleMake" class="flex-grow-1"/>
                <b-input-group-append v-else class="input-group-sm flex-grow-1 d-flex">
                    <b-input-group-text style="background-color: #333333" class="d-flex flex-grow-1">
                        <span class="flex-grow-1 align-content-center">Select a make first</span>
                    </b-input-group-text>
                </b-input-group-append>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Color" style="margin-bottom: 1px">
                <b-form-input style="width: 15px" v-model="vehicleInfo.data.color" v-uppercase/>
                <b-input-group-append class="input-group-sm">
                    <b-input-group-text>Year</b-input-group-text>
                </b-input-group-append>
                <b-form-select :options="arrayify(1900,(new Date()).getFullYear() + 1, false)"
                               type="number"
                               v-model.number="vehicleInfo.data.year"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Plate" style="margin-bottom: 1px">
                <b-form-input v-model="vehicleInfo.data.plate" v-uppercase style="width: 265px"/>
                <b-input-group-append class="input-group-sm">
                    <b-input-group-text>State</b-input-group-text>
                </b-input-group-append>
                <b-form-select :options="states" v-model="vehicleInfo.data.state"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="VIN" style="margin-bottom: 1px">
                <b-form-input v-model="vin" v-uppercase/>
            </b-input-group>
            <div style="text-align: center;">
                <b-button :disabled="vin === ''" @click="encodeVehicleVIN()" style="margin: 3px;">Decode Information
                    with VIN
                </b-button>
                <p style="font-size: 10px; margin: 3px;">Press button to autofill vehicle fields based on the VIN
                    number</p>
            </div>
            <b-input-group class="input-group-sm" prepend="Registered Owner" style="margin-bottom: 1px">
                <b-form-input v-model="vehicleInfo.data.registeredOwner" v-uppercase/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Description" style="margin-bottom: 1px">
                    <textarea class="form-control rounded-0" rows="3" v-model="vehicleInfo.data.description"
                              type="text"></textarea>
            </b-input-group>
            <br>
            <b-form-group>
                <label>Other Notes</label>&nbsp;
                <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(vehicleInfo.data.notes, {text: '', scope: scope})"/>
                <div v-if="vehicleInfo.data.notes" :key="i"
                     v-for="(note,i) in refactorScope(vehicleInfo.data.notes)">
                    <b-input-group v-if="!note.scope || note.scope === scope || note.scope === 'global'" class="input-group-sm">
                        <div class="col" style="padding: 0; width: 55px">
                            <b-form-input size="sm" v-model="vehicleInfo.data.notes[i].text"/>
                        </div>
                        <b-input-group-append v-if="vehicleInfo.data.notes.length > 1">
                            <b-button @click="splice(i, vehicleInfo.data.notes)"
                                      variant="dark">
                                <fa-icon :icon="['fas', 'close']"/>
                            </b-button>
                        </b-input-group-append>

                    </b-input-group>
                </div>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="vehicleInfo.addVehicleFlag = false; vin = ''; vehicleMake = '';" size="sm" variant="secondary">
                    Cancel
                </b-button>
                <b-button v-if="vehicleInfo.add"
                          :disabled="oneFilledOut(vehicleInfo.data,vehicleInfo.fields) || disableButtonFlag"
                          @click="postVehicle()" size="sm" variant="primary">Submit
                </b-button>
            </template>
        </b-modal>

        <div style="display: flex; align-content: space-between; align-items: center; width: 100%; margin-bottom: 15px;">
            <span v-if="editable === true" class="d-flex align-items-center" style="cursor: pointer;" @click="openModalAddVehicles()">
                <fa-icon :icon="['fas', 'plus']" style="margin-right: 5px"/> Add Vehicle
            </span>
            <span style="margin-left: auto;" @click="getImages = !getImages">
                <b-button size="sm">Get Images</b-button>
            </span>
        </div>
        <p v-if="vehicleArray.length === 0" class="noAdded"><i><strong>No Vehicles Added</strong></i></p>
        <div :key="index" lg="12" v-for="(vehicle, index) in vehicles">
            <VehicleComponent :vehicleObject="vehicle"
                              :profileName="vehicle.profileName"
                              :scope="scope"
                              :editable="editable"
                              :linkId="linkId"
                              :linkType="linkType"
                              :getImages="getImages"
                              @deleteVehicle="deleteVehicle"/>
        </div>
    </div>
</template>

<script>

import VehicleComponent from "@/views/expansion_modules/risc/components/vehicle_components/VehicleComponent";
import formSelectSearch from "@/views/expansion_modules/risc/formSelectSearch";
import axios from "axios";
import risc from "@/services/risc";

export default {
    name: "VehicleTabComponent",

    components: {
        VehicleComponent,
        formSelectSearch
    },

    props: {
        vehicleArray: {
          type: Array
        },
        scope: {
            type: String
        },
        editable: {
            type: Boolean
        },
        linkId: {
            type: String
        },
        linkType: {
            type: String
        }
    },
    data() {
        return {
            getImages: false,
            vehicles: null,
            vehicleInfo: {
                addVehicleFlag: false,
                data: {},
                add: true,
                fields: {
                    state: '',
                    vin: '',
                    registeredOwner: '',
                    make: '',
                    model: '',
                    year: 0,
                    plate: '',
                    color: '',
                    description: '',
                    notes: [{text: '', scope: this.scope}],
                    documents: [],
                    vehicleId: '',
                },
            },
            allMakes: [],
            allModels: [],
            vehicleMake: '',
            states: [
                'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
                'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
                'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
                'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
                'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
            ],
            vin: '',
            notesNewToOld: false,
            refresh: false,
            disableButtonFlag: false,
        }
    },

    created() {
        this.vehicles = this.vehicleArray;
    },

    methods: {
        arrayify(start, end, addToEnd) {
            let result = [];
            for (let i = start; i < end + 1; i++) {
                if (addToEnd) {
                    if (i > 9) {
                        result.push({text: i.toString(), value: i})
                    } else if (i >= 0) {
                        result.push({text: "0" + i, value: i})
                    }
                } else {
                    if (i > 9) {
                        result.unshift({text: i.toString(), value: i})
                    } else if (i >= 0) {
                        result.unshift({text: "0" + i, value: i})
                    }
                }
            }
            return (result);
        },
        deleteVehicle(vehicleId) {
            this.$emit('deleteVehicle', vehicleId)
        },
        encodeVehicleVIN() {
            axios
                .get('https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/' + this.vin + '*BA?format=json')
                .then(response => {
                    response.data.Results.forEach(result => {
                        if (result.Variable === 'Make') {
                            this.vehicleInfo.data.make = result.Value;
                            this.vehicleMake = result.Value;
                        }
                        if (result.Variable === 'Model Year') {
                            this.vehicleInfo.data.year = result.Value;
                        }
                        if (result.Variable === 'Model') {
                            this.vehicleInfo.data.model = result.Value;
                        }
                    })
                })
                .catch(() => {})
        },
        getAllMakes() {
            this.allMakes = [];
            axios
                .get('https://vpic.nhtsa.dot.gov/api/vehicles/GetAllMakes?format=json')
                .then(response => {
                    this.allMakes = response.data.Results.map(i => ({
                        text: i.Make_Name,
                        value: i.Make_ID
                    }));
                })
                .catch(() => {
                    this.$mToast({
                        style: 'error',
                        title: 'Error Loading Vehicle API',
                        text: 'Vehicle API did not load correctly, contact Montel Support.'
                    });
                })
        },
        getAllModels() {
            let temp = this.allMakes.find(_ => _.value === this.vehicleMake)
            if(temp) {
                axios
                    .get('https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMakeId/' + temp.value + '?format=json')
                    .then(response => {
                        this.allModels = response.data.Results.map(i => i.Model_Name);
                    })
                    .catch(() => {
                        this.$mToast({
                            style: 'error',
                            title: 'Error Loading Vehicle API',
                            text: 'Vehicle API did not load correctly, contact Montel Support.'
                        });
                    })
            }
        },
        oneFilledOut(obj, fields) {
            if(this.disableButtonFlag === true){
                return this.disableButtonFlag;
            }
            let result = false;
            let newObj = Object.assign({}, JSON.parse(JSON.stringify(obj)));
            Object.keys(fields).forEach((field, index) => {
                if (fields[field] && Array.isArray(fields[field]) && obj[field].length > 1) {
                    let match = obj[field].every(ele => JSON.stringify(ele) === JSON.stringify(fields[field][0]));
                    newObj[field] = match ? [obj[field][0]] : false;
                }
                if (index + 1 === Object.keys(fields).length) {
                    result = JSON.stringify(newObj) === JSON.stringify(fields);
                }
            })
            return result;
        },
        openModalAddVehicles() {
            this.vehicleInfo.data = JSON.parse(JSON.stringify(this.vehicleInfo.fields))
            this.getAllMakes();
            this.vehicleInfo.addVehicleFlag = true;
        },
        postVehicle() {
            this.disableButtonFlag = true;
            this.vehicleInfo.data.vin = this.vin;
            this.vehicleInfo.data.make = this.allMakes.find(_ => _.value === this.vehicleMake) ? this.allMakes.find(_ => _.value === this.vehicleMake).text : this.vehicleMake;
            if (this.vehicleInfo.data.vehicleId === '') {
                delete this.vehicleInfo.data.vehicleId;
                risc.postVehicle(this.vehicleInfo.data).then(response => {
                    this.vehicleInfo.data.vehicleId = response.data.vehicleId
                    risc.linkProfileVehicle(this.linkId, this.vehicleInfo.data.vehicleId).then(() => {
                        this.vehicles.push(response.data);
                        this.disableButtonFlag = false;
                    }).catch(err => {
                        this.disableButtonFlag = false;
                    })
                })
            }
            this.vehicleInfo.addVehicleFlag = false
            this.vin = '';
            this.vehicleMake = '';
        },
        push(field, item) {
            if (this.notesNewToOld === true) {
                field.unshift(JSON.parse(JSON.stringify(item)));
            } else {
                field.push(JSON.parse(JSON.stringify(item)));
            }
            this.refresh = false;
            this.refresh = true;
        },
        refactorScope(arr) {
            return arr ? arr.map(_ => {_.scope = !_.text ? this.scope : _.scope; return _}): []
        },
        splice(index, field, item) {
            field.splice(index, 1);
            if (item && field.length === 0) {
                this.push(field, item)
            }
            this.refresh = false;
            this.refresh = true;
        },
    },

    watch: {
        vehicleMake() {
            this.getAllModels();
        },
        vehicleArray() {
            this.vehicles = this.vehicleArray;
        }
    }
}
</script>

<style scoped>

</style>