<template>
    <div v-if="criminalHistories !== undefined">

        <!--Edit Criminal History Modal-->
        <b-modal class="modal-dark" scrollable title="Criminal History" no-close-on-backdrop v-model="criminalHistoryFields.postPut" size="md" v-if="criminalHistoryFields.postPut" no-body>
            <b-input-group class="input-group-sm" prepend="Offense " style="margin-bottom: 1px">
                <b-form-input v-model="criminalHistoryFields.data.offense" v-uppercase/>
            </b-input-group>
            <b-input-group class="input-group-sm d-flex" style="margin-bottom: 1px">
                <b-input-group-prepend class="flex-grow-1 d-flex">
                    <b-input-group-text>
                        <span style="text-align: left">Offenses</span>
                    </b-input-group-text>
                    <b-input-group-text style="background-color: #515b65" class="flex-grow-1 d-flex justify-content-between">
                        <fa-icon :icon="['fas', 'circle-minus']" style="margin: 0 5px 0 0; color: darkgrey; cursor: pointer;"
                           @click="incrafy('numberOf', false)"/>
                        <b>{{criminalHistoryFields.data.numberOf}}</b>
                        <fa-icon :icon="['fas', 'circle-plus']" style="margin: 0 0 0 5px; color: darkgrey; cursor: pointer;"
                           @click="incrafy('numberOf', true)"/>
                    </b-input-group-text>
                </b-input-group-prepend>
                <b-input-group-append class="flex-grow-1 d-flex">
                    <b-input-group-text>
                        <span style="text-align: left">Convictions</span>
                    </b-input-group-text>
                    <b-input-group-text style="background-color: #515b65" class="flex-grow-1 d-flex justify-content-between">
                        <fa-icon :icon="['fas', 'circle-minus']" style="margin: 0 5px 0 0; color: darkgrey; cursor: pointer;"
                           @click="incrafy('numberOfConvictions', false)"/>
                        <b>{{criminalHistoryFields.data.numberOfConvictions}}</b>
                        <fa-icon :icon="['fas', 'circle-plus']" style="margin: 0 0 0 5px; color: darkgrey; cursor: pointer;"
                           @click="incrafy('numberOfConvictions', true)"/>
                    </b-input-group-text>
                </b-input-group-append>
                <b-input-group-append class="flex-grow-1 d-flex">
                    <b-input-group-text>
                        <span style=";text-align: left">Arrests</span>
                    </b-input-group-text>
                    <b-input-group-text style="background-color: #515b65" class="flex-grow-1 d-flex justify-content-between">
                        <fa-icon :icon="['fas', 'circle-minus']" style="margin: 0 5px 0 0; color: darkgrey; cursor: pointer;"
                           @click="incrafy('numberOfArrests', false)"/>
                        <b>{{criminalHistoryFields.data.numberOfArrests}}</b>
                        <fa-icon :icon="['fas', 'circle-plus']" style="margin: 0 0 0 5px; color: darkgrey; cursor: pointer;"
                           @click="incrafy('numberOfArrests', true)"/>
                    </b-input-group-text>
                </b-input-group-append>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Description" style="margin-bottom: 1px">
                    <textarea maxlength="500"
                              class="form-control rounded-0" rows="4"
                              v-model="criminalHistoryFields.data.description" type="text"></textarea>
            </b-input-group>
            <br>
            <label class="labelForm">Notes</label>&nbsp;
            <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(criminalHistoryFields.data.notes, {text: ''})"/>
            <b-input-group :key="i" class="input-group-sm" v-for="(e,i) in criminalHistoryFields.data.notes">
                <b-form-input v-model="criminalHistoryFields.data.notes[i].text"/>
                <b-input-group-append>
                    <b-button @click="splice(i, criminalHistoryFields.data.notes)" variant="dark">
                        <fa-icon :icon="['fas', 'close']"/>
                    </b-button>
                </b-input-group-append>
            </b-input-group>
            <template slot="modal-footer">
                <b-button @click="criminalHistoryFields.postPut = false" size="sm" variant="secondary">Cancel
                </b-button>
                <b-button @click="updateCriminalHistory()" size="sm" variant="primary" :disabled="disableButtonFlag">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <!--Remove Criminal History Modal-->
        <b-modal class="modal-dark" scrollable title="Remove Criminal History" v-model="deleteObjects.modal">
            <p>If you would like to delete this {{deleteObjects.name}} please type the prompt below.</p>
            <br>
            <p style="margin-bottom: 0">Enter "{{deleteObjects.name}}":</p>
            <b-form-group description="case sensitive">
                <b-form-input id="basicName2" type="text" v-model="deleteObjects.deleteText"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="deleteObjects.modal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button :disabled="deleteObjects.deleteText !== deleteObjects.name || disableDeleteFlag" @click="deleteCriminalHistory()"
                          size="sm"
                          variant="danger">Delete {{deleteObjects.name}}
                </b-button>
            </template>
        </b-modal>

        <!--Main Body-->
        <b-card no-body style="border-color: #0e0e0e;background-color: #202020">
        <span class="d-flex w-100 justify-content-between"
              style="background-color: #0E0E0E; padding: 0 5px 0 5px; border-radius: 3px 3px 0 0">
            <b style="font-size: medium">OFFENSE {{ index + 1 }}</b>
            <span v-if="editable === true">
                <fa-icon :icon="['fas', 'pen-to-square']" @click="openModalEditCriminalHistories(criminalHistories, index)" style="cursor: pointer;"/>&nbsp;
                <fa-icon :icon="['fas', 'close']" @click="openDeleteModal(criminalHistories.criminalHistoryId)" style="cursor: pointer;"/>
            </span>
        </span>
            <b-row style="margin: 0; padding: 0">
                <b-col class="d-flex flex-column"
                       style="margin: 0; padding: 0"
                       xl="12">
                    <div style="margin: 5px">
                        <div><strong>Offense: </strong>{{ criminalHistories.offense }}</div>
                        <div><strong>Number Of Offenses : </strong>{{ criminalHistories.numberOf }}</div>
                        <div><strong>Number Of Arrests: </strong>{{ criminalHistories.numberOfArrests }}</div>
                        <div><strong>Number Of Convictions: </strong>{{ criminalHistories.numberOfConvictions }}</div>
                        <div><strong>Description: </strong>{{ criminalHistories.description }}</div>
                    </div>
                </b-col>
            </b-row>
            <div v-if="noteExpand" style="padding: 5px">
                <NotesCardComponent :notes="criminalHistories.notes"/>
                <p style="text-align: center; margin: 0; cursor: pointer;" @click="noteExpand = false">
                    <fa-icon :icon="['fas', 'angle-up']" style="text-align: center;"/>
                </p>
            </div>
            <div v-else style="text-align: center; cursor: pointer" @click="noteExpand = true">
                <fa-icon :icon="['fas', 'angle-down']"/>
            </div>
        </b-card>
    </div>
</template>

<script>

import NotesCardComponent from "@/views/expansion_modules/risc/components/notes_components/NotesCardComponent";

export default {
    name: "CriminalHistoryComponent",

    components: {
        NotesCardComponent,
    },

    props: {
        criminalHistories: { type: Object },
        editable: { type: Boolean },
        index: {type: Number},
    },

    data() {
        return {
            disableDeleteFlag: false,
            noteExpand: false,
            profileEdit: null,
            disableButtonFlag: false,
            criminalHistoryFields: {
                postPut: false,
                data: {},
                noteExpand: [],
                index: '',
            },
            deleteObjects: {
                modal: false,
                deleteText: '',
                id: '',
                name: "Criminal History",
            }
        }
    },

    methods: {
        deleteCriminalHistory() {
            this.disableDeleteFlag = true;
            this.$emit('deleteCriminalHistory', this.criminalHistories);
            this.deleteObjects.modal = false;
        },
        incrafy(name, add){
            if(add){
                this.criminalHistoryFields.data[name]++;
            }else if(this.criminalHistoryFields.data[name] > 0){
                this.criminalHistoryFields.data[name]--;
            }
        },
        openDeleteModal(id) {
            this.disableDeleteFlag = false;
            this.deleteObjects.modal = true
            this.deleteObjects.deleteText = ''
            this.deleteObjects.id = id
        },
        openModalEditCriminalHistories(item, index) {
            this.profileEdit = JSON.parse(JSON.stringify(this.criminalHistories))
            this.criminalHistoryFields.data = JSON.parse(JSON.stringify(item))
            this.criminalHistoryFields.index = index
            this.criminalHistoryFields.postPut = true
        },
        push(field, item) {
            field.push(JSON.parse(JSON.stringify(item)));
        },

        updateCriminalHistory() {
            this.$emit('updateCriminalHistory', this.criminalHistoryFields.data);
            this.criminalHistoryFields.postPut = false;
        },
        splice(index, field) {
            field.splice(index, 1);
        },
    }
}
</script>

<style scoped>

</style>