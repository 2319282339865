import axios from 'axios'
//import * as risckeeper from '@/plugins/risckeeper-plugin'
import authStore from "@/store/authStore";

function Risc() {
    return axios.create({
        baseURL: get_risc_engine(),
        withCredentials: false,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authStore.getters.getUserManager.getAccessToken()
        }
    });
}

function get_risc_engine() {
    //return JSON.parse(localStorage.getItem("customFields")).risc_engine || null;
    return config.VUE_APP_RISCENGINE || process.env.VUE_APP_RISCENGINE;
}

function riscStorage() {
    return axios.create({
        baseURL: get_risc_storage(),
        withCredentials: false,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authStore.getters.getUserManager.getAccessToken()
        }
    });
}

function get_risc_storage() {
    //return JSON.parse(localStorage.getItem("customFields")).risc_storage || null;
    return config.VUE_APP_RISCSTORAGE || process.env.VUE_APP_RISCSTORAGE;
}

export default {
    get_risc_storage,
    get_risc_engine,

    //Involvements
    getInvolvements(){
        return new Risc().get('/risc/involvement/');
    },
    putInvolvement(data){
        return new Risc().put('/risc/involvement/involvementId?involvementId=' + data.involvementId , data);
    },
    postInvolvement(data){
        return new Risc().post('/risc/involvement', data);
    },
    getInvolvement(involvementId){
        return new Risc().get('/risc/involvement/' + involvementId);
    },
    deleteInvolvement(involvementId){
        return new Risc().delete('/risc/involvement/' + involvementId);
    },
    linkLeReportInvolvement(leReportId, involvementId) {
        return new Risc().post('/risc/lereports/linkinvolvement/' + leReportId + '/' + involvementId);
    },
    linkProfileInvolvement(profileId, involvementId) {
        return new Risc().post('/risc/profiles/linkinvolvement/' + profileId + '/' + involvementId);
    },
    linkOrgInvolvement(orgId, involvementId) {
        return new Risc().post('/risc/organizations/linkinvolvement/' + orgId + '/' + involvementId);
    },
    unlinkLeReportInvolvement(leReportId, involvementId) {
        return new Risc().post('/risc/lereports/breakinvolvement/' + leReportId + '/' + involvementId);
    },
    unlinkProfileInvolvement(profileId, involvementId) {
        return new Risc().delete('/risc/profiles/breakinvolvement/' + profileId + '/' + involvementId);
    },
    unlinkOrgInvolvement(orgId, involvementId) {
        return new Risc().delete('/risc/organizations/breakinvolvement/' + orgId + '/' + involvementId);
    },

    // Get Image
    getImage(storageId) {
        return get_risc_storage() + "/File/image/intel/" + storageId;
    },

    //Address
    getAddresses(){
        return new Risc().get('/risc/address/');
    },
    putAddress(data){
        return new Risc().put('/risc/address', data);
    },
    postAddress(data){
        return new Risc().post('/risc/address', data);
    },
    getAddress(addressId){
        return new Risc().get('/risc/address/' + addressId);
    },
    deleteAddress(addressId){
        return new Risc().delete('/risc/address/' + addressId);
    },

    //Drug
    getDrugs() {
        return new Risc().get('/risc/drugs');
    },
    putDrug(data){
        return new Risc().put('/risc/drugs', data);
    },
    postDrug(data){
        return new Risc().post('/risc/drugs', data);
    },
    getDrug(drugId){
        return new Risc().get('/risc/drugs/' + drugId);
    },
    deleteDrug(drugId){
        return new Risc().delete('/risc/drugs/' + drugId);
    },

    //IntelCase
    getCases(){
        return new Risc().get('/risc/intelCases/');
    },
    putCase(data){
        return new Risc().put('/risc/intelCases/', data);
    },
    postCase(data){
        return new Risc().post('/risc/intelCases', data);
    },
    getCase(intelCaseId){
        return new Risc().get('/risc/intelCases/' + intelCaseId);
    },
    deleteCase(intelCaseId){
        return new Risc().delete('/risc/intelCases/' + intelCaseId);
    },
    linkCaseVehicle(caseId, vehicleId) {
        return new Risc().post('/risc/intelCases/linkVehicle/' + caseId + "/" + vehicleId);
    },
    linkCaseAddress(caseId, addressId) {
        return new Risc().post('/risc/intelCases/linkaddress/' + caseId + "/" + addressId);
    },
    linkCaseDrug(caseId, drugId) {
        return new Risc().post('/risc/intelCases/linkdrug/' + caseId + "/" + drugId);
    },
    linkCaseProfile(caseId, profileId){
        return new Risc().post('/risc/intelCases/linkprofile/' + caseId + '/' + profileId);
    },
    linkCaseWeapon(caseId, weaponId) {
        return new Risc().post('/risc/intelCases/linkweapon/' + caseId + "/" + weaponId);
    },
    linkCaseLeReport(caseId, leReportId) {
        return new Risc().post('/risc/intelCases/linklereport/' + caseId + "/" + leReportId);
    },
    linkCaseOrganization(caseId, organizationId) {
        return new Risc().post('/risc/intelCases/linkOrg/' + caseId + "/" + organizationId);
    },
    unlinkCaseVehicle(caseId, vehicleId) {
        return new Risc().delete('/risc/intelCases/breakVehicle/' + caseId + "/" + vehicleId);
    },
    unlinkCaseAddress(caseId, addressId) {
        return new Risc().delete('/risc/intelCases/breakaddress/' + caseId + "/" + addressId);
    },
    unlinkCaseDrug(caseId, drugId) {
        return new Risc().delete('/risc/intelCases/breakdrug/' + caseId + "/" + drugId);
    },
    unlinkCaseProfile(caseId, profileId){
        return new Risc().delete('/risc/intelCases/breakprofile/' + caseId + '/' + profileId);
    },
    unlinkCaseWeapon(caseId, weaponId) {
        return new Risc().delete('/risc/intelCases/breakweapon/' + caseId + "/" + weaponId);
    },
    unlinkCaseOrganization(caseId, organizationId) {
        return new Risc().delete('/risc/intelCases/breakOrg/' + caseId + "/" + organizationId);
    },
    unlinkCaseLeReport(caseId, leReportId) {
        return new Risc().delete('/risc/intelCases/breakLeReport/' + caseId + "/" + leReportId);
    },

    //LE-Reports
    getLeReports() {
        return new Risc().get('/risc/lereports');
    },
    putLeReport(data){
        return new Risc().put('/risc/lereports', data);
    },
    postLeReport(data){
        return new Risc().post('/risc/lereports', data);
    },
    getLeReport(leReportId){
        return new Risc().get('/risc/lereports/' + leReportId);
    },
    deleteLeReport(leReportId){
        return new Risc().delete('/risc/lereports/' + leReportId);
    },

    //Organizations
    getOrganizations() {
        return new Risc().get('/risc/organizations');
    },
    putOrganizations(organization){
        return new Risc().put('/risc/organizations/', /*+ organization.organizationId,*/ organization);
    },
    postOrganizations(organization) {
        return new Risc().post('/risc/organizations', organization);
    },
    getOneOrganization(organization) {
        return new Risc().get('/risc/organizations/' + organization);
    },
    getOrganizationsById(orgArr){
        return new Risc().post('/risc/organizations/organizationsbyid', orgArr);
    },
    deleteOrganizations(organization) {
        return new Risc().delete('/risc/organizations/' + organization.organizationId);
    },
    linkOrgAddress(organizationId, addressId) {
        return new Risc().post('/risc/organizations/linkAddress/' + organizationId + "/" + addressId);
    },
    linkOrgLeReport(organizationId, leReportId) {
        return new Risc().post('/risc/organizations/linklereport/' + organizationId + "/" + leReportId);
    },
    linkAddressLeReport(addressId, leReportId) {
        return new Risc().post('/risc/lereports/linkaddress/' + leReportId + '/' + addressId);
    },
    unlinkAddressLeReport(addressId, leReportId) {
        return new Risc().post('/risc/lereports/breakaddress/' + leReportId + '/' + addressId);
    },
    unlinkOrgAddress(organizationId, profileId) {
        return new Risc().delete('/risc/organizations/breakAddress/' + organizationId + "/" + profileId);
    },
    unlinkOrgLeReport(organizationId, leReportId) {
        return new Risc().delete('/risc/organizations/breaklereport/' + organizationId + "/" + leReportId);
    },


    //Profiles
    getProfiles(){
        return new Risc().get('/risc/profiles' );
    },
    putProfiles(profile){
        return new Risc().put('/risc/profiles', profile);
    },
    postProfiles(profile){
        return new Risc().post('/risc/profiles', profile);
    },
    getOneProfile(id){
        return new Risc().get('/risc/profiles/' + id);
    },
    deleteProfiles(id){
        return new Risc().delete('/risc/profiles/' + id);
    },
    getProfilesById(idArr){
        return new Risc().post('/risc/profiles/profilesbyid', idArr);
    },
    linkProfile(profileId, groupProfileId) {
        return new Risc().post('/risc/profiles/linkprofile/' + profileId + "/" + groupProfileId);
    },
    unlinkProfile(profileId, groupProfileId) {
        return new Risc().delete('/risc/profiles/breakprofile/' + profileId + "/" + groupProfileId);
    },
    linkProfileAddress(profileId, leReportId) {
        return new Risc().post('/risc/profiles/linkaddress/' + profileId + "/" + leReportId);
    },
    linkProfileDrug(profileId, drugId) {
        return new Risc().post('/risc/profiles/linkdrug/' + profileId + "/" + drugId);
    },
    linkProfileVehicle(profileId, vehicleId) {
        return new Risc().post('/risc/profiles/linkvehicle/' + profileId + "/" + vehicleId);
    },
    linkProfileWeapon(profileId, weaponId) {
        return new Risc().post('/risc/profiles/linkweapon/' + profileId + "/" + weaponId);
    },
    linkProfileLeReport(profileId, leReportId) {
        return new Risc().post('/risc/profiles/linkreport/' + profileId + "/" + leReportId);
    },
    linkProfileFelonies(profileId, felonyId) {
        return new Risc().post('/risc/profiles/felonies/' + profileId + "/" + felonyId);
    },
    unlinkProfileAddress(profileId, leReportId) {
        return new Risc().delete('/risc/profiles/breakaddress/' + profileId + "/" + leReportId);
    },
    unlinkProfileDrug(profileId, drugId) {
        return new Risc().delete('/risc/profiles/breakdrug/' + profileId + "/" + drugId);
    },
    unlinkProfileVehicle(profileId, vehicleId) {
        return new Risc().delete('/risc/profiles/breakvehicle/' + profileId + "/" + vehicleId);
    },
    unlinkProfileWeapon(profileId, weaponId) {
        return new Risc().delete('/risc/profiles/breakweapon/' + profileId + "/" + weaponId);
    },
    unlinkProfileLeReport(profileId, leReportId) {
        return new Risc().delete('/risc/profiles/breakreport/' + profileId + "/" + leReportId);
    },
    linkOrg(organizationId, profileId) {
        return new Risc().post('/risc/profiles/linkorg/' + profileId + "/" + organizationId);
    },
    unlinkOrg(organizationId, profileId) {
        return new Risc().delete('/risc/profiles/breakorg/' + profileId + "/" + organizationId);
    },

    //Vehicles
    getVehicles() {
        return new Risc().get('/risc/vehicles');
    },
    putVehicle(data){
        return new Risc().put('/risc/vehicles', data);
    },
    postVehicle(data){
        return new Risc().post('/risc/vehicles', data);
    },
    getVehicle(vehicleId){
        return new Risc().get('/risc/vehicles/' + vehicleId);
    },
    deleteVehicle(vehicleId){
        return new Risc().delete('/risc/vehicles/' + vehicleId);
    },

    //Weapons
    getWeapons() {
        return new Risc().get('/risc/weapons');
    },
    putWeapon(data){
        return new Risc().put('/risc/weapons', data);
    },
    postWeapon(data){
        return new Risc().post('/risc/weapons', data);
    },
    getWeapon(weaponId){
        return new Risc().get('/risc/weapons/' + weaponId);
    },
    deleteWeapon(weaponId){
        return new Risc().delete('/risc/weapons/' + weaponId);
    },
    deleteProfileWeapon(weaponId, profileId){
        return new Risc().put('/risc/weapons/' + weaponId + '/profile', {profileId: profileId});
    },


///////////////////////////////////////////////OLD Requests//////////////////////////////////////////////////
    //Profiles
    putCorrespondingProfiles(assocId, payload){
        return risc.put('/risc/profiles/' + assocId, {associates: payload})
    },
    getAssociates(profileId) {
        return risc.get('/risc/organizations/associates/' + profileId)
    },

    //IntelCase
    getProfileCase(profileId){
        return risc.get('/risc/intelCases/profile/' + profileId)
    },


    //Vehicles
    deleteProfileVehicle(vehicleId, profileId){
        return risc.put('/risc/vehicles/' + vehicleId + '/profile', {profileId: profileId})
    },
    deleteCaseVehicle(vehicleId, caseId){
        return risc.put('/risc/vehicles/' + vehicleId + '/case', {caseId: caseId})
    },
    postCaseVehicle(vehicleId, caseId){
        return risc.post('/risc/vehicles/' + vehicleId + '/case', {caseId: caseId})
    },
    getProfileVehicles(profileId){
        return risc.get('/risc/vehicles/profile/' + profileId)
    },
    getCaseVehicles(caseId){
        return risc.get('/risc/vehicles/case/' + caseId)
    },

    //Drugs
    deleteProfileDrug(drugId, profileId){
        return risc.put('/risc/drugs/' + drugId + '/profile', {profileId: profileId})
    },
    deleteCaseDrug(drugId, caseId){
        return risc.put('/risc/drugs/' + drugId + '/case', {caseId: caseId})
    },
    /*postProfileDrug(drugId, profileId){
        return risc.post('/risc/drugs/' + drugId + '/profile', {profileId: profileId})
    },*/
    postCaseDrug(drugId, caseId){
        return risc.post('/risc/drugs/' + drugId + '/case', {caseId: caseId})
    },
    getProfileDrugs(profileId){
        return risc.get('/risc/drugs/profile/' + profileId)
    },
    getCaseDrugs(caseId){
        return risc.get('/risc/drugs/case/' + caseId)
    },

    //Weapons
    deleteCaseWeapon(weaponId, caseId){
        return risc.put('/risc/weapons/' + weaponId + '/case', {caseId: caseId})
    },
    /*postProfileWeapon(weaponId, profileId){
        return risc.post('/risc/weapons/' + weaponId + '/profile', {profileId: profileId})
    },*/
    postCaseWeapon(weaponId, caseId){
        return risc.post('/risc/weapons/' + weaponId + '/case', {caseId: caseId})
    },
    getProfileWeapons(profileId){
        return risc.get('/risc/weapons/profile/' + profileId)
    },
    getCaseWeapons(caseId){
        return risc.get('/risc/weapons/case/' + caseId)
    },

    //LE-Reports
    deleteProfileLeReport(leReportId, profileId){
        return risc.put('/risc/lereports/' + leReportId + '/profile', {profileId: profileId})
    },
    deleteCaseLeReport(leReportId, caseId){
        return risc.put('/risc/lereports/' + leReportId + '/case', {caseId: caseId})
    },
    /*postProfileLeReport(leReportId, profileId){
        return risc.post('/risc/lereports/' + leReportId + '/profile', {profileId: profileId})
    },*/
    postCaseLeReport(leReportId, caseId){
        return risc.post('/risc/lereports/' + leReportId + '/case', {caseId: caseId})
    },
    getProfileLeReports(profileId){
        return risc.get('/risc/lereports/profile/' + profileId)
    },
    getCaseLeReports(caseId){
        return risc.get('/risc/lereports/case/' + caseId)
    }

}