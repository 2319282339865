<template>
    <div v-if="address !== null">

        <!--View Files-->
        <FileViewer2 :flag="fileViewerFlag" :fileId="fileViewStorageId" :closeMethod="closeViewModal"/>

        <!--Remove Address-->
        <b-modal class="modal-dark" scrollable title="Remove Address" v-model="deleteObjects.modal">
            <p>If you would like to delete this {{deleteObjects.name}} please type the prompt below.</p>
            <br>
            <p style="margin-bottom: 0">Enter "{{deleteObjects.name}}":</p>
            <b-form-group description="case sensitive">
                <b-form-input id="basicName2" type="text" v-model="deleteObjects.deleteText"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="deleteObjects.modal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button :disabled="deleteObjects.deleteText !== deleteObjects.name" @click="deleteAddress()"
                          size="sm"
                          variant="danger">Delete {{deleteObjects.name}}
                </b-button>
            </template>
        </b-modal>

        <!--Edit Addresses-->
        <b-modal no-close-on-backdrop class="modal-dark" scrollable title="Edit Address" size="md" v-model="addressFields.addressEdit" v-if="addressFields.addressEdit" no-body>
            <span>
                <b-input-group class="input-group-sm" prepend="Address" style="margin-bottom: 1px">
                    <b-form-input ref="autocomplete"
                                  id="autocomplete"
                                  placeholder="Enter Address"
                                  type="text"
                                  v-model="addressInput"
                                  types=""
                                  autocomplete="on"
                                  v-uppercase/>
                    <b-dropdown :text="addressFields.data.active ? 'Active' : 'Inactive'"
                                size="sm" variant="dark">
                        <b-dropdown-item @click="addressFields.data.active = true">Active
                        </b-dropdown-item>
                        <b-dropdown-item @click="addressFields.data.active = false">Inactive
                        </b-dropdown-item>
                    </b-dropdown>
                </b-input-group>
                <b-input-group class="input-group-sm" prepend="Apartment/Unit" style="margin-bottom: 1px">
                    <b-form-input v-model="addressFields.data.address2" v-uppercase/>
                </b-input-group>
                <b-input-group class="input-group-sm" prepend="City">
                    <b-form-input v-model="addressFields.data.city" v-uppercase/>
                    <b-input-group-append class="input-group-sm">
                        <b-input-group-text>State</b-input-group-text>
                    </b-input-group-append>
                    <b-form-select :options="states" size="sm"
                                   v-model="addressFields.data.state"/>
                    <b-input-group-append class="input-group-sm">
                        <b-input-group-text>Zip</b-input-group-text>
                    </b-input-group-append>
                    <b-form-input type="number"
                                  :class=" 'form-control is-' + (filledOut(addressFields.data.zip) ? 'valid':'invalid')"
                                  v-model.number="addressFields.data.zip"/>
                </b-input-group>
                <b-input-group class="input-group-sm">
                </b-input-group>
                <b-form-group><br>
                    <label>Other Notes</label>&nbsp;
                    <fa-icon :icon="['fas', 'circle-plus']" @click="push(addressFields.data.notes, {text: '', scope: scope})"/>
                    <div v-if="addressFields.data.notes" :key="i"
                         v-for="(note,i) in refactorScope(addressFields.data.notes)">
                        <b-input-group v-if="!note.scope || note.scope === scope || note.scope === 'global'" class="input-group-sm">
                            <div class="col" style="padding: 0; width: 55px">
                                <b-form-input size="sm" v-model="addressFields.data.notes[i].text"></b-form-input>
                            </div>
                            <b-input-group-append>
                                <div class="col-auto" style="padding: 0 4px 0 2px;">
                                    <b-form-select v-model ="addressFields.data.notes[i].scope"
                                                   size="sm" plain
                                                   style="background-color: #191919; border-color: #191919; ">
                                        <option :value="scope">Scoped</option>
                                        <option :value="'global'">Global</option>
                                    </b-form-select>
                                </div>
                                <b-button @click="splice(i, addressFields.data.notes)"
                                          variant="dark">
                                    <fa-icon :icon="['fas', 'close']"/>
                                </b-button>
                            </b-input-group-append>

                        </b-input-group>
                    </div>
                </b-form-group>
            </span>
            <template slot="modal-footer">
                <b-button @click="addressFields.addressEdit = false" size="sm"
                          variant="secondary">Cancel
                </b-button>
                <b-button @click="updateAddress()" size="sm" variant="primary"
                          :disabled="!filledOut(addressFields.data.zip)">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <!-- Main Address Card -->
        <b-card no-body style="border-color: #0e0e0e;background-color: #202020">
            <span class="d-flex w-100 justify-content-between"
                  style="background-color: #0e0e0e; padding: 0 5px 0 5px; border-radius: 3px 3px 0 0">
                <span class="d-flex align-items-center">
                    <img style="height: 21px" :src="'https://maps.google.com/mapfiles/ms/icons/' + (color !== undefined ? color : 'blue') + '-dot.png'" alt=""/>
                    <b v-if="title !== null" style="font-size: medium; margin-right: 5px">{{ title }}&nbsp;</b>&nbsp;
                    <b-badge :class="'badge badge-' + (address.active ? 'success' : 'danger')"
                             style="height: fit-content; margin-bottom: 3px;">
                        {{ (address.active ? "Active" : "Not Active") }}
                    </b-badge>
                </span>
                <span v-if="editable === true">
                    <fa-icon :icon="['fas', 'pen-to-square']" @click="openModalEditAddresses()" style="cursor: pointer;"/>&nbsp;
                    <fa-icon :icon="['fas', 'close']" @click="openDeleteModal(address.addressId)" size="lg"
                             style="cursor: pointer; transform: translateY(1px);"/>
                </span>
            </span>
            <b-row style="margin: 0; padding: 0">
                <b-col class="d-flex flex-column" style="margin: 0; padding: 0"
                       :xl="(address.documents && address.documents.length > 0) ? 7 : 12">
                    <div style="padding: 5px 0 5px 0; height: 100%">
                        <div class="p-0 d-flex flex-column align-items-center justify-content-center"
                             style="border-bottom: #282d32 1px; border: none; height: 100%">
                            <strong v-if="address.address1 !== ''"
                                    style="font-size: 15px">{{ address.address1 }}<br></strong>
                            <strong v-if="address.address2 !== ''"
                                    style="font-size: 15px">{{ address.address2 }}<br></strong>
                            <strong style="text-align: center; font-size: 15px" v-if="address.city !== ''">
                                {{ address.city + ", " + address.state + " " + address.zip }}
                            </strong>
                            <strong style="text-align: center; font-size: 15px; position: relative" v-else>
                                {{ address.state + " " + address.zip }}
                            </strong>
                            <strong
                                class="text-muted text-uppercase font-weight-bold font-xs d-flex flex-row justify-content-center align-items-end"
                                style="width: 100%; height: 100%; text-align: end; padding: 1px">
                                Created By: {{ address.createdBy }}
                            </strong>
                        </div>
                    </div>
                </b-col>
                <b-col style="margin: 0; padding: 0" xl="5"
                       class="d-flex justify-content-center"
                       v-if="address.documents && address.documents.length > 0">
                    <span style="width: auto; max-height: 100px; margin: 5px; border: 2px solid #23282c; border-radius: 5px; background-color: #0E0E0E;"
                        class="image-parent">
                        <img class="img-center-ass"
                             style="border-radius: 5px; max-height: 100px"
                             v-if="address.documents && address.documents.length > 0"
                             v-auth-image="getFirstDocumentImage(address.documents)"
                             @click="openViewModal(address.documents[0].storageFileName)"
                             alt=""/>
                        <img v-else class="img-center-ass"
                             style="border-radius: 5px;"
                             src="/NoImageAvailable.png"
                             alt="">
                    </span>
                </b-col>
            </b-row>
            <div v-if="noteExpand === true" style="border-radius: 3px">
                <br>
                <div style="padding: 10px">
                    <NotesCardComponent :notes="address.notes" :scope="scope"/>
                    <br>
                    <DocumentCardComponent :editable="editable"
                                           :documents="address.documents ? address.documents : []"
                                           :editMethod="changeDocumentDesc"
                                           :deleteMethod="deleteEmbeddedDoc"
                                           :submitSelectorMethod="setFileFavorite"
                                           :submitUploadMethod="addAddressDocument"/>
                </div>
                <div style="text-align: center; margin: 0; cursor: pointer;" @click="noteExpand = false">
                    <fa-icon :icon="['fas', 'angle-up']" style="text-align: center;"/>
                </div>
            </div>
            <div v-else style="text-align: center; margin: 0; border-radius: 3px; z-index: 1; cursor: pointer;" @click="openMoreInfo()">
                <fa-icon :icon="['fas', 'angle-down']"/>
            </div>
        </b-card>
    </div>
</template>

<script>
import risc from "@/services/risc";
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import {gmapApi} from "vue2-google-maps";
import DocumentCardComponent from "@/views/expansion_modules/risc/components/documents_components/DocumentCardComponent";
import NotesCardComponent from "@/views/expansion_modules/risc/components/notes_components/NotesCardComponent";
import FileViewer2 from '@/views/expansion_modules/risc/components/utility_components/FileViewer2.0';
import PubSub from 'pubsub-js';

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDiNNTEEV7dTzuxZW1NCvR26RmHmOUhg2U',
        libraries: 'places'
    }
});

export default {
    name: "AddressComponent",
    components: {
        DocumentCardComponent,
        NotesCardComponent,
        FileViewer2
    },
    props: {
        addressObject: {
            type: Object
        },
        title: {
            type: String
        },
        editable: {
            type: Boolean
        },
        scope: {
            type: String
        },
        linkId: {
            type: String
        },
        linkType: {
            type: String
        },
        color: {
            type: String
        },
        getImages: {
            type: Boolean
        }
    },
    computed: {
        google: gmapApi
    },
    created() {
        //this.getAddress();
        this.address = this.addressObject;
        PubSub.publish('addaddressmarker', {address: this.addressObject, color: this.color});
    },
    data() {
        return {
            address: null,
            noteExpand: false,
            moreInfo: false,
            fileViewStorageId: null,
            fileViewerFlag: false,
            documentDescription: '',
            editDocDesc: '',
            addressInput: '',
            dTable: {
                theme: 'bootstrap4',
                columns: ['file', 'description', 'edit_delete'],
                uneditableColumns: ['file', 'description'],
                tOptions: {
                    sortable: [],
                    filterable: [],
                    orderBy: {column: 'file', ascending: true},
                    headings: {
                        edit_delete: 'Edit/Delete'
                    },
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll'
                    },
                    perPage: 25,
                    skin: 'table table-striped table-hover',
                },
                useVuex: false,
                template: 'default',
                createCaseModal: false
            },
            addressFields: {
                addressEdit: false,
                data: {}
            },
            deleteObjects: {
                modal: false,
                name: "Address",
                deleteText: "",
                id: '',
            },
            deleteED: {
                modal: false,
                name: 'Address Document',
                deleteText: '',
                dId: ''
            },
            states: [
                'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
                'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
                'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
                'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
                'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
            ]
        };
    },
    methods: {
        addAddressDocument(description, fileId) {
            this.address.documents.push({
                description: description,
                storageFileName: fileId,
                arrayOrder: this.address.documents.length
            })
            risc.putAddress(this.address).then(() => {
                this.getAddress()
            });
        },
        changeDocumentDesc(doc) {
            this.address.documents.forEach(document => {
                if (document.documentId === doc.documentId) {
                    document.description = doc.description
                }
            })
            risc.putAddress(this.address).then(() => {
                this.getAddress()
            });
        },
        closeViewModal() {
            this.fileViewerFlag = false;
        },
        deleteAddress() {
            if (this.linkType === 'Org') {
                risc.unlinkOrgAddress(this.linkId, this.deleteObjects.id).then(() => {
                    this.deleteObjects.modal = false
                    this.$emit('deleteAddress', this.address);
                });
            } else if (this.linkType === 'LeReport') {
                risc.unlinkAddressLeReport(this.deleteObjects.id, this.linkId).then(() => {
                    this.deleteObjects.modal = false
                    this.$emit('deleteAddress', this.address);
                });
            } else if (this.linkType === 'Profile') {
                risc.unlinkProfileAddress(this.linkId, this.deleteObjects.id).then(() => {
                    this.deleteObjects.modal = false;
                    this.$emit('deleteAddress', this.address);
                })
            }
        },
        deleteEmbeddedDoc(doc) {
            this.address.documents = this.address.documents.filter((document) => {
                return document.documentId !== doc.documentId;
            });
            risc.putAddress(this.address).then(() => {
                this.getAddress();
            });
        },
        filledOut(i){
            return !(i === null || i === undefined || i === '' || i === 0);
        },
        getAddress(callback) {
            risc.getAddress(this.address.addressId).then(response => {
                this.address = response.data;
                this.moreInfo = true;
                if (callback) {
                    callback();
                }
            });
        },
        async getAddressData(autocomplete) {
            this.$forceUpdate();
            const place = await autocomplete.getPlace();
            const ac = place.address_components;
            let streetNumber = '';
            let route = '';
            for (let i = 0; i < ac.length; i++) {
                let addressType = ac[i].types[0];
                if (addressType === 'street_number') {
                    streetNumber = ac[i].long_name.toUpperCase();
                }
                if (addressType === 'route') {
                    route = ac[i].long_name.toUpperCase();
                }
                if (addressType === 'intersection') {
                    route = ac[i].long_name.toUpperCase();
                }
                if (addressType === 'locality') {
                    this.addressFields.data.city = ac[i].long_name.toUpperCase();
                }
                if (addressType === 'administrative_area_level_1') {
                    this.addressFields.data.state = ac[i].short_name.toUpperCase();
                }
                if (addressType === 'postal_code') {
                    this.addressFields.data.zip = parseInt(ac[i].long_name);
                }
            }
            this.addressInput = streetNumber + ' ' + route;
            this.addressFields.data.address1 = streetNumber + ' ' + route;
        },
        getFirstDocumentImage(docArray) {
            let stId = 0;
            docArray.forEach(doc => {
                if (doc.arrayOrder === 0) {
                    stId = doc.storageFileName;
                }
            })
            if (stId !== 0) {
                return risc.getImage(stId);
            } else {
                return risc.getImage(docArray[0].storageFileName);
            }
        },
        getImage(storageId) {
            if (storageId !== undefined) {
                return risc.getImage(storageId);
            } else {
                return ('/img/NoPictureOnFileGrey.png')
            }
        },
        openDeleteModal(id) {
            this.deleteObjects.modal = true
            this.deleteObjects.deleteText = ''
            this.deleteObjects.id = id
        },
        openModalEditAddresses() {
            if (this.moreInfo === false) {
                this.getAddress(() => {
                    this.addressFields.data = JSON.parse(JSON.stringify(this.address))
                    this.addressFields.addressEdit = true;
                    this.addressInput = this.addressFields.data.address1;
                });
            } else {
                this.addressFields.data = JSON.parse(JSON.stringify(this.address))
                this.addressFields.addressEdit = true;
                this.addressInput = this.addressFields.data.address1;
            }
        },
        openMoreInfo() {
            if (this.moreInfo === false) {
                this.getAddress(() => {
                    this.noteExpand = true;
                });
            } else {
                this.noteExpand = true;
            }
        },
        openViewModal(storageId) {
            this.fileViewStorageId = storageId
            this.fileViewerFlag = true;
        },
        push(field, item) {
            field.push(JSON.parse(JSON.stringify(item)));
        },
        refactorScope(arr) {
            return arr ? arr.map(_ => {_.scope = !_.text ? this.scope : _.scope; return _}) : [];
        },
        setFileFavorite(files) {
            this.address.documents = files
            risc.putAddress(this.address).then(() => {
                this.getAddress();
            });
        },
        splice(index, field) {
            field.splice(index, 1);
        },
        updateAddress() {
            risc.putAddress(this.addressFields.data).then(() => {
                this.getAddress();
            });
            this.addressFields.addressEdit = false;
            this.addressInput = '';
        },

    },
    watch: {
        getImages() {
            if (this.getImages === true && this.moreInfo === false) {
                this.getAddress();
                this.moreInfo = true;
            }
        },
        addressObject() {
            this.address = this.addressObject;
        },
        addressInput() {
            const autocomplete = new this.google.maps.places.Autocomplete(
                (document.getElementById('autocomplete')), {types: ['geocode']}
            );
            autocomplete.setFields(['address_components']);
            autocomplete.addListener('place_changed', () => {
                this.getAddressData(autocomplete);
            });
        }
    }
}
</script>

<style scoped>

</style>