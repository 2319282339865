<template>
    <div v-if="phone !== undefined">

        <!--Delete Phone Number -->
        <b-modal class="modal-dark" scrollable title="Remove Phone Number" v-model="deleteObjects.modal" no-close-on-backdrop>
            <p>Are you sure you want to delete this {{deleteObjects.name}}?</p>
            <template slot="modal-footer">
                <b-button @click="deleteObjects.modal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="deletePhoneNumber()" size="sm" variant="danger" :disabled="disableDeleteFlag">Delete {{deleteObjects.name}}
                </b-button>
            </template>
        </b-modal>

        <!--Edit Phone Numbers-->
        <b-modal title="Edit Phone Number"
                 class="modal-dark" no-close-on-backdrop
                 scrollable v-model="editPhoneFlag">
            <b-input-group class="input-group-sm" prepend="Number" style="margin-bottom: 1px">
                <input maxlength="14" @keypress="onlyNumber"
                       type="text" v-mask="'(###) ###-####'" placeholder="(___) ___-____"
                       :class=" 'form-control is-' + (filledOut(phoneEdit.number) ? 'valid' : 'invalid')"
                       v-model="phoneEdit.number"/>
                <b-dropdown :text="phoneEdit.active ? 'Active' : 'Inactive'" size="sm"
                            variant="dark">
                    <b-dropdown-item @click="phoneEdit.active = true">Active</b-dropdown-item>
                    <b-dropdown-item @click="phoneEdit.active = false">Inactive</b-dropdown-item>
                </b-dropdown>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Note" style="margin-bottom: 1px">
                <b-form-input
                    v-model="phoneEdit.notes"/>
            </b-input-group>

            <template slot="modal-footer">
                <b-button @click="openDeleteModal(); disableDeleteFlag = false"
                          size="sm" style="float: left" variant="danger">Delete
                </b-button>
                <b-button @click="editPhoneFlag = false" size="sm" variant="secondary">Cancel
                </b-button>
                <b-button :disabled="disablePhoneSubmit()" @click="updatePhone()" size="sm" variant="primary">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <b-card style="margin-bottom: 10px; padding: 5px; background: #151515" no-body>
            <div class="d-flex w-100 justify-content-between">
                <div class="d-flex">
                    <h3 style="margin: 0 10px 0 0;"><fa-icon :icon="['fas', 'phone']"/>&nbsp;{{phone.number}}</h3>&nbsp;
                    <span :class="'badge badge-' + (phone.active ? 'success' : 'danger')"
                          style="height: fit-content; align-self: center">
                        {{(phone.active ? "Active" : "Not Active")}}
                    </span>
                </div>
                <span v-if="editable === true">
                    <fa-icon :icon="['fas', 'pen-to-square']" @click="openEditPhone()" style="cursor: pointer;"/>
                </span>
            </div>
            <div style="font-size: smaller">
                <strong>Note: </strong>
                {{phone.notes}}
            </div>
        </b-card>
    </div>
</template>

<script>
export default {
    name: "PhoneNumberProfileComponent",
    props: {
        phone: { type: Object },
        editable: { type: Boolean }
    },
    data() {
        return {
            disableDeleteFlag: false,
            editPhoneFlag: false,
            phoneEdit: {},
            deleteObjects: {
                modal: false,
                name: "Phone Number",
                phoneNumber: null,
            }
        };
    },
    methods: {
        deletePhoneNumber() {
            this.disableDeleteFlag = true;
            this.$emit('deletePhone', this.deleteObjects.phoneNumber);
            this.editPhoneFlag = false;
            this.deleteObjects.modal = false;
        },
        disablePhoneSubmit() {
            return !this.filledOut(this.phoneEdit.number);
        },
        filledOut(i) {
            return !(i === null || i === undefined || i === '' || i === 0);
        },
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        openDeleteModal() {
            this.deleteObjects.phoneNumber = this.phone;
            this.deleteObjects.modal = true;
        },
        openEditPhone() {
            this.phoneEdit = JSON.parse(JSON.stringify(this.phone));
            this.editPhoneFlag = true;
        },
        updatePhone() {
            this.$emit('updatePhone', this.phoneEdit);
            this.editPhoneFlag = false;
        },

    }
}
</script>

<style scoped>

</style>