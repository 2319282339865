<template>
    <div v-if="marking !== undefined">

        <FileViewer2 :flag="fileViewerFlag" :fileId="fileViewStorageId" :closeMethod="closeViewModal"/>

        <!--Remove Item Modal-->
        <b-modal class="modal-dark" scrollable title="Remove Marking" v-model="deleteObjects.modal">
            <p>If you would like to delete this {{deleteObjects.name}} please type the prompt below.</p>
            <br>
            <p style="margin-bottom: 0">Enter "{{deleteObjects.name}}":</p>
            <b-form-group description="case sensitive">
                <b-form-input id="basicName2" type="text" v-model="deleteObjects.deleteText"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="deleteObjects.modal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button :disabled="deleteObjects.deleteText !== deleteObjects.name || disableDeleteFlag" @click="deleteMarking()"
                          size="sm"
                          variant="danger">Delete {{deleteObjects.name}}
                </b-button>
            </template>
        </b-modal>

        <!--Edit Marking-->
        <b-modal class="modal-dark" scrollable :title="'Edit ' + (other === true ? 'Other Desc.' : 'S/M/T')" v-model="editMarkingFlag" no-close-on-backdrop>
            <b-input-group v-if="other === true" class="input-group-sm" prepend="Type" style="margin-bottom: 1px">
                <b-form-input v-model="markingEdit.type" v-uppercase/>
            </b-input-group>
            <b-input-group v-else class="input-group-sm" prepend="Type" style="margin-bottom: 1px">
                <b-form-select :options="markingTypeOptions" v-model="markingEdit.type"/>
            </b-input-group>

            <b-input-group v-if="other === false" class="input-group-sm" prepend="Location" style="margin-bottom: 1px">
                <b-form-select :options="markingLocationOptions" v-model="markingEdit.location"/>
            </b-input-group>

            <b-input-group class="input-group-sm" prepend="Description" style="margin-bottom: 1px">
                <b-form-input maxlength="500"
                              :class=" 'form-control is-' + (filledOut(markingEdit.description) ? 'valid':'invalid')"
                              v-model="markingEdit.description"/>
            </b-input-group>
            <br>
            <label class="labelForm">Notes</label>&nbsp;
            <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(markingEdit.notes, {text: ''})"/>
            <b-input-group :key="i" class="input-group-sm" v-for="(e,i) in markingEdit.notes">
                <b-form-input v-model="e.text"/>
                <b-input-group-append>
                    <b-button @click="splice(i, markingEdit.notes)" variant="dark">
                        <fa-icon :icon="['fas', 'close']"/>
                    </b-button>
                </b-input-group-append>
            </b-input-group>
            <template slot="modal-footer">
                <b-button @click="editMarkingFlag = false" size="sm" variant="secondary">Cancel
                </b-button>
                <b-button :disabled="disableMarkingSubmit()" @click="updateMarking()" size="sm" variant="primary">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <!-- Main Card -->
        <b-card no-body style="border-color: #0e0e0e;background-color: #202020">
            <span class="d-flex w-100 justify-content-between"
                  style="background-color: #0e0e0e; padding: 0 5px 0 5px; border-radius: 3px 3px 0 0">
                <b style="font-size: medium">Type: {{marking.type}}</b>
                <span v-if="editable !== null && editable === true">
                    <fa-icon :icon="['fas', 'pen-to-square']" @click="openModalEditMarking()" style="cursor: pointer;"/>&nbsp;
                    <fa-icon :icon="['fas', 'close']" @click="openDeleteModal(marking.markingId)" size="lg"
                             style="cursor: pointer; transform: translateY(1px);"/>
                </span>
            </span>
            <b-row style="margin: 0; padding: 0">
                <b-col class="d-flex flex-column" style="margin: 0; padding: 0"
                       :xl="marking.images && marking.images.length > 0 ? 7 : 12">
                    <div style="margin: 5px">
                        <div v-if="other === false"><strong>Location: </strong>{{marking.location}}</div>
                        <div><strong>Description: </strong>{{marking.description}}</div>
                    </div>
                </b-col>
                <b-col style="margin: 0; padding: 0" xl="5"
                       class="d-flex justify-content-center"
                       v-if="marking.images && marking.images.length > 0">
                    <span style="width: auto; max-height: 100px; margin: 5px; border: 2px solid #23282c; border-radius: 5px; background-color: #0E0E0E;"
                          class="image-parent">
                        <img class="img-center-ass"
                             style="border-radius: 5px; max-height: 100px"
                             v-if="marking.images && marking.images.length > 0"
                             v-auth-image="getFirstDocumentImage(marking.images)"
                             @click="openViewModal(marking.images[0].storageFileName)"
                             alt=""/>
                        <img v-else class="img-center-ass"
                             style="border-radius: 5px;"
                             src="/NoImageAvailable.png"
                             alt="">
                    </span>
                </b-col>
            </b-row>
            <div v-if="noteExpand === true" style="padding: 5px">
                <br>
                <div style="padding: 10px">
                    <NotesCardComponent :notes="marking.notes" :scope="scope"/>
                    <br>
                    <DocumentCardComponent :editable="editable"
                                           :documents="marking.images ? marking.images : []"
                                           :editMethod="changeDocumentDesc"
                                           :deleteMethod="deleteEmbeddedDoc"
                                           :submitSelectorMethod="setFileFavorite"
                                           :submitUploadMethod="addMarkingDocument"/>
                </div>
                <div style="text-align: center; margin: 0;cursor: pointer;" @click="noteExpand = false">
                    <fa-icon :icon="['fas', 'angle-up']" style="text-align: center"/>
                </div>
            </div>
            <div v-else>
                <div style="text-align: center; cursor: pointer;" @click="noteExpand = true;">
                    <fa-icon :icon="['fas', 'angle-down']"/>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>

import DocumentCardComponent from "@/views/expansion_modules/risc/components/documents_components/DocumentCardComponent";
import NotesCardComponent from "@/views/expansion_modules/risc/components/notes_components/NotesCardComponent";
import FileViewer2 from '@/views/expansion_modules/risc/components/utility_components/FileViewer2.0';
import risc from "@/services/risc";

export default {
    name: "MarkingComponent",
    components: {
        DocumentCardComponent,
        NotesCardComponent,
        FileViewer2
    },
    props: {
        editable: { type: Boolean },
        marking: { type: Object },
        scope: { type: String },
        other: { type: Boolean }
    },
    data() {
        return {
            disableDeleteFlag: false,
            noteExpand: false,
            fileViewerFlag: false,
            editMarkingFlag: false,
            fileViewStorageId: null,
            markingEdit: {},
            deleteObjects: {
                modal: false,
                name: "Marking",
                deleteText: "",
                id: '',
            },
            markingTypeOptions: ['TATTOO', 'SCAR', 'MARKING'],
            markingLocationOptions: [
                "ABDOMEN",
                "ABDOMEN, LEFT",
                "ABDOMEN, RIGHT",
                "ARM, LEFT, LOWER",
                "ARM, LEFT, UPPER",
                "ARM, NONSPECIFIC",
                "ARM, NONSPECIFIC, LOWER",
                "ARM, RIGHT",
                "ARM, RIGHT, LOWER",
                "ARM, RIGHT, UPPER",
                "BACK",
                "BREAST, LEFT",
                "BREAST, NONSPECIFIC",
                "BREAST, RIGHT",
                "BUTTOCK, LEFT",
                "BUTTOCK, RIGHT",
                "BUTTOCKS, NONSPECIFIC",
                "CALF, LEFT",
                "CALF, NONSPECIFIC",
                "CALF, RIGHT",
                "CHEEK, LEFT",
                "CHEEK, NONSPECIFIC",
                "CHEEK, RIGHT",
                "CHEST",
                "CHEST, LEFT",
                "CHEST, RIGHT",
                "CHIN",
                "EAR, LEFT",
                "EAR, NONSPECIFIC",
                "EAR, RIGHT",
                "ELBOW, LEFT",
                "ELBOW, NONSPECIFIC",
                "ELBOW, RIGHT",
                "EYE, LEFT",
                "EYE, NONSPECIFIC",
                "EYE, RIGHT",
                "FACE, NONSPECIFIC",
                "FINGER(S), LEFT HAND",
                "FINGER(S), RIGHT HAND",
                "FINGER, NONSPECIFIC",
                "FOOT, LEFT",
                "FOOT, NONSPECIFIC",
                "FOOT, RIGHT",
                "FOREHEAD",
                "FULL BODY",
                "GROIN AREA",
                "HAND, LEFT",
                "HAND, NONSPECIFIC",
                "HAND, RIGHT",
                "HEAD, NONSPECIFIC",
                "HIP, LEFT",
                "HIP, NONSPECIFIC",
                "HIP, RIGHT",
                "KNEE, LEFT",
                "KNEE, NONSPECIFIC",
                "KNEE, RIGHT",
                "LEG, LEFT",
                "LEG, NONSPECIFIC",
                "LEG, RIGHT",
                "LIP, LOWER",
                "LIP, NONSPECIFIC",
                "LIP, UPPER",
                "NECK",
                "NOSE",
                "PENIS",
                "SHOULDER, LEFT",
                "SHOULDER, NONSPECIFIC",
                "SHOULDER, RIGHT",
                "THIGH, LEFT",
                "THIGH, NONSPECIFIC",
                "THIGH, RIGHT",
                "TOE(S), NONSPECIFIC",
                "TOE, LEFT",
                "TOE, RIGHT",
                "WRIST, LEFT",
                "WRIST, NONSPECIFIC",
                "WRIST, RIGHT"
            ]
        };
    },
    methods: {
        addMarkingDocument(description, fileId) {
            this.marking.images.push({
                description: description,
                storageFileName: fileId,
                arrayOrder: this.marking.images.length
            })
            this.$emit('updateMarking', this.marking);
        },
        changeDocumentDesc(doc) {
            this.marking.images.forEach(document => {
                if (document.imageId === doc.imageId) {
                    document.description = doc.description;
                }
            })
            this.$emit('updateMarking', this.marking);
        },
        closeViewModal() {
            this.fileViewerFlag = false;
        },
        deleteEmbeddedDoc(doc) {
            this.marking.images = this.marking.images.filter((document) => {
                return document.imageId !== doc.imageId;
            });
            this.$emit('updateMarking', this.marking);
        },
        deleteMarking() {
            this.disableDeleteFlag = true;
            this.$emit('deleteMarking', this.marking);
            this.deleteObjects.modal = false;
        },
        disableMarkingSubmit() {
            return !this.filledOut(this.markingEdit.description) ||
                !this.filledOut(this.markingEdit.type);
        },
        filledOut(i){
            return !(i === null || i === undefined || i === '' || i === 0);
        },
        getFirstDocumentImage(docArray) {
            let stId = 0;
            docArray.forEach(doc => {
                if (doc.arrayOrder === 0) {
                    stId = doc.storageFileName;
                }
            })
            if (stId !== 0) {
                return risc.getImage(stId);
            } else {
                return risc.getImage(docArray[0].storageFileName);
            }
        },
        openDeleteModal(id) {
            this.disableDeleteFlag = false;
            this.deleteObjects.modal = true;
            this.deleteObjects.deleteText = '';
            this.deleteObjects.id = id;
        },
        openModalEditMarking() {
            this.markingEdit = JSON.parse(JSON.stringify(this.marking));
            this.editMarkingFlag = true;
        },
        openViewModal(storageId) {
            this.fileViewStorageId = storageId;
            this.fileViewerFlag = true;
        },
        push(field, item) {
            field.push(JSON.parse(JSON.stringify(item)));
        },
        setFileFavorite(files) {
            this.marking.images = files;
            this.$emit('updateMarking', this.marking);
        },
        splice(index, field) {
            field.splice(index, 1);
        },
        updateMarking() {
            this.$emit('updateMarking', this.markingEdit);
            this.editMarkingFlag = false;
        },

    }
}
</script>

<style scoped>

</style>