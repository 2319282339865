
<template>
    <div v-show="false" id="printMe" style="position: relative">
        <div style="position: absolute; z-index: -999; width: 100%; overflow-y: hidden">
            <div style="height: 1000px; width: 100%"
                 class="d-flex justify-content-center align-items-end">
                <img src="../../../../../../public/img/Recruiting-Booklet-2018-1.png" style="max-height: 40%; max-width: 40%; opacity: 0.05" alt=""/>
            </div>
        </div>
        <div class="w-100 d-flex justify-content-between" style="padding: 7px 7px 0 7px">
            <div>
                <div style=" position: absolute; z-index: 999; height: 95px; width: 90px"
                     class="d-flex justify-content-center align-items-center">
                    <img src="../../../../../../public/img/Recruiting-Booklet-2018-1.png" style="max-height: 100%; max-width: 100%" alt=""/>
                </div>
                <div style="padding-left: 100px;font-size: 28px">
                    <div><b>Rockford Police Department</b></div>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-end" style="font-size: 12px">
                <div><b style="color: red">Not for media release</b></div>
                <div><b style="color: red">LAW ENFORCEMENT SENSITIVE</b></div>
            </div>
        </div>
        <div style="height: 5px"/>

        <!-- RIBBON -->
        <div style="text-align: center;">
            <div style="display: flex; align-items: center; height: 50px">
                <div class="bg-warning text-dark" style="flex: 1; height: 100%; display: flex; align-items: center">
                    <h1 style="margin: 0; width: 100%; text-align: center">
                        <b>WANTED FOR QUESTIONING</b>
                    </h1>
                </div>
            </div>
        </div>
        <div style="height: 5px"/>

        <!-- NAME -->
        <h2 style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px; padding: 0">
            <b v-if="printOptions.name">
                {{ profile.firstName }} {{ profile.middleName }} {{ profile.lastName }} {{ profile.suffix }}
            </b>
            <b v-else>UNKNOWN</b>
        </h2>

        <!-- PICTURE & GENERAL INFORMATION -->
        <b-row class="d-flex align-items-stretch" style="height: 605px; padding: 0 7px 0 7px">

            <!-- PICTURE -->
            <b-col sm="5" class="flex-grow-1" style="padding: 10px; height: 595px" ref="iContainer">
                <span
                    style="height: calc(625px - 45px); width: calc(100% + 2px); transform: translate(7px, -10px);background-color: #28282e"
                    class="d-flex justify-content-center align-items-center flex-column m-0 p-0" ref="imgCon">
                        <img v-if="profile.images && profile.images[0] && printOptions.image" ref="imgEle"
                             v-auth-image="getImage(profile.images[0].storageFileName)"
                             style="object-fit: contain; height: 100%; width: 100%" alt=""/>
                        <img v-else src="../../../../../../public/img/NoPictureOnFileGrey.png" style=" max-height: 100%; max-width: 100%" alt=""/>
                </span>
                <div style="transform: translate(7px, -10px); width: calc(100% + 2px); border: #28282e solid 2px"
                     class="d-flex justify-content-center">
                    Source:&nbsp;
                    <span v-if="!profile.images || profile.images.length === 0 || !printOptions.image"> Satellite </span>
                    <span v-else-if="(profile.images && profile.images.length > 0) && profile.images[0].description !== undefined && profile.images[0].description !== ''">
                        {{ profile.images[0].description }}
                    </span>
                </div>
            </b-col>


            <!-- GENERAL INFORMATION -->
            <b-col sm="7" class="flex-fill" style="font-size: 17px" v-if="printOptions.general.show">
                <b-row>
                    <b-col sm="6">
                        <b>STREET NAME</b>
                        <div v-if="profile.streetName && profile.streetName.length > 0 && isShow(printOptions.general.streetnames)">
                            <span v-for="(sName, i) in profile.streetName">
                                <span v-if="printOptions.general.streetnames.children[i].show">
                                    {{ sName.streetName }}
                                    <span v-if="i !== profile.streetName.length - 1">, </span>
                                </span>
                            </span>
                        </div>
                        <div v-else> N/A</div>
                    </b-col>
                    <b-col sm="3">
                        <b>EMPLOYER</b>
                        <div v-if="printOptions.general.employer"> {{ profile.employer || "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                    <b-col sm="3">
                        <b>DOB</b>
                        <div v-if="printOptions.general.dob"> {{ dateOfBirth || "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                </b-row>
                <hr style="padding: 3px 5px 3px 5px; margin: 0"/>
                <b-row>
                    <b-col sm="4">
                        <b>RACE</b>
                        <div v-if="printOptions.general.race"> {{ profile.race || "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                    <b-col sm="3">
                        <b>SEX</b>
                        <div v-if="printOptions.general.sex"> {{ profile.sex || "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                    <b-col sm="2">
                        <b>HEIGHT</b>
                        <div v-if="printOptions.general.height"> {{ height || "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                    <b-col sm="2">
                        <b>WEIGHT</b>
                        <div v-if="printOptions.general.weight"> {{ weight ? weight + "lbs." : "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                </b-row>
                <hr style="padding: 3px 5px 3px 5px; margin: 0"/>
                <b-row>
                    <b-col>
                        <b>SSN</b>
                        <div v-if="printOptions.general.ssn"> {{ profile.socialSecurity || "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                    <b-col>
                        <b>MID</b>
                        <div v-if="printOptions.general.mid"> {{ profile.mid || "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                    <b-col>
                        <b>SID</b>
                        <div v-if="printOptions.general.sid"> {{ profile.sid || "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                    <b-col>
                        <b>FBI</b>
                        <div v-if="printOptions.general.fbi"> {{ profile.fbi || "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                </b-row>
                <hr style="padding: 3px 5px 3px 5px; margin: 0"/>
                <b-row>
                    <b-col>
                        <b>IDs</b>
                        <div v-if="profile.identifications && profile.identifications.length > 0 && isShow(printOptions.general.ids)">
                            <span v-for="(id, i) in profile.identifications">
                                <span v-if="printOptions.general.ids.children[i].show">
                                    {{ id.state }} {{ id.number }} ({{ id.status }} {{ id.type }})
                                    <span v-if="i !== profile.identifications.length - 1">, </span>
                                </span>
                            </span>
                        </div>
                        <div v-else>N/A</div>
                    </b-col>
                </b-row>
                <hr style="padding: 3px 5px 3px 5px; margin: 0"/>
                <b-row>
                    <b-col>
                        <b>ADDRESSES</b>
                        <div v-if="profile.addresses && profile.addresses.length > 0 && isShow(printOptions.general.addresses)"><span v-for="(address, index) in printOptions.general.addresses.children" :key="index">
                                <span v-if="address.show === true">
                                    {{address.name}}
                                    <span v-if="index !== profile.addresses.length - 1">| </span>
                                </span>
                            </span>
                        </div>
                        <div v-else>N/A</div>
                    </b-col>
                </b-row>
                <hr style="padding: 3px 5px 3px 5px; margin: 0"/>
                <b-row>
                    <b-col>
                        <b>ALIAS</b>
                        <div v-if="profile.alias && profile.alias.length > 0 && isShow(printOptions.general.alias)">
                            <span v-for="(alias, i) in profile.alias">
                                <span v-if="printOptions.general.alias.children[i].show">
                                    {{ alias.knownAlias }}
                                    <span v-if="i !== profile.alias.length - 1">, </span>
                                </span>
                            </span>
                        </div>
                        <div v-else>N/A</div>
                    </b-col>
                </b-row>
                <hr style="padding: 3px 5px 3px 5px; margin: 0"/>
                <b-row>
                    <b-col>
                        <b>ORGANIZATION AFFILIATION(S)</b>
                        <div
                            v-if="profile.organizations && profile.organizations.length > 0 && isShow(printOptions.general.orgs)">
                            <span v-for="(org, i) in profile.organizations">
                                <span v-if="printOptions.general.orgs.children[i].show">
                                    {{ org.orgName }}
                                    <span v-if="i !== profile.organizations.length - 1">, </span>
                                </span>
                            </span>
                        </div>
                        <div v-else> N/A</div>
                    </b-col>
                </b-row>
                <hr style="padding: 3px 5px 3px 5px; margin: 0"/>
                <b-row>
                    <b-col>
                        <b>PHONE NUMBERS</b>
                        <div v-if="profile.phone && profile.phone.length > 0 && isShow(printOptions.general.phone)">
                            <span v-for="(phone, i) in profile.phone">
                                <span v-if="printOptions.general.phone.children[i].show">
                                    {{ phone.number }} ({{ phone.notes }})
                                    <span v-if="i !== profile.phone.length - 1">, </span>
                                </span>
                            </span>
                        </div>
                        <div v-else>N/A</div>
                    </b-col>
                </b-row>
                <hr style="padding: 3px 5px 3px 5px; margin: 0"/>
                <b-row>
                    <b-col>
                        <b>SOCIAL MEDIA</b>
                        <div v-if="profile.socialMedia && profile.socialMedia.length > 0 && isShow(printOptions.general.social)">
                            <span v-for="(social, i) in profile.socialMedia">
                                <span v-if="printOptions.general.social.children[i].show">
                                    {{ social.userName }} ({{ social.site }})
                                    <span v-if="i !== profile.socialMedia.length - 1">, </span>
                                </span>
                            </span>
                        </div>
                        <div v-else>N/A</div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <!-- OTHER INFORMATION -->
        <div>
            <!-- Notes -->
            <div style="display: grid;  page-break-inside: avoid; break-inside: avoid; margin-top: 10px">
                <h5 style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px">
                    NOTES
                </h5>
                <p v-if="printOptions.wanted.notes" style="margin: 0 7px 0 7px; height: 305px; white-space: pre-line">
                    {{printOptions.wanted.notes}}
                </p>
                <div v-else class="d-flex w-100 justify-content-center align-items-center" style="margin: 5px; height: 305px; color: grey">
                    <span>No notes available</span>
                </div>
            </div>

            <div style="display: grid;  page-break-inside: avoid; break-inside: avoid; margin-top: 10px">
                <h5 style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px">
                    Other Known Associates, Vehicles, & Frequented Locations
                </h5>
                <div class="d-flex" style="padding: 0 7px 0 7px;">
                    <b-card v-for="(i, index) in [0,1,2,3,4]" :key="index" no-body style="width: 20%; height: 322px; margin: 5px; text-align: center" class="d-flex flex-column">
                        <div v-if="printOptions.wanted.otherObjects[i] && printOptions.wanted.otherObjects[i].obj">
                            <b>{{printOptions.wanted.otherObjects[i].title}}</b>

                            <div v-if="printOptions.wanted.otherObjects[i].title === 'ADDRESS' && printOptions.images.addresses === true ||
                                           printOptions.wanted.otherObjects[i].title === 'VEHICLE' && printOptions.images.vehicles === true ||
                                           printOptions.wanted.otherObjects[i].title === 'LINKED ASSOCIATE' && printOptions.images.linkedAssociates === true ||
                                           printOptions.wanted.otherObjects[i].title === 'OTHER ASSOCIATE' && printOptions.images.otherAssociates === true"
                                 style="height: 150px; background-color: black">
                                <b-card-img v-auth-image="getObjectImage(printOptions.wanted.otherObjects[i].obj[1], true)"
                                            style="object-fit: contain; height: 100%; width: 100%"/>
                            </div>
                            <div v-else style="padding-bottom: 6px">
                                <br><br><br><br><br><br>
                            </div>

                            <div class="d-flex flex-column justify-content-between">
                                <div v-if="printOptions.wanted.otherObjects[i].title !== 'LINKED ASSOCIATE'" style="text-align: center">{{printOptions.wanted.otherObjects[i].obj[0] || "Relationship"}}</div>
                                <div style="text-align: center">{{printOptions.wanted.otherObjects[i].obj[2] || "L, F M"}}</div>
                                <div>{{printOptions.wanted.otherObjects[i].obj[3] || "DOB"}}</div>
                                <div v-if="printOptions.wanted.otherObjects[i].title !== 'LINKED ASSOCIATE'">{{printOptions.wanted.otherObjects[i].obj[4] || "LKA"}}</div>
                            </div>
                        </div>
                    </b-card>
                </div>
            </div>
        </div>

        <!-- Footer -->
        <div style="padding: 10px; font-weight: bold">
            <div style="color: red; text-align: center; width: 100%">
                CONFIDENTIAL - LAW ENFORCEMENT SENSITIVE - DO NOT DISSEMINATE FURTHER WITHOUT PERMISSION FROM ORIGINATING AGENCY
            </div>
            <b-row sm="6" class="d-flex justify-content-between" style="width: 100%">
                <b-col sm="3">UNIT: {{printOptions.wanted.unit || "N/A"}}</b-col>
                <b-col sm="6" style="text-align: center">
                    <div>Produced: {{new Date().toJSON().slice(5,10).replace(/-/g,'/') + "/" + new Date().toJSON().slice(0,4) + " " + (printOptions.wanted.author || "N/A")}}</div>
                    <div>Approved By: {{printOptions.wanted.approvedBy || "N/A"}}</div>
                </b-col>
                <b-col sm="3" style="text-align: right">{{printOptions.wanted.phone || "N/A"}}</b-col>
            </b-row>
        </div>

    </div>
</template>

<script>
import * as moment from 'moment';
import risc from '@/services/risc';
import PubSub from "pubsub-js";

export default {
    name: "WantedForQuestioningPrint",
    props: {
        printOptions: {type: Object},
        profile: {type: Object},
        alias: {type: String},
        dateOfBirth: {type: String},
        height: {type: String},
        name: {type: String},
        ribbons: {type: Array},
        streetName: {type: String},
        weight: {type: Number},
    },
    data: () => {
        return {
            pubsub: null,
            imageCount: 0,
            imagesDone: false,
            pageDone: false,
            noDataText: "NO RECORDED DATA",
            centerText: "w-100 d-flex justify-content-center",
            printOptionsReady: false,
        }
    },
    created() {
        if (this.printOptions.imagesLength === 0) {
            this.imagesDone = true;
        }
        this.pubsub = PubSub.subscribe('riscImageLoad', () => {
            this.imageCount++;
            if (this.imageCount === this.printOptions.imagesLength) {
                this.imagesDone = true;
            }
        });
    },
    mounted() {
        this.$nextTick(function () {
            this.pageDone = true;
        });
    },
    beforeDestroy() {
        PubSub.unsubscribe(this.pubsub);
    },
    watch: {
        imagesDone() {
            if (this.imagesDone === true && this.pageDone === true) {
                this.$emit('actuallyPrint');
            }
        },
        pageDone() {
            if (this.imagesDone === true && this.pageDone === true) {
                this.$emit('actuallyPrint');
            }
        }
    },
    methods: {
        getObjectImage(images, notProfile) {
            if (images && images.length >= 1) {
                return (this.getFirstDocumentImage(images));
            } else if (notProfile) {
                return '/img/NoImageAvailableBlack.png';
            }
            return ('/img/NoPictureOnFileGrey.png');
        },
        getFirstDocumentImage(docArray) {
            let stId = 0;
            docArray.forEach(doc => {
                if (doc.arrayOrder === 0) {
                    stId = doc.storageFileName;
                }
            })
            if (stId !== 0) {
                return risc.getImage(stId);
            } else {
                return risc.getImage(docArray[0].storageFileName);
            }
        },
        removeData(arr, field) {
            let result = []
            arr.forEach((e, i) => {
                if (this.printOptions.otherInfo[field].children[i].show) {
                    result.push(Object.assign({}, e));
                }
            })
            return result;
        },
        isShow(obj) {
            return obj.show && obj.children.some(_ => {return _.show});
        },
        dateToString(date) {
            if (date === '0001-01-01T00:00:00') {
                return '';
            } else {
                return (moment(date).format('L'));
            }
        },
        getImage(image) {
            return risc.getImage(image);
        },
        ribbonVariant(ribbon) {
            switch (ribbon) {
                case 'IN CUSTODY':
                    return 'success';
                case 'PAROLE/PROBATION':
                    return 'warning text-dark';
                case 'WANTED':
                    return 'danger';
                case 'OUT OF TOWN':
                    return 'warning text-dark';
                case 'DECEASED':
                    return 'dark';
                default:
                    return 'primary';
            }
        },
    },
};
</script>

<style scoped>
.centerText {
    width: 100%;
    justify-content: center;
    display: flex
}


</style>