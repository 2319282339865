<template>
    <div v-if="leReports !== undefined">

        <!--Add LE-Report-->
        <b-modal no-close-on-backdrop class="modal-dark" scrollable title="Add LE-Report" v-model="leReportFields.addLeReportFlag"
                 :size="!leReportFields.add ? 'lg' : 'md'" v-if="leReportFields.addLeReportFlag" no-body>
            <span v-if="!leReportFields.add">
                <b-form-radio-group v-model="leReportFields.data">
                    <v-client-table style="width: 100%" class="dataTable"
                                    :columns="table.columns"
                                    :data="leReportFields.allLeReports ? leReportFields.allLeReports : []"
                                    :options="table.tOptions"
                                    :theme="table.theme" ref="dTable">
                        <b-form-radio slot="Select" slot-scope="props" :value="props.row"/>
                        <span slot="createdDate" slot-scope="props">{{momentDate(props.row.createdDate)}}</span>
                    </v-client-table>
                </b-form-radio-group>
            </span>
            <span v-else>
                <b-input-group class="input-group-sm" prepend="Subject" style="margin-bottom: 1px;">
                    <b-form-input
                        :class=" 'form-control is-' + (filledOut(leReportFields.data.subject) ? 'valid':'invalid')"
                        v-model="leReportFields.data.subject"/>
                </b-input-group>
                <b-input-group class="input-group-sm" prepend="Report Number" style="margin-bottom: 1px">
                    <b-form-input
                        :class=" 'form-control is-' + (filledOut(leReportFields.data.leReportNumber) ? 'valid':'invalid')"
                        v-model="leReportFields.data.leReportNumber"/>
                </b-input-group>
                <b-input-group class="input-group-sm" prepend="Date" style="margin-bottom: 1px">
                        <date-picker :config="dateOptions" v-model="leReportFields.data.date" />
                </b-input-group>
                <b-input-group class="input-group-sm" prepend="Description" style="margin-bottom: 1px">
                    <textarea maxlength="500"
                              :class=" 'form-control is-' + (filledOut(leReportFields.data.description) ? 'valid':'invalid')"
                              class="form-control rounded-0" rows="4"
                              v-model="leReportFields.data.description" type="text"></textarea>
                </b-input-group>
                <br>
                <b-form-group>
                    <label>Other Notes</label>&nbsp;
                    <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(leReportFields.data.notes, {text: '', scope: scope})"/>
                    <div v-if="leReportFields.data.notes" :key="i"
                         v-for="(note,i) in refactorScope(leReportFields.data.notes)">

                        <b-input-group v-if="!note.scope || note.scope === scope || note.scope === 'global'" class="input-group-sm">

                            <div class="col" style="padding: 0; width: 55px">
                                <b-form-input v-model="leReportFields.data.notes[i].text" size="sm"/>
                            </div>

                            <div class="col-auto" style="padding: 0 4px 0 0;">
                                    <b-form-select v-model ="leReportFields.data.notes[i].scope"
                                                   size="sm" plain
                                                   style="background-color: #191919; border-color: #191919; ">
                                        <option :value="scope">Scoped</option>
                                        <option :value="'global'">Global</option>
                                    </b-form-select>
                                </div>
                            <b-input-group-append v-if="leReportFields.data.notes.length > 1">
                                <b-button @click="splice(i, leReportFields.data.notes)" variant="dark">
                                    <fa-icon :icon="['fas', 'close']"/>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </b-form-group>
            </span>
            <template slot="modal-footer">
                <b-button @click="leReportFields.addLeReportFlag = false" size="sm" variant="secondary">Cancel
                </b-button>
                <b-button @click="leReportFields.add = !leReportFields.add" size="sm"
                          variant="success"
                          v-if="!leReportFields.data.leReportId">
                    {{leReportFields.add ? "Add Existing" : "Create New"}}
                </b-button>
                <b-button
                    :disabled="(disableLeReportSubmit() && leReportFields.add) || (!leReportFields.data.leReportId && !leReportFields.add)"
                    @click="addLeReport()" size="sm" variant="primary">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <div style="display: flex; align-content: space-between; align-items: center; width: 100%; margin-bottom: 15px;">
            <span v-if="editable === true" class="d-flex align-items-center" style="cursor: pointer;" @click="openModalAddLeReport()">
                <fa-icon :icon="['fas', 'plus']" style="margin-right: 5px"/> Add LE-Report
            </span>
            <span style="margin-left: auto" @click="getImages = !getImages">
                <b-button size="sm">Get Images</b-button>
            </span>
        </div>
        <p v-if="leReports.length === 0" class="noAdded"><i><strong>No LE-Reports Added</strong></i></p>
        <div :key="index" lg="12" v-for="(report, index) in leReports">
            <LeReportComponent :editable="editable"
                               :scope="scope"
                               :leReportObject="report"
                               :linkId="linkId"
                               :linkType="linkType"
                               :profiles="report.profiles"
                               :getImages="getImages"
                               @deleteLeReport="deleteLeReport"
                                @updateLeReport="updateLeReport"/>
        </div>
    </div>
</template>

<script>

import LeReportComponent from "@/views/expansion_modules/risc/components/lereport_components/LeReportComponent";
import risc from "@/services/risc";
import * as moment from "moment";
import datePicker from "vue-bootstrap-datetimepicker";
import InvolvementEditAddComponent from "@/views/expansion_modules/risc/components/involvement_components/InvolvementEditAddComponent";

export default {
    name: "LeReportTabComponent",
    components: {
        InvolvementEditAddComponent,
        LeReportComponent,
        datePicker
    },
    props: {
        leReportsObject: { type: Array },
        editable: { type: Boolean },
        scope: { type: String },
        linkId: { type: String },
        linkType: { type: String }
    },
    watch: {
        leReportsObject() {
            this.leReports = JSON.parse(JSON.stringify(this.leReportsObject));
            this.sort();
        }
    },
    created() {
        this.leReports = JSON.parse(JSON.stringify(this.leReportsObject));
        this.sort();
    },
    data() {
        return {
            leReports: [],
            getImages: false,
            leReportFields: {
                addLeReportFlag: false,
                allLeReports: [],
                data: {},
                add: false
            },
            dateOptions: {
                format: 'MM/DD/YYYY',
                useCurrent: false
            },
            table: {
                theme: 'bootstrap4',
                columns: ['Select', 'leReportNumber', 'involvement', 'description', 'createdDate'],
                tOptions: {
                    filterable: ['leReportNumber', 'involvement', 'description', 'createdDate'],
                    sortable: ['leReportNumber', 'involvement', 'description', 'createdDate'],
                    orderBy: {column: 'file', ascending: true},
                    headings: {
                        leReportNumber: 'Report Number',
                        createdDate: 'Created Date'
                    },
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll'
                    },
                    perPage: 10,
                    skin: 'table table-striped table-hover',
                },
                useVuex: false,
                template: 'default',
                createCaseModal: false
            }
        };
    },
    methods: {
        addLeReport() {
            if (this.leReportFields.data.date !== undefined) {
                this.leReportFields.data.date = new Date(this.leReportFields.data.date);
            }
            if (this.linkType === 'Org') {
                if (!this.leReportFields.add) {
                    risc.linkOrgLeReport(this.linkId, this.leReportFields.data.leReportId).then(() => {
                        this.leReports.push(this.leReportFields.data);
                        this.sort();
                        this.leReportFields.addLeReportFlag = false;
                        this.updateLeReport();
                    })
                } else if (this.leReportFields.data.leReportId === '') {
                    delete this.leReportFields.data.leReportId;
                    risc.postLeReport(this.leReportFields.data).then(response => {
                        this.leReportFields.data.leReportId = response.data.leReportId
                        risc.linkOrgLeReport(this.linkId, this.leReportFields.data.leReportId).then(() => {
                            this.leReports.push(response.data);
                            this.sort();
                            this.leReportFields.addLeReportFlag = false;
                            this.updateLeReport();
                        })
                    })
                }
            } else if (this.linkType === 'Profile') {
                if (!this.leReportFields.add) {
                    risc.linkProfileLeReport(this.linkId, this.leReportFields.data.leReportId).then(() => {
                        this.leReports.push(this.leReportFields.data);
                        this.sort();
                        this.leReportFields.addLeReportFlag = false;
                        this.updateLeReport();
                    })
                } else if (this.leReportFields.data.leReportId === '') {
                    delete this.leReportFields.data.leReportId;
                    risc.postLeReport(this.leReportFields.data).then(response => {
                        this.leReportFields.data.leReportId = response.data.leReportId
                        risc.linkProfileLeReport(this.linkId, this.leReportFields.data.leReportId).then(() => {
                            this.leReports.push(response.data);
                            this.sort();
                            this.leReportFields.addLeReportFlag = false;
                            this.updateLeReport();
                        })
                    })
                }
            }
            //this.leReportFields.addLeReportFlag = false;
        },
        deleteLeReport(leReport) {
            this.$emit('deleteLeReport', leReport);
        },
        disableLeReportSubmit() {
            return !this.filledOut(this.leReportFields.data.leReportNumber) ||
                //!this.filledOut(this.leReportFields.data.involvement) ||
                !this.filledOut(this.leReportFields.data.description);

        },
        filledOut(i){
            return !(i === null || i === undefined || i === '' || i === 0);
        },
        momentDate(date){
            return(moment(date).format('LLLL'))
        },
        openModalAddLeReport() {
            this.leReportFields.add = false;
            this.leReportFields.data = {
                subject: '',
                leReportNumber: '',
                involvement: '',
                date: undefined,
                description: '',
                notes: [{text: '', scope: this.scope}],
                documents: [],
                leReportId: '',
            }
            risc.getLeReports().then(response => {
                this.leReportFields.allLeReports = response.data.filter(_ => {
                    return this.leReports.find(report => report.leReportId === _.leReportId) === undefined;
                });
                this.leReportFields.addLeReportFlag = true;
            });
        },
        push(field, item) {
            field.push(JSON.parse(JSON.stringify(item)));
        },
        refactorScope(arr) {
            return arr ? arr.map(_ => {_.scope = !_.text ? this.scope : _.scope; return _}): []
        },
        sort() {
            this.leReports.sort((current, other) => {
                let Current = new Date(current.date).getTime()
                let Other = new Date(other.date).getTime()
                return Other - Current});
        },
        splice(index, field) {
            field.splice(index, 1);
        },
        updateLeReport() {
            this.$emit('updateLeReport');
        },

    }
}
</script>

<style scoped>

.noAdded {
    text-align: center;
    margin-bottom: 0;
    color: grey
}

</style>