<template>
    <div>
        <label>Involvements</label>&nbsp;
        <fa-icon :icon="['fas', 'circle-plus']" @click="addInvolvement()" style="cursor: pointer;"/>
        <div v-for="(involvement, index) in involvements">
            <involvementComponent :involvementObject="involvement" :editAdd="editAdd" :submit="submit":leReportLink="leReportLink" :editable="true"/>
        </div>
    </div>
</template>

<script>
import risc from "@/services/risc";
import InvolvementComponent from "@/views/expansion_modules/risc/components/involvement_components/InvolvementComponent";

export default {
    name: "InvolvementEditAddComponent",
    components: {InvolvementComponent},
    props: {
        editAdd: Boolean, // false for Edit, true for Add
        submit: Boolean,
        leReportLink: String
    },
    data() {
        return {
            involvements: []
        };
    },
    methods: {
        addInvolvement() {
            this.involvements.push({});
        },
        splice(index, field) {
            field.splice(index, 1);
        }
    }
}
</script>

<style scoped>

</style>