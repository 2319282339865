<template>
    <div v-if="criminalHistories !== undefined">

        <!--Add Criminal History-->
        <b-modal class="modal-dark" scrollable title="Criminal History" no-close-on-backdrop
                 v-model="addCriminalHistoryModal" size="md"
                 v-if="addCriminalHistoryModal" no-body>
            <b-input-group class="input-group-sm" prepend="Offense " style="margin-bottom: 1px">
                <b-form-input v-model="criminalHistoryAdd.offense" v-uppercase/>
            </b-input-group>
            <b-input-group class="input-group-sm d-flex" style="margin-bottom: 1px">
                <b-input-group-prepend class="flex-grow-1 d-flex">
                    <b-input-group-text>
                        <span style="text-align: left">Offenses</span>
                    </b-input-group-text>
                    <b-input-group-text style="background-color: #515b65" class="flex-grow-1 d-flex justify-content-between">
                        <fa-icon :icon="['fas', 'circle-minus']" style="margin: 0 5px 0 0; color: darkgrey; cursor: pointer;"
                           @click="incrafy('numberOf', false)"/>
                        <b>{{criminalHistoryAdd.numberOf}}</b>
                        <fa-icon :icon="['fas', 'circle-plus']" style="margin: 0 0 0 5px; color: darkgrey; cursor: pointer;"
                           @click="incrafy('numberOf', true)"/>
                    </b-input-group-text>
                </b-input-group-prepend>
                <b-input-group-append class="flex-grow-1 d-flex">
                    <b-input-group-text>
                        <span style="text-align: left">Convictions</span>
                    </b-input-group-text>
                    <b-input-group-text style="background-color: #515b65" class="flex-grow-1 d-flex justify-content-between">
                        <fa-icon :icon="['fas', 'circle-minus']" style="margin: 0 5px 0 0; color: darkgrey; cursor: pointer;"
                           @click="incrafy('numberOfConvictions', false)"/>
                        <b>{{criminalHistoryAdd.numberOfConvictions}}</b>
                        <fa-icon :icon="['fas', 'circle-plus']" style="margin: 0 0 0 5px; color: darkgrey; cursor: pointer;"
                           @click="incrafy('numberOfConvictions', true)"/>
                    </b-input-group-text>
                </b-input-group-append>
                <b-input-group-append class="flex-grow-1 d-flex">
                    <b-input-group-text>
                        <span style=";text-align: left">Arrests</span>
                    </b-input-group-text>
                    <b-input-group-text style="background-color: #515b65" class="flex-grow-1 d-flex justify-content-between">
                        <fa-icon :icon="['fas', 'circle-minus']" style="margin: 0 5px 0 0; color: darkgrey; cursor: pointer;"
                           @click="incrafy('numberOfArrests', false)"/>
                        <b>{{criminalHistoryAdd.numberOfArrests}}</b>
                        <fa-icon :icon="['fas', 'circle-plus']" style="margin: 0 0 0 5px; color: darkgrey; cursor: pointer;"
                           @click="incrafy('numberOfArrests', true)"/>
                    </b-input-group-text>
                </b-input-group-append>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Description" style="margin-bottom: 1px">
                    <textarea maxlength="500"
                              class="form-control rounded-0" rows="4"
                              v-model="criminalHistoryAdd.description" type="text"></textarea>
            </b-input-group>
            <br>
            <label class="labelForm">Notes</label>&nbsp;
            <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(criminalHistoryAdd.notes, {text: ''})"/>
            <b-input-group :key="i" class="input-group-sm" v-for="(e,i) in criminalHistoryAdd.notes">
                <b-form-input v-model="criminalHistoryAdd.notes[i].text"/>
                <b-input-group-append>
                    <b-button @click="splice(i, criminalHistoryAdd.notes)" variant="dark">
                        <fa-icon :icon="['fas', 'close']"/>
                    </b-button>
                </b-input-group-append>
            </b-input-group>
            <template slot="modal-footer">
                <b-button @click="addCriminalHistoryModal = false" size="sm" variant="secondary">Cancel
                </b-button>
                <b-button @click="addCriminalHistory()" size="sm" variant="primary" :disabled="disableCriminalHistory() || disableButtonFlag">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <!--Main Body-->
        <div v-if="editable === true" style="text-align: center; width: 100%; margin-bottom: 10px;">
            <span style="cursor: pointer;" @click="openModalAddCriminalHistories()">
                <fa-icon :icon="['fas', 'plus']"/> Add Criminal History
            </span>
        </div>
        <p v-if="criminalHistories.length === 0" class="noAdded"><i><strong>No Criminal History Added</strong></i></p>
        <div :key="index" lg="12" v-for="(criminalHistory, index) in criminalHistories">
            <CriminalHistoryComponent :criminalHistories="criminalHistory"
                                      :editable="editable"
                                      :index="index"
                                      @updateCriminalHistory="updateCriminalHistory"
                                      @deleteCriminalHistory="deleteCriminalHistory"/>
        </div>
    </div>
</template>

<script>

import CriminalHistoryComponent from "@/views/expansion_modules/risc/components/criminalhistory_components/CriminalHistoryComponent";

export default {
    name: "CriminalHistoryTabComponent",

    components: {
      CriminalHistoryComponent,
    },

    props: {
        criminalHistories: { type: Array},
        editable: { type: Boolean },
    },

    data() {
        return {
            criminalHistoryAdd: {
                numberOf: 0,
                numberOfConvictions: 0,
                numberOfArrests: 0,
                notes: [],
            },
            addCriminalHistoryModal: false,
            disableButtonFlag: false
        }
    },

    methods: {
        addCriminalHistory() {
            this.disableButtonFlag = true;
            this.$emit('addCriminalHistory', this.criminalHistoryAdd);
            this.addCriminalHistoryModal = false;
        },
        deleteCriminalHistory(criminalHistory) {
            this.$emit('deleteCriminalHistory', criminalHistory);
        },
        disableCriminalHistory() {
            return !this.filledOut(this.criminalHistoryAdd.offense)
        },
        filledOut(i){
            return !(i === null || i === undefined || i === '' || i === 0);
        },
        incrafy(name, add){
            if(add){
                this.criminalHistoryAdd[name]++;
            }else if(this.criminalHistoryAdd[name] > 0){
                this.criminalHistoryAdd[name]--;
            }
        },
        openModalAddCriminalHistories() {
            this.disableButtonFlag = false;
            this.criminalHistoryAdd = {
                notes: [],
                numberOf: 0,
                numberOfConvictions: 0,
                numberOfArrests: 0,
            };
            this.addCriminalHistoryModal = true;
        },
        push(field, item) {
            field.push(JSON.parse(JSON.stringify(item)));
        },
        splice(index, field) {
            field.splice(index, 1);
        },
        updateCriminalHistory(criminalHistory) {
            this.$emit('updateCriminalHistory', criminalHistory);
        },

    },
}
</script>

<style scoped>

.noAdded {
    text-align: center;
    margin-bottom: 0;
    color: grey
}

</style>