<template>
    <div v-if="leReport !== null">

        <!--View Files-->
        <FileViewer2 :flag="fileViewerFlag" :fileId="fileViewStorageId" :closeMethod="closeViewModal"/>

        <!--Remove Item Modal-->
        <b-modal class="modal-dark" scrollable title="Remove LE-Report" v-model="deleteObjects.modal">
            <p>If you would like to delete this {{deleteObjects.name}} please type the prompt below.</p>
            <br>
            <p style="margin-bottom: 0">Enter "{{deleteObjects.name}}":</p>
            <b-form-group description="case sensitive">
                <b-form-input id="basicName2" type="text" v-model="deleteObjects.deleteText"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="deleteObjects.modal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button :disabled="deleteObjects.deleteText !== deleteObjects.name || disableDeleteFlag" @click="deleteLeReport()"
                          size="sm"
                          variant="danger">Delete {{deleteObjects.name}}
                </b-button>
            </template>
        </b-modal>

        <!--Edit LE-Report-->
        <b-modal no-close-on-backdrop class="modal-dark" scrollable title="Edit LE-Report" v-model="leReportFields.editLeReportFlag"
                 size="md" v-if="leReportFields.editLeReportFlag" no-body>
            <b-input-group class="input-group-sm" prepend="Subject" style="margin-bottom: 1px;">
                <b-form-input
                    :class=" 'form-control is-' + (filledOut(leReportFields.data.subject) ? 'valid':'invalid')"
                    v-model="leReportFields.data.subject"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Report Number" style="margin-bottom: 1px">
                <b-form-input
                    :class=" 'form-control is-' + (filledOut(leReportFields.data.leReportNumber) ? 'valid':'invalid')"
                    v-model="leReportFields.data.leReportNumber"/>
            </b-input-group>
<!--            <b-input-group class="input-group-sm" prepend="Involvement" style="margin-bottom: 1px">
                <b-form-select
                    :class=" 'form-control is-' + (filledOut(leReportFields.data.involvement) ? 'valid':'invalid')"
                    v-model="leReportFields.data.involvement"
                    :options="['ARRESTEE', 'WITNESS', 'SUSPECT', 'VICTIM', 'OTHER']"/>
            </b-input-group>-->
            <b-input-group class="input-group-sm" prepend="Date" style="margin-bottom: 1px">
                <date-picker :config="dateOptions" v-model="leReportFields.data.date" />
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Description" style="margin-bottom: 1px">
                <textarea maxlength="500"
                          :class=" 'form-control is-' + (filledOut(leReportFields.data.description) ? 'valid':'invalid')"
                          class="form-control rounded-0" rows="4"
                          v-model="leReportFields.data.description" type="text"></textarea>
            </b-input-group>
            <br>
            <b-form-group>
                <label>Other Notes</label>&nbsp;
                <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(leReportFields.data.notes, {text: '', scope: scope})"/>
                <div v-if="leReportFields.data.notes" :key="i"
                     v-for="(note,i) in refactorScope(leReportFields.data.notes)">

                    <b-input-group v-if="!note.scope || note.scope === scope || note.scope === 'global'" class="input-group-sm">

                        <div class="col" style="padding: 0; width: 55px">
                            <b-form-input v-model="leReportFields.data.notes[i].text" size="sm"/>
                        </div>

                        <div class="col-auto" style="padding: 0 4px 0 0;">
                            <b-form-select v-model ="leReportFields.data.notes[i].scope"
                                           size="sm" plain
                                           style="background-color: #191919; border-color: #191919; ">
                                <option :value="scope">Scoped</option>
                                <option :value="'global'">Global</option>
                            </b-form-select>
                        </div>
                        <b-input-group-append v-if="leReportFields.data.notes.length > 1">
                            <b-button @click="splice(i, leReportFields.data.notes)" variant="dark">
                                <fa-icon :icon="['fas', 'close']"/>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="leReportFields.editLeReportFlag = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button
                    :disabled="disableLeReportSubmit()"
                    @click="updateLeReport()" size="sm" variant="primary">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <!-- Main Card -->
        <b-card style="border-color: #0e0e0e; background-color: #202020;" no-body>
            <span class="d-flex w-100 justify-content-between" style="background-color: #0e0e0e; padding: 0 5px 0 5px; border-radius: 3px 3px 0 0">
                <b style="font-size: medium">{{leReport.leReportNumber}}</b>
                <span v-if="editable === true">
                    <fa-icon :icon="['fas', 'pen-to-square']" @click="openModalEditLeReport()" style="cursor: pointer;"/>&nbsp;
                    <fa-icon :icon="['fas', 'close']" @click="openDeleteModal(leReport.leReportId)" size="lg"
                             style="cursor: pointer; transform: translateY(1px);"/>
                </span>
            </span>
            <b-row style="margin: 0; padding: 0">
                <b-col class="d-flex flex-column" style="margin: 0; padding: 0" :md="leReport.documents && leReport.documents.length > 0 ? 7 : 12">
                    <div style="margin: 5px">
                        <div><strong>Subject: </strong>{{leReport.subject}}</div>
                        <div><strong>Date: </strong>{{dateToString(leReport.date)}}</div>
                        <div><strong>Description: </strong>{{leReport.description}}</div>
                    </div>
                </b-col>
                <b-col style="margin: 0; padding: 0" xl="5"
                       class="d-flex justify-content-center"
                       v-if="leReport.documents && leReport.documents.length > 0">
                    <span style="width: auto; max-height: 100px; margin: 5px; border: 2px solid #23282c; border-radius: 5px; background-color: #0E0E0E;"
                          class="image-parent">
                        <img class="img-center-ass"
                             style="border-radius: 5px; max-height: 100px"
                             v-if="leReport.documents && leReport.documents.length > 0"
                             v-auth-image="getImage(leReport.documents[0].storageFileName)"
                             @click="openViewModal(leReport.documents[0].storageFileName)"
                             alt=""/>
                        <img v-else class="img-center-ass"
                             style="border-radius: 5px;"
                             src="/NoImageAvailable.png"
                             alt="">
                    </span>
                </b-col>
            </b-row>
            <div  v-if="noteExpand === false" style="text-align: center">
                <div style="text-align: center; cursor: pointer;" @click="openMoreInfo()">
                    <fa-icon :icon="['fas', 'angle-down']"/>
                </div>
            </div>
            <div v-else style="margin: 5px">
                <br/>
                <InvolvementCardComponent :involvements="leReport.involvements" :leReportLink="leReport.leReportId.toString()" :editable="editable" @updateInvolvements="updateInvolvements()"/>
                <br/>
                <NotesCardComponent :notes="leReport.notes" :scope="scope"/>
                <br>
                <DocumentCardComponent :editable="editable"
                                       :documents="leReport.documents ? leReport.documents : []"
                                       :editMethod="changeDocumentDesc"
                                       :deleteMethod="deleteEmbeddedDoc"
                                       :submitSelectorMethod="setFileFavorite"
                                       :submitUploadMethod="addLeReportDocument"/>
                <br>
                <AddressCardComponent :editable="editable"
                                      :addresses="leReport.addresses"
                                      :scope="scope"
                                      :linkId="leReport.leReportId.toString()"
                                      :linkType="'LeReport'"
                                      :color="(profiles ? 'orange' : 'green')"
                                      @deleteAddress="deleteAddress"/>
                <p style="text-align: center; margin: 0; cursor: pointer;" @click="noteExpand = false">
                    <fa-icon :icon="['fas', 'angle-up']" style="text-align: center;"/>
                </p>
            </div>
            <!-- Extra Profile List -->
            <b-list-group v-if="editable === false && profiles && profiles.length > 0" style="border: none" :key="sIndex" v-for="(profile, sIndex) in profiles">
                <b-list-group-item style="margin: 5px">
                        <span class="d-flex w-100 justify-content-between align-content-center">
                            <h5 style="margin-bottom: 0">{{ profile.name }}</h5>
                            <router-link class="non-link"
                                         :to="{ name: 'Profile Details', params: {id: profile.profileId}}"
                                         target="_blank">
                                <fa-icon :icon="['fas', 'up-right-from-square']" class="fa fa-external-link"/>
                            </router-link>
                        </span>
                </b-list-group-item>
            </b-list-group>
        </b-card>
    </div>
</template>

<script>

import risc from '@/services/risc';
import DocumentCardComponent from "@/views/expansion_modules/risc/components/documents_components/DocumentCardComponent";
import NotesCardComponent from "@/views/expansion_modules/risc/components/notes_components/NotesCardComponent";
import AddressCardComponent from "@/views/expansion_modules/risc/components/address_components/AddressCardComponent";
import * as moment from "moment";
import datePicker from "vue-bootstrap-datetimepicker";
import FileViewer2 from '@/views/expansion_modules/risc/components/utility_components/FileViewer2.0';
import InvolvementCardComponent from "@/views/expansion_modules/risc/components/involvement_components/InvolvementCardComponent";

export default {
    name: "LeReportComponent",
    components: {
        InvolvementCardComponent,
        NotesCardComponent,
        DocumentCardComponent,
        AddressCardComponent,
        FileViewer2,
        datePicker
    },
    props: {
        editable: { type: Boolean },
        leReportObject: { type: Object },
        scope: { type: String },
        linkId: { type: String },
        linkType: { type: String },
        profiles: { type: Array },
        getImages: { type: Boolean }
    },
    data() {
        return {
            disableDeleteFlag: false,
            fileViewStorageId: null,
            fileViewerFlag: false,
            leReport: {},
            moreInfo: false,
            noteExpand: false,
            leReportFields: {
                editLeReportFlag: false,
                allLeReports: [],
                data: {},
                add: false
            },
            dateOptions: {
                format: 'MM/DD/YYYY',
                useCurrent: false
            },
            deleteObjects: {
                modal: false,
                name: "LeReport",
                deleteText: "",
                id: '',
            }
        };
    },
    created() {
        this.leReport = JSON.parse(JSON.stringify(this.leReportObject));
    },
    methods: {
        addLeReportDocument(description, fileId) {
            this.leReport.documents.push({
                description: description,
                storageFileName: fileId,
                arrayOrder: this.leReport.documents.length
            })
            risc.putLeReport(this.leReport).then(() => {
                this.getLeReport()
            });
        },
        changeDocumentDesc(doc) {
            this.leReport.documents.forEach(document => {
                if (document.documentId === doc.documentId) {
                    document.description = doc.description
                }
            })
            risc.putLeReport(this.leReport).then(() => {
                this.getLeReport()
            });
        },
        closeViewModal() {
            this.fileViewerFlag = false;
        },
        dateToString(date) {
            if (date === '0001-01-01T00:00:00') {
                return '';
            } else {
                return (moment(date).format('L'));
            }
        },
        deleteAddress(address) {
            this.leReport.addresses = this.leReport.addresses.filter(_ => _.addressId !== address.addressId);
        },
        deleteEmbeddedDoc(doc) {
            this.leReport.documents = this.leReport.documents.filter((document) => {
                return document.documentId !== doc.documentId;
            });
            risc.putLeReport(this.leReport).then(() => {
                this.getLeReport();
            });
        },
        deleteLeReport() {
            this.disableDeleteFlag = true;
            if (this.linkType === 'Org') {
                risc.unlinkOrgLeReport(this.linkId, this.deleteObjects.id).then(() => {
                    //this.getOrganization()
                    this.deleteObjects.modal = false;
                    this.noteExpand = false;
                    this.$emit('deleteLeReport', this.leReport);
                });
            } else if (this.linkType === 'Profile') {
                risc.unlinkProfileLeReport(this.linkId, this.deleteObjects.id).then(() => {
                    //this.getOrganization()
                    this.deleteObjects.modal = false;
                    this.noteExpand = false;
                    this.$emit('deleteLeReport', this.leReport);
                });
            }
        },
        disableLeReportSubmit() {
            return !this.filledOut(this.leReportFields.data.leReportNumber) ||
                //!this.filledOut(this.leReportFields.data.involvement) ||
                !this.filledOut(this.leReportFields.data.description);

        },
        filledOut(i){
            return !(i === null || i === undefined || i === '' || i === 0);
        },
        getImage(storageId) {
            if (storageId !== undefined) {
                return risc.getImage(storageId);
            } else {
                return ('/img/NoPictureOnFileGrey.png')
            }
        },
        getLeReport() {
            risc.getLeReport(this.leReport.leReportId).then(response => {
                this.leReport = Object.assign({}, response.data);
            });
        },
        openDeleteModal(id) {
            this.disableDeleteFlag = false;
            this.deleteObjects.modal = true
            this.deleteObjects.deleteText = ''
            this.deleteObjects.id = id
        },
        openModalEditLeReport() {
            if (this.moreInfo === false) {
                risc.getLeReport(this.leReport.leReportId).then(response => {
                    this.leReport = response.data;
                    this.leReportFields.data = JSON.parse(JSON.stringify(this.leReport))
                    this.leReportFields.data.date = new Date(this.leReportFields.data.date);
                    this.leReportFields.editLeReportFlag = true;
                    this.moreInfo = true;
                });
            } else {
                this.leReportFields.data = JSON.parse(JSON.stringify(this.leReport))
                this.leReportFields.data.date = new Date(this.leReportFields.data.date);
                this.leReportFields.editLeReportFlag = true;
            }
        },
        openMoreInfo() {
            if (this.moreInfo === false) {
                risc.getLeReport(this.leReport.leReportId).then(response => {
                    this.leReport = response.data;
                    this.noteExpand = true;
                    this.moreInfo = true;
                });
            } else {
                this.noteExpand = true;
            }
        },
        openViewModal(storageId) {
            this.fileViewStorageId = storageId
            this.fileViewerFlag = true;
        },
        push(field, item) {
            field.push(JSON.parse(JSON.stringify(item)));
        },
        refactorScope(arr) {
            return arr ? arr.map(_ => {_.scope = !_.text ? this.scope : _.scope; return _}): []
        },
        setFileFavorite(files) {
            this.leReport.documents = files
            risc.putLeReport(this.leReport).then(() => {
                this.getLeReport();
            });
        },
        splice(index, field) {
            field.splice(index, 1);
        },
        updateInvolvements() {
            this.getLeReport();
        },
        updateLeReport() {
            if (this.leReportFields.data.date !== undefined) {
                this.leReportFields.data.date = new Date(this.leReportFields.data.date);
            }
            risc.putLeReport(this.leReportFields.data).then(() => {
                this.getLeReport();
                this.$emit('updateLeReport');
            });
            this.leReportFields.editLeReportFlag = false;
        },

    },
    watch: {
        getImages() {
            if (this.getImages === true && this.moreInfo === false) {
                this.getLeReport();
                this.moreInfo = true;
            }
        },
        leReportObject() {
            this.leReport = JSON.parse(JSON.stringify(this.leReportObject));
            if (this.noteExpand === true) {
                this.getLeReport();
            }
        }
    }
}
</script>

<style scoped>

</style>