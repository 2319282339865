<template>
    <div v-if="markingsObject !== undefined">

        <!--Add  Marking-->
        <b-modal class="modal-dark" scrollable :title="'Add ' + (other === true ? 'Other Desc.' : 'S/M/T')" v-model="addMarkingFlag" no-close-on-backdrop>
            <b-input-group v-if="other === true" class="input-group-sm" prepend="Type" style="margin-bottom: 1px">
                <b-form-input v-model="markingAdd.type" v-uppercase/>
            </b-input-group>
            <b-input-group v-else class="input-group-sm" prepend="Type" style="margin-bottom: 1px">
                <b-form-select :options="markingTypeOptions" v-model="markingAdd.type"/>
            </b-input-group>

            <b-input-group v-if="other === false" class="input-group-sm" prepend="Location" style="margin-bottom: 1px">
                <b-form-select
                    :options="markingLocationOptions"
                    v-model="markingAdd.location"/>
            </b-input-group>

            <b-input-group class="input-group-sm" prepend="Description" style="margin-bottom: 1px">
                <b-form-input maxlength="500"
                              :class=" 'form-control is-' + (filledOut(markingAdd.description) ? 'valid':'invalid')"
                              v-model="markingAdd.description"/>
            </b-input-group>
            <br>
            <label class="labelForm">Notes</label>&nbsp;
            <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(markingAdd.notes, {text: ''})"/>
            <b-input-group :key="i" class="input-group-sm" v-for="(e,i) in markingAdd.notes">
                <b-form-input v-model="e.text"/>
                <b-input-group-append>
                    <b-button @click="splice(i, markingAdd.notes)" variant="dark">
                        <fa-icon :icon="['fas', 'close']"/>
                    </b-button>
                </b-input-group-append>
            </b-input-group>
            <template slot="modal-footer">
                <b-button @click="addMarkingFlag = false" size="sm" variant="secondary">Cancel
                </b-button>
                <b-button :disabled="disableMarkingSubmit() || disableButtonFlag" @click="addMarking()" size="sm" variant="primary">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <!--Main Body-->
        <div v-if="editable === true" style="text-align: center; width: 100%; margin-bottom: 10px;">
            <span style="cursor: pointer;" @click="openModalAddMarking()">
                <fa-icon :icon="['fas', 'plus']"/> Add {{ other === true ? 'Other Desc.' : 'Marking' }}
            </span>
        </div>
        <div style="overflow-y: auto;">
            <div :key="i" v-for="(marking,i) in markings">
                <MarkingComponent :marking="marking" :editable="true" :scope="scope" :other="other" @deleteMarking="deleteMarking" @updateMarking="updateMarking"/>
            </div>
            <p class="noAdded" v-if="markings && markings.length === 0"><i><strong>No {{ other === true ? 'Other Desc.' : 'S / M / T' }} Added</strong></i></p>
        </div>
    </div>
</template>

<script>
import MarkingComponent from "@/views/expansion_modules/risc/components/marking_components/MarkingComponent";

export default {
    name: "MarkingTabComponent",
    components: {
        MarkingComponent
    },
    props: {
        editable: { type: Boolean },
        markingsObject: { type: Array },
        scope: { type: String },
        other: { type: Boolean }
    },
    created() {
        this.markings = this.markingsObject;
    },
    data() {
        return {
            markings: null,
            addMarkingFlag: false,
            disableButtonFlag: false,
            markingAdd: {},
            markingTypeOptions: ['TATTOO', 'SCAR', 'MARKING'],
            markingLocationOptions: [
                "ABDOMEN",
                "ABDOMEN, LEFT",
                "ABDOMEN, RIGHT",
                "ARM, LEFT, LOWER",
                "ARM, LEFT, UPPER",
                "ARM, NONSPECIFIC",
                "ARM, NONSPECIFIC, LOWER",
                "ARM, RIGHT",
                "ARM, RIGHT, LOWER",
                "ARM, RIGHT, UPPER",
                "BACK",
                "BREAST, LEFT",
                "BREAST, NONSPECIFIC",
                "BREAST, RIGHT",
                "BUTTOCK, LEFT",
                "BUTTOCK, RIGHT",
                "BUTTOCKS, NONSPECIFIC",
                "CALF, LEFT",
                "CALF, NONSPECIFIC",
                "CALF, RIGHT",
                "CHEEK, LEFT",
                "CHEEK, NONSPECIFIC",
                "CHEEK, RIGHT",
                "CHEST",
                "CHEST, LEFT",
                "CHEST, RIGHT",
                "CHIN",
                "EAR, LEFT",
                "EAR, NONSPECIFIC",
                "EAR, RIGHT",
                "ELBOW, LEFT",
                "ELBOW, NONSPECIFIC",
                "ELBOW, RIGHT",
                "EYE, LEFT",
                "EYE, NONSPECIFIC",
                "EYE, RIGHT",
                "FACE, NONSPECIFIC",
                "FINGER(S), LEFT HAND",
                "FINGER(S), RIGHT HAND",
                "FINGER, NONSPECIFIC",
                "FOOT, LEFT",
                "FOOT, NONSPECIFIC",
                "FOOT, RIGHT",
                "FOREHEAD",
                "FULL BODY",
                "GROIN AREA",
                "HAND, LEFT",
                "HAND, NONSPECIFIC",
                "HAND, RIGHT",
                "HEAD, NONSPECIFIC",
                "HIP, LEFT",
                "HIP, NONSPECIFIC",
                "HIP, RIGHT",
                "KNEE, LEFT",
                "KNEE, NONSPECIFIC",
                "KNEE, RIGHT",
                "LEG, LEFT",
                "LEG, NONSPECIFIC",
                "LEG, RIGHT",
                "LIP, LOWER",
                "LIP, NONSPECIFIC",
                "LIP, UPPER",
                "NECK",
                "NOSE",
                "PENIS",
                "SHOULDER, LEFT",
                "SHOULDER, NONSPECIFIC",
                "SHOULDER, RIGHT",
                "THIGH, LEFT",
                "THIGH, NONSPECIFIC",
                "THIGH, RIGHT",
                "TOE(S), NONSPECIFIC",
                "TOE, LEFT",
                "TOE, RIGHT",
                "WRIST, LEFT",
                "WRIST, NONSPECIFIC",
                "WRIST, RIGHT"
            ]
        };
    },
    methods: {
        addMarking() {
            this.disableButtonFlag = true;
            this.$emit('addMarking', this.markingAdd);
            this.addMarkingFlag = false;
        },
        deleteMarking(marking) {
            this.$emit('deleteMarking', marking);
        },
        disableMarkingSubmit() {
            return !this.filledOut(this.markingAdd.description) ||
                !this.filledOut(this.markingAdd.type);
        },
        filledOut(i){
            return !(i === null || i === undefined || i === '' || i === 0);
        },
        openModalAddMarking() {
            this.disableButtonFlag = false;
            this.markingAdd = {
                notes: []
            };
            this.addMarkingFlag = true;
        },
        push(field, item) {
            field.push(JSON.parse(JSON.stringify(item)));
        },
        splice(index, field) {
            field.splice(index, 1);
        },
        updateMarking(marking) {
            this.$emit('updateMarking', marking);
        },

    },
    watch: {
        markingsObject() {
            this.markings = this.markingsObject;
        }
    }
}
</script>

<style scoped>

.noAdded {
    text-align: center;
    margin-bottom: 0;
    color: grey
}

</style>