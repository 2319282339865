import { render, staticRenderFns } from "./FelonyTabComponent.vue?vue&type=template&id=70b706ae&scoped=true"
import script from "./FelonyTabComponent.vue?vue&type=script&lang=js"
export * from "./FelonyTabComponent.vue?vue&type=script&lang=js"
import style0 from "./FelonyTabComponent.vue?vue&type=style&index=0&id=70b706ae&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70b706ae",
  null
  
)

export default component.exports