<template>
    <div id="printMe" style="position: relative">
        <div style="position: absolute; z-index: -999; width: 100%; overflow-y: hidden">
            <div style="height: 1000px; width: 100%"
                 class="d-flex justify-content-center align-items-end">
                <img src="../../../../../../public/img/Recruiting-Booklet-2018-1.png" style="max-height: 40%; max-width: 40%; opacity: 0.05" alt=""/>
            </div>
        </div>
        <div class="w-100 d-flex justify-content-between" style="padding: 7px 7px 0 7px">
            <div>
                <div style=" position: absolute; z-index: 999; height: 95px; width: 90px"
                     class="d-flex justify-content-center align-items-center">
                    <img src="../../../../../../public/img/Recruiting-Booklet-2018-1.png" style="max-height: 100%; max-width: 100%" alt=""/>
                </div>
                <div style="padding-left: 100px">
                    <div><b>Rockford Police Department</b></div>
                    <div><b>Intelligence Unit</b></div>
                    <div style="font-size: smaller">1410 Broadway Rockford, IL 61104</div>
                    <div style="font-size: smaller">779-500-6555</div>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-end">
                <div><b>Information Sheet</b></div>
                <div><b style="color: red">Not for media release</b></div>
                <div><b style="color: red">LAW ENFORCEMENT SENSITIVE</b></div>
            </div>
        </div>
        <div style="height: 14px"/>

        <!-- NAME -->
        <h1 style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px; padding: 0">
            <b v-if="printOptions.name">
                {{ profile.firstName }} {{ profile.middleName }} {{ profile.lastName }} {{ profile.suffix }}
            </b>
            <b v-else>UNKNOWN</b>
        </h1>

        <!-- RIBBON -->
        <div style="text-align: center;">
            <div v-if="ribbons && ribbons.length > 0"
                  style="display: flex; align-items: center; height: 40px">
                <div v-for="(r, i) in ribbons" :key="i + 'r1'"
                      :class="'bg-' + ribbonVariant(ribbons.length > 0 && printOptions.ribbon ? r.ribbon : 'DECEASED')"
                      style="flex: 1; height: 100%; display: flex; align-items: center">
                    <h3 style="margin: 0; width: 100%; text-align: center">
                        {{ ribbons.length > 0 && printOptions.ribbon ? r.ribbon : 'UNKNOWN' }}
                    </h3>
                </div>
            </div>
        </div>
        <div style="height: 5px"/>

        <!-- PICTURE & GENERAL INFORMATION -->
        <b-row class="d-flex align-items-stretch" style="height: 600px; padding: 0 7px 0 7px">

            <!-- PICTURE -->
            <b-col sm="5" class="flex-grow-1" style="padding: 10px; height: 600px" ref="iContainer">
                <span
                    style="height: calc(600px - 28px); width: calc(100% + 2px); transform: translate(7px, -10px);background-color: #28282e"
                    class="d-flex justify-content-center align-items-center flex-column m-0 p-0" ref="imgCon">
                        <img v-if="profile.images && profile.images[0] && printOptions.image" ref="imgEle"
                             v-auth-image="getImage(profile.images[0].storageFileName)"
                             style="object-fit: contain; height: 100%; width: 100%" alt=""/>
                        <img v-else src="../../../../../../public/img/NoPictureOnFileGrey.png" style="max-height: 100%; max-width: 100%" alt=""/>
                </span>
                <div style="transform: translate(7px, -10px); width: calc(100% + 2px); border: #28282e solid 2px"
                     class="d-flex justify-content-center">
                    Source:
                    <span v-if="!profile.images || profile.images.length === 0 || !printOptions.image"> Satellite </span>
                    <span v-else-if="(profile.images && profile.images.length > 0) && profile.images[0].description !== undefined && profile.images[0].description !== ''">
                        {{ profile.images[0].description }}
                    </span>
                </div>
            </b-col>


            <!-- GENERAL INFORMATION -->
            <b-col sm="7" class="flex-fill" style="font-size: 17px" v-if="printOptions.general.show">
                <b-row>
                    <b-col sm="6">
                        <b>STREET NAME</b>
                        <div v-if="profile.streetName && profile.streetName.length > 0 && isShow(printOptions.general.streetnames)">
                            <span v-for="(sName, i) in profile.streetName">
                                <span v-if="printOptions.general.streetnames.children[i].show">
                                    {{ sName.streetName }}
                                    <span v-if="i !== profile.streetName.length - 1">, </span>
                                </span>
                            </span>
                        </div>
                        <div v-else> N/A</div>
                    </b-col>
                    <b-col sm="3">
                        <b>EMPLOYER</b>
                        <div v-if="printOptions.general.employer"> {{ profile.employer || "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                    <b-col sm="3">
                        <b>DOB</b>
                        <div v-if="printOptions.general.dob"> {{ dateOfBirth || "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                </b-row>
                <hr style="padding: 3px 5px 3px 5px; margin: 0"/>
                <b-row>
                    <b-col sm="4">
                        <b>RACE</b>
                        <div v-if="printOptions.general.race"> {{ profile.race || "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                    <b-col sm="3">
                        <b>SEX</b>
                        <div v-if="printOptions.general.sex"> {{ profile.sex || "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                    <b-col sm="2">
                        <b>HEIGHT</b>
                        <div v-if="printOptions.general.height"> {{ height || "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                    <b-col sm="2">
                        <b>WEIGHT</b>
                        <div v-if="printOptions.general.weight"> {{ weight ? weight + "lbs." : "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                </b-row>
                <hr style="padding: 3px 5px 3px 5px; margin: 0"/>
                <b-row>
                    <b-col>
                        <b>SSN</b>
                        <div v-if="printOptions.general.ssn"> {{ profile.socialSecurity || "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                    <b-col>
                        <b>MID</b>
                        <div v-if="printOptions.general.mid"> {{ profile.mid || "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                    <b-col>
                        <b>SID</b>
                        <div v-if="printOptions.general.sid"> {{ profile.sid || "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                    <b-col>
                        <b>FBI</b>
                        <div v-if="printOptions.general.fbi"> {{ profile.fbi || "N/A" }}</div>
                        <div v-else> N/A</div>
                    </b-col>
                </b-row>
                <hr style="padding: 3px 5px 3px 5px; margin: 0"/>
                <b-row>
                    <b-col>
                        <b>IDs</b>
                        <div v-if="profile.identifications && profile.identifications.length > 0 && isShow(printOptions.general.ids)">
                            <span v-for="(id, i) in profile.identifications">
                                <span v-if="printOptions.general.ids.children[i].show">
                                    {{ id.state }} {{ id.number }} ({{ id.status }} {{ id.type }})
                                    <span v-if="i !== profile.identifications.length - 1">, </span>
                                </span>
                            </span>
                        </div>
                        <div v-else>N/A</div>
                    </b-col>
                </b-row>
                <hr style="padding: 3px 5px 3px 5px; margin: 0"/>
                <b-row>
                    <b-col>
                        <b>ACTIVE ADDRESSES</b>
                        <div v-if="profile.addresses && profile.addresses.length > 0 && isShow(printOptions.general.activeAddresses)">
                            <div v-for="(address, index) in profile.addresses">
                                <span v-if="printOptions.general.activeAddresses.children[index].show === true && address.active === true">
                                    {{ address.address1 }}{{ address.address2 ? (" " + address.address2) : "" }}
                                    , {{ address.city }}, {{ address.state }} {{ address.zip }}
                                </span>
                            </div>
                        </div>
                        <div v-else>N/A</div>
                    </b-col>
                </b-row>
                <hr style="padding: 3px 5px 3px 5px; margin: 0"/>
                <b-row>
                    <b-col>
                        <b>ALIAS</b>
                        <div v-if="profile.alias && profile.alias.length > 0 && isShow(printOptions.general.alias)">
                            <span v-for="(alias, i) in profile.alias">
                                <span v-if="printOptions.general.alias.children[i].show">
                                    {{ alias.knownAlias }}
                                    <span v-if="i !== profile.alias.length - 1">, </span>
                                </span>
                            </span>
                        </div>
                        <div v-else>N/A</div>
                    </b-col>
                </b-row>
                <hr style="padding: 3px 5px 3px 5px; margin: 0"/>
                <b-row>
                    <b-col>
                        <b>ORGANIZATION AFFILIATION(S)</b>
                        <div
                            v-if="profile.organizations && profile.organizations.length > 0 && isShow(printOptions.general.orgs)">
                            <span v-for="(org, i) in profile.organizations">
                                <span v-if="printOptions.general.orgs.children[i].show">
                                    {{ org.orgName }}
                                    <span v-if="i !== profile.organizations.length - 1">, </span>
                                </span>
                            </span>
                        </div>
                        <div v-else> N/A</div>
                    </b-col>
                </b-row>
                <hr style="padding: 3px 5px 3px 5px; margin: 0"/>
                <b-row>
                    <b-col>
                        <b>PHONE NUMBERS</b>
                        <div v-if="profile.phone && profile.phone.length > 0 && isShow(printOptions.general.phone)">
                            <span v-for="(phone, i) in profile.phone">
                                <span v-if="printOptions.general.phone.children[i].show">
                                    {{ phone.number }} <span v-if="phone.notes">({{ phone.notes }})</span>
                                    <span v-if="i !== profile.phone.length - 1">, </span>
                                </span>
                            </span>
                        </div>
                        <div v-else>N/A</div>
                    </b-col>
                </b-row>
                <hr style="padding: 3px 5px 3px 5px; margin: 0"/>
                <b-row>
                    <b-col>
                        <b>SOCIAL MEDIA</b>
                        <div v-if="profile.socialMedia && profile.socialMedia.length > 0 && isShow(printOptions.general.social)">
                            <span v-for="(social, i) in profile.socialMedia">
                                <span v-if="printOptions.general.social.children[i].show">
                                    {{ social.userName }} ({{ social.site }})
                                    <span v-if="i !== profile.socialMedia.length - 1">, </span>
                                </span>
                            </span>
                        </div>
                        <div v-else>N/A</div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <!-- OTHER INFORMATION -->
        <div v-if="printOptions.otherInfo.show">

            <div v-if="addressesFiltered !== null && addressesFiltered.length > 0 && isShow(printOptions.otherInfo.addresses)" v-for="index in Math.ceil(addressesFiltered.length / (printOptions.images.addresses === true ? 16 : 22))" style="display: grid;  page-break-inside: avoid; break-inside: avoid; margin-top: 10px" :key="index + 'addresses'">
                <h5 style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px; margin-top: 10px">
                    ADDRESSES
                </h5><br>
                <b-row class="m-0 p-0">
                    <b-col v-for="addressesIndex in (printOptions.images.addresses === true ? 16 : 22)" :key="addressesIndex" sm="6" class="m-0 p-0">
                        <b-card v-if="addressesFiltered[(addressesIndex-1) + ((index-1)*(printOptions.images.addresses === true ? 16 : 22))]" no-body style="margin: 0 5px 10px 5px; padding: 0;">
                            <b-row no-gutters>
                                <b-col sm="4" v-if="printOptions.images.addresses === true" style="height: 170px" class="p-0 m-0">
                                    <b-card-img v-auth-image="getObjectImage(addressesFiltered[(addressesIndex-1) + ((index-1)*(printOptions.images.addresses === true ? 16 : 22))].documents, true)"
                                                style="object-fit: contain; height: 100%; width: 100%"/>
                                </b-col>
                                <b-col :sm="printOptions.images.addresses === true ? '8' : '12'" class="m-0 d-flex justify-content-center align-items-center flex-column"
                                       style="padding-left: 10px; min-height: 120px">

                                    <div>{{ addressesFiltered[(addressesIndex-1) + ((index-1)*(printOptions.images.addresses === true ? 16 : 22))].address1 }}</div>
                                    <div v-if="addressesFiltered[(addressesIndex-1) + ((index-1)*(printOptions.images.addresses === true ? 16 : 22))].address2">{{ addressesFiltered[(addressesIndex-1) + ((index-1)*(printOptions.images.addresses === true ? 16 : 22))].address2 }}</div>
                                    <div>{{ addressesFiltered[(addressesIndex-1) + ((index-1)*(printOptions.images.addresses === true ? 16 : 22))].city }}, {{ addressesFiltered[(addressesIndex-1) + ((index-1)*(printOptions.images.addresses === true ? 16 : 22))].state }} {{ addressesFiltered[(addressesIndex-1) + ((index-1)*(printOptions.images.addresses === true ? 16 : 22))].zip }}</div>
                                    <div>{{addressesFiltered[(addressesIndex-1) + ((index-1)*(printOptions.images.addresses === true ? 16 : 22))].active ? "ACTIVE" : "INACTIVE"}}</div>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
<!--            <div v-if="profile.addresses && profile.addresses.length > 0 && isShow(printOptions.otherInfo.addresses)"
                 style="display: grid;  page-break-inside: avoid; break-inside: avoid; margin-top: 10px">
                <h5>
                    <b-col style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px;">
                        ADDRESSES
                    </b-col>
                </h5>
                <b-row class="m-0" style="padding: 0 7px 0 7px">
                    <b-col v-for="(address, index) in removeData(profile.addresses, 'addresses')" :key="index" sm="6" class="m-0 p-0">
                        <b-card no-body style="margin: 0 5px 10px 5px; padding: 0">
                            <b-row no-gutters>
                                <b-col sm="4" v-if="printOptions.images.addresses === true" style="height: 150px" class="p-0 m-0">
                                    <b-card-img v-auth-image="getObjectImage(address.documents, true)"
                                                style="object-fit: contain; height: 100%; width: 100%"/>
                                </b-col>
                                <b-col :sm="printOptions.images.addresses === true ? '8' : '12'" class="m-0 d-flex justify-content-center align-items-center flex-column"
                                       style="padding-left: 10px; min-height: 120px">
                                    <div>{{ address.address1 }}</div>
                                    <div v-if="address.address2">{{ address.address2 }}</div>
                                    <div>{{ address.city }}, {{ address.state }} {{ address.zip }}</div>
                                    <div>{{address.active ? "ACTIVE" : "INACTIVE"}}</div>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
            </div>-->

            <b-row>
                <b-col v-if="printOptions.otherInfo.drugs.show" style="padding-right: 0; display: grid;  page-break-inside: avoid; break-inside: avoid; margin-top: 10px">
                    <h5>
                        <b-col style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px;">
                            DRUGS ({{ profile.hasDrugHistory ? "HAS HISTORY" : "NO HISTORY" }})
                        </b-col>
                    </h5>
                    <div v-if="profile.drugs && profile.drugs.length > 0 && isShow(printOptions.otherInfo.drugs)" style="padding: 0 7px 0 7px">
                        <div v-for="(drug, index) in removeData(profile.drugs, 'drugs')">
                            <b>{{ index + 1 }}) SOURCE:</b> {{ drug.source }}
                            <div style="padding-left: 17px"><b>DESC:</b> {{ drug.description }}</div>
                        </div>
                    </div>
                </b-col>

                <b-col v-if="printOptions.otherInfo.weapons.show" style="padding-left: 0; display: grid;  page-break-inside: avoid; break-inside: avoid; margin-top: 10px">
                    <h5>
                        <b-col style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px;">
                            WEAPONS ({{ profile.hasWeaponHistory ? "HAS HISTORY" : "NO HISTORY" }})
                        </b-col>
                    </h5>
                    <div v-if="profile.weapons && profile.weapons.length > 0 && isShow(printOptions.otherInfo.weapons)"  style="padding: 0 7px 0 7px">
                        <div v-for="(weapon, index) in removeData(profile.weapons, 'weapons')">
                            <b>{{ index + 1 }}) SOURCE:</b> {{ weapon.source }}
                            <div style="padding-left: 17px"><b>DESC:</b> {{ weapon.description }}</div>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <div v-if="vehiclesFiltered !== null && vehiclesFiltered.length > 0 && isShow(printOptions.otherInfo.vehicles)" v-for="index in Math.ceil(vehiclesFiltered.length / (printOptions.images.vehicles === true ? 16 : 22))" style="display: grid;  page-break-inside: avoid; break-inside: avoid; margin-top: 10px" :key="index + 'vehicles'">
                <h5 style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px; margin-top: 10px">
                    VEHICLES
                </h5><br>
                <b-row class="m-0 p-0">
                    <b-col v-for="vehiclesIndex in (printOptions.images.vehicles === true ? 16 : 22)" :key="vehiclesIndex" sm="6" class="m-0 p-0">
                        <b-card v-if="vehiclesFiltered[(vehiclesIndex-1) + ((index-1)*(printOptions.images.vehicles === true ? 16 : 22))]" no-body style="margin: 0 5px 10px 5px; padding: 0;">
                            <b-row no-gutters>
                                <b-col sm="4" v-if="printOptions.images.vehicles === true" style="height: 170px" class="p-0 m-0">
                                    <b-card-img v-auth-image="getObjectImage(vehiclesFiltered[(vehiclesIndex-1) + ((index-1)*(printOptions.images.vehicles === true ? 16 : 22))].documents, true)"
                                                style="object-fit: contain; height: 100%; width: 100%"/>
                                </b-col>
                                <b-col :sm="printOptions.images.vehicles === true ? '8' : '12'" class="m-0 d-flex justify-content-center align-items-center flex-column"
                                       style="padding-left: 10px; min-height: 120px">

                                    <div>{{ vehiclesFiltered[(vehiclesIndex-1) + ((index-1)*(printOptions.images.vehicles === true ? 16 : 22))].color }}</div>
                                    <div>{{ (vehiclesFiltered[(vehiclesIndex-1) + ((index-1)*(printOptions.images.vehicles === true ? 16 : 22))].year !== 0 ? vehiclesFiltered[(vehiclesIndex-1) + ((index-1)*(printOptions.images.vehicles === true ? 16 : 22))].year : '') }} {{ vehiclesFiltered[(vehiclesIndex-1) + ((index-1)*(printOptions.images.vehicles === true ? 16 : 22))].make }} {{ vehiclesFiltered[(vehiclesIndex-1) + ((index-1)*(printOptions.images.vehicles === true ? 16 : 22))].model }}</div>
                                    <div>PLATE: {{(vehiclesFiltered[(vehiclesIndex - 1) + ((index - 1) * (printOptions.images.vehicles === true ? 16 : 22))].plate ? (vehiclesFiltered[(vehiclesIndex - 1) + ((index - 1) * (printOptions.images.vehicles === true ? 16 : 22))].plate) : 'N/A') }}</div>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
            </div>

<!--            <div v-if="profile.vehicles && profile.vehicles.length > 0 && isShow(printOptions.otherInfo.vehicles)"
                 style="display: grid;  page-break-inside: avoid; break-inside: avoid; margin-top: 10px">
                <h5>
                    <b-col style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px;">
                        VEHICLES
                    </b-col>
                </h5>
                <b-row class="m-0" style="padding: 0 7px 0 7px">
                    <b-col v-for="(vehicle, index) in removeData(profile.vehicles, 'vehicles')" :key="index" sm="6" class="m-0 p-0">
                        <b-card no-body style="margin: 0 5px 10px 5px; padding: 0">
                            <b-row no-gutters>
                                <b-col sm="4" v-if="printOptions.images.vehicles === true" style="height: 150px" class="p-0 m-0">
                                    <b-card-img v-auth-image="getObjectImage(vehicle.documents, true)"
                                                style="object-fit: contain; height: 100%; width: 100%"/>
                                </b-col>
                                <b-col :sm="printOptions.images.vehicles === true ? '8' : '12'" class="m-0 d-flex justify-content-center align-items-center flex-column"
                                       style="padding-left: 10px; min-height: 120px">
                                    <div>{{ vehicle.color }}</div>
                                    <div>{{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}</div>
                                    <div>PLATE {{vehicle.plate}}</div>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
            </div>-->

            <div v-if="profile.felonies && profile.felonies.length > 0 && isShow(printOptions.otherInfo.felonies)"
                 style="display: grid;  page-break-inside: avoid; break-inside: avoid; margin-top: 10px">
                <h5>
                    <b-col style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px;">
                        FELONIES
                    </b-col>
                </h5>
                <div style="padding: 0 7px 0 7px">
                    <div v-for="(felony, index) in removeData(profile.felonies, 'felonies')">
                        <b>{{ index + 1 }})</b> {{ felony.caseNumber }}&nbsp; {{ felony.felonyType }}&nbsp;
                        <b>CONVICTION DATE:</b> {{ dateToString(felony.convictionDate) }}
                        <div style="padding-left: 17px">
                            <b>STATUS:</b> {{ felony.status }}&nbsp;
                            <b>DESC:</b> {{ felony.description }}
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="profile.criminalHistories && profile.criminalHistories.length > 0 && isShow(printOptions.otherInfo.criminalHistory)"
                 style="display: grid;  page-break-inside: avoid; break-inside: avoid; margin-top: 10px">
                <h5>
                    <b-col style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px;">
                        CRIMINAL HISTORY
                    </b-col>
                </h5>
                <b-row style="padding: 0 7px 0 7px">
                    <b-col sm="6" v-for="(cHistory, index) in removeData(profile.criminalHistories, 'criminalHistory')" :key="index">
                        <b>{{ index + 1 }})</b> {{ cHistory.offense }}
                        <div style="padding-left: 17px">
                            <b>OFFENCES:</b> {{ cHistory.numberOf }} &nbsp;
                            <b>ARRESTS:</b> {{ cHistory.numberOfArrests }} &nbsp;
                            <b>CONVICTIONS:</b> {{ cHistory.numberOfConvictions }} &nbsp;
                        </div>
                        <div style="padding-left: 17px"><b>DESC:</b> {{ cHistory.description }}</div>
                    </b-col>
                </b-row>
            </div>

            <div v-if="profile.leReports && profile.leReports.length > 0 && isShow(printOptions.otherInfo.leReports)"
                 style="display: grid;  page-break-inside: avoid; break-inside: avoid; margin-top: 10px">
                <h5>
                    <b-col style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px;">
                        LE-REPORTS
                    </b-col>
                </h5>
                <div style="padding: 0 7px 0 7px">
                    <div v-for="(leReport, index) in removeData(profile.leReports, 'leReports')">
                        <div>
                            <b>{{ index + 1 }})</b><b> REPORT:</b> {{ leReport.leReportNumber }}&nbsp;
                            <b>SUBJECT:</b>{{ leReport.subject }}&nbsp;
                            <b>DATE:</b> {{ dateToString(leReport.date) }}&nbsp;
                        </div>
                        <div style="padding-left: 17px">
                            <b>INVOLVEMENT:</b>
                            <div v-if="leReport.involvements.length > 0">
                                <div style="padding-left: 17px" v-for="involvements in leReport.involvements">
                                    <span><b>{{involvements.involvementInfo}} - </b></span>
                                    <span v-for="(profile, index) in involvements.profiles" :key="index + 'involvements'">
                                        <span>{{profile.firstName}} {{profile.middleName}} {{profile.lastName}}<span v-if="involvements.profiles.length !== 0 && involvements.profiles.length > index + 1">, </span></span>
                                    </span>
                                </div>
                            </div>
                            <div v-else style="padding-left: 17px">
                                <span>NO INVOLVEMENTS</span>
                            </div>
                        </div>
                        <div style="padding-left: 17px">
                            <b>DESC:</b> {{ leReport.description }}
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="markingsFiltered !== null && markingsFiltered.length > 0 && isShow(printOptions.otherInfo.markings)" v-for="index in Math.ceil(markingsFiltered.length / (printOptions.images.markings === true ? 16 : 30))" style="display: grid;  page-break-inside: avoid; break-inside: avoid; margin-top: 10px" :key="index + 'markings'">
                <h5 style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px; margin-top: 10px">
                    MARKINGS
                </h5><br>
                <b-row class="m-0 p-0">
                    <b-col v-for="markingsIndex in (printOptions.images.markings === true ? 16 : 30)" :key="markingsIndex" sm="6" class="m-0 p-0">
                        <b-card v-if="markingsFiltered[(markingsIndex-1) + ((index-1)*(printOptions.images.markings === true ? 16 : 30))]" no-body style="margin: 0 5px 10px 5px; padding: 0;">
                            <b-row no-gutters>
                                <b-col sm="4" v-if="printOptions.images.markings === true" style="height: 170px" class="p-0 m-0">
                                    <b-card-img v-auth-image="getObjectImage(markingsFiltered[(markingsIndex-1) + ((index-1)*16)].images, true)"
                                                style="object-fit: contain; height: 100%; width: 100%"/>
                                </b-col>
                                <b-col :sm="printOptions.images.markings === true ? '8' : '12'" class="m-0 d-flex justify-content-center align-items-center flex-column"
                                       style="padding-left: 10px; min-height: 90px">

                                    <div><b>{{ markingsFiltered[(markingsIndex-1) + ((index-1)*(printOptions.images.markings === true ? 16 : 30))].type }}{{ markingsFiltered[(markingsIndex-1) + ((index-1)*(printOptions.images.markings === true ? 16 : 30))].location ? " (" + markingsFiltered[(markingsIndex-1) + ((index-1)*(printOptions.images.markings === true ? 16 : 30))].location + ")" : ""}}</b></div>
                                    <div>{{ markingsFiltered[(markingsIndex-1) + ((index-1)*(printOptions.images.markings === true ? 16 : 30))].description }}</div>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
<!--            <div v-if="profile.markings && profile.markings.length > 0 && isShow(printOptions.otherInfo.markings)"
                 style="display: grid;  page-break-inside: avoid; break-inside: avoid; margin-top: 10px">
                <h5>
                    <b-col style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px;">
                        MARKINGS
                    </b-col>
                </h5>
                <b-row class="m-0" style="padding: 0 7px 0 7px">
                    <b-col v-for="(mark, index) in removeData(profile.markings, 'markings')" :key="index" sm="6" class="m-0 p-0">
                        <b-card no-body style="margin: 0 5px 10px 5px; padding: 0">
                            <b-row no-gutters>
                                <b-col sm="4" v-if="printOptions.images.markings === true" style="height: 150px" class="p-0 m-0">
                                    <b-card-img v-auth-image="getObjectImage(mark.images, true)"
                                                style="object-fit: contain; height: 100%; width: 100%"/>
                                </b-col>
                                <b-col :sm="printOptions.images.markings === true ? '8' : '12'" class="m-0 d-flex justify-content-center align-items-center flex-column"
                                       style="padding-left: 10px; min-height: 90px">
                                    <div><b>{{ mark.type }}{{ mark.location ? " (" + mark.location + ")" : ""}}</b></div>
                                    <div>{{ mark.description }}</div>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
            </div>-->

            <div v-if="linkedAssociatesFiltered !== null && linkedAssociatesFiltered.length > 0 && isShow(printOptions.otherInfo.linkedAssociates)" v-for="index in Math.ceil(linkedAssociatesFiltered.length / (printOptions.images.linkedAssociates === true ? 16 : 42))" style="display: grid;  page-break-inside: avoid; break-inside: avoid; margin-top: 10px" :key="index + 'linkedAssociate'">
                <h5 style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px; margin-top: 10px">
                    LINKED ASSOCIATES
                </h5><br>
                <b-row class="m-0 p-0">
                    <b-col v-for="linkedAssIndex in (printOptions.images.linkedAssociates === true ? 16 : 42)" :key="linkedAssIndex" sm="6" class="m-0 p-0">
                        <b-card v-if="linkedAssociatesFiltered[(linkedAssIndex-1) + ((index-1)*(printOptions.images.linkedAssociates === true ? 16 : 42))]" no-body style="margin: 0 5px 10px 5px; padding: 0;">
                            <b-row no-gutters>
                                <b-col sm="4" v-if="printOptions.images.linkedAssociates === true" style="height: 170px" class="p-0 m-0">
                                    <b-card-img v-auth-image="getObjectImage(linkedAssociatesFiltered[(linkedAssIndex-1) + ((index-1)*16)].images)"
                                                style="object-fit: contain; height: 100%; width: 100%"/>
                                </b-col>
                                <b-col :sm="printOptions.images.linkedAssociates === true ? '8' : '12'" class="m-0 d-flex justify-content-center align-items-center flex-column"
                                       style="padding-left: 10px; min-height: 60px">
                                    <div>{{ linkedAssociatesFiltered[(linkedAssIndex-1) + ((index-1)*(printOptions.images.linkedAssociates === true ? 16 : 42))].firstName }}
                                         {{ linkedAssociatesFiltered[(linkedAssIndex-1) + ((index-1)*(printOptions.images.linkedAssociates === true ? 16 : 42))].middleName }}
                                        {{ linkedAssociatesFiltered[(linkedAssIndex-1) + ((index-1)*(printOptions.images.linkedAssociates === true ? 16 : 42))].lastName }}&nbsp;</div>
                                    <div><b>DOB:</b> {{ linkedAssociatesFiltered[(linkedAssIndex-1) + ((index-1)*(printOptions.images.linkedAssociates === true ? 16 : 42))].birthMonth || "XX" }}/{{ linkedAssociatesFiltered[(linkedAssIndex-1) + ((index-1)*(printOptions.images.linkedAssociates === true ? 16 : 42))].birthDay || "XX" }}/{{ linkedAssociatesFiltered[(linkedAssIndex-1) + ((index-1)*(printOptions.images.linkedAssociates === true ? 16 : 42))].birthYear || "XXXX" }}&nbsp;</div>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
            <div v-if="otherAssociatesFiltered !== null && otherAssociatesFiltered.length > 0 && isShow(printOptions.otherInfo.associates)" v-for="index in Math.ceil(otherAssociatesFiltered.length / (printOptions.images.otherAssociates === true ? 16 : 42))" style="display: grid;  page-break-inside: avoid; break-inside: avoid; margin-top: 10px" :key="index + 'otherAssociate'">
                <h5 style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px; margin-top: 10px">
                    OTHER ASSOCIATES
                </h5><br>
                <b-row class="m-0 p-0">
                    <b-col v-for="otherAssIndex in (printOptions.images.otherAssociates === true ? 16 : 42)" :key="otherAssIndex" sm="6" class="m-0 p-0">
                        <b-card v-if="otherAssociatesFiltered[(otherAssIndex-1) + ((index-1)*(printOptions.images.otherAssociates === true ? 16 : 42))]" no-body style="margin: 0 5px 10px 5px; padding: 0;">
                            <b-row no-gutters>
                                <b-col sm="4" v-if="printOptions.images.otherAssociates === true" style="height: 170px" class="p-0 m-0">
                                    <b-card-img v-auth-image="getObjectImage(otherAssociatesFiltered[(otherAssIndex-1) + ((index-1)*16)].images)"
                                                style="object-fit: contain; height: 100%; width: 100%"/>
                                </b-col>
                                <b-col :sm="printOptions.images.otherAssociates === true ? '8' : '12'" class="m-0 d-flex justify-content-center align-items-center flex-column"
                                       style="padding-left: 10px; min-height: 60px">
                                    <div>{{ otherAssociatesFiltered[(otherAssIndex-1) + ((index-1)*(printOptions.images.otherAssociates === true ? 16 : 42))].firstName }}
                                        {{ otherAssociatesFiltered[(otherAssIndex-1) + ((index-1)*(printOptions.images.otherAssociates === true ? 16 : 42))].middleName }}
                                        {{ otherAssociatesFiltered[(otherAssIndex-1) + ((index-1)*(printOptions.images.otherAssociates === true ? 16 : 42))].lastName }}&nbsp;</div>
                                    <div><b>DOB:</b> {{ otherAssociatesFiltered[(otherAssIndex-1) + ((index-1)*(printOptions.images.otherAssociates === true ? 16 : 42))].birthMonth || "XX" }}/{{ otherAssociatesFiltered[(otherAssIndex-1) + ((index-1)*(printOptions.images.otherAssociates === true ? 16 : 42))].birthDay || "XX" }}/{{ otherAssociatesFiltered[(otherAssIndex-1) + ((index-1)*(printOptions.images.otherAssociates === true ? 16 : 42))].birthYear || "XXXX" }}&nbsp;</div>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
            <div v-if="profile.notes && profile.notes.length > 0 && isShow(printOptions.otherInfo.notes)"
                 style="display: grid;  page-break-inside: avoid; break-inside: avoid; margin-top: 10px">
                <h5 style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px">
                    NOTES
                </h5>
                <div style="padding: 0 7px 0 7px">
                    <div v-for="(note, index) in removeData(profile.notes, 'notes')">
                        <b>{{ index + 1 }})</b> {{ note.text }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as moment from 'moment';
import risc from '@/services/risc';
import PubSub from 'pubsub-js';

export default {
    name: 'ProfilePrint',
    props: {
        printOptions: {type: Object},
        profile: {type: Object},
        alias: {type: String},
        dateOfBirth: {type: String},
        height: {type: String},
        name: {type: String},
        ribbons: {type: Array},
        streetName: {type: String},
        weight: {type: Number},
    },
    data: () => {
        return {
            pubsub: null,
            linkedAssociatesFiltered: null,
            otherAssociatesFiltered: null,
            markingsFiltered: null,
            vehiclesFiltered: null,
            addressesFiltered: null,
            imageCount: 0,
            imagesDone: false,
            pageDone: false,
            noDataText: "NO RECORDED DATA",
            centerText: "w-100 d-flex justify-content-center",
            printOptionsReady: false,
            profileCopy: {
                leReports: [{
                    involvements: []
                }]
            },
        }
    },
    created() {
        this.linkedAssociatesFiltered = this.removeData(this.profile.profileGroups, 'linkedAssociates');
        this.otherAssociatesFiltered = this.removeData(this.profile.associates, 'associates');
        this.markingsFiltered = this.removeData(this.profile.markings, 'markings');
        this.vehiclesFiltered = this.removeData(this.profile.vehicles, 'vehicles');
        this.addressesFiltered = this.removeData(this.profile.addresses, 'addresses');
        if (this.printOptions.imagesLength === 0) {
            this.imagesDone = true;
        }
        this.pubsub = PubSub.subscribe('riscImageLoad', () => {
            this.imageCount++;
            if (this.imageCount === this.printOptions.imagesLength) {
                this.imagesDone = true;
            }
        });
    },
    mounted() {
        this.$nextTick(function () {
            this.pageDone = true;
        });
    },
    beforeDestroy() {
        PubSub.unsubscribe(this.pubsub);
    },
    watch: {
        imagesDone() {
            if (this.imagesDone === true && this.pageDone === true) {
                this.$emit('actuallyPrint');
            }
        },
        pageDone() {
            if (this.imagesDone === true && this.pageDone === true) {
                this.$emit('actuallyPrint');
            }
        },
    },
    methods: {
        actuallyPrint() {},
        getObjectImage(images, notProfile) {
            if (images && images.length > 0) {
                return this.getFirstDocumentImage(images);
            } else if (notProfile) {
                return '/img/NoImageAvailableBlack.png';
            }
            return ('/img/NoPictureOnFileGrey.png');
        },
        getFirstDocumentImage(docArray) {
            let stId = 0;
            docArray.forEach(doc => {
                if (doc.arrayOrder === 0) {
                    stId = doc.storageFileName;
                }
            });
            if (stId !== 0) {
                return risc.getImage(stId);
            } else {
                return risc.getImage(docArray[0].storageFileName);
            }
        },
        removeData(arr, field) {
            let result = []
            arr.forEach((e, i) => {
                if (this.printOptions.otherInfo[field].children[i].show) {
                    result.push(Object.assign({}, e));
                }
            })
            return result;
        },
        isShow(obj) {
            return obj.show && obj.children.some(_ => {return _.show});
        },
        dateToString(date) {
            if (date === '0001-01-01T00:00:00') {
                return '';
            } else {
                return (moment(date).format('L'));
            }
        },
        getImage(image) {
            return risc.getImage(image);
        },
        ribbonVariant(ribbon) {
            switch (ribbon) {
                case 'IN CUSTODY':
                    return 'success';
                case 'PAROLE/PROBATION':
                    return 'warning text-dark';
                case 'WANTED':
                    return 'danger';
                case 'OUT OF TOWN':
                    return 'warning text-dark';
                case 'DECEASED':
                    return 'dark';
                default:
                    return 'primary';
            }
        },
    },

};
</script>

<style scoped>
.centerText {
    width: 100%;
    justify-content: center;
    display: flex
}


</style>