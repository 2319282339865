<template>
    <div>
        <b-card bg-variant="dark" header-tag="header" no-body :style="'width: 100%; height: ' + height + 'px; margin: 0;'">
            <div slot="header">Map</div>
            <gmap-map ref="myMap" :center="center" :options="mapStyle" :zoom="11" style="height: 100%; width: 100%">
                <gmap-info-window :opened="infoWinOpen" :options="infoOptions"
                                  :position="infoWindowPos" @closeclick="infoWinOpen=false">
                    <b-link :href="infoLink" target="_blank">{{infoContent}}</b-link>
                </gmap-info-window>
                <span v-for="(m, index) in markers">
                    <gmap-marker :icon="'https://maps.google.com/mapfiles/ms/icons/' + m.color + '-dot.png'"
                                 :clickable="true" :draggable="m.draggable" :key="index + 'report'"
                                 :label="m.label"
                                 :position="m.position" :title="m.title"
                                 @click="center=m.position; linkToGoogleMaps(m)"
                                 @mouseover="toggleInfoWindow(m)"
                                 @mouseout="markerWindow.open=false">
                    </gmap-marker>
                </span>
                <gmap-info-window
                    :opened="markerWindow.open"
                    @closeclick="markerWindow.open=false"
                    :position="markerWindow.position"
                    :options="{
                        pixelOffset: {
                            width: 0,
                            height: -35
                        }
                     }">
                    <div style="color: black;">
                        {{ markerWindow.address }}
                    </div>
                </gmap-info-window>
            </gmap-map>
        </b-card>
    </div>
</template>

<script>
import fcr from "@/services/fcr";
import PubSub from "pubsub-js";
import {gmapApi} from "vue2-google-maps";
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import axios from 'axios';

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDiNNTEEV7dTzuxZW1NCvR26RmHmOUhg2U',
        libraries: 'places'
    }
});

export default {
    name: "MapComponent",
    props: {
        height: Number
    },
    computed: {
        google() {
            return gmapApi;
        }
    },
    created() {
        this.pubsub = PubSub.subscribe('addaddressmarker', (msg, data) => {
            this.addAddressMarker(data.address, data.color);
        });
    },
    beforeDestroy() {
        PubSub.unsubscribe(this.pubsub);
    },
    data() {
        return {
            pubsub: null,
            markers: [],
            center: {lat: 42.2711, lng: -89.0940},
            markerWindow: {
                open: false,
                position: {lat: 0, lng: 0},
                address: ''
            },
            bounds: [],
            infoContent: '',
            infoLink: '',
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            infoWindowPos: {
                lat: 0,
                lng: 0
            },
            infoWinOpen: false,
            mapStyle: {
                styles: [
                    {
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#212121"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#212121"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.country",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.locality",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#bdbdbd"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#181818"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#616161"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#1b1b1b"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#2c2c2c"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#8a8a8a"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#373737"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#3c3c3c"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#20a8d8"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway.controlled_access",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#4e4e4e"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#616161"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#000000"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#3d3d3d"
                            }
                        ]
                    }
                ]
            }
        };
    },
    methods: {
        addAddressMarker(address, color) {
            let addressQuery = '';
            if (address.address1 !== '') {
                addressQuery = address.address1.replace('&', 'and') + ', ' + address.city + ', ' + address.state + ', ' + address.zip;
            } else {
                addressQuery = address.city + ', ' + address.state + ', ' + address.zip;
            }
            addressQuery = addressQuery.toLowerCase();
            if (!this.markers.some(_ => _.position.formatted_address === addressQuery)) {
                axios.create({
                    baseURL: 'https://maps.googleapis.com'
                }).post('/maps/api/geocode/json?address=' + addressQuery + '&key=' + 'AIzaSyDiNNTEEV7dTzuxZW1NCvR26RmHmOUhg2U').then(response => {
                    if (response.data.results[0]) {
                        const marker = {
                            lat: response.data.results[0].geometry.location.lat,
                            lng: response.data.results[0].geometry.location.lng,
                            formatted_address: addressQuery
                        }
                        this.markers.push({position: marker, color: color});
                    }
                })
            }
        },
        fitMapBounds() {
            this.$gmapApiPromiseLazy().then(() => {
                // eslint-disable-next-line no-undef
                this.bounds = new google.maps.LatLngBounds();
                this.markers.forEach(marker => {
                    this.bounds.extend(marker.position);
                })
                this.$refs.myMap.$mapObject.fitBounds(this.bounds);
            })
        },
        linkToGoogleMaps(marker) {
            window.open('https://www.google.com/maps/search/?api=1&query=' + marker.position.formatted_address);
        },
        toggleInfoWindow(marker) {
            this.markerWindow.position = {lat: marker.position.lat, lng: marker.position.lng};
            this.markerWindow.address = marker.position.formatted_address;
            this.markerWindow.open = true;
        },
    },
    watch: {
        markers() {
            this.fitMapBounds();
        }
    }
}
</script>

<style scoped>

</style>