<template>
    <div v-if="weapons !== undefined">

        <!--Add Weapon history Notes-->
        <b-modal  class="modal-dark" scrollable title="Notes" v-model="weaponNoteAddFlag" no-close-on-backdrop>
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <b-form-textarea v-model="weaponNoteAdd"></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button @click="weaponNoteAddFlag = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="putWeaponNote()" :disabled="!weaponNoteAdd || disableButtonFlag" size="sm" variant="primary">Submit</b-button>
            </template>
        </b-modal>

        <!--Edit Weapon History Note-->
        <b-modal v-model="weaponNoteEditFlag" no-close-on-backdrop scrollable title="Edit Note" class="modal-dark">
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <b-form-textarea v-model="editNoteText"></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button @click="weaponNoteEditFlag = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="editWeaponHistoryNote()" size="sm" variant="primary" :disabled="disableButtonFlag">Submit</b-button>
            </template>
        </b-modal>

        <!--Delete Weapon History Note-->
        <b-modal class="modal-dark" scrollable title="Remove Item" v-model="deleteObjects.modal" no-close-on-backdrop>
            <p>Are you sure you want to delete this {{deleteObjects.name}}?</p>
            <template slot="modal-footer">
                <b-button @click="deleteObjects.modal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="deleteWeaponNote()" size="sm" variant="danger">Delete {{deleteObjects.name}}</b-button>
            </template>
        </b-modal>

        <!--Add Weapon -->
        <b-modal class="modal-dark" scrollable title="Weapon" v-model="weaponAddFlag" no-close-on-backdrop>
            <b-input-group class="input-group-sm" prepend="Source" style="margin-bottom: 1px">
                <b-form-input
                    :class=" 'form-control is-' + (filledOut(weaponAdd.source) ? 'valid':'invalid')"
                    v-model="weaponAdd.source"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Description" style="margin-bottom: 1px">
                <b-form-input
                    :class=" 'form-control is-' + (filledOut(weaponAdd.description) ? 'valid':'invalid')"
                    v-model="weaponAdd.description"/>
            </b-input-group>
            <br>
            <b-form-group>
                <label>Other Notes</label>&nbsp;
                <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(weaponAdd.notes, {text: ''})"/>
                <b-input-group :key="i" class="input-group-sm" v-for="(e, i) in weaponAdd.notes">
                    <b-form-input v-model="weaponAdd.notes[i].text"/>
                    <b-input-group-append>
                        <b-button @click="splice(i, weaponAdd.notes)" variant="dark">
                            <fa-icon :icon="['fas', 'close']"/>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="weaponAddFlag = false" size="sm" variant="secondary">Cancel
                </b-button>
                <b-button :disabled="disableWeaponsSubmit() || disableButtonFlag" @click="postWeapons()" size="sm" variant="primary">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <!--Main Body-->
        <b-card  no-body style="height: fit-content; position: relative; z-index: 1; border-color: #0e0e0e; background-color:#0e0e0e"
                 class="flex-grow-1 d-flex flex-column w-100">
            <div class="d-flex flex-row justify-content-between align-items-center" style="padding: 5px">
                <strong style="font-size: large" class="m-0 p-0">Weapon History</strong>
                <div  class="custom-control custom-switch" style="padding: 0">
                    NO <b-form-checkbox style="display: inline;" v-model="localHasWeaponHistory" @change="editWeaponHistory" switch/>YES
                </div>
            </div>
            <div class="d-flex flex-column" style="height: 100%">
                <div v-if="weaponNotes && weaponNotes.length < 1"
                     style="display: flex; align-items: center; width: 100%; height: 100%;">
                    <span style="text-align: center; width: 100%;">
                        <span style="cursor: pointer;" @click="openModalAddWeaponNote()">
                            <fa-icon :icon="['fas', 'circle-plus']" style="padding-top: 10px;"/>
                            Add Note
                        </span>
                    </span>
                </div>
                <div v-else style="overflow-y: auto; padding: 5px" >
                    <div v-if="noteExpand === true">
                        <div>
                            <div v-for="(note, index) in weaponNotes" :key="index">
                                <b-card no-body style="margin-bottom: 5px">
                                    <div class="d-flex flex-wrap-reverse" style="height: fit-content; padding-left:5px">
                                        <div style="margin-bottom: 0;overflow-y: auto"><strong>{{(index+1) + ") "}}</strong>{{note.text}}</div>
                                        <span style="flex-grow: 1; margin: 3px" class="d-flex justify-content-end">
                                            <fa-icon :icon="['fas', 'pen-to-square']" @click="changeWeaponNoteText(note.text, note.noteId)"
                                                     style="margin: 2px; cursor: pointer;"/>
                                            <fa-icon :icon="['fas', 'close']" @click="deleteWeaponNoteModal(index, note.noteId)"
                                                     style="margin: 2px; cursor: pointer;"/>
                                        </span>
                                    </div>
                                    <div class="text-muted text-uppercase font-weight-bold font-xs"
                                         style=" float: right; padding-left: 5px;">Created By: {{note.createdBy}}
                                    </div>
                                </b-card>
                            </div>
                        </div>
                        <div style="text-align: center">
                            <span @click="openModalAddWeaponNote()" style="margin: 0; cursor: pointer">
                                <fa-icon :icon="['fas', 'circle-plus']" style="padding-top: 10px"/>
                                &nbsp;Add Note
                            </span>
                        </div>
                        <p style="text-align: center; margin: 0; cursor: pointer;" @click="noteExpand = false">
                            <fa-icon :icon="['fas', 'angle-up']" style="text-align: center"/>
                        </p>
                    </div>
                    <div v-else style="text-align: center; cursor: pointer;" @click="noteExpand = true">
                        <fa-icon :icon="['fas', 'angle-down']"/>
                    </div>
                </div>
            </div>
        </b-card>

        <div style="display: flex; align-content: space-between; align-items: center; width: 100%; margin-bottom: 15px;">
            <span v-if="editable === true" class="d-flex align-items-center" style="cursor: pointer;" @click="openModalAddWeapons()">
                <fa-icon :icon="['fas', 'plus']" style="margin-right: 5px"/> Add Weapon
            </span>
            <span style="margin-left: auto" @click="getImages = !getImages">
                <b-button size="sm">Get Images</b-button>
            </span>
        </div>
        <p v-if="weapons.length === 0" class="noAdded"><i><strong>No Weapons Added</strong></i></p>
        <div :key="index" lg="12" v-for="(weapon, index) in weapons">
            <WeaponsComponent :weaponObject="weapon"
                              :index="index"
                              :scope="scope"
                              :linkId="linkId"
                              :editable="true"
                              :getImages="getImages"
                              @deleteWeapon="deleteWeapon"/>
        </div>
    </div>
</template>

<script>
import WeaponsComponent from "@/views/expansion_modules/risc/components/weapons_components/WeaponsComponent";
import risc from "@/services/risc";
export default {
    name: "WeaponsTabComponent",

    props: {
        weapons: {
            Object
        },
        scope: {
            String
        },
        linkId: {
            String
        },
        editable: {
            Boolean
        },
        weaponNotes: {
            Array
        },
        hasWeaponHistory: {
            Boolean
        },
    },

    components: {
        WeaponsComponent
    },

    created() {
        this.localHasWeaponHistory = this.hasWeaponHistory;
    },

    data() {
        return {
            getImages: false,
            weaponAdd: {notes: []},
            weaponAddFlag: false,
            weaponNoteAdd: '',
            weaponNoteAddFlag: false,
            localHasWeaponHistory: null,
            disableButtonFlag: false,
            modifyProfile: {},
            weaponNoteEditFlag: false,
            editNoteText: '',
            editNoteId: null,
            deleteObjects: {
                modal: false,
                name: 'Weapon History',
                deleteText: '',
                id: '',
            },
            noteExpand: false,
        }
    },

    methods: {
        changeWeaponNoteText(text, id) {
            this.editNoteText = text;
            this.editNoteId = id;
            this.weaponNoteEditFlag = true;
        },
        deleteWeaponNote() {
            this.$emit("deleteWeaponNote", this.deleteObjects.id);
            this.deleteObjects.modal = false;
        },
        disableWeaponsSubmit() {
            return !this.filledOut(this.weaponAdd.source) ||
                !this.filledOut(this.weaponAdd.description);
        },
        editWeaponHistory(){
            this.$emit("editWeaponHistory", this.localHasWeaponHistory);
        },
        editWeaponHistoryNote() {
            this.$emit("editWeaponNote", this.editNoteText, this.editNoteId);
            this.weaponNoteEditFlag = false;
        },
        filledOut(i) {
            return !(i === null || i === undefined || i === '' || i === 0);
        },
        openModalAddWeaponNote() {
            this.weaponNoteAdd = '';
            this.weaponNoteAddFlag = true;
        },
        openModalAddWeapons() {
            this.weaponAdd = {notes: []};
            this.weaponAddFlag = true;
        },
        deleteWeapon(weaponId) {
            this.$emit('deleteWeapon', weaponId);
        },
        deleteWeaponNoteModal(index, id) {
            this.deleteObjects.index = index;
            this.deleteObjects.id = id;
            this.deleteObjects.modal = true;
        },
        postWeapons() {
            this.disableButtonFlag = true;
                delete this.weaponAdd.weaponId;
                risc.postWeapon(this.weaponAdd).then(response => {
                    this.weaponAdd.weaponId = response.data.weaponId;
                    risc.linkProfileWeapon(this.linkId, this.weaponAdd.weaponId).then(() => {
                        this.weapons.push(response.data);
                        this.disableButtonFlag = false;
                    }).catch(err => {
                        this.disableButtonFlag = false;
                    })
                })
            this.weaponAddFlag = false;
        },
        push(field, item) {
                field.push(JSON.parse(JSON.stringify(item)));
        },
        putWeaponNote() {
                this.$emit("addWeaponNote", this.weaponNoteAdd);
                this.weaponNoteAddFlag = false;
                this.weaponNoteAdd = '';
        },
        splice(index, field, item) {
            field.splice(index, 1);
            if (item && field.length === 0) {
                this.push(field, item);
            }
        },

    }
}
</script>

<style scoped>

.noAdded {
    text-align: center;
    margin-bottom: 0;
    color: grey
}

</style>