<template>
    <div v-if="documents !== null">

        <!--File Selector-->
        <FileSelector2 v-if="editable" :flag="fileSelectorFlag" :files="documents" :submitMethod="submitSelectorMethod" :closeMethod="closeSelector"/>

        <!--Add Document Modal-->
        <FileUpload2 v-if="editable" :flag="fileUploadFlag" :closeMethod="closeUpload" :submitMethod="submitUploadMethod"/>

        <!--Main Body-->
        <div v-if="editable === true" style="text-align: center; width: 100%; margin-bottom: 10px;">
            <span style="cursor: pointer;" @click="openUpload()">
                <fa-icon :icon="['fas', 'plus']"/> Add Document/Media
            </span>
        </div>
        <div style="padding-left: 10px">
            <DocumentTableComponent :editable="editable" :documents="documents" :editMethod="editMethod" :deleteMethod="deleteMethod"/>
        </div>
    </div>
</template>

<script>

import DocumentTableComponent from "@/views/expansion_modules/risc/components/documents_components/DocumentTableComponent";
import FileSelector2 from "@/views/expansion_modules/risc/components/utility_components/FileSelector2.0";
import FileUpload2 from "@/views/expansion_modules/risc/components/utility_components/FileUpload2.0";

export default {
    name: "DocumentTabComponent",
    components: {
        DocumentTableComponent,
        FileSelector2,
        FileUpload2
    },
    props: {
        editable: { type: Boolean },
        documents: { type: Array },
        editMethod: { type: Function },
        deleteMethod: { type: Function },
        submitSelectorMethod: { type: Function },
        submitUploadMethod: { type: Function }
    },
    data() {
        return {
            fileSelectorFlag: false,
            fileUploadFlag: false,
        };
    },
    methods: {
        closeSelector() {
            this.fileSelectorFlag = false;
        },
        closeUpload() {
            this.fileUploadFlag = false;
        },
        openSelector() {
            this.fileSelectorFlag = true;
        },
        openUpload() {
            this.fileUploadFlag = true;
        },
    }
}
</script>

<style scoped>

</style>