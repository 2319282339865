import risc from "@/services/risc";
import PubSub from 'pubsub-js';

export const printSetupMixin = {
    props: {
        profile: {type: Object},
        open: {type: Boolean},
    },
    data: () => {
        return {
            pubsub: null,
            printing: false,
            options: null
        }
    },
    created() {
        this.pubsub = PubSub.subscribe('riscPrintDone', () => {
            this.printing = false;
        });
    },
    beforeDestroy() {
        PubSub.unsubscribe(this.pubsub);
    },
    methods: {
        print(num) {
            this.printing = true;
            this.options.type = num;
            //this.$emit('printOptions', this.options);
            this.$emit('print', this.options);
        },
        close(num) {
            this.$bvModal.hide('print-doc-' + num)
            this.$emit('closed');
        },
        getOneNote(notes) {
            if (Array.isArray(notes) && notes[0]) {
                return notes[0].text;
            }
            return undefined;
        },
        nameify(profile) {
            if (profile.firstName && profile.lastName && profile.middleName) {
                return (profile.lastName + ', ' + profile.firstName + ' ' + profile.middleName.slice(0, 1))
            } else if (profile.firstName && profile.lastName) {
                return (profile.lastName + ', ' + profile.firstName)
            } else if (profile.firstName && profile.middleName) {
                return ('Unknown, ' + profile.firstName + ' ' + profile.middleName.slice(0, 1))
            } else if (profile.lastName && profile.middleName) {
                return (profile.lastName + ', Unknown ' + profile.middleName.slice(0, 1))
            } else if (profile.firstName) {
                return ('Unknown, ' + profile.firstName)
            } else if (profile.lastName) {
                return (profile.lastName + ', Unknown ')
            } else if (profile.middleName) {
                return ('Unknown, Unknown ' + profile.middleName.slice(0, 1))
            }
            return '';
        },
        addTitle(genericObj, title) {
            return Object.assign(genericObj, {title: title})
        },
        updateOptions() {
            this.options = Object.assign({}, {
                wanted: {
                    caseNumber: "",
                    offense: "",
                    bond: "",
                    notes: "",
                    unit: "",
                    author: "",
                    approvedBy: "",
                    phone: "",
                    otherObjects: [],
                },
                images: {
                  addresses: true,
                  vehicles: true,
                  markings: true,
                  linkedAssociates: true,
                  otherAssociates: true,
                },
                name: true,
                image: true,
                ribbon: true,
                general: {
                    show: true,
                    streetnames: {show: true, children: []},
                    employer: true,
                    dob: true,
                    race: true,
                    sex: true,
                    height: true,
                    weight: true,
                    ssn: true,
                    mid: true,
                    sid: true,
                    fbi: true,
                    ids: {show: true, children: []},
                    activeAddresses: {size: 0, show: true, children: []},
                    alias: {show: true, children: []},
                    orgs: {show: true, children: []},
                    phone: {show: true, children: []},
                    social: {show: true, children: []},
                    addresses: {show: true, children: []},
                },
                otherInfo: {
                    show: true,
                    addresses: {show: true, children: []},
                    vehicles: {show: true, children: []},
                    felonies: {show: true, children: []},
                    criminalHistory: {show: true, children: []},
                    drugs: {show: true, children: []},
                    weapons: {show: true, children: []},
                    leReports: {show: true, children: []},
                    markings: {show: true, children: []},
                    associates: {show: true, children: []},
                    linkedAssociates: {show: true, children: []},
                    notes: {show: true, children: []},
                },
            })
            this.options.wanted.otherObjects = [];
            this.profile.streetName.forEach((e, i) => {
                this.options.general.streetnames.children[i] = Object.assign({}, {
                    name: e.streetName,
                    show: true
                })
            })
            this.profile.alias.forEach((e, i) => {
                this.options.general.alias.children[i] = Object.assign({}, {
                    name: e.knownAlias,
                    show: true
                })
            })
            this.profile.organizations.forEach((e, i) => {
                this.options.general.orgs.children[i] = Object.assign({}, {
                    name: e.orgName,
                    show: true
                })
            })
            this.profile.phone.forEach((e, i) => {
                this.options.general.phone.children[i] = Object.assign({}, {
                    name: e.number,
                    show: true
                })
            })
            this.profile.addresses.forEach((e, i) => {
                this.options.general.activeAddresses.children[i] = Object.assign({}, {
                    name: e.address1 + (e.address2 ? (" " + e.address2) : "") + ", " + e.city + ", " + e.state + " " + e.zip,
                    active: !!(e.active),
                    show: !!(e.active)
                })
                this.options.general.activeAddresses.size += e.active ? 1 : 0;
            })
            this.profile.socialMedia.forEach((e, i) => {
                this.options.general.social.children[i] = Object.assign({}, {
                    name: e.userName + " (" + e.site + ")",
                    show: true

                })
            })
            this.profile.identifications.forEach((e, i) => {
                this.options.general.ids.children[i] = Object.assign({}, {
                    name: e.number + " (" + e.type + ")",
                    show: true
                })
            })
            this.profile.addresses.forEach((e, i) => {
                this.options.otherInfo.addresses.children[i] = Object.assign({}, {
                    name: e.address1 + (e.address2 ? (" " + e.address2) : "") + ", " + e.city + ", " + e.state + " " + e.zip,
                    show: true,
                    obj: [(e.address1 || "Address") + e.address2,
                            e.documents,
                            (e.city || "City") + ", " + (e.state || "State"),
                            e.zip || "Zip",
                            e.active? "ACTIVE" : "INACTIVE",
                            this.getOneNote(e.notes),],
                    addressId: e.addressId,
                })
                this.options.general.addresses.children[i] = Object.assign({}, {
                    name: e.address1 + (e.address2 ? (" " + e.address2) : "") + ", " + e.city + ", " + e.state + " " + e.zip,
                    show: true,
                    obj: [(e.address1 || "Address") + e.address2,
                            e.documents,
                            (e.city || "City") + ", " + (e.state || "State"),
                            e.zip || "Zip",
                            e.active? "ACTIVE" : "INACTIVE",
                            this.getOneNote(e.notes)],
                    addressId: e.addressId,
                })
            })
            this.profile.vehicles.forEach((e, i) => {
                this.options.otherInfo.vehicles.children[i] = Object.assign({}, {
                    name: e.color + " " + (e.year !== 0 ? e.year : '') + " " + e.make + " " + e.model + (e.plate ? "(PLATE " + e.plate + ")": ''),
                    show: true,
                    obj: [(e.make || "Make") + " " + (e.model || "Model"),
                            e.documents, (e.year !== 0 ? "Year: " + e.year : "Year: N/A"),
                            "Color: " + (e.color || "N/A"),
                            "Plate: " + (e.plate || "N/A") ,
                            e.description || "No Description"],
                    vehicleId: e.vehicleId,
                })
            })
            this.profile.felonies.forEach((e, i) => {
                this.options.otherInfo.felonies.children[i] = Object.assign({}, {
                    name: e.caseNumber + " (" + e.felonyType + ")",
                    show: true
                })
            })
            this.profile.criminalHistories.forEach((e, i) => {
                this.options.otherInfo.criminalHistory.children[i] = Object.assign({}, {
                    name: e.offense,
                    show: true
                })
            })
            this.profile.drugs.forEach((e, i) => {
                this.options.otherInfo.drugs.children[i] = Object.assign({}, {
                    name: e.description + " (" + e.source + ")",
                    show: true,
                })
            })
            this.profile.weapons.forEach((e, i) => {
                this.options.otherInfo.weapons.children[i] = Object.assign({}, {
                    name: e.description + " (" + e.source + ")",
                    show: true,
                })
            })
            this.profile.leReports.forEach((e, i) => {
                this.options.otherInfo.leReports.children[i] = Object.assign({}, {
                    name: e.leReportNumber + " (" + e.subject + ")",
                    involvement:  [],
                    show: true,
                    leReportId: e.leReportId,
                });
            });
            this.profile.markings.forEach((e, i) => {
                this.options.otherInfo.markings.children[i] = Object.assign({}, {
                    name: e.type + " (" + e.description + ")",
                    show: true,
                })
            })
            this.profile.associates.forEach((e, i) => {
                        this.options.otherInfo.associates.children[i] = Object.assign({}, {
                            name: this.nameify(e) + (e.relation ? " (" + (e.relation) + ")" : ''),
                            show: true,
                            obj: [e.relation,
                                e.documents,
                                this.nameify(e),
                                "DOB: " + (e.birthMonth || "XX") + "/" + (e.birthDay || "XX") + "/" + (e.birthYear || "XX"),
                                e.shortAddress,
                                e.description],
                            otherAssociateId: e.associateId
                })
            })
            this.profile.profileGroups.forEach((e, i) => {
                this.options.otherInfo.linkedAssociates.children[i] = Object.assign({}, {
                    name: this.nameify(e),
                    show: true,
                    obj: [e.relation,
                        e.images,
                        this.nameify(e),
                        "DOB: " + (e.birthMonth || "XX") + "/" + (e.birthDay || "XX") + "/" + (e.birthYear || "XX"),
                        e.description],
                    linkedAssociateId: e.profileId
                })
            })
            this.profile.notes.forEach((e, i) => {
                this.options.otherInfo.notes.children[i] = Object.assign({}, {
                    name: e.text,
                    show: true
                })
            })
            return true;
        }
    }
}