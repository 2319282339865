<template>
    <div v-if="id !== undefined">

        <FileViewer2 :flag="fileViewerFlag" :fileId="fileViewStorageId" :closeMethod="closeViewModal"/>

        <!--Remove Item Modal-->
        <b-modal class="modal-dark" scrollable title="Remove ID" v-model="deleteObjects.modal">
            <p>If you would like to delete this {{deleteObjects.name}} please type the prompt below.</p>
            <br>
            <p style="margin-bottom: 0">Enter "{{deleteObjects.name}}":</p>
            <b-form-group description="case sensitive">
                <b-form-input id="basicName2" type="text" v-model="deleteObjects.deleteText"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="deleteObjects.modal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button :disabled="deleteObjects.deleteText !== deleteObjects.name || disableDeleteFlag" @click="deleteId()"
                          size="sm"
                          variant="danger">Delete {{deleteObjects.name}}
                </b-button>
            </template>
        </b-modal>

        <!-- Edit ID -->
        <b-modal title="Edit ID" class="modal-dark" scrollable v-model="editIdFlag" no-close-on-backdrop>
            <b-input-group class="input-group-sm" prepend="Type" style="margin-bottom: 1px">
                <b-form-select :options="idTypes"
                               :class=" 'form-control is-' + (filledOut(idEdit.type) ? 'valid':'invalid')"
                               v-model="idEdit.type"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Status" style="margin-bottom: 1px">
                <b-form-select :options="idStatus"
                               :class=" 'form-control is-' + (filledOut(idEdit.status) ? 'valid':'invalid')"
                               v-model="idEdit.status"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Number" style="margin-bottom: 1px">
                <b-form-input
                    :class=" 'form-control is-' + (filledOut(idEdit.number) ? 'valid':'invalid')"
                    v-model="idEdit.number" v-uppercase/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="State" style="margin-bottom: 1px">
                <b-form-select
                    :options="IdStates"
                    v-model="idEdit.state"/>
            </b-input-group>
            <br>
            <label class="labelForm">Notes</label>&nbsp;
            <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(idEdit.notes, {text: ''})"/>
            <b-input-group :key="i" class="input-group-sm" v-for="(e,i) in idEdit.notes">
                <b-form-input v-model="idEdit.notes[i].text"/>
                <b-input-group-append>
                    <b-button @click="splice(i, idEdit.notes)" variant="dark">
                        <fa-icon :icon="['fas', 'close']"/>
                    </b-button>
                </b-input-group-append>
            </b-input-group>
            <template slot="modal-footer">
                <b-button @click="editIdFlag = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button :disabled="disableIdSubmit()" @click="updateId()" size="sm" variant="primary">Submit
                </b-button>
            </template>
        </b-modal>

        <!-- Main Card -->
        <b-card no-body style="border-color: #0e0e0e;background-color: #202020">
            <span class="d-flex w-100 justify-content-between" style="background-color: #0E0E0E; padding: 0 5px 0 5px; border-radius: 3px 3px 0 0">
                <b style="font-size: medium">{{id.type}}</b>
                <span v-if="editable === true">
                    <fa-icon :icon="['fas', 'pen-to-square']" @click="openEditModal()" style="cursor: pointer;"/>&nbsp;
                    <fa-icon :icon="['fas', 'close']" @click="openDeleteModal(id.identificationId)" size="lg"
                             style="cursor: pointer; transform: translateY(1px);"/>
                </span>
            </span>
            <b-row style="margin: 0; padding: 0">
                <b-col class="d-flex flex-column"
                       style="margin: 0; padding: 0"
                       :xl="id.documents && id.documents.length > 0 ? 7 : 12">
                    <div style="margin: 5px">
                        <div><strong>Status: </strong>{{id.status}}</div>
                        <div><strong>Number: </strong>{{id.number}}</div>
                        <div><strong>State: </strong>{{id.state}}</div>
                    </div>
                </b-col>
                <b-col style="margin: 0; padding: 0" xl="5"
                       class="d-flex justify-content-center"
                       v-if="id.documents && id.documents.length > 0">
                    <span style="width: auto; max-height: 100px; margin: 5px; border: 2px solid #23282c; border-radius: 5px; background-color: #0E0E0E;"
                          class="image-parent">
                        <img class="img-center-ass"
                             style="border-radius: 5px;"
                             v-if="id.documents && id.documents.length > 0"
                             v-auth-image="getFirstDocumentImage(id.documents)"
                             @click="openViewModal(id.documents[0].storageFileName)"
                             alt=""/>
                        <img v-else class="img-center-ass"
                             style="border-radius: 5px;"
                             src="/NoImageAvailable.png"
                             alt="">
                    </span>
                </b-col>
            </b-row>
            <div v-if="noteExpand === true"
                 style="padding: 5px">
                <NotesCardComponent :notes="id.notes"/>
                <br>
                <DocumentCardComponent :editable="editable"
                                       :documents="id.documents ? id.documents : []"
                                       :editMethod="changeDocumentDesc"
                                       :deleteMethod="deleteEmbeddedDoc"
                                       :submitSelectorMethod="setFileFavorite"
                                       :submitUploadMethod="addIdDocument"/>
                <p style="text-align: center; margin: 0; cursor: pointer;" @click="noteExpand = false">
                    <fa-icon :icon="['fas', 'angle-up']" style="text-align: center;"/>
                </p>
            </div>
            <div v-else style="text-align: center; cursor: pointer;" @click="noteExpand = true">
                <fa-icon :icon="['fas', 'angle-down']"/>
            </div>
        </b-card>
    </div>
</template>

<script>
import NotesCardComponent from "@/views/expansion_modules/risc/components/notes_components/NotesCardComponent";
import DocumentCardComponent from "@/views/expansion_modules/risc/components/documents_components/DocumentCardComponent";
import FileViewer2 from "@/views/expansion_modules/risc/components/utility_components/FileViewer2.0";
import risc from "@/services/risc";

export default {
    name: "IdComponent",
    components: {
        DocumentCardComponent,
        NotesCardComponent,
        FileViewer2
    },
    props: {
        id: { type: Object },
        editable: { type: Boolean }
    },
    data() {
        return {
            disableDeleteFlag: false,
            editIdFlag: false,
            idEdit: {},
            noteExpand: false,
            fileViewStorageId: null,
            fileViewerFlag: false,
            deleteObjects: {
                modal: false,
                name: "ID",
                deleteText: "",
                id: '',
            },
            idTypes: ['CONCEALED CARRY', 'DRIVERS LICENSE', 'FOID CARD', 'STATE ID', 'OTHER'],
            idStatus: ['ACTIVE', 'EXPIRED', 'REVOKED', 'SUSPENDED', 'VALID', 'N/A', 'OTHER'],
            IdStates:['AL','AK','AS','AZ','AR','CA','CO','CT','DE','DC','FM','FL','GA',
                'GU','HI','ID','IL','IN','IA','KS','KY','LA','ME','MH','MD','MA',
                'MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND',
                'MP','OH','OK','OR','PW','PA','PR','RI','SC','SD','TN','TX','UT',
                'VT','VI','VA','WA','WV','WI','WY',"Mexico","Canada","Other"]
        };
    },
    methods: {
        addIdDocument(description, fileId) {
            this.id.documents.push({
                description: description,
                storageFileName: fileId,
                arrayOrder: this.id.documents.length
            })
            this.$emit('updateId', this.id);
        },
        changeDocumentDesc(doc) {
            this.id.documents.forEach(document => {
                if (document.documentId === doc.documentId) {
                    document.description = doc.description
                }
            })
            this.$emit('updateId', this.id);
        },
        closeViewModal() {
            this.fileViewerFlag = false;
        },
        deleteEmbeddedDoc(doc) {
            this.id.documents = this.id.documents.filter((document) => {
                return document.documentId !== doc.documentId;
            });
            this.$emit('updateId', this.id);
        },
        deleteId() {
            this.disableDeleteFlag = true;
            this.$emit('deleteId', this.id);
            this.deleteObjects.modal = false;
        },
        disableIdSubmit() {
            return !this.filledOut(this.idEdit.type) ||
                !this.filledOut(this.idEdit.status) ||
                !this.filledOut(this.idEdit.number);

        },
        filledOut(i){
            return !(i === null || i === undefined || i === '' || i === 0);
        },
        getFirstDocumentImage(docArray) {
            let stId = 0;
            docArray.forEach(doc => {
                if (doc.arrayOrder === 0) {
                    stId = doc.storageFileName;
                }
            })
            if (stId !== 0) {
                return risc.getImage(stId);
            } else {
                return risc.getImage(docArray[0].storageFileName);
            }
        },
        openDeleteModal(id) {
            this.disableDeleteFlag = false;
            this.deleteObjects.modal = true
            this.deleteObjects.deleteText = ''
            this.deleteObjects.id = id
        },
        openEditModal() {
            this.idEdit = JSON.parse(JSON.stringify(this.id));
            this.editIdFlag = true;
        },
        openViewModal(storageId) {
            this.fileViewStorageId = storageId
            this.fileViewerFlag = true;
        },
        push(field, item) {
            field.push(JSON.parse(JSON.stringify(item)));
        },
        setFileFavorite(files) {
            this.id.documents = files
            this.$emit('updateId', this.id);
        },
        splice(index, field) {
            field.splice(index, 1);
        },
        updateId() {
            this.$emit('updateId', this.idEdit);
            this.editIdFlag = false;
        },

    }
}
</script>

<style scoped>

</style>