<template>
     <div style="position: relative">
         <b-input-group>
             <b-form-input autocomplete="off" :disabled="disabled"
                           @keydown.tab="show = false" class="fieldBackgroundColor"
                           @focus="show = true"
                           v-model="inputNew"
                           :class="(show && results.length > 0 ? 'mergeInputs ' : '') + (mergeLeft ? 'mergeLeft ' : '')"
                           :size="size ? size : 'md'"
                           style="border-right: none; outline-width: 0"
                           :style="height === 0 ? '' : 'height:' +  height + 'px;'"
                           @keyup.down="drop()"
                           @keyup.enter.native="drop()"/>
             <div class="input-group-append" :style="height === 0 ? '' : 'height:' +  height + 'px;'">
                 <span class="input-group-text fieldBackgroundColor"
                       style="border-left: none; background-color: #333333; outline-width: 0; cursor: pointer;"
                       :class="(show && results.length > 0 ? 'mergeInputs ' : '') + (mergeRight ? 'mergeRight' : '')">
                     <fa-icon v-if="show && results.length > 0" :icon="['fas', 'caret-up']" @click="show = false"/>
                     <fa-icon v-else-if="arrow" :icon="['fas', 'caret-down']" @click="show = true"/>
                 </span>
             </div>
         </b-input-group>
         <b-form-select v-if="show && results && Array.isArray(results) && results.length > 0" class="input fieldBackgroundColor"
                        :style="'transform: translateY(' + (height / 2) + 'px)'"
                        @keyup.enter.native=""
                        v-model="output" v-show="show"
                        style="z-index: 1000; position: absolute; border-top-left-radius: 0; border-top-right-radius: 0"
                        :upperCase="true" :arrow="false"
                        :size="size ? size : 'md'"
                        :options="results.length < 1 ? ['no results'] : results"
                        ref="select" :select-size="results.length > 5 ? 5 : (results.length < 2 ? 2 : results.length)" />

     </div>
</template>

<script>

    export default {
        name: "formSelectSearch",

        props: {
            height:{
              type: Number,
              default: 0
            },
            disabled: {
                type: Boolean,
                default: false
            },
            options: {
                type: Array,
                default: []
            },
            size: {
                type: String,
            },
            userValue: {
                type: Boolean,
                default: true
            },
            arrow: {
                type: Boolean,
                default: true
            },
            upperCase: {
                type: Boolean,
                default: false
            },
            mergeLeft: {
                type: Boolean,
                default: false
            },
            mergeRight: {
                type: Boolean,
                default: false
            },
            value: {
                required: true
            },
        },

        data: () => {
            return {
                inputValid: false,
                focusInput: false,
                focusSelect: false,
                optionData: [],
                show: false,
                input: '',
                inputV: null,
                inputNew: null,
                inputVNew: null,
                results: [],
                output: '',
                temp: null,
                awaitSearch: false,
                oldInput: '',
                oldInputAlt: '',
            }
        },

        created() {
            this.atCreated()
        },

        updated() {
        },

        methods: {
            atCreated(){
                this.inputNew = this.value
                if (this.upperCase) {
                    this.optionData = this.options.map(e => e.text ? e.text.toUpperCase() : e.toUpperCase()).sort((a,b) => {return this.sortify(a,b)});
                    this.results = this.optionData
                } else {
                    this.optionData = this.options.map(e => e.text ? e.text : e).sort((a,b) => {return this.sortify(a,b)});
                    this.results = this.optionData
                }
            },
            search(data){
                if(data && Array.isArray(data) && data.length > 0 && this.input.length > 0){
                    let results = [];
                    results = data.filter(e => e.includes(this.input))
                    this.results = this.insertionSort(results);
                }else{
                    this.results = data;
                }
            },
            sortify(a,b){
                if(a.startsWith(this.input) && !b.startsWith(this.input)){
                    return  -1;
                }else if (!a.startsWith(this.input) && b.startsWith(this.input)){
                    return 1;
                }
                return 0
            },
            drop(){
                if(this.results && this.results[0]){
                    this.$refs.select.focus()
                    this.output = this.results[0]
                    this.$refs.select.$forceUpdate()
                }
            },
            findObj(text){
                let result;
                if(this.upperCase){
                    result = this.options.find(i => typeof i === 'object' ? (i.text.toUpperCase() === text || i.value === text) : (text.toUpperCase() === i))
                    return(result ? {text: result.text.toUpperCase(), value: result.value} : text.toUpperCase())
                }
                result = this.options.find(i => typeof i === 'object' ? (i.text === text || i.value === text) : (text === i))
                return(result ? {text: result.text, value: result.value} : {text: result.text, value: result.value})

            },
            searchTxt(txt){
                let result;
                if (txt) {
                    txt = txt.toString()
                }
                result = this.options.find(i => typeof i === 'object' ? ((this.upperCase ? txt.toUpperCase() : txt) === i.text) : ((this.upperCase ? txt.toUpperCase() : txt) === i))
                return(result ? result : false)
            },
            searchVal(val){
                let result;
                if (val) {
                    val = val.toString()
                }
                result = this.options.find(i => typeof i === 'object' ? (val === i.value.toString()) : ((this.upperCase ? val.toUpperCase() : val) === i.toString()))
                return(result ? result : false)
            },
            insertionSort(arr){
                let length = arr.length;
                for (let i = 1; i < length; i++) {
                    let key = arr[i];
                    let j = i - 1;
                    while (j >= 0 && this.sortify(arr[j], key) > 0) {
                        arr[j + 1] = arr[j];
                        j = j - 1;
                    }
                    arr[j + 1] = key;
                }
                return arr;
            }
        },

        watch: {
            value(){
                if(this.value){
                    this.inputNew = this.value
                }
            },
            output(){
                if(this.output){
                    this.inputNew = this.output
                }
            },
            inputNew(){
                let go = 1
                while (go === 1) {
                    let searchVal = this.searchVal(this.inputNew)
                    let searchTxt = this.searchTxt(this.inputNew)
                    let uppC = ((typeof this.inputNew === "string") && this.upperCase)
                    if ((uppC ? this.inputNew.toUpperCase() : this.inputNew) === this.input || (this.inputNew === this.inputV)) {
                        this.inputNew = this.input
                        go = 2
                    } else if (!this.inputNew) {
                        this.input = '';
                        this.inputV = null;
                        this.results = this.optionData
                        this.oldInput = ''
                        this.$emit('input', '')
                        go = 3
                    } else if (searchVal) {
                        this.inputV = JSON.parse(JSON.stringify(this.inputNew));
                        this.input = JSON.parse(JSON.stringify(searchVal.text ? searchVal.text : searchVal));
                        this.$emit('input', this.inputV)
                        go = 4
                    } else if (this.upperCase && (uppC ? this.inputNew.toUpperCase() : this.inputNew) !== this.inputNew ) {
                        this.inputNew = (uppC ? this.inputNew.toUpperCase() : this.inputNew)
                    } else if (searchTxt) {
                        this.inputV = JSON.parse(JSON.stringify(searchTxt.value ? searchTxt.value : null));
                        this.input = JSON.parse(JSON.stringify(this.inputNew))
                        this.$emit('input', this.inputV)
                        go = 5
                    } else {
                        this.input = JSON.parse(JSON.stringify(this.inputNew))
                        this.inputV = JSON.parse(JSON.stringify(this.inputNew))
                        if (!this.userValue) this.$emit('input', '')
                        go = 0
                    }
                }
                if (go === 4) {
                    this.inputValid = true
                } else {
                    this.inputValid = false
                }

                if (go === 0) {
                    if (this.input.startsWith(this.oldInput)) {
                        if (this.userValue) {
                            this.$emit('input', this.inputV ? this.inputV : this.input)
                        }
                        this.search(this.results)
                        this.oldInput = this.input
                    } else {
                        if (this.userValue) {
                            this.$emit('input', this.inputV ? this.inputV : this.input)
                        }
                        this.search(this.optionData)
                        this.oldInput = this.input
                    }
                }
            },
            focusInput(){
                this.show = this.focusSelect || this.focusInput;
            },
            focusSelect(){
                this.show = this.focusSelect || this.focusInput;
            },
            options() {
                if (this.upperCase === true) {
                    this.optionData = this.options.map(e => e.text ? e.text.toUpperCase() : e.toUpperCase());
                    this.atCreated();
                } else {
                    this.optionData = this.options.map(e => e.text ? e.text : e);
                    this.atCreated();
                }
            }
        }
    }
</script>

<style scoped>
    .mergeLeft{
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
    .mergeRight{
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
    .mergeInputs{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-width: 0
    }
    :focus{
        box-shadow: none;
        border-color: rgb(35, 40, 44);
    }
</style>

<style>
.invalidInput {
    color: #ff2828
}
.invalidInput:focus {
    color: #ff2828
}
</style>