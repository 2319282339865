<template>
    <div v-if="drug !== undefined">

        <!--View Files-->
        <FileViewer2 :flag="fileViewerFlag" :fileId="fileViewStorageId" :closeMethod="closeViewModal"/>

        <!--Edit Drug-->
        <b-modal class="modal-dark" scrollable title="Drug" v-model="drugInfo.postPut" no-close-on-backdrop>
            <b-input-group class="input-group-sm" prepend="Source" style="margin-bottom: 1px">
                <b-form-input
                    :class=" 'form-control is-' + (filledOut(drugInfo.data.source) ? 'valid':'invalid')"
                    v-model="drugInfo.data.source"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Description" style="margin-bottom: 1px">
                <b-form-input
                    :class=" 'form-control is-' + (filledOut(drugInfo.data.description) ? 'valid':'invalid')"
                    v-model="drugInfo.data.description"/>
            </b-input-group>
            <br>
            <b-form-group>
                <label>Other Notes</label>&nbsp;
                <fa-icon :icon="['fas', 'circle-plus']" @click="push(drugInfo.data.notes, {text: ''})"/>
                <b-input-group :key="i" class="input-group-sm" v-for="(e,i) in drugInfo.data.notes">
                    <b-form-input v-model="drugInfo.data.notes[i].text"/>
                    <b-input-group-append>
                        <b-button @click="splice(i, drugInfo.data.notes)" variant="dark">
                            <fa-icon :icon="['fas', 'close']"/>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="drugInfo.postPut = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button :disabled="disableDrugsSubmit()" @click="putDrugs()" size="sm"
                          variant="primary">Submit
                </b-button>
            </template>
        </b-modal>

        <!--Delete Drug-->
        <b-modal class="modal-dark" scrollable title="Remove Drug" v-model="deleteObjects.modal"  no-close-on-backdrop>
            <p>If you would like to delete this {{ deleteObjects.name }} please type the prompt below.</p>
            <br>
            <p style="margin-bottom: 0">Enter "{{ deleteObjects.name }}":</p>
            <b-form-group description="case sensitive">
                <b-form-input id="basicName2" type="text" v-model="deleteObjects.deleteText"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="deleteObjects.modal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button :disabled="deleteObjects.deleteText !== deleteObjects.name || disableDeleteFlag"
                          @click="deleteObjectsMethod()"
                          size="sm" variant="danger">
                    Delete {{ deleteObjects.name }}
                </b-button>
            </template>
        </b-modal>

        <!--Main Body-->
        <b-card no-body style="border-color: #0e0e0e;background-color: #202020">
            <span class="d-flex w-100 justify-content-between"
                  style="background-color: #0E0E0E; padding: 0 5px 0 5px; border-radius: 3px 3px 0 0">
                <b style="font-size: medium">Drug #{{ index + 1 }}</b>
                <span v-if="editable === true">
                    <fa-icon :icon="['fas', 'pen-to-square']" @click="openModalEditDrugs(drug)" style="cursor: pointer;"/>&nbsp;
                    <fa-icon :icon="['fas', 'close']" @click="openModalDeleteObject(drug.drugId)" style="cursor: pointer; transform: translateY(1px)" size="lg"/>
                </span>
            </span>
            <b-row style="margin: 0; padding: 0">
                <b-col class="d-flex flex-column" style="margin: 0; padding: 0"
                       :xl="drug.documents && drug.documents.length > 0 ? 7 : 12">
                    <div style="margin: 5px">
                        <div><strong>Description: </strong> {{ drug.description }}</div>
                        <div><strong>Source: </strong>{{ drug.source }}</div>
                    </div>
                </b-col>
                <b-col style="margin: 0; padding: 0" xl="5"
                       class="d-flex justify-content-center"
                       v-if="drug.documents && drug.documents.length > 0">
                    <span
                        style="width: auto; max-height: 100px; margin: 5px; border: 2px solid #23282c; border-radius: 5px; background-color: #0E0E0E;"
                        class="image-parent">
                        <img class="img-center-ass" style="border-radius: 5px;"
                             v-if="drug.documents && drug.documents.length > 0"
                             v-auth-image="getImage(drug.documents[0].storageFileName)"
                             @click="openViewModal(drug.documents[0].storageFileName)"
                             alt=""/>
                        <img v-else class="img-center-ass"
                             style="border-radius: 5px;"
                             src="/NoImageAvailable.png"
                             alt="">
                    </span>
                </b-col>
            </b-row>
            <div v-if="noteExpand === true" style="padding: 5px">
                <div style="padding: 10px">
                    <NotesCardComponent :notes="drug.notes" :scope="scope"/>
                    <br>
                    <DocumentCardComponent :editable="editable"
                                           :documents="drug.documents ? drug.documents : []"
                                           :editMethod="changeDocumentDesc"
                                           :deleteMethod="deleteEmbeddedDoc"
                                           :submitSelectorMethod="setFileFavorite"
                                           :submitUploadMethod="addDrugDocument"/>
                </div>
                <div style="text-align: center; margin: 0; cursor: pointer;" @click="noteExpand = false">
                    <fa-icon :icon="['fas', 'angle-up']" style="text-align: center"/>
                </div>
            </div>
            <div v-else style="text-align: center; cursor: pointer;" @click="getMoreInfo">
                <fa-icon :icon="['fas', 'angle-down']"/>
            </div>
        </b-card>
    </div>
</template>

<script>
import risc from "@/services/risc";
import NotesCardComponent from "@/views/expansion_modules/risc/components/notes_components/NotesCardComponent";
import DocumentCardComponent
    from "@/views/expansion_modules/risc/components/documents_components/DocumentCardComponent";
import FileViewer2 from '@/views/expansion_modules/risc/components/utility_components/FileViewer2.0';

export default {
    name: "DrugsComponent",

    components: {
        NotesCardComponent,
        DocumentCardComponent,
        FileViewer2
    },

    props: {
        drugObject: {
            Object
        },
        index: {
            Number
        },
        linkId: {
            String
        },
        editable: {
            Boolean
        },
        scope: {
            String
        },
        getImages: {
            type: Boolean
        }
    },

    created() {
        this.drug = this.drugObject;
    },
    data() {
        return {
            drug: null,
            drugInfo: {
                data: {},
                postPut: false,
            },
            notesNewToOld: false,
            deleteObjects: {
                modal: false,
                name: 'Drug',
                deleteText: '',
                id: '',
            },
            fileViewStorageId: null,
            fileViewerFlag: false,
            noteExpand: false,
            moreInfo: false,
            disableDeleteFlag: false,
        }
    },

    methods: {
        closeViewModal() {
            this.fileViewerFlag = false;
        },
        addDrugDocument(description, fileId) {
            this.drug.documents.push({
                description: description,
                storageFileName: fileId,
                arrayOrder: this.drug.documents.length
            })
            risc.putDrug(this.drug).then(() => {
                this.getDrug()
            });
        },
        changeDocumentDesc(doc) {
            this.drug.images.forEach(document => {
                if (document.imageId === doc.imageId) {
                    document.description = doc.description
                }
            })
            risc.putDrug(this.drug).then(() => {
                this.getDrug();
            });
        },
        deleteEmbeddedDoc(doc) {
            this.drug.documents = this.drug.documents.filter((document) => {
                return document.documentId !== doc.documentId;
            });
            risc.putDrug(this.drug).then(() => {
                this.getDrug();
            });
        },
        deleteObjectsMethod() {
            this.disableDeleteFlag = true;
            risc.unlinkProfileDrug(this.linkId, this.deleteObjects.id).then(() => {
                this.deleteObjects.modal = false;
                this.$emit('deleteDrug', this.deleteObjects.id)
            })
        },
        disableDrugsSubmit() {
            return !this.filledOut(this.drugInfo.data.source) ||
                !this.filledOut(this.drugInfo.data.description);

        },
        filledOut(i) {
            return !(i === null || i === undefined || i === '' || i === 0);

        },
        getImage(storageId) {
            return risc.getImage(storageId);
        },
        getDrug(callback) {
            risc.getDrug(this.drug.drugId).then(response => {
                this.drug = response.data;
                this.moreInfo = true;
                if (callback) {
                    callback();
                }
            })
        },
        getMoreInfo() {
            if (this.moreInfo === false) {
                this.getDrug(() => {
                    this.noteExpand = true;
                });
            } else {
                this.noteExpand = true;
            }
        },
        openModalDeleteObject(id) {
            this.disableDeleteFlag = false;
            this.deleteObjects.modal = true;
            this.deleteObjects.deleteText = '';
            this.deleteObjects.id = id;
        },
        openModalEditDrugs() {
            if (this.moreInfo === false) {
                this.getDrug(() => {
                    this.drugInfo.data = Object.assign({}, JSON.parse(JSON.stringify(this.drug)));
                    this.drugInfo.postPut = true;
                });
            } else {
                this.drugInfo.data = Object.assign({}, JSON.parse(JSON.stringify(this.drug)));
                this.drugInfo.postPut = true;
            }
        },
        openViewModal(storageId) {
            this.fileViewStorageId = storageId;
            this.fileViewerFlag = true;
        },
        putDrugs() {
            risc.putDrug(this.drugInfo.data).then(() => {
                this.getDrug();
            })
            this.drugInfo.postPut = false;
        },
        push(field, item) {
            if (this.notesNewToOld === true) {
                field.unshift(JSON.parse(JSON.stringify(item)));
            } else {
                field.push(JSON.parse(JSON.stringify(item)));
            }
        },
        setFileFavorite(files) {
            this.drug.documents = files
            risc.putDrug(this.drug).then(() => {
                this.getDrug();
            });
        },
        splice(index, field, item) {
            field.splice(index, 1);
            if (item && field.length === 0) {
                this.push(field, item)
            }
        },
    },
    watch: {
        getImages() {
            if (this.getImages === true && this.moreInfo === false) {
                this.getDrug();
            }
        },
        drugObject() {
            this.drug = this.drugObject;
        }
    }
}
</script>

<style scoped>

</style>