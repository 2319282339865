<template>

    <!--Upload Document Modal-->
    <b-modal v-model="flag" no-close-on-backdrop scrollable class="modal-dark">

        <template slot="modal-header">
            <h4 class="modal-title">Add Document/Media</h4>
        </template>

        <!--Document Description-->
        <b-input-group prepend="Description" class="input-group-sm" style="margin-bottom: 1px">
            <b-form-input v-model="description"/>
        </b-input-group>

        <!--Input tag shows if upload data image length is 0-->
        <input v-if="upload.dataImageData.length === 0" type="file" id="files" ref="files"
               v-on:change="handleFileUpload()" accept="image/*" style="margin-top: 10px;"/>

        <!--Div showing image uploaded and ability to change the name-->
        <div v-else-if="upload.dataImageData.length !== 0" style="margin-top: 10px; margin-bottom: 10px;">
            <div v-for="(image,index) in upload.dataImageData" :key="index">
                <b-row>
                    <b-col md="3">
                        <img style="width: 110px; height: 70px; object-fit: contain" :src="makeUrl(upload.dataImageData[index])" alt=""/>
                    </b-col>
                    <b-col md="9">
                        <span><b>Name for "{{image.name}}":</b></span>
                        <b-input :placeholder="image.name" v-model="upload.dataImageName[index]"/>
                    </b-col>
                </b-row>
                <br>
            </div>
        </div>

        <div @click="upload.dataImageName = []; upload.dataImageData = ''"
             style="display: inline-block; white-space: nowrap; cursor: pointer;">
            <fa-icon :icon="['fas', 'backward']" size="lg" style="padding-left: 10px;"/>
            <span> Cancel File</span>
        </div>

        <!--Cancel and Submit Buttons-->
        <template slot="modal-footer">
            <b-button size="sm" variant="secondary" @click="closeUpload()">Cancel</b-button>
            <b-button size="sm" variant="primary" @click="uploadSubmit()" :disabled="upload.dataImageData.length === 0">Submit</b-button>
        </template>

    </b-modal>

</template>

<script>
import axios from 'axios'
import risc from '@/services/risc';
import authStore from "@/store/authStore";

export default {
    name: "FileUpload2",

    props: {
        flag: Boolean,
        closeMethod: Function,
        submitMethod: Function,
    },

    data() {
        return {
            description: '',
            images: [],
            upload: {
                dataImageData: '',
                dataImageName: []
            }
        }
    },
    methods: {
        closeUpload() {
            this.description = ''
            this.upload.dataImageName = []
            this.upload.dataImageData = ''
            this.closeMethod();
        },
        handleFileUpload() {
            this.upload.dataImageData = this.$refs.files.files;
        },
        makeUrl(file){
            return(URL.createObjectURL(file))
        },
        uploadSubmit() {
            for (let i = 0; i < this.upload.dataImageData.length; i++) {
                let formData = new FormData()
                // let fileName = this.upload.dataImageData[i].name
                let fileName = Math.floor(Math.random() * 1000000000) + this.upload.dataImageData[i].name.slice(this.upload.dataImageData[i].name.lastIndexOf("."), this.upload.dataImageData[i].name.length);
                formData.append('FileData', this.upload.dataImageData[i])
                formData.append('BucketName', "intel")
                //formData.append('ContainerName', "Blurb")
                formData.append('FileId', fileName)
                axios.post(risc.get_risc_storage() + '/File/createfile', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + authStore.getters.getUserManager.getAccessToken()
                    }
                }).then(response => {
                    this.submitMethod(this.description, fileName);
                    this.description = ''
                });
            }
            this.closeMethod();
            this.upload.dataImageName = []
            this.upload.dataImageData = ''
        },
    }
}
</script>

<style scoped>

</style>