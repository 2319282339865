<template>
    <div>
        <b-modal class="modal-dark" :id="'print-doc-' + this.printType" no-close-on-backdrop scrollable title="Print Profile">
            <div v-if="open && options">
                <b-checkbox v-model="options.name"> NAME </b-checkbox>
                <b-checkbox v-model="options.image"> IMAGE </b-checkbox>
                <b-checkbox v-model="options.ribbon"> RIBBON </b-checkbox>
                <b-checkbox v-model="options.general.show"> GENERAL INFO </b-checkbox>
                <div style="padding-left: 24px">
                    <b-checkbox v-model="options.general.streetnames.show" :disabled="!options.general.show">
                        STREET NAMES
                    </b-checkbox>
                    <div style="padding-left: 24px" v-for="sName in options.general.streetnames.children">
                        <b-checkbox v-model="sName.show" :disabled="!options.general.show || !options.general.streetnames.show">
                            {{sName.name}}
                        </b-checkbox>
                    </div>
                    <b-checkbox v-model="options.general.employer" :disabled="!options.general.show">
                        EMPLOYER
                    </b-checkbox>
                    <b-checkbox v-model="options.general.dob" :disabled="!options.general.show">
                        DOB
                    </b-checkbox>
                    <b-checkbox v-model="options.general.race" :disabled="!options.general.show">
                        RACE
                    </b-checkbox>
                    <b-checkbox v-model="options.general.sex" :disabled="!options.general.show">
                        SEX
                    </b-checkbox>
                    <b-checkbox v-model="options.general.height" :disabled="!options.general.show">
                        HEIGHT
                    </b-checkbox>
                    <b-checkbox v-model="options.general.weight" :disabled="!options.general.show">
                        WEIGHT
                    </b-checkbox>
                    <b-checkbox v-model="options.general.ssn" :disabled="!options.general.show">
                        SSN
                    </b-checkbox>
                    <b-checkbox v-model="options.general.mid" :disabled="!options.general.show">
                        MID
                    </b-checkbox>
                    <b-checkbox v-model="options.general.sid" :disabled="!options.general.show">
                        SID
                    </b-checkbox>
                    <b-checkbox v-model="options.general.fbi" :disabled="!options.general.show">
                        FBI
                    </b-checkbox>

                    <b-checkbox v-model="options.general.ids.show" :disabled="!options.general.show">
                        IDs
                    </b-checkbox>
                    <div style="padding-left: 24px" v-for="id in options.general.ids.children">
                        <b-checkbox v-model="id.show" :disabled="!options.general.show || !options.general.ids.show">
                            {{id.name}}
                        </b-checkbox>
                    </div>

                    <b-checkbox v-model="options.general.activeAddresses.show" :disabled="!options.general.show">
                        ACTIVE ADDRESSES
                    </b-checkbox>
                    <div style="padding-left: 24px" v-for="address in options.general.activeAddresses.children">
                        <b-checkbox :disabled="!options.general.show || !options.general.activeAddresses.show"
                                    v-model="address.show" v-if="address.active">
                            {{address.name}}
                        </b-checkbox>
                    </div>

                    <b-checkbox v-model="options.general.alias.show" :disabled="!options.general.show">
                        ALIAS
                    </b-checkbox>
                    <div style="padding-left: 24px" v-for="alias in options.general.alias.children">
                        <b-checkbox v-model="alias.show" :disabled="!options.general.show || !options.general.alias.show">
                            {{alias.name}}
                        </b-checkbox>
                    </div>

                    <b-checkbox v-model="options.general.orgs.show" :disabled="!options.general.show">
                        ORGANIZATIONS
                    </b-checkbox>
                    <div style="padding-left: 24px" v-for="org in options.general.orgs.children">
                        <b-checkbox v-model="org.show" :disabled="!options.general.show || !options.general.orgs.show">
                            {{org.name}}
                        </b-checkbox>
                    </div>

                    <b-checkbox v-model="options.general.phone.show" :disabled="!options.general.show">
                        PHONE NUMBERS
                    </b-checkbox>
                    <div style="padding-left: 24px" v-for="phone in options.general.phone.children">
                        <b-checkbox v-model="phone.show" :disabled="!options.general.show || !options.general.phone.show">
                            {{phone.name}}
                        </b-checkbox>
                    </div>

                    <b-checkbox v-model="options.general.social.show" :disabled="!options.general.show">
                        SOCIAL MEDIA
                    </b-checkbox>
                    <div style="padding-left: 24px" v-for="social in options.general.social.children">
                        <b-checkbox v-model="social.show" :disabled="!options.general.show || !options.general.social.show">
                            {{social.name}}
                        </b-checkbox>
                    </div>

                </div>

                <b-checkbox v-model="options.otherInfo.show"> OTHER INFORMATION </b-checkbox>
                <div style="padding-left: 24px; padding-right: 24px;">
                    <span style="display: flex; justify-content: space-between">
                        <b-checkbox v-model="options.otherInfo.addresses.show" :disabled="!options.otherInfo.show" @change="options.images.addresses = options.otherInfo.addresses.show">
                            ADDRESSES
                        </b-checkbox>
                        <b-checkbox v-model="options.images.addresses" :disabled="!options.otherInfo.show">
                            IMAGES
                        </b-checkbox>
                    </span>
                    <div style="padding-left: 24px" v-for="address in options.otherInfo.addresses.children">
                        <b-checkbox v-model="address.show" :disabled="!options.otherInfo.addresses.show || !options.otherInfo.show">
                            {{address.name}}
                        </b-checkbox>
                    </div>
                    <span style="display: flex; justify-content: space-between">
                        <b-checkbox v-model="options.otherInfo.vehicles.show" :disabled="!options.otherInfo.show" @change="options.images.vehicles = options.otherInfo.vehicles.show">
                            VEHICLES
                        </b-checkbox>
                        <b-checkbox v-model="options.images.vehicles" :disabled="!options.otherInfo.show">
                            IMAGES
                        </b-checkbox>
                    </span>
                    <div style="padding-left: 24px" v-for="vehicle in options.otherInfo.vehicles.children">
                        <b-checkbox v-model="vehicle.show" :disabled="!options.otherInfo.vehicles.show || !options.otherInfo.show">
                            {{vehicle.name}}
                        </b-checkbox>
                    </div>

                    <b-checkbox v-model="options.otherInfo.felonies.show" :disabled="!options.otherInfo.show">
                        FELONIES
                    </b-checkbox>
                    <div style="padding-left: 24px" v-for="felony in options.otherInfo.felonies.children">
                        <b-checkbox v-model="felony.show" :disabled="!options.otherInfo.felonies.show || !options.otherInfo.show">
                            {{felony.name}}
                        </b-checkbox>
                    </div>

                    <b-checkbox v-model="options.otherInfo.criminalHistory.show" :disabled="!options.otherInfo.show">
                        CRIMINAL HISTORY
                    </b-checkbox>
                    <div style="padding-left: 24px" v-for="history in options.otherInfo.criminalHistory.children">
                        <b-checkbox v-model="history.show" :disabled="!options.otherInfo.criminalHistory.show || !options.otherInfo.show">
                            {{history.name}}
                        </b-checkbox>
                    </div>

                    <b-checkbox v-model="options.otherInfo.drugs.show" :disabled="!options.otherInfo.show">
                        DRUGS
                    </b-checkbox>
                    <div style="padding-left: 24px" v-for="drug in options.otherInfo.drugs.children">
                        <b-checkbox v-model="drug.show" :disabled="!options.otherInfo.drugs.show || !options.otherInfo.show">
                            {{drug.name}}
                        </b-checkbox>
                    </div>

                    <b-checkbox v-model="options.otherInfo.weapons.show" :disabled="!options.otherInfo.show">
                        WEAPONS
                    </b-checkbox>
                    <div style="padding-left: 24px" v-for="weapon in options.otherInfo.weapons.children">
                        <b-checkbox v-model="weapon.show" :disabled="!options.otherInfo.weapons.show || !options.otherInfo.show">
                            {{weapon.name}}
                        </b-checkbox>
                    </div>

                    <b-checkbox v-model="options.otherInfo.leReports.show" :disabled="!options.otherInfo.show">
                        LE-REPORTS
                    </b-checkbox>
                    <div style="padding-left: 24px" v-for="leReport in options.otherInfo.leReports.children">
                        <b-checkbox v-model="leReport.show" :disabled="!options.otherInfo.leReports.show || !options.otherInfo.show">
                            {{leReport.name}}
                        </b-checkbox>
                    </div>
                    <span style="display: flex; justify-content: space-between">
                        <b-checkbox v-model="options.otherInfo.markings.show" :disabled="!options.otherInfo.show" @change="options.images.markings = options.otherInfo.markings.show">
                            MARKINGS
                        </b-checkbox>
                        <b-checkbox v-model="options.images.markings" :disabled="!options.otherInfo.show">
                            IMAGES
                        </b-checkbox>
                    </span>
                    <div style="padding-left: 24px" v-for="marking in options.otherInfo.markings.children">
                        <b-checkbox v-model="marking.show" :disabled="!options.otherInfo.markings.show || !options.otherInfo.show">
                            {{marking.name}}
                        </b-checkbox>
                    </div>

                    <span style="display: flex; justify-content: space-between">
                        <b-checkbox v-model="options.otherInfo.linkedAssociates.show" :disabled="!options.otherInfo.show" @change="options.images.linkedAssociates = options.otherInfo.linkedAssociates.show">
                            LINKED ASSOCIATES
                        </b-checkbox>
                        <b-checkbox v-model="options.images.linkedAssociates" :disabled="!options.otherInfo.show">
                            IMAGES
                        </b-checkbox>
                    </span>
                    <div style="padding-left: 24px" v-for="associate in options.otherInfo.linkedAssociates.children">
                        <b-checkbox v-model="associate.show" :disabled="!options.otherInfo.linkedAssociates.show || !options.otherInfo.show">
                            {{associate.name}}
                        </b-checkbox>
                    </div>
                    <span style="display: flex; justify-content: space-between">
                        <b-checkbox v-model="options.otherInfo.associates.show" :disabled="!options.otherInfo.show" @change="options.images.otherAssociates = options.otherInfo.associates.show">
                            OTHER ASSOCIATES
                        </b-checkbox>
                        <b-checkbox v-model="options.images.otherAssociates" :disabled="!options.otherInfo.show">
                            IMAGES
                        </b-checkbox>
                    </span>
                    <div style="padding-left: 24px" v-for="associate in options.otherInfo.associates.children">
                        <b-checkbox v-model="associate.show" :disabled="!options.otherInfo.associates.show || !options.otherInfo.show">
                            {{associate.name}}
                        </b-checkbox>
                    </div>

                    <b-checkbox v-model="options.otherInfo.notes.show" :disabled="!options.otherInfo.show">
                        NOTES
                    </b-checkbox>
                    <div style="padding-left: 24px" v-for="note in options.otherInfo.notes.children">
                        <b-checkbox v-model="note.show" :disabled="!options.otherInfo.notes.show || !options.otherInfo.show">
                            {{note.name}}
                        </b-checkbox>
                    </div>
                </div>
            </div>

            <!--Cancel and Submit Buttons-->
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="close(printType)">Close</b-button>
                <b-button size="sm" variant="primary" :disabled="printing === true" @click="print(printType);">Print
                    <fa-icon v-if="printing === true" :icon="['fas', 'circle-notch']" spin/>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import {printSetupMixin} from "@/views/expansion_modules/risc/profile_management/printSetups/PrintSetupMixin";

export default {
    name: "WantedPrintSetup",
    mixins: [printSetupMixin],
    data: () => {
        return {
            printType: 1,
        }
    },
    watch: {
        open() {
            if (this.open) {
                if (this.updateOptions()) {
                    this.$bvModal.show('print-doc-' + this.printType);
                }
            }
        },

    },
    methods: {
        test() {
        }
    }

}
</script>

<style scoped>

</style>