
<template>
    <div>
        <b-modal class="modal-dark" :id="'print-doc-' + printType" no-close-on-backdrop scrollable title="Officer Safety Print">
            <div v-if="open && options && options.data">

                <!-- Upload Images -->
                <b-card :class="options.data.length === 0 ? 'center-upload' : ''" style="background-color: #070707" no-body>
                    <div v-if="options.data.length === 0"
                         class="w-100 d-flex flex-row justify-content-center align-items-center"
                         style="width: 100%; height: 100%">
                        <input :id="'Files-print-doc-' + printType" type="file" ref="files" @change="handleFileUpload()" multiple style="width: 97px"
                               accept="image/*"/>
                    </div>
                    <span v-else>
                            <fa-icon :icon="['fas', 'backward']" style="margin-left: 3px; cursor: pointer;" @click="options.data = []"/>
                            <span @click="options.data = []; options.dataNames = []">&nbsp;Go Back</span>
                        </span>
                    <div v-if="options.data.length > 0" style="overflow-x: auto; position: relative" id="card-scroll">
                        <div v-if="uploading !== ''" style="position: absolute; background-color: rgba(0,0,0,0.54); height: 100%; width: 100%; z-index: 900"
                             class="d-flex align-items-center justify-content-center">
                            <div class="d-flex align-items-center justify-content-center rounded" style="background-color: black; padding: 15px">
                                <fa-icon :icon="['fas', 'spinner']" spin size="2x" style="margin-right: 15px;"/>
                                <h1><b>{{ uploading }}...</b></h1>
                            </div>
                        </div>
                        <div class="card-grid" style="background-color: #131313">
                            <b-card v-for="(file, index) in options.data" :key="index" class="profile-card" no-body>
                                <div class="text-content">
                                    {{ options.data[index].data.name }}
                                </div>
                                <div class="image-parent">
                                    <img v-if="checkURL(options.data[index].data) === 1" class="image-resize"
                                         :src="options.data[index].image" alt="">
                                    <b-embed v-else-if="checkURL(options.data[index].data) === 2" class="image-resize"
                                             :src="options.data[index].image" allowfullscreen/>
                                    <div v-else class="d-flex w-100 h-100 justify-content-center align-items-center"
                                         style="background: #20a8d8">
                                        <fa-icon :icon="['fas', 'file']" size="lg"/>
                                    </div>
                                </div>
                                <div class="text-content">
                                    <b-input size="sm" placeholder="Description"
                                             style="border-top-left-radius: 0; border-top-right-radius: 0;"
                                             v-model="options.dataNames[index]"></b-input>
                                </div>
                            </b-card>
                        </div>
                    </div>
                    <div v-if="options.data.length" class="d-flex align-items-center justify-content-between w-100">
                        <div style="margin-left: 3px">{{options.data.length + ' Image' + (options.data.length > 1 ? 's' : '')}}</div>
                        <div class=" d-flex align-items-center justify-content-end">
                            Rows: <b-form-input v-model.number="options.rows" size="sm" :class="{'border-danger' : options.rows * options.cols < options.data.length}" style="margin: 0 10px; width: 50px"/>
                            Columns: <b-form-input v-model.number="options.cols" size="sm" :class="{'border-danger' : options.rows * options.cols < options.data.length}" style="margin-left: 10px; width: 50px"/>
                        </div>
                    </div>

                </b-card>

                <div style="margin-top: 10px">Page Title</div>
                <b-form-input v-model="options.pageTitle"/>

                <div style="margin-top: 10px">Notes</div>
                <b-form-textarea v-model="options.notes"/>

                <div style="margin-top: 10px">Case</div>
                <b-form-input v-model="options.case"/>

                <div style="margin-top: 10px">Event</div>
                <b-form-input v-model="options.event"/>

                <div style="margin-top: 10px">Name</div>
                <b-form-input v-model="options.name"/>

                <div style="margin-top: 10px">Information</div>
                <b-form-input v-model="options.info"/>

                <div style="margin-top: 10px">Unit</div>
                <b-form-input v-model="options.unit"/>

                <div style="margin-top: 10px">Detective/Officer</div>
                <b-form-input v-model="options.author"/>

                <div style="margin-top: 10px">Approved by Supervisor/Rank</div>
                <b-form-input v-model="options.approvedBy"/>

                <div style="margin-top: 10px">Phone</div>
                <masked-input placeholder="(999) 999-9999" type="tel" :guide="true" class="form-control"
                              v-model="options.phone" :mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]" />
            </div>


            <!--Cancel and Submit Buttons-->
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="close(printType)">Close</b-button>
                <b-button size="sm" variant="primary" :disabled="printing === true" @click="print(printType);">Print
                    <fa-icon v-if="printing === true" :icon="['fas', 'circle-notch']" spin/>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import MaskedInput from "vue-text-mask";
import PubSub from "pubsub-js";

export default {
    name: "OfficerSafetyPrintSetup",
    components: { MaskedInput },
    data: () => {
        return {
            pubsub: null,
            printing: false,
            test: [],
            uploadedDataNames: [],
            printType: 4,
            uploading: "",
            options: {}
        }
    },
    props: {
        profile: {type: Object},
        open: {type: Boolean},
    },
    created() {
        this.pubsub = PubSub.subscribe('riscPrintDone', () => {
            this.printing = false;
        });
    },
    beforeDestroy() {
        PubSub.unsubscribe(this.pubsub);
    },
    watch: {
        open() {
            if (this.open) {
                // this.$emit('input', this.updateOptions());
                if (this.updateOptions()) {
                    this.$bvModal.show('print-doc-' + this.printType);
                }
            }
        }
    },
    methods: {
        updateOptions() {
            this.options = Object.assign({}, {
                data: [],
                dataNames: [],
                rows: 0,
                cols: 0,
                pageTitle: "OFFICER SAFETY / MYOC",
                notes: "",
                case: "",
                event: "",
                name: "",
                info: "",
                unit: "",
                author: "",
                approvedBy: "",
                phone: "",
            })
            return true;
        },
        print(num) {
            this.printing = true;
            this.options.type = num;
            this.$emit('print', this.options);
        },
        close(num) {
            this.$bvModal.hide('print-doc-' + num)
            this.$emit('closed');
        },
        handleFileUpload() {
            this.options.rows = 1;
            this.options.cols = 1;
            for (let i = 1; i < this.$refs.files.files.length + 1; i++) {
                if (this.options.rows * this.options.cols < i) {
                    if (this.options.rows === this.options.cols) {
                        this.options.cols++
                    } else {
                        this.options.rows++
                    }
                }
            }
            this.options.data = Object.values(this.$refs.files.files).reduce((res, data) => {
                res.push({image: this.makeUrl(data), data: data})
                return res;
            }, []);
        },
        isWantedDisabled(data) {
            return this.options.wanted.otherObjects.length > 4 && !this.options.wanted.otherObjects.includes(data)
        },
        checkURL(file) {
            if (file.type.includes('image')) {
                return 1;
            } else if (file.type.includes('video')) {
                return 2;
            } else {
                return null;
            }
        },
        makeUrl(file) {
            return URL.createObjectURL(file);
        },
    }
}
</script>

<style scoped>

.card-grid {
    min-width: fit-content;
    display: flex;
    flex-flow: row nowrap;
    height: 311px;
    padding: 5px;
    background: #2f353a;
}

.card-grid::before, .card-grid::after {
    content: '';
    margin: auto;
}

.profile-card {
    background: #282828;
    height: calc(100% - 0px);
    width: 200px;
    display: flex;
    flex-direction: column;
    margin: 0 5px 5px 5px !important;
}

.text-content {
    text-align: center;
    font-weight: bold;
    width: 100%;
}

.text-content {
    text-align: center;
    font-weight: bold;
    width: 100%;
}

.image-parent {
    border: 1px solid #23282c;
    min-height: 0;
    flex-grow: 1;
    background: black;
    display: flex;
    align-Items: center;
    justify-content: center;
}

.image-resize {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.center-upload {
    height: 338px;
    display: flex;
    justify-content: center;
    align-items: center;
}

iframe {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
}
</style>