<template>
    <div  v-if="drugs !== undefined">

        <!--Add Drug History Notes-->
        <b-modal  class="modal-dark" scrollable title="Notes" v-model="addDrugNoteFlag" no-close-on-backdrop>
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <b-form-textarea v-model="drugNoteAdd"></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button @click="addDrugNoteFlag = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="putDrugNote()" :disabled="!drugNoteAdd || disableButtonFlag" size="sm" variant="primary">Submit</b-button>
            </template>
        </b-modal>

        <!--Edit Drug History Note-->
        <b-modal class="modal-dark" scrollable title="Edit Note" v-model="changeDrugNoteModal" no-close-on-backdrop  >
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <b-form-textarea v-model="noteText"></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button @click="changeDrugNoteModal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="editDrugHistoryNote()" size="sm" variant="primary" :disabled="disableButtonFlag">Submit</b-button>
            </template>
        </b-modal>

        <!--Delete Drug History Note-->
        <b-modal class="modal-dark" scrollable title="Remove Note" v-model="deleteObjects.modal" no-close-on-backdrop>
            <p>Are you sure you want to delete this {{deleteObjects.name}}?</p>
            <template slot="modal-footer">
                <b-button @click="deleteObjects.modal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="deleteDrugNote()" size="sm" variant="danger">Delete {{deleteObjects.name}}</b-button>
            </template>
        </b-modal>

        <!--Add Drug-->
        <b-modal class="modal-dark" scrollable title="Add Drug" v-model="addDrugFlag" no-close-on-backdrop>
            <b-input-group class="input-group-sm" prepend="Source" style="margin-bottom: 1px">
                <b-form-input
                    :class=" 'form-control is-' + (filledOut(drugAdd.source) ? 'valid':'invalid')"
                    v-model="drugAdd.source"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Description" style="margin-bottom: 1px">
                <b-form-input
                    :class=" 'form-control is-' + (filledOut(drugAdd.description) ? 'valid':'invalid')"
                    v-model="drugAdd.description"/>
            </b-input-group>
            <br>
            <b-form-group>
                <label>Other Notes</label>&nbsp;
                <fa-icon :icon="['fas', 'circle-plus']" @click="push(drugAdd.notes, {text: ''})" style="cursor: pointer;"/>
                <b-input-group :key="i" class="input-group-sm" v-for="(e,i) in drugAdd.notes">
                    <b-form-input v-model="drugAdd.notes[i].text"/>
                    <b-input-group-append>
                        <b-button @click="splice(i, drugAdd.notes)" variant="dark">
                            <fa-icon :icon="['fas', 'close']"/>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="addDrugFlag = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button :disabled="disableDrugsSubmit() || disableButtonFlag" @click="postDrugs()" size="sm" variant="primary">Submit
                </b-button>
            </template>
        </b-modal>

        <!--Main Body-->
        <b-card no-body
                style="height: fit-content; position: relative; z-index: 10; border-color: #0e0e0e; background-color:#0e0e0e"
                class="flex-grow-1 d-flex flex-column w-100">
            <div class="d-flex flex-row justify-content-between align-items-center" style="padding: 5px">
                <strong style="font-size: large" class="m-0 p-0">Drug History</strong>
                <div class="custom-control custom-switch">
                    NO
                    <b-form-checkbox style="display: inline;" v-model="localHasDrugHistory"
                                     @change="editDrugHistory" switch/>
                    YES
                </div>
            </div>
            <div class="d-flex flex-column" style="height: 100%">
                <div style="display: flex; align-items: center; width: 100%; height: 100%;"
                     v-if="drugNotes && drugNotes.length < 1">
                    <span style="text-align: center; width: 100%">
                        <span style="cursor: pointer;" @click="openModalAddDrugNote()">
                        <fa-icon :icon="['fas', 'circle-plus']" style="padding-top: 10px;"/>
                        Add Note
                        </span>
                    </span>
                </div>
                <div v-else style="overflow-y: auto; padding: 5px">
                    <div v-if="noteExpand === true">
                        <div>
                            <div v-for="(note, index) in drugNotes" :key="index">
                                <b-card no-body style="margin-bottom: 5px">
                                    <div class="d-flex flex-wrap-reverse" style="height: fit-content; padding-left:5px">
                                        <div style="margin-bottom: 0;overflow-y: auto">
                                            <strong>{{ (index + 1) + ") " }}</strong>{{ note.text }}
                                        </div>
                                        <span style="flex-grow: 1; margin: 3px" class="d-flex justify-content-end">
                                            <fa-icon :icon="['fas', 'pen-to-square']" style="margin: 2px; cursor: pointer;"
                                                     @click="changeDrugNoteText(note.text, note.noteId)"/>
                                            <fa-icon :icon="['fas', 'close']" style="margin: 2px; cursor: pointer;"
                                                     @click="openModalDeleteProfileObject(index, note.noteId)"/>
                                        </span>
                                    </div>
                                    <div class="text-muted text-uppercase font-weight-bold font-xs"
                                         style=" float: right; padding-left: 5px;">Created By: {{ note.createdBy }}
                                    </div>
                                </b-card>
                            </div>
                        </div>
                        <div style="text-align: center">
                            <span @click="openModalAddDrugNote()" style="margin: 0; cursor: pointer;">
                                <fa-icon :icon="['fas', 'circle-plus']" style="padding-top: 10px;"/>
                                &nbsp;Add Note
                            </span>
                        </div>
                        <p style="text-align: center; margin: 0; cursor: pointer;" @click="noteExpand = false">
                            <fa-icon :icon="['fas', 'angle-up']" style="text-align: center"/>
                        </p>
                    </div>
                    <div v-else style="text-align: center; cursor: pointer;" @click="noteExpand = true">
                        <fa-icon :icon="['fas', 'angle-down']"/>
                    </div>
                </div>
            </div>
        </b-card>

        <div style="display: flex; align-content: space-between; align-items: center; width: 100%; margin-bottom: 15px;">
            <span v-if="editable === true" class="d-flex align-items-center" style="cursor: pointer;" @click="openModalAddDrugs()">
                <fa-icon :icon="['fas', 'plus']" style="margin-right: 5px"/> Add Drug
            </span>
            <span style="margin-left: auto" @click="getImages = !getImages">
                <b-button size="sm">Get Images</b-button>
            </span>
        </div>
        <p v-if="drugs.length === 0" class="noAdded"><i><strong>No Drugs Added</strong></i></p>
        <div :key="index" lg="12" v-for="(drug, index) in drugs">
            <DrugsComponent :index="index"
                            :drugObject="drug"
                            :scope="scope"
                            :linkId="linkId"
                            :editable="true"
                            :getImages="getImages"
                            @deleteDrug="deleteDrug"/>
        </div>
    </div>
</template>

<script>
import DrugsComponent from "@/views/expansion_modules/risc/components/drugs_components/DrugsComponent";
import risc from "@/services/risc";

export default {
    name: "DrugsTabComponent",

    props: {
        drugs: {
            Array
        },
        linkId: {
            String
        },
        editable: {
            Boolean
        },
        scope: {
            String
        },
        drugNotes: {
            Array
        },
        hasDrugHistory: {
            Boolean
        },
    },

    components: {
        DrugsComponent,
    },

    created() {
        this.localHasDrugHistory = this.hasDrugHistory;
    },

    data() {
        return {
            getImages: false,
            drugAdd: {notes: []},
            addDrugFlag: false,
            drugNoteAdd: '',
            addDrugNoteFlag: false,
            localHasDrugHistory: null,
            disableButtonFlag: false,
            noteText: '',
            editDrugId: '',
            changeDrugNoteModal: false,
            modifyProfile: {},
            deleteObjects: {
                modal: false,
                name: 'Drug History',
                deleteText: '',
                id: '',
            },
            drugFields: {
                addDrugFlag: false,
                data: {},
            },
            profileEdit: null,
            notesNewToOld: false,
            noteExpand: false,
        }
    },

    methods: {
        editDrugHistory(){
            this.$emit("editDrugHistory", this.localHasDrugHistory);
        },
        changeDrugNoteText(text, id) {
            this.noteText = text;
            this.editDrugId = id;
            this.changeDrugNoteModal = true;
        },
        deleteDrug(drugId) {
            this.$emit("deleteDrug", drugId);
        },
        deleteDrugNote() {
            this.$emit("deleteDrugNote", this.deleteObjects.id)
            this.deleteObjects.modal = false;
        },
        disableDrugsSubmit() {
            return !this.filledOut(this.drugAdd.source) ||
                !this.filledOut(this.drugAdd.description);
        },
        editDrugHistoryNote() {
            this.$emit("editDrugNote", this.noteText, this.editDrugId)
            this.changeDrugNoteModal = false;
        },
        filledOut(i) {
            return !(i === null || i === undefined || i === '' || i === 0);
        },
        getProfiles() {
            risc.getOneProfile(this.linkId).then(profile => {
                this.modifyProfile = profile.data;
            })
        },
        openModalAddDrugNote() {
            this.drugNoteAdd = '';
            this.addDrugNoteFlag = true;
        },
        openModalAddDrugs() {
            this.drugAdd = {notes: []};
            this.addDrugFlag = true;
        },
        openModalDeleteProfileObject(index, id) {
            this.deleteObjects.index = index;
            this.deleteObjects.modal = true;
            this.deleteObjects.id = id;
        },
        push(field, item) {
            if (this.notesNewToOld === true) {
                field.unshift(JSON.parse(JSON.stringify(item)));
            } else {
                field.push(JSON.parse(JSON.stringify(item)));
            }
        },
        postDrugs() {
            this.disableButtonFlag = true;
                delete this.drugAdd.drugId;
                risc.postDrug(this.drugAdd).then(response => {
                    this.drugAdd.drugId = response.data.drugId;
                    risc.linkProfileDrug(this.linkId, this.drugAdd.drugId).then(() => {
                        this.drugs.push(response.data);
                        this.disableButtonFlag = false;
                    }).catch(err => {
                        this.disableButtonFlag = false;
                    })
                })
            this.addDrugFlag = false;
        },
        putDrugNote() {
            this.$emit("addDrugNote", this.drugNoteAdd)
            this.addDrugNoteFlag = false;
            this.drugNoteAdd = '';

        },
        splice(index, field, item) {
            field.splice(index, 1);
            if (item && field.length === 0) {
                this.push(field, item)
            }
        },
    }
}
</script>

<style scoped>

.noAdded {
    text-align: center;
    margin-bottom: 0;
    color: grey
}

</style>