<template>
    <b-modal v-model="flag" no-close-on-backdrop scrollable class="modal-dark">
        <template slot="modal-header">
            <h4 class="modal-title">Change Favorite File</h4>
        </template>
        <p v-if="!files || files.length === 0" style="text-align: center; margin-bottom: 0">
            <i>No Files To Display</i>
        </p>
        <div v-else>
            <b-card v-for="(file, index) in filesUpdated" :key="index" no-body header-tag="header"
                    bg-variant="dark">
                <div slot="header" class="d-flex w-100 justify-content-between">
                    <span>
                        <span v-if="file.description">{{file.description}}</span>
                        <span v-else>No Description</span>
                    </span>
                    <fa-icon v-if="file.arrayOrder === 0" :icon="['fas', 'star']" size="lg" style="float: right; cursor: pointer;"/>
                    <fa-icon v-else :icon="['fas', 'star']" size="lg" style="float: right; color: #5E696B; cursor: pointer;"
                       @click="favoriteFile(index)"/>
                </div>
                <div class="card-image">
                    <img width="100%" v-auth-image="getImage(file.storageFileName)" alt="">
                </div>
            </b-card>
        </div>
        <template slot="modal-footer">
            <b-button size="sm" variant="secondary" @click="closeMethod">Cancel</b-button>
            <b-button size="sm" variant="primary" @click="setFileFavorite()">Submit</b-button>
        </template>
    </b-modal>
</template>

<script>
import risc from "@/services/risc";

export default {
    name: "FileSelector2",
    props: {
        flag: { type: Boolean },
        files: { type: Array },
        submitMethod: { type: Function },
        closeMethod: { type: Function }
    },
    data() {
        return {
            filesUpdated: null
        };
    },
    watch: {
        files() {
            this.filesUpdated = this.files;
        }
    },
    methods: {
        favoriteFile(index) {
            let fav = this.filesUpdated[index];
            this.filesUpdated.splice(index, 1);
            this.filesUpdated.unshift(fav);
            this.filesUpdated.forEach((file, index) => {
                file.arrayOrder = index;
            });
        },
        getImage(storageId) {
            return risc.getImage(storageId);
        },
        setFileFavorite() {
            this.filesUpdated.forEach((file, index) => {
                file.arrayOrder = index;
            });
            this.submitMethod(this.filesUpdated);
            this.closeMethod();
        }
    }
}
</script>