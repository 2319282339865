<template>
    <div v-if="addresses !== null">

        <!-- Add Addresses-->
        <b-modal no-close-on-backdrop class="modal-dark" scrollable title="Add Address"
                 :size="!addressFields.add ? 'lg' : 'md'"
                 v-model="addressFields.addAddressFlag" v-if="addressFields.addAddressFlag" no-body>
            <span v-if="!addressFields.add">
                <b-form-radio-group v-model="addressFields.data">
                    <v-client-table style="width: 100%" class="dataTable"
                                    :columns="table.columns"
                                    :data="addressFields.allAddresses ? addressFields.allAddresses : []"
                                    :options="table.tOptions"
                                    :theme="table.theme" ref="dTable">
                        <b-form-radio slot="Select" slot-scope="props" :value="props.row"/>
                    </v-client-table>
                </b-form-radio-group>
            </span>
            <span v-else>
                <b-input-group class="input-group-sm" prepend="Address" style="margin-bottom: 1px">
                    <b-form-input ref="autocomplete"
                                  id="autocomplete"
                                  placeholder="Enter Address"
                                  type="text"
                                  v-model="addressInput"
                                  types=""
                                  autocomplete="on"
                                  v-uppercase/>
                    <b-dropdown :text="addressFields.data.active ? 'Active' : 'Inactive'"
                                size="sm" variant="dark">
                        <b-dropdown-item @click="addressFields.data.active = true">Active
                        </b-dropdown-item>
                        <b-dropdown-item @click="addressFields.data.active = false">Inactive
                        </b-dropdown-item>
                    </b-dropdown>
                </b-input-group>
                <b-input-group class="input-group-sm" prepend="Apartment/Unit" style="margin-bottom: 1px">
                    <b-form-input v-model="addressFields.data.address2" v-uppercase/>
                </b-input-group>
                <b-input-group class="input-group-sm" prepend="City">
                    <b-form-input v-model="addressFields.data.city" v-uppercase/>
                    <b-input-group-append class="input-group-sm">
                        <b-input-group-text>State</b-input-group-text>
                    </b-input-group-append>
                    <b-form-select :options="states" size="sm"
                                   v-model="addressFields.data.state"/>
                    <b-input-group-append class="input-group-sm">
                        <b-input-group-text>Zip</b-input-group-text>
                    </b-input-group-append>
                    <b-form-input type="number"
                                  :class=" 'form-control is-' + (filledOut(addressFields.data.zip) ? 'valid':'invalid')"
                                  v-model.number="addressFields.data.zip"/>
                </b-input-group>
                <b-input-group class="input-group-sm">
                </b-input-group>
                <b-form-group><br>
                    <label>Other Notes</label>&nbsp;
                    <fa-icon :icon="['fas', 'circle-plus']" @click="push(addressFields.data.notes, {text: '', scope: scope})"/>
                    <div v-if="addressFields.data.notes" :key="i"
                         v-for="(note,i) in refactorScope(addressFields.data.notes)">
                        <b-input-group v-if="!note.scope || note.scope === scope || note.scope === 'global'" class="input-group-sm">
                            <div class="col" style="padding: 0; width: 55px">
                                <b-form-input size="sm" v-model="addressFields.data.notes[i].text"></b-form-input>
                            </div>
                            <b-input-group-append>
                                <div class="col-auto" style="padding: 0 4px 0 2px;">
                                    <b-form-select v-model ="addressFields.data.notes[i].scope"
                                                   size="sm" plain
                                                   style="background-color: #191919; border-color: #191919; ">
                                        <option :value="scope">Scoped</option>
                                        <option :value="'global'">Global</option>
                                    </b-form-select>
                                </div>
                                <b-button @click="splice(i, addressFields.data.notes)"
                                          variant="dark">
                                    <fa-icon :icon="['fas', 'close']"/>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </b-form-group>
            </span>
            <template slot="modal-footer">
                <b-button @click="addressFields.addAddressFlag = false" size="sm"
                          variant="secondary">Cancel
                </b-button>
                <b-button @click="addressFields.add = !addressFields.add; addressInput = ''" size="sm"
                          variant="success"
                          v-if="!addressFields.data.addressId">
                    {{addressFields.add ? "Add Existing" : "Create New"}}
                </b-button>
                <b-button @click="postAddresses()" size="sm" variant="primary"
                          :disabled="(!filledOut(addressFields.data.zip) && addressFields.add) || (!addressFields.data.addressId && !addressFields.add) || disableButtonFlag">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <div style="display: flex; align-content: space-between; align-items: center; width: 100%; margin-bottom: 15px;">
            <span v-if="editable === true" class="d-flex align-items-center" style="cursor: pointer;" @click="openModalAddAddress()">
                <fa-icon :icon="['fas', 'plus']" style="margin-right: 5px"/> Add Address
            </span>
            <span style="margin-left: auto" @click="getImages = !getImages">
                <b-button size="sm">Get Images</b-button>
            </span>
        </div>
        <p v-if="addresses.length === 0" class="noAdded"><i><strong>No Addresses Added</strong></i></p>
        <div :key="index" lg="12" v-for="(address, index) in addresses">
            <AddressComponent :addressObject="address"
                              :title="title !== undefined ? title : address.profileName"
                              :editable="editable"
                              :scope="scope"
                              :linkId="linkId"
                              :linkType="linkType"
                              :color="color"
                              :getImages="getImages"
                              @deleteAddress="deleteAddress"/>
        </div>
    </div>
</template>

<script>

import AddressComponent from "@/views/expansion_modules/risc/components/address_components/AddressComponent";
import risc from "@/services/risc";
import Vue from "vue";
import * as VueGoogleMaps from 'vue2-google-maps';
import {gmapApi} from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDiNNTEEV7dTzuxZW1NCvR26RmHmOUhg2U',
        libraries: 'places'
    }
});

export default {
    name: "AddressTabComponent",
    components: {
        AddressComponent
    },
    props: {
        addresses: {
            type: Array
        },
        title: {
            type: String
        },
        editable: {
            type: Boolean
        },
        scope: {
            type: String
        },
        linkId: {
            type: String
        },
        linkType: {
            type: String
        },
        color: {
            type: String
        }
    },
    computed: {
        google: gmapApi
    },
    data() {
        return {
            disableButtonFlag: false,
            getImages: false,
            addressInput: '',
            addressFields: {
                addAddressFlag: false,
                allAddresses: [],
                data: {},
                add: false
            },
            table: {
                theme: 'bootstrap4',
                columns: ['Select', 'address1', 'address2', 'city', 'state', 'zip'],
                tOptions: {
                    filterable: ['address1', 'address2', 'city', 'state', 'zip'],
                    sortable: ['address1', 'address2', 'city', 'state', 'zip'],
                    orderBy: {column: 'file', ascending: true},
                    headings: {
                        address1: 'Address',
                        address2: 'Apartment/Unit'
                    },
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll'
                    },
                    perPage: 10,
                    skin: 'table table-striped table-hover',
                },
                useVuex: false,
                template: 'default',
                createCaseModal: false
            },
            states: [
                'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
                'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
                'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
                'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
                'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
            ]
        };
    },
    methods: {
        deleteAddress(address) {
            this.$emit('deleteAddress', address);
        },
        filledOut(i){
            return !(i === null || i === undefined || i === '' || i === 0);
        },
        async getAddressData(autocomplete) {
            this.$forceUpdate();
            const place = await autocomplete.getPlace();
            const ac = place.address_components;
            let streetNumber = '';
            let route = '';
            for (let i = 0; i < ac.length; i++) {
                let addressType = ac[i].types[0];
                if (addressType === 'street_number') {
                    streetNumber = ac[i].long_name.toUpperCase();
                }
                if (addressType === 'route') {
                    route = ac[i].long_name.toUpperCase();
                }
                if (addressType === 'intersection') {
                    route = ac[i].long_name.toUpperCase();
                }
                if (addressType === 'locality') {
                    this.addressFields.data.city = ac[i].long_name.toUpperCase();
                }
                if (addressType === 'administrative_area_level_1') {
                    this.addressFields.data.state = ac[i].short_name.toUpperCase();
                }
                if (addressType === 'postal_code') {
                    this.addressFields.data.zip = parseInt(ac[i].long_name);
                }
            }
            this.addressInput = streetNumber + ' ' + route;
            this.addressFields.data.address1 = streetNumber + ' ' + route;
        },
        openModalAddAddress() {
            this.addressFields.data = {
                active: true,
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: null,
                notes: [{text: '', scope: this.orgId}],
                addressId: '',
            };
            this.addressFields.add = false;
            risc.getAddresses().then(response => {
                if (!this.addresses || this.addresses.length === 0) {
                    this.addressFields.allAddresses = response.data
                    this.$forceUpdate()
                    this.addressFields.addAddressFlag = true;
                } else {
                    this.addressFields.allAddresses = []
                    response.data.forEach((address, index) => {
                        let add = true
                        this.addresses.forEach((oAddress, oIndex) => {
                            if (oAddress.addressId === address.addressId) {
                                add = false
                            }
                            if (oIndex + 1 === this.addresses.length) {
                                if (add) this.addressFields.allAddresses.push(address)
                                if (index + 1 === response.data.length) {
                                    this.$forceUpdate()
                                    this.addressFields.addAddressFlag = true;
                                }
                            }
                        })
                    })
                }
            })
        },
        postAddresses() {
            this.disableButtonFlag = true;
            if (this.linkType === 'Org') {
                if (!this.addressFields.add) {
                    risc.linkOrgAddress(this.linkId, this.addressFields.data.addressId).then(() => {
                        this.addresses.push(this.addressFields.data);
                        this.disableButtonFlag = false;
                    })
                } else if (this.addressFields.data.addressId === '') {
                    delete this.addressFields.data.addressId
                    risc.postAddress(this.addressFields.data).then(response => {
                        this.addressFields.data.addressId = response.data.addressId;
                        risc.linkOrgAddress(this.linkId, this.addressFields.data.addressId).then(() => {
                            this.addresses.push(response.data);
                            this.disableButtonFlag = false;
                        })
                    })
                }
            } else if (this.linkType === 'LeReport') {
                if(!this.addressFields.add){
                    risc.linkAddressLeReport(this.addressFields.data.addressId, this.linkId).then(() => {
                        this.addresses.push(this.addressFields.data);
                        this.disableButtonFlag = false;
                    })
                }else if (this.addressFields.data.addressId === '') {
                    delete this.addressFields.data.addressId
                    risc.postAddress(this.addressFields.data).then(response => {
                        this.addressFields.data.addressId = response.data.addressId
                        risc.linkAddressLeReport(this.addressFields.data.addressId, this.linkId).then(() => {
                            this.addresses.push(response.data);
                            this.disableButtonFlag = false;
                        })
                    })
                }
            } else if (this.linkType === 'Profile') {
                if(!this.addressFields.add){
                    risc.linkProfileAddress(this.linkId, this.addressFields.data.addressId).then(() => {
                        this.addresses.push(this.addressFields.data);
                        this.disableButtonFlag = false;
                    })
                }else if (this.addressFields.data.addressId === '') {
                    delete this.addressFields.data.addressId
                    risc.postAddress(this.addressFields.data).then(response => {
                        this.addressFields.data.addressId = response.data.addressId
                        risc.linkProfileAddress(this.linkId, this.addressFields.data.addressId).then(() => {
                            this.addresses.push(this.addressFields.data);
                            this.disableButtonFlag = false;
                        })
                    })
                }
            }
            this.addressFields.addAddressFlag = false;
        },
        push(field, item) {
            field.push(JSON.parse(JSON.stringify(item)));
        },
        refactorScope(arr) {
            return arr ? arr.map(_ => {_.scope = !_.text ? this.scope : _.scope; return _}): []
        },
        splice(index, field) {
            field.splice(index, 1);
        },
    },
    watch: {
        addressInput() {
            const autocomplete = new this.google.maps.places.Autocomplete(
                (document.getElementById('autocomplete')), {types: ['geocode']}
            );
            autocomplete.setFields(['address_components']);
            autocomplete.addListener('place_changed', () => {
                this.getAddressData(autocomplete);
            });
        }
    }
}
</script>

<style scoped>

.noAdded {
    text-align: center;
    margin-bottom: 0;
    color: grey
}

</style>