<template>
    <div v-if="ids !== undefined">

        <!-- Add ID -->
        <b-modal title="Add ID" class="modal-dark" scrollable v-model="addIdFlag" no-close-on-backdrop>
            <b-input-group class="input-group-sm" prepend="Type" style="margin-bottom: 1px">
                <b-form-select :options="idTypes"
                               :class=" 'form-control is-' + (filledOut(idAdd.type) ? 'valid':'invalid')"
                               v-model="idAdd.type"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Status" style="margin-bottom: 1px">
                <b-form-select :options="idStatus"
                               :class=" 'form-control is-' + (filledOut(idAdd.status) ? 'valid':'invalid')"
                               v-model="idAdd.status"/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Number" style="margin-bottom: 1px">
                <b-form-input
                    :class=" 'form-control is-' + (filledOut(idAdd.number) ? 'valid':'invalid')"
                    v-model="idAdd.number" v-uppercase/>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="State" style="margin-bottom: 1px">
                <b-form-select
                    :options="IdStates"
                    v-model="idAdd.state"/>
            </b-input-group>
            <br>
            <label class="labelForm">Notes</label>&nbsp;
            <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(idAdd.notes, {text: ''})"/>
            <b-input-group :key="i" class="input-group-sm" v-for="(e,i) in idAdd.notes">
                <b-form-input v-model="idAdd.notes[i].text"/>
                <b-input-group-append>
                    <b-button @click="splice(i, idAdd.notes)" variant="dark">
                        <fa-icon :icon="['fas', 'close']"/>
                    </b-button>
                </b-input-group-append>
            </b-input-group>
            <template slot="modal-footer">
                <b-button @click="addIdFlag = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button :disabled="disableIdSubmit() || disableButtonFlag" @click="addId()" size="sm" variant="primary">Submit
                </b-button>
            </template>
        </b-modal>

        <div v-if="editable === true" style="text-align: center; width: 100%; margin-bottom: 10px;">
            <span style="cursor: pointer;" @click="openAddModal()">
                <fa-icon :icon="['fas', 'plus']"/> Add ID
            </span>
        </div>
        <p v-if="ids.length === 0" class="noAdded"><i><strong>No IDs Added</strong></i></p>
        <div :key="index" v-for="(id, index) in ids">
            <IdComponent :id="id" :editable="editable" @updateId="updateId" @deleteId="deleteId"/>
        </div>
    </div>
</template>

<script>
import IdComponent from "@/views/expansion_modules/risc/components/id_components/IdComponent";
export default {
    name: "IdTabComponent",
    components: {IdComponent},
    props: {
        ids: { type: Array },
        editable: { type: Boolean }
    },
    data() {
        return {
            idAdd: {notes: []},
            addIdFlag: false,
            disableButtonFlag: false,
            idTypes: ['CONCEALED CARRY', 'DRIVERS LICENSE', 'FOID CARD', 'STATE ID', 'OTHER'],
            idStatus: ['ACTIVE', 'EXPIRED', 'REVOKED', 'SUSPENDED', 'VALID', 'N/A', 'OTHER'],
            IdStates:['AL','AK','AS','AZ','AR','CA','CO','CT','DE','DC','FM','FL','GA',
                'GU','HI','ID','IL','IN','IA','KS','KY','LA','ME','MH','MD','MA',
                'MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND',
                'MP','OH','OK','OR','PW','PA','PR','RI','SC','SD','TN','TX','UT',
                'VT','VI','VA','WA','WV','WI','WY',"Mexico","Canada","Other"]
        };
    },
    methods: {
        addId() {
            this.disableButtonFlag = true;
            this.$emit('addId', this.idAdd);
            this.addIdFlag = false;
        },
        deleteId(id) {
            this.$emit('deleteId', id);
        },
        disableIdSubmit() {
            return !this.filledOut(this.idAdd.type) ||
                !this.filledOut(this.idAdd.status) ||
                !this.filledOut(this.idAdd.number);

        },
        filledOut(i){
            return !(i === null || i === undefined || i === '' || i === 0);
        },
        openAddModal() {
            this.disableButtonFlag = false;
            this.idAdd = {notes: []};
            this.addIdFlag = true;
        },
        push(field, item) {
            field.push(JSON.parse(JSON.stringify(item)));
        },
        splice(index, field) {
            field.splice(index, 1);
        },
        updateId(id) {
            this.$emit('updateId', id);
        },

    }
}
</script>

<style scoped>

.noAdded {
    text-align: center;
    margin-bottom: 0;
    color: grey
}

</style>