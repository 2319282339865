
<template>
    <div v-show="false" id="printMe" style="position: relative">
        <div style="position: absolute; z-index: -999; width: 100%; overflow-y: hidden">
            <div style="height: 1000px; width: 100%"
                 class="d-flex justify-content-center align-items-end">
                <img src="../../../../../../public/img/Recruiting-Booklet-2018-1.png" style="max-height: 40%; max-width: 40%; opacity: 0.05" alt=""/>
            </div>
        </div>
        <div class="w-100 d-flex justify-content-between" style="padding: 7px 7px 0 7px">
            <div>
                <div style=" position: absolute; z-index: 999; height: 95px; width: 90px"
                     class="d-flex justify-content-center align-items-center">
                    <img src="../../../../../../public/img/Recruiting-Booklet-2018-1.png" style="max-height: 100%; max-width: 100%" alt=""/>
                </div>
                <div style="padding-left: 100px;font-size: 28px">
                    <div><b>Rockford Police Department</b></div>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-end" style="font-size: 12px">
                <div><b style="color: red">Not for media release</b></div>
                <div><b style="color: red">LAW ENFORCEMENT SENSITIVE</b></div>
            </div>
        </div>
        <div style="height: 5px"/>

        <!-- RIBBON -->
        <div style="text-align: center;">
            <div style="display: flex; align-items: center; height: 50px">
                <div class="bg-dark" style="flex: 1; height: 100%; display: flex; align-items: center">
                    <h1 style="margin: 0; width: 100%; text-align: center">
                        <b>{{ printOptions.pageTitle }}</b>
                    </h1>
                </div>
            </div>
        </div>
        <div style="height: 5px"/>

        <!-- NAME -->
        <h2 style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px; padding: 0">
            <b>
                {{ printOptions.case ? printOptions.case + " /" : "" }} {{ printOptions.event ? printOptions.event + " /" : "" }} {{ printOptions.name ? printOptions.name + " /" : "" }} {{ printOptions.info || "" }}
            </b>
        </h2>

        <!-- Images -->
        <div style="display: grid; page-break-inside: avoid; break-inside: avoid; margin-top: 10px">
            <h5 style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px">
                IMAGES
            </h5>
            <div v-if="printOptions.data.length > 0 && printOptions.rows !== undefined" style="margin: 5px; height: 825px; width: 100%">
                <div v-for="row in printOptions.rows" :style="'width: 100%; height: ' + Math.floor(100 / printOptions.rows) + '%'" class="d-flex">
                    <div v-for="col in printOptions.cols" :style="'width: ' + Math.floor(100 / printOptions.cols) + '%; height: 100%'">
                        <div v-if="printOptions.data && printOptions.data[((row - 1) * printOptions.cols) + col - 1]" class="w-100 h-100">
                            <img v-if="checkURL(printOptions.data[((row - 1) * printOptions.cols) + col - 1].data) === 1" style="object-fit: contain; width: 100%"
                                 :style="'height: ' + (printOptions.dataNames[((row - 1) * printOptions.cols) + col - 1] ? 80 : 100) + '%'"
                                 :src="printOptions.data[((row - 1) * printOptions.cols) + col - 1].image" alt="">
                            <div v-if="printOptions.dataNames[((row - 1) * printOptions.cols) + col - 1]" style="width: 100%; height: 20%; text-align: center">
                                {{printOptions.dataNames[((row - 1) * printOptions.cols) + col - 1]}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="printOptions.data.length <= 0" class="d-flex w-100 justify-content-center align-items-center" style="margin: 5px; height: 625px; color: grey">
                <span>No files available</span>
            </div>
        </div>

        <!-- Notes -->
        <div style="display: grid;  page-break-inside: avoid; break-inside: avoid; margin-top: 10px">
            <h5 style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px">
                NOTES
            </h5>
            <p v-if="printOptions.notes" style="margin: 5px; height: 400px; white-space: pre-line">
                {{printOptions.notes}}
            </p>
            <div v-else class="d-flex w-100 justify-content-center align-items-center" style="margin: 5px; height: 400px; color: grey">
                <span>No notes available</span>
            </div>
        </div>

        <!-- Footer -->
        <div style="padding: 10px; font-weight: bold">
            <div style="color: red; text-align: center; width: 100%">
                CONFIDENTIAL - LAW ENFORCEMENT SENSITIVE - DO NOT DISSEMINATE FURTHER WITHOUT PERMISSION FROM ORIGINATING AGENCY
            </div>
            <b-row sm="6" class="d-flex justify-content-between" style="width: 100%">
                <b-col sm="3">UNIT: {{printOptions.unit || "N/A"}}</b-col>
                <b-col sm="6" style="text-align: center">
                    <div>Produced: {{new Date().toJSON().slice(5,10).replace(/-/g,'/') + "/" + new Date().toJSON().slice(0,4) + " " + (printOptions.author || "N/A")}}</div>
                    <div>Approved By: {{printOptions.approvedBy || "N/A"}}</div>
                </b-col>
                <b-col sm="3" style="text-align: right">{{printOptions.phone || "N/A"}}</b-col>
            </b-row>
        </div>

    </div>
</template>

<script>
import * as moment from 'moment';
import risc from '@/services/risc';
import PubSub from "pubsub-js";

export default {
    name: "OfficerSafetyPrint",
    props: {
        printOptions: {type: Object},
        profile: {type: Object},
        alias: {type: String},
        dateOfBirth: {type: String},
        height: {type: String},
        name: {type: String},
        ribbons: {type: Array},
        streetName: {type: String},
        weight: {type: Number},
    },
    data: () => {
        return {
            pageDone: false,
            noDataText: "NO RECORDED DATA",
            centerText: "w-100 d-flex justify-content-center",
            printOptionsReady: false,
        }
    },
    mounted() {
        this.$nextTick(function () {
            this.pageDone = true;
        });
    },
    watch: {
        pageDone() {
            if (this.pageDone === true) {
                this.$emit('actuallyPrint');
            }
        }
    },
    methods: {
        checkURL(file) {
            if (file.type.includes('image')) {
                return 1;
            } else if (file.type.includes('video')) {
                return 2;
            } else {
                return null;
            }
        },
        makeUrl(file) {
            return URL.createObjectURL(file);
        },
        isShow(obj) {
            return obj.show && obj.children.some(_ => {return _.show});
        },
        dateToString(date) {
            if (date === '0001-01-01T00:00:00') {
                return '';
            } else {
                return (moment(date).format('L'));
            }
        },
        getImage(image) {
            return risc.getImage(image);
        },
        ribbonVariant(ribbon) {
            switch (ribbon) {
                case 'IN CUSTODY':
                    return 'success';
                case 'PAROLE/PROBATION':
                    return 'warning text-dark';
                case 'WANTED':
                    return 'danger';
                case 'OUT OF TOWN':
                    return 'warning text-dark';
                case 'DECEASED':
                    return 'dark';
                default:
                    return 'primary';
            }
        },
    },
};
</script>

<style scoped>
.centerText {
    width: 100%;
    justify-content: center;
    display: flex
}


</style>