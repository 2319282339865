<template>
    <div v-if="associatesObject !== undefined">

        <!--Link Profiles-->
        <b-modal class="modal-dark" no-close-on-backdrop scrollable size="lg"
                 title="Link Existing Profiles"
                 v-model="allProfilesModal">
            <v-client-table :columns="profiles.columns" :data="profiles.all" :options="profiles.tOptions"
                            :theme="profiles.theme"
                            class="dataTable" ref="dTable" style="width: 100%">
                <b-form-checkbox :value="props.row.profileId" slot="check" slot-scope="props"
                                 v-model="profiles.selected" @change="checkSelectAll"></b-form-checkbox>
                <div slot="h__check">
                    <b-form-checkbox
                        @change="profiles.selected = unmarkAndMarkAll(profiles.selected, profiles.allMarked)"
                        class="check-all" style="padding-left: 24px"
                        type="checkbox"
                        v-model='profiles.allMarked'></b-form-checkbox>
                </div>
            </v-client-table>
            <template slot="modal-footer">
                <b-button @click="allProfilesModal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="updateProfiles()" :disabled="submitting" size="sm" variant="primary">Submit
                    <fa-icon v-if="submitting === true" :icon="['fas', 'circle-notch']" spin/>
                </b-button>
            </template>
        </b-modal>

        <!--Delete Associate -->
        <b-modal class="modal-dark" scrollable title="Remove Associate" v-model="deleteObjects.modal" no-close-on-backdrop>
            <p>Are you sure you want to delete this {{deleteObjects.name}}?</p>
            <template slot="modal-footer">
                <b-button @click="deleteObjects.modal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="deleteAssociate()" size="sm" variant="danger">Delete {{deleteObjects.name}}
                </b-button>
            </template>
        </b-modal>

        <!--Unlink Associate-->
        <b-modal class="modal-dark" scrollable title="Remove Associate" v-model="unlinkProfileModal" no-close-on-backdrop>
            <p>Are you sure you want to remove this associate?</p>
            <template slot="modal-footer">
                <b-button @click="unlinkProfileModal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="unlinkProfile()" size="sm" variant="danger" :disabled="disableUnlinkFlag">Remove
                </b-button>
            </template>
        </b-modal>

        <!--Edit/Add Associates-->
        <b-modal :title="(editAdd === true ? 'New' : 'Edit') + ' Associate'" class="modal-dark"
                 scrollable v-model="editAddAssociateFlag" v-uppercase no-close-on-backdrop>
            <b-row>
                <b-col sm="4">
                    <b-form-group>
                        <label class="labelForm">First</label>
                        <b-form-input v-model="editAddAssociate.firstName" v-uppercase/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label class="labelForm">Last</label>
                        <b-form-input v-model="editAddAssociate.lastName" v-uppercase/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label class="labelForm">Middle</label>
                        <b-form-input v-model="editAddAssociate.middleName" v-uppercase/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label class="labelForm">Birth Month</label>
                        <b-form-select :options="arrayify(1,12, true)"
                                       v-model="editAddAssociate.birthMonth"/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label class="labelForm">Birth Day</label>
                        <b-form-select :options="arrayify(1,31, true)"
                                       v-model="editAddAssociate.birthDay"/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label class="labelForm">Birth Year</label>
                        <b-form-select :options="arrayify(1900,(new Date()).getFullYear(), false)"
                                       v-model="editAddAssociate.birthYear"/>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group>
                        <label class="labelForm">Relation</label>
                        <b-form-input v-model="editAddAssociate.relation" v-uppercase/>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group>
                        <label class="labelForm">Address</label>
                        <b-form-input v-model="editAddAssociate.shortAddress" v-uppercase/>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group>
                        <label class="labelForm">Phone Number</label>
                        <b-form-input
                            maxlength="14" @keypress="onlyNumber"
                            type="text" v-mask="'(###) ###-####'" placeholder="(___) ___-____"
                            v-model="editAddAssociate.phone"/>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group>
                        <label class="labelForm">Description</label>
                        <b-form-input v-model="editAddAssociate.description"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <label class="labelForm">Notes</label>&nbsp;
            <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(editAddAssociate.notes, {text: ''})"/>
            <b-input-group :key="i" v-for="(e,i) in editAddAssociate.notes">
                <b-form-input v-model="editAddAssociate.notes[i].text"/>
                <b-input-group-append>
                    <b-button @click="splice(i, editAddAssociate.notes)" variant="dark">
                        <fa-icon :icon="['fas', 'close']"/>
                    </b-button>
                </b-input-group-append>
            </b-input-group>
            <div v-if="editAdd === false" style="margin-top: 10px;">
                <br>
                <DocumentCardComponent :editable="true"
                                       :documents="editAddAssociate.documents ? editAddAssociate.documents : []"
                                       :editMethod="changeDocumentDesc"
                                       :deleteMethod="deleteEmbeddedDoc"
                                       :submitSelectorMethod="setFileFavorite"
                                       :submitUploadMethod="addAssociateDocument"/>
            </div>
            <template slot="modal-footer">
                <b-button @click="openDeleteModal()" size="sm"
                          style="float: left" v-if="editAdd === false"
                          variant="danger">Delete
                </b-button>
                <b-button @click="editAddAssociateFlag = false" size="sm" variant="secondary">Cancel
                </b-button>
                <b-button @click="submit()" size="sm" variant="primary" :disabled="disableButtonFlag">Submit</b-button>
            </template>
        </b-modal>

        <!--View Associates-->
        <b-modal v-model="viewAssociateFlag" title="Profile Associate Info" class="modal-dark" scrollable v-uppercase no-close-on-backdrop>
            <div>
                <div v-if="viewAssociate.shortAddress !== '' && viewAssociate.shortAddress !== null">
                    <strong style="font-size: 20px;">Address</strong>
                    <p style="margin-left: 8px; margin-top: 5px;">{{ viewAssociate.shortAddress }}</p>
                </div>
                <div v-else style="width: 100%; display: flex; align-items: center">
                    <span style="text-align: center; width: 100%; margin-bottom: 21px;">no address</span>
                </div>
            </div>
            <div>
                <div v-if="viewAssociate.phone !== '' && viewAssociate.phone !== null">
                    <strong style="font-size: 20px;">Phone Number</strong>
                    <p style="margin-left: 8px; margin-top: 5px;">{{ viewAssociate.phone }}</p>
                </div>
                <div v-else style="width: 100%; display: flex; align-items: center">
                    <span style="text-align: center; width: 100%; margin-bottom: 21px;">no phone number</span>
                </div>
            </div>
            <div>
                <div v-if="viewAssociate.description !== ''">
                    <strong style="font-size: 20px;">Description</strong>
                    <p style="margin-left: 8px; margin-top: 5px;">{{ viewAssociate.description }}</p>
                </div>
                <div v-else style="width: 100%; display: flex; align-items: center">
                    <span style="text-align: center; width: 100%; margin-bottom: 21px;">no description</span>
                </div>
            </div>
            <NotesCardComponent :notes="viewAssociate.notes"/>
            <div style="height: 16px;"></div>
            <DocumentCardComponent :editable="false" :documents="viewAssociate.documents"/>
            <div slot="modal-footer" style="background-image: linear-gradient(to bottom, #708090, blue);">
                <b-button @click="viewAssociateFlag = false" size="sm" variant="primary">Ok</b-button>
            </div>
        </b-modal>

        <!--Profile Associates Draggable-->
        <div style="display: flex; flex-wrap: wrap">
            <draggable v-if="associates.length > 0 && profilesTab === 'profile'" v-model="associates" @change="reorderProfileAssociates()" style="display: flex; flex-wrap: wrap">
                <div class="card" v-for="(associate, index) in associates"
                     :key="index"
                     style="width: 250px; height: 420px; margin: 10px; background-color: #0E0E0E; padding: 0;">
                    <span class="d-flex justify-content-between">
                        <strong style="text-align: center;">Relationship: {{associate.relation}}</strong>
                        <span>
                            <fa-icon :icon="['fas', 'info']" style="cursor: pointer;" @click="openViewModal(associate)"/>
                            <fa-icon v-if="editable === true" :icon="['fas', 'pen-to-square']"
                                     @click="openEditModal(associate)" style="padding: 0 5px 0 10px; cursor: pointer;"/>
                        </span>
                    </span>
                    <div class="image-parent" style="position: relative" v-if="isDocumentImage(associate.documents)">
                        <img v-auth-image="getFirstDocumentImage(associate.documents)" alt="" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%">
                        <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; backdrop-filter: blur(50px)"/>
                        <img v-auth-image="getFirstDocumentImage(associate.documents)" style="position: absolute"
                             class="img-center-ass" alt=""/>
                    </div>
                    <div class="image-parent" v-else>
                        <img v-auth-image="getFirstDocumentImage(associate.documents)"
                             class="img-center-ass" alt=""/>
                    </div>
                    <div style="text-align: center;">
                        <strong style="text-align: center">
                            {{nameify(associate)}}
                        </strong>
                    </div>
                    <div style="text-align: center;">
                        <strong style="text-align: center;">
                            <span v-if="associate.birthMonth">{{associate.birthMonth}}</span>
                            <span v-else>XX</span>
                            <span v-if="associate.birthDay">/{{associate.birthDay}}</span>
                            <span v-else>/XX</span>
                            <span v-if="associate.birthYear">/{{associate.birthYear}}</span>
                            <span v-else>/XXXX</span>
                        </strong>
                    </div>
                </div>
            </draggable>
            <div v-if="linkedAssociates.length > 0 && profilesTab === 'linked'" v-model="linkedAssociates" @change="reorderProfileAssociates()" style="display: flex; flex-wrap: wrap">
                <div class="card" v-for="(profile, index2) in linkedAssociates"
                 :key="index2 + 'profiles'"
                 style="width: 250px; height: 420px; margin: 10px; background-color: #0E0E0E; padding: 0;">
                    <span class="d-flex justify-content-between">
                        <strong>Relationship: Associate</strong>
                        <span>
                            <router-link class="non-link"
                                         :to="{ name: 'Profile Details', params: {id: profile.profileId}}"
                                         target="_blank">
                                <fa-icon :icon="['fas', 'up-right-from-square']"/>
                            </router-link>
                        <fa-icon :icon="['fas', 'close']" size="lg" @click="openUnlinkProfileModal(profile.profileId)"
                                 style="margin: 0 5px 0 8px; cursor: pointer;"/>
                        </span>
                    </span>
                    <router-link class="image-parent" target="_blank" style="position: relative" v-if="isDocumentImage(profile.images)"
                             :to="{ name: 'Profile Details', params: {id: profile.profileId}}">
                        <img v-auth-image="getFirstDocumentImage(profile.images)" alt="" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%">
                        <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; backdrop-filter: blur(50px)"/>
                        <img v-auth-image="getFirstDocumentImage(profile.images)" style="position: absolute"
                             class="img-center-ass" alt=""/>
                    </router-link>
                    <router-link class="image-parent" target="_blank" v-else
                                 :to="{ name: 'Profile Details', params: {id: profile.profileId}}">
                        <img v-auth-image="getFirstDocumentImage(profile.images)"
                             class="img-center-ass" alt=""/>
                    </router-link>
                    <div v-if="profile.ribbons && Array.isArray(profile.ribbons) && profile.ribbons.length > 0"
                         style="display: flex; align-items: center; height: 17px; width: 250px">
                        <div v-for="(r, i) in profile.ribbons" :key="i + 'r1'"
                             :class="'bg-' + ribbonVariant(r.ribbon)"
                             style="flex: 1; height: 100%; display: flex; align-items: center">
                            <p style="margin: 0; width: 100%; text-align: center; font-size: 12px">{{r.ribbon}}</p>
                        </div>
                    </div>
                    <div style="text-align: center;">
                        <strong style="text-align: center">
                            {{nameify(profile)}}
                        </strong>
                    </div>
                    <div style="text-align: center;">
                        <strong style="text-align: center;">
                            <span v-if="profile.birthMonth">{{profile.birthMonth}}</span>
                            <span v-else>XX</span>
                            <span v-if="profile.birthDay">/{{profile.birthDay}}</span>
                            <span v-else>/XX</span>
                            <span v-if="profile.birthYear">/{{profile.birthYear}}</span>
                            <span v-else>/XXXX</span>
                        </strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import risc from "@/services/risc";
import NotesCardComponent from "@/views/expansion_modules/risc/components/notes_components/NotesCardComponent";
import DocumentCardComponent from "@/views/expansion_modules/risc/components/documents_components/DocumentCardComponent";
import draggable from 'vuedraggable';

export default {
    name: "ProfileAssociateComponent",
    components: {DocumentCardComponent, NotesCardComponent, draggable},
    props: {
        associatesObject: Array,
        editable: Boolean,
        addAssociate: Boolean,
        linkAssociate: Boolean,
        linkId: String,
        linkedProfilesObject: Array,
        profilesTab: String,
    },
    data() {
        return {
            associates: [],
            linkedAssociates: [],
            viewAssociateFlag: false,
            disableButtonFlag: false,
            disableUnlinkFlag: false,
            unlinkProfileModal: false,
            unlinkProfileId: '',
            allProfilesModal: false,
            viewAssociate: {notes: []},
            editAddAssociateFlag: false,
            editAddAssociate: {},
            submitting: false,
            editAdd: false, // False for edit, True for add
            deleteObjects: {
                modal: false,
                name: "Associate",
                associate: null,
            },
            profiles: {
                linked: [],
                all: [],
                selected: [],
                allMarked: false,
                modal: false,
                theme: 'bootstrap4',
                columns: ['check', 'tableName', 'tableDOB'],
                tOptions: {
                    sortable: ['tableName', 'tableDOB'],
                    filterable: ['tableName', 'tableDOB'],
                    orderBy: {column: 'tableName', ascending: true},
                    headings: {tableName: 'Name', tableDOB: 'DOB',},
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {chunk: 5, edge: false, nav: 'scroll'},
                    perPage: 25,
                    skin: 'table table-striped table-hover',
                },
            }
        };
    },
    methods: {
        addAssociateDocument(description, fileId) {
            this.editAddAssociate.documents.push({
                description: description,
                storageFileName: fileId,
                arrayOrder: this.editAddAssociate.documents.length
            })
            let index = this.associates.findIndex(_ => _.associateId === this.editAddAssociate.associateId);
            this.associates[index] = this.editAddAssociate;
            this.$emit('updateAssociates', this.associates);
        },
        alphabetify(sortArray) {
             return sortArray.sort((cur, oth) => {
                let nCur = cur.lastName ? cur.lastName : "zzzzzz";
                let nOth = oth.lastName ? oth.lastName : "zzzzzz";
                return nCur.localeCompare(nOth) || (cur.firstName ? cur.firstName : "zzzzzz").localeCompare(oth.firstName ? oth.firstName : "zzzzzz")
            });
        },
        arrayify(start, end, addToEnd) {
            let result = [];
            for (let i = start; i < end + 1; i++) {
                if (addToEnd) {
                    if (i > 9) {
                        result.push({text: i.toString(), value: i})
                    } else if (i >= 0) {
                        result.push({text: "0" + i, value: i})
                    }
                } else {
                    if (i > 9) {
                        result.unshift({text: i.toString(), value: i})
                    } else if (i >= 0) {
                        result.unshift({text: "0" + i, value: i})
                    }
                }
            }
            return (result);
        },
        changeDocumentDesc(doc) {
            this.editAddAssociate.documents.forEach(document => {
                if (document.documentId === doc.documentId) {
                    document.description = doc.description
                }
            })
            let index = this.associates.findIndex(_ => _.associateId === this.editAddAssociate.associateId);
            this.associates[index] = this.editAddAssociate;
            this.$emit('updateAssociates', this.associates);
        },
        checkSelectAll() {
            this.profiles.allMarked = this.profiles.selected.length === this.profiles.all.length;
        },
        dateify(month, day, year) {
            let result = "";
            result += (!month ? 'XX' : (month < 10 ? "0" + month : month)) + '/';
            result += (!day ? 'XX' : (day < 10 ? "0" + day : day)) + '/';
            result += (!year ? 'XXXX' : year);
            return result;
        },
        deleteAssociate() {
            this.associates = this.associates.filter((associate) => {
                return associate.associateId !== this.deleteObjects.associate.associateId;
            });
            this.$emit('updateAssociates', this.associates);
            this.editAddAssociateFlag = false;
            this.deleteObjects.modal = false;
        },
        deleteEmbeddedDoc(doc) {
            this.editAddAssociate.documents = this.editAddAssociate.documents.filter((document) => {
                return document.documentId !== doc.documentId;
            });
            let index = this.associates.findIndex(_ => _.associateId === this.editAddAssociate.associateId);
            this.associates[index] = this.editAddAssociate;
            this.$emit('updateAssociates', this.associates);
        },
        getFirstDocumentImage(docArray) {
            if (docArray && docArray.length > 0) {
                let stId = 0;
                docArray.forEach(doc => {
                    if (doc.arrayOrder === 0) {
                        stId = doc.storageFileName;
                    }
                })
                if (stId !== 0) {
                    return risc.getImage(stId);
                } else {
                    return risc.getImage(docArray[0].storageFileName);
                }
            } else {
                return ('/img/NoPictureOnFileGrey.png');
            }
        },
        isDocumentImage(docArray) {
            return (docArray && docArray.length > 0)
        },
        getProfiles() {
            this.$emit('getProfiles');
        },
        nameify(profile) {
            if (profile.firstName && profile.lastName && profile.middleName) {
                return (profile.lastName + ', ' + profile.firstName + ' ' + profile.middleName.slice(0, 1))
            } else if (profile.firstName && profile.lastName) {
                return (profile.lastName + ', ' + profile.firstName)
            } else if (profile.firstName && profile.middleName) {
                return ('Unknown, ' + profile.firstName + ' ' + profile.middleName.slice(0, 1))
            } else if (profile.lastName && profile.middleName) {
                return (profile.lastName + ', Unknown ' + profile.middleName.slice(0, 1))
            } else if (profile.firstName) {
                return ('Unknown, ' + profile.firstName)
            } else if (profile.lastName) {
                return (profile.lastName + ', Unknown ')
            } else if (profile.middleName) {
                return ('Unknown, Unknown ' + profile.middleName.slice(0, 1))
            }
            return '';
        },
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        openAddModal() {
            this.disableButtonFlag = false;
            this.editAddAssociate = {notes: []};
            this.editAdd = true;
            this.editAddAssociateFlag = true;
        },
        openDeleteModal() {
            this.deleteObjects.associate = this.editAddAssociate;
            this.deleteObjects.modal = true;
        },
        openEditModal(associate) {
            this.disableButtonFlag = false;
            this.editAddAssociate = associate;
            this.editAdd = false;
            this.editAddAssociateFlag = true;
        },
        openModalAllProfiles() {
            risc.getProfiles().then(response => {
                this.profiles.selected = this.linkedProfilesObject.map(_ => {return _.profileId});
                this.profiles.all = response.data.map((profile) => {
                        return ({
                            ...JSON.parse(JSON.stringify(profile)),
                            tableDOB: this.dateify(profile.birthMonth, profile.birthDay, profile.birthYear),
                            tableName: this.nameify(profile)
                        })
                })
                this.profiles.all = this.profiles.all.filter(profile => {
                    return profile.profileId.toString() !== this.linkId
                })
                this.profiles.allMarked = this.profiles.selected.length === this.profiles.all.length;
                this.allProfilesModal = true;
            })
        },
        openUnlinkProfileModal(id) {
            this.unlinkProfileModal = true;
            this.unlinkProfileId = id;
        },
        openViewModal(associate) {
            this.viewAssociate = associate;
            this.viewAssociateFlag = true;
        },
        push(field, item) {
            field.push(JSON.parse(JSON.stringify(item)));
        },
        reorderProfileAssociates() {
            for (let x = 0; x < this.associates.length; x++) {
                this.associates[x].arrayOrder = x
            }
            this.$emit('updateAssociates', this.associates);
        },
        ribbonVariant(ribbon) {
            switch (ribbon) {
                case 'IN CUSTODY':
                    return 'success'
                case 'PAROLE/PROBATION':
                    return 'warning text-dark'
                case 'WANTED':
                    return 'danger'
                case 'OUT OF TOWN':
                    return 'warning text-dark'
                case 'DECEASED':
                    return 'dark';
                default:
                    return 'primary';
            }
        },
        setFileFavorite(files) {
            this.editAddAssociate.documents = files
            let index = this.associates.findIndex(_ => _.associateId === this.editAddAssociate.associateId);
            this.associates[index] = this.editAddAssociate;
            this.$emit('updateAssociates', this.associates);
        },
        splice(index, field) {
            field.splice(index, 1);
        },
        submit() {
            this.disableButtonFlag = true;
            if (this.editAdd === true) {
                this.associates.push(this.editAddAssociate);
                this.$emit('updateAssociates', this.associates);
            } else {
                let index = this.associates.findIndex(_ => _.associateId === this.editAddAssociate.associateId);
                this.associates[index] = this.editAddAssociate;
                this.$emit('updateAssociates', this.associates);
            }
            this.editAddAssociateFlag = false;
        },
        unlinkProfile() {
            this.disableUnlinkFlag = true;
            risc.unlinkProfile(this.linkId, this.unlinkProfileId).then(() => {
                this.getProfiles();
                this.disableUnlinkFlag = false;
                this.unlinkProfileModal = false;
            }).catch(() => {
                this.disableUnlinkFlag = false;
                this.unlinkProfileModal = false;
            })
        },
        unmarkAndMarkAll(selected, allMarked) {
            if (allMarked === true) {
                let temp = [];
                if (this.$refs.dTable.allFilteredData) {
                    this.$refs.dTable.allFilteredData.forEach(function (item) {
                        temp.push(item.profileId);
                    });
                }
                allMarked = false;
                return (temp)
            } else {
                allMarked = true;
                return ([]);
            }
        },
        async updateProfiles() {
            this.submitting = true;
            let profilesToAdd = this.profiles.selected.filter(_ => !this.linkedProfilesObject.some(profile => profile.profileId === _));
            let profilesToRemove = this.linkedProfilesObject.filter(_ => !this.profiles.selected.some(profile => profile === _.profileId));
            for (let _ of profilesToAdd) {
                await risc.linkProfile(this.linkId, _).then(() => {
                }).catch(e => {
                    console.error("link fail", e);
                });
            }
            for (let _ of profilesToRemove) {
                await risc.unlinkProfile(this.linkId, _.profileId).then(() => {
                }).catch(e => {
                    console.error("unlink fail", e);
                });
            }
            await this.getProfiles();
            this.submitting = false;
            this.allProfilesModal = false;
        },
    },
    watch: {
        associatesObject() {
            this.associates = this.associatesObject;
        },
        async linkedProfilesObject() {
            let tempProfileObjects = [...this.linkedProfilesObject];
            this.linkedAssociates = await this.alphabetify(tempProfileObjects);
            this.linkedAssociates.forEach((profile, index) => {
                this.profiles.selected.push(profile.profileId);
                risc.getOneProfile(profile.profileId).then(response => {
                    this.linkedAssociates[index] = Object.assign(this.linkedAssociates[index], response.data);
                });
            });
        },
        addAssociate() {
            this.openAddModal();
        },
        linkAssociate() {
            this.openModalAllProfiles();
        },
    },
}
</script>

<style scoped>

</style>