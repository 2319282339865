<template>
    <div v-if="phones !== undefined">

        <!--Add Phone Numbers-->
        <b-modal title="Add Phone Number"
                 class="modal-dark" no-close-on-backdrop
                 scrollable v-model="addPhoneFlag">
            <b-input-group class="input-group-sm" prepend="Number" style="margin-bottom: 1px">
                <input maxlength="14" @keypress="onlyNumber"
                       type="text" v-mask="'(###) ###-####'" placeholder="(___) ___-____"
                       :class=" 'form-control is-' + (filledOut(phoneAdd.number) ? 'valid' : 'invalid')"
                       v-model="phoneAdd.number"/>
                <b-dropdown :text="phoneAdd.active ? 'Active' : 'Inactive'" size="sm"
                            variant="dark">
                    <b-dropdown-item @click="phoneAdd.active = true">Active</b-dropdown-item>
                    <b-dropdown-item @click="phoneAdd.active = false">Inactive</b-dropdown-item>
                </b-dropdown>
            </b-input-group>
            <b-input-group class="input-group-sm" prepend="Note" style="margin-bottom: 1px">
                <b-form-input
                    v-model="phoneAdd.notes"/>
            </b-input-group>

            <template slot="modal-footer">
                <b-button @click="addPhoneFlag = false" size="sm" variant="secondary">Cancel
                </b-button>
                <b-button :disabled="disablePhoneSubmit() || disableButtonFlag" @click="addPhone()" size="sm" variant="primary">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <div v-if="editable === true" style="text-align: center; width: 100%; margin-bottom: 10px;">
            <span style="cursor: pointer;" @click="openAddPhone()">
                <fa-icon :icon="['fas', 'plus']"/> Add Phone Number
            </span>
        </div>
        <p v-if="phones.length === 0" class="noAdded"><i><strong>No Phone Numbers Added</strong></i></p>
        <div v-for="(phone,i) in phones">
            <PhoneNumberProfileComponent :phone="phone" :editable="editable" @updatePhone="updatePhone" @deletePhone="deletePhone"/>
        </div>
    </div>
</template>

<script>

import PhoneNumberProfileComponent from "@/views/expansion_modules/risc/components/phonenumberprofile_components/PhoneNumberProfileComponent";

export default {
    name: "PhoneNumberProfileTabComponent",
    components: {
        PhoneNumberProfileComponent
    },
    props: {
        phones: { type: Array },
        editable: { type: Boolean }
    },
    data() {
        return {
            addPhoneFlag: false,
            phoneAdd: {active: true},
            disableButtonFlag: false,
        };
    },
    methods: {
        addPhone() {
            this.disableButtonFlag = true;
            this.$emit('addPhone', this.phoneAdd);
            this.addPhoneFlag = false;
        },
        deletePhone(phone) {
            this.$emit('deletePhone', phone);
        },
        disablePhoneSubmit() {
            return !this.filledOut(this.phoneAdd.number);
        },
        filledOut(i) {
            return !(i === null || i === undefined || i === '' || i === 0);
        },
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        openAddPhone() {
            this.phoneAdd = {active: true};
            this.addPhoneFlag = true;
            this.disableButtonFlag = false;
        },
        updatePhone(phone) {
            this.$emit('updatePhone', phone);
        },

    }
}
</script>

<style scoped>

.noAdded {
    text-align: center;
    margin-bottom: 0;
    color: grey
}

</style>