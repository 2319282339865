<template>
    <div v-if="documents !== null">

        <!--File Selector-->
        <FileSelector2 v-if="editable" :flag="fileSelectorFlag" :files="documents" :submitMethod="submitSelectorMethod" :closeMethod="closeSelector"/>

        <!--Add Document Modal-->
        <FileUpload2 v-if="editable" :flag="fileUploadFlag" :closeMethod="closeUpload" :submitMethod="submitUploadMethod"/>

        <div style="display: flex; align-content: space-between; align-items: center;">
            <h4 style="margin-bottom: 5px; font-weight: bold; margin-right: 5px">Documents/Media</h4>
            <b-badge v-if="editable !== null && editable === true" variant="success" style="height: fit-content; margin-bottom: 5px; cursor: pointer;"
                     @click="openUpload">
                <fa-icon :icon="['fas', 'plus']"/>Add
            </b-badge>
            <div style="margin-left: auto;">
                <fa-icon v-if="documents && documents.length > 1 && editable === true"
                         :icon="['fas', 'right-left']"
                         @click="openSelector"
                         style="flex-grow: 1; margin-bottom: 5px; width: fit-content;"/>
            </div>
        </div>
        <div style="padding-left: 10px">
            <DocumentTableComponent :editable="editable" :documents="documents" :editMethod="editMethod" :deleteMethod="deleteMethod"/>
        </div>
    </div>
</template>

<script>

import DocumentTableComponent from "@/views/expansion_modules/risc/components/documents_components/DocumentTableComponent";
import FileSelector2 from "@/views/expansion_modules/risc/components/utility_components/FileSelector2.0";
import FileUpload2 from "@/views/expansion_modules/risc/components/utility_components/FileUpload2.0";

export default {
    name: "DocumentCardComponent",
    components: {
        DocumentTableComponent,
        FileSelector2,
        FileUpload2
    },
    props: {
        editable: { type: Boolean },
        documents: { type: Array },
        editMethod: { type: Function },
        deleteMethod: { type: Function },
        submitSelectorMethod: { type: Function },
        submitUploadMethod: { type: Function }
    },
    data() {
        return {
            fileSelectorFlag: false,
            fileUploadFlag: false
        };
    },
    methods: {
        closeSelector() {
            this.fileSelectorFlag = false;
        },
        closeUpload() {
            this.fileUploadFlag = false;
        },
        openSelector() {
            this.fileSelectorFlag = true;
        },
        openUpload() {
            this.fileUploadFlag = true;
        },
    }
}
</script>

<style scoped>

</style>