<template>

    <b-modal ok-only no-close-on-backdrop v-model="flag" class="modal-dark" size="lg">
        <template slot="modal-header">
            <h4 class="modal-title">Image Viewer</h4>
        </template>
        <b-card style="padding-left: 0; overflow: hidden; position: relative;" class="align-center" no-body>
            <b-card-img style="object-fit: contain" v-auth-image="getImage(fileId)"/>
        </b-card>
        <template slot="modal-footer">
            <b-button size="sm" variant="secondary" @click="closeMethod">Close</b-button>
        </template>
    </b-modal>

</template>

<script>
import risc from '@/services/risc.js';

export default {
    name: "FileViewer2",
    props: {
        flag: {type: Boolean},
        fileId: { type: String },
        closeMethod: { type: Function }
    },
    methods: {
        getImage(storageId) {
            return risc.getImage(storageId);
        }
    }
}
</script>

<style>

</style>